<script>
import BaseOffer from './BaseOffer.vue'
import OrderExcludeOffer from './ExcludeOfferModal.vue'

export default {
  name: 'BaseOfferView',
  extends: BaseOffer,
  data () {
    return {
      bidsLimitTo: false,
      criteriaOpeningDate: false
    }
  },
  computed: {
    canExclude: function () {
      return (this.order.procedureType === 'tste' || this.order.type === 'nh') && this.offer.stage !== 'excluded'
    }
  },
  methods: {
    taxInclusion (criterion) {
      if (criterion.type === 'price') {
        return criterion.taxInclusion ? 's DPH' : 'bez DPH'
      } else {
        return ''
      }
    },
    excludeModal: function () {
      var vm = this
      this.$emit('vuedals:new', {
        name: 'exclude-offer',
        escapable: true,
        title: 'Vylúčiť ponuku',
        props: {
          offer: this.offer
        },
        component: OrderExcludeOffer,
        onClose: function (data) {
          if (data && data.stage === 'excluded') {
            vm.offer.stage = 'excluded'
            vm.offer.decision = data.decision
          }
        }
      })
    },
    prepareCriteria () {
      for (let proposal of this.offer.proposals) {
        for (let criterion of this.order.criteria) {
          if (criterion._id === proposal.criterion && proposal.value) {
            criterion.unit = criterion.type === 'price' ? 'EUR' : criterion.unit
            this.criteria.push(Object.assign({}, criterion, { value: proposal.value }))
          }
        }
      }
      this.priceCriterion = this.findPriceCriterion()
      // Pre pripad, ze zakazka bude vytvorena bez hodnotiacich kriterii. Ano aj take sa objavili ....
      if (this.priceCriterion) {
        this.decimalCount = this.priceCriterion.decimalCount
      }
    }
  }
}
</script>
