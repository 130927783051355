import { ReactiveModel } from 'vue-rawmodel'
import { TenderiaHttp } from '@/services/TenderiaHttp.js'

export default class User extends ReactiveModel {
  constructor (data = {}) {
    super(data)
    let oldEmailVal = ''
    this.defineValidator('uniqeEmail', async (value) => {
      let unique = true
      if (value !== oldEmailVal) {
        oldEmailVal = value
        await TenderiaHttp.post('/user/exists', { user: { email: value } }).then(() => {
          if (data.mode === 'invite') {
            data.mode = 'create'
            this.populate(data)
          }
          unique = true
        }).catch(() => {
          if (data.mode !== 'registration') {
            data.mode = 'invite'
            this.populate(data)
            unique = true
          } else {
            unique = false
          }
        })
      }
      return unique
    })

    this.defineField('firstname', {
      type: 'String',
      emptyValue: '',
      defaultValue: '',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné',
          condition () {
            return this.owner.mode !== 'invite'
          }
        }
      ]
    })

    this.defineField('lastname', {
      type: 'String',
      emptyValue: '',
      defaultValue: '',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné',
          condition () {
            return this.owner.mode !== 'invite'
          }
        }
      ]
    })

    this.defineField('email', {
      type: 'String',
      emptyValue: '',
      defaultValue: '',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'stringEmail',
          message: 'Email musí byť uvedený v tvare  napr. meno@domena.sk'
        },
        {
          validator: 'uniqeEmail',
          message: 'Používateľ s týmto emailom už v systéme existuje.',
          condition () {
            return !!this.value && this.owner.mode !== 'edit' && this.owner.mode !== 'profile'
          }
        }
      ]
    })

    this.defineField('roles', {
      type: ['String'],
      emptyValue: [],
      defaultValue: [],
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné',
          condition () {
            return this.owner.mode !== 'registration' && this.owner.mode !== 'profile'
          }
        }
      ]
    })

    this.defineField('phone', {
      type: 'String',
      emptyValue: '',
      defaultValue: '',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné',
          condition () {
            return this.owner.mode !== 'invite'
          }
        },
        {
          validator: 'stringMatch',
          regexp: /^(\+(?:[0-9] ?){6,14}[0-9])$/,
          message: 'Telefónne čislo musí byť uvedené v tvare napr. +421123456789',
          condition () {
            return this.owner.mode !== 'invite'
          }
        }
      ]
    })

    this.defineField('title1', {
      type: 'String',
      emptyValue: '',
      defaultValue: ''
    })

    this.defineField('title2', {
      type: 'String',
      emptyValue: '',
      defaultValue: ''
    })

    this.defineField('type', {
      type: 'String',
      emptyValue: '',
      defaultValue: 'client'
    })

    this.defineField('mode', {
      type: 'String',
      emptyValue: '',
      defaultValue: 'create'
    })

    this.populate(data)
    this.commit()
  }
}
