<template>
  <div class="container-fluid">
    <div>
      <div v-if="stats" key="stats" class="card stats-grid grid-border scrollable">
        <statistic-info icon="icon-edit-sheet" v-bind="stats.contracts" :format="$format"/>
        <statistic-info icon="icon-win" v-bind="stats.finalValueSum"  :format="$format"/>
        <statistic-info icon="icon-line-graph" v-bind="stats.savingAvg"  :format="$format"/>
        <statistic-info icon="icon-calendar" v-bind="stats.durationAvg"  :format="$format"/>
      </div>
      <div class="grid-tindex">
        <div class="card chart-card val-card primary">
          <div class="card-heading"><h4 class="card-title">Najvýznamnejší dodávateľ</h4></div>
          <div v-if="topContractor" class="p-3 my-auto">
            <strong v-text="topContractor.contractor.businessName"/>
            <span>Objem zmlúv v hodnote: {{ $format.vatCurrency(topContractor.finalValueSum, 'EUR', true) }}</span>
          </div>
        </div>
        <div class="card chart-card val-card green">
          <div class="card-heading"><h4 class="card-title">Najčastejší uchádzač</h4></div>
          <div v-if="topApplicant" class="p-3 my-auto">
            <strong v-text="topApplicant.contractor.businessName"/>
            <span>Počet výskytov: {{ topApplicant.contracts }}</span>
          </div>
        </div>
        <div class="card chart-card val-card red">
          <div class="card-heading"><h4 class="card-title">Top zákazka podľa objemu</h4></div>
          <div v-if="topContract" class="col p-3 my-auto">
            <span v-text="topContract.title"/>
            <span>Hodnota zákazky: {{ $format.vatCurrency(topContract.finalValueAmount, topContract.finalValueCurrency, topContract.finalValueVatIncluded) }}</span>
          </div>
        </div>
        <div class="card chart-card val-card orange">
          <div class="card-heading"><h4 class="card-title">Priemer z referencií</h4></div>
          <div v-if="referencesRating" class="rating my-auto p-2">
            <v-badge color="green" glow>
              {{ $format.num(referencesRating.avgRating) }} %
            </v-badge>
            <v-star-rating
              :min="referencesRating.minRating"
              :max="referencesRating.maxRating"
              :value="referencesRating.avgRating"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card chart-card">
            <div class="card-heading">
              <h4 class="card-title">Vývoj úspor</h4>
            </div>
            <multi-line-chart-echart
              v-if="cpvChartsData"
              :series="cpvChartsSeries"
              :data="cpvChartsData.savings"
              :formatLabel="$format.perc"
              :yFormat="$format.perc"
              :showLastNumValues="5"
            />
            <div v-else class="card-block">
              <span>Momentálne neobsahuje žiadne záznamy</span>
            </div>
          </div>
          <div class="card chart-card">
            <div class="card-heading">
              <h4 class="card-title">Počet vyhlásených zákaziek</h4>
            </div>
            <multi-line-chart-echart
              v-if="cpvChartsData"
              :series="cpvChartsSeries"
              :data="cpvChartsData.contracts"
              :showLastNumValues="5"
            />
            <div v-else class="card-block">
              <span>Momentálne neobsahuje žiadne záznamy</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card chart-card">
            <div class="card-heading">
              <h4 class="card-title">Hodnota vyhlásených zákaziek</h4>
            </div>
            <multi-line-chart-echart
              v-if="cpvChartsData"
              :series="cpvChartsSeries"
              :data="cpvChartsData.estimatedValues"
              :formatLabel="$format.shortCurrencyFixed2"
              :yFormat="$format.shortCurrency"
              :showLastNumValues="5"
            />
            <div v-else class="card-block">
              <span>Momentálne neobsahuje žiadne záznamy</span>
            </div>
          </div>
          <div class="card chart-card">
            <div class="card-heading">
              <h4 class="card-title">Priemerný počet uchádzačov</h4>
            </div>
            <multi-line-chart-echart
              v-if="cpvChartsData"
              :series="cpvChartsSeries"
              :data="cpvChartsData.applicants"
              :formatLabel="$format.num"
              :showLastNumValues="5"
            />
            <div v-else class="card-block">
              <span>Momentálne neobsahuje žiadne záznamy</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-heading">
          <h4 class="card-title">Moje zákazky</h4>
        </div>
        <v-table
          :items="contracts"
          :columns="contractsColumns"
          :sort.sync="tableSort"
          item-key="id"
          class="light"
        >
          <template v-slot:cell(name)="{ item }">
            <order-link :user-type="$globals.user.permissions && $globals.user.permissions.includes('orders/order/create/1') ? 'admin' : 'user'" :order="item" />
          </template>
          <template v-slot:cell(journalAnnouncementDate)="{ item }">
            {{ item.journalAnnouncementDate | formatDateTime }}
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="row no-gutters align-items-baseline">
              <order-state :state="item.state" display="text"></order-state>
              <order-actions-menu :order="item" />
            </div>
          </template>
        </v-table>
        <div class="card-footer">
          <div class="row justify-content-center">
            <router-link
              :to="`/app/my-orders/${showOrdersAsRole}/all`"
              class="btn btn-tenderia-blue"
            >
              Všetky moje zákazky
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import uniq from 'lodash/uniq'
import OrderState from './OrderState.vue'
import OrderLink from './order/OrderLink.vue'
import VTable from '../components/ui/Table.vue'
import StatisticInfo, { statsData } from '@/components/ui/StatisticInfo.vue'
import VStarRating from '@/components/ui/StarRating.vue'
import VBadge from '@/components/ui/Badge.vue'
import MultiLineChartEchart from '@/components/ui/MultiLineChartEchart.vue'
import { permissions } from '@/router/permissions.js'
import { bfs } from '@/services/TransparexService'
import OrderActionsMenu from './OrderActionsMenu.vue'

export default {
  name: 'Dashboard',
  components: { OrderState, OrderLink, VTable, StatisticInfo, VStarRating, VBadge, OrderActionsMenu, MultiLineChartEchart },
  inject: ['eventBus'],
  data () {
    return {
      cpvStatsData: {},
      topContractData: {},
      referencesData: {},
      topApplicantData: {},
      topContractorsData: {},
      contracts: [],
      tableSort: {
        sortBy: 'journalAnnouncementDate',
        sort: 'desc'
      }
    }
  },
  mounted () {
    this.fetchContracts()
    this.fetchCpvStats()
    this.fetchTopContractor()
    this.fetchTopApplicant()
    this.fetchReferences()
    this.fetchTopContract()
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    },
    userOrganization () {
      /* this.fetchContracts() */
      this.fetchCpvStats()
      this.fetchTopContractor()
      this.fetchTopApplicant()
      this.fetchReferences()
      this.fetchTopContract()
    }
  },
  computed: {
    showOrdersAsRole () {
      return this.$globals.user.permissions && this.$globals.user.permissions.includes(permissions.ORDER_ORDER_CREATE_0) ? 'buyer' : 'contractor'
    },
    userOrganization () {
      return this.$globals.userOrganization
    },
    cpvStats () {
      return this.cpvStatsData && this.cpvStatsData.content
    },
    topContract () {
      return this.topContractData && this.topContractData.content && this.topContractData.content[0]
    },
    topContractor () {
      return this.topContractorData && this.topContractorData.content && this.topContractorData.content[0]
    },
    topApplicant () {
      return this.topApplicantData && this.topApplicantData.content && this.topApplicantData.content[0]
    },
    referencesRating () {
      return this.referencesData && this.referencesData.content && this.referencesData.content[0]
    },
    topCpvData () {
      return this.topCpvCodesData && this.topCpvCodesData.content
    },
    topContractors () {
      return this.topContractorsData && this.topContractorsData.content
    },
    stats () {
      if (this.cpvStats) {
        const cpvStats = attr => this.cpvStats.map(i => [i.year, i[attr]])
        const { num, perc, shortCurrencyFixed2 } = this.$format
        return {
          contracts: statsData('POČET VYHLÁSENÝCH SÚŤAŽÍ', cpvStats('contracts'), num),
          finalValueSum: statsData('OBJEM UKONČENÝCH SÚŤAŽÍ', cpvStats('finalValueSum'), shortCurrencyFixed2),
          savingAvg: statsData('PRIEMERNÁ VÝŠKA ÚSPOR', cpvStats('savingAvg'), perc),
          durationAvg: statsData('PRIEMERNÁ DOBA TRVANIA OBSTARÁVANIA V DŇOCH', cpvStats('durationAvg'), num)
        }
      }
      return null
    },
    cpvChartsData () {
      const items = this.cpvStats && this.cpvStats.filter(i => i.year)
      if (!items || (items && items.length === 0)) {
        return null
      }
      const years = uniq(items.map(i => i.year)).sort()

      const byCpv = {
        all: keyBy(items, 'year')
      }
      const charts = {
        savings: 'savingAvg',
        contracts: 'contracts',
        estimatedValues: 'estimatedValueSum',
        applicants: 'applicantsAvg'
      }
      const cpvBandData = (attr, data) => years.map(year => {
        const value = data[year] && data[year][attr] ? data[year][attr] : 0
        return { xAxis: year, value }
      })
      return mapValues(charts, attr => mapValues(byCpv, v => cpvBandData(attr, v)))
    },
    cpvChartsTooltips () {
      return mapValues(this.cpvChartsSeries, i => i.label)
    },
    cpvChartsSeries () {
      return {
        all: {
          color: '#3E97EF',
          label: 'Všetky CPV',
        }
      }
    },
    fetchParams () {
      return {
        limit: 10,
        sort: this.$format.sortString(this.tableSort),
        role: this.$globals.user.permissions?.includes(permissions.ORDER_ORDER_CREATE_0) ? 'buyer' : 'contractor'
      }
    },
    contractsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov zákazky',
          sortable: true
        },
        {
          key: 'journalAnnouncementDate',
          label: 'Dátum vyhlásenia súťaže',
          sortable: true
        },
        {
          key: 'organization.name',
          label: 'Názov obstarávateľa',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie',
          header: {
            width: 180
          }
        }
      ]
    }
  },
  methods: {
    async fetchTopCpv () {
      const params = {
        'contractingAuthority.country.codeAlpha2': 'SK',
        'contractingAuthority.cin': this.$globals.userOrganization.ico,
        'cpvCode.id': 'not(empty())',
        'year': 'empty()',
        'sort': 'finalValueSum,desc',
        'size': 4
      }
      const { data } = await bfs.get('/contracting-authorities/cpv-statistics', { params })
      this.topCpvCodesData = data
    },
    async fetchTopContractor () {
      const params = {
        'contractingAuthority.country.codeAlpha2': 'SK',
        'contractingAuthority.cin': this.$globals.userOrganization.ico,
        year: 'empty()',
        sort: 'finalValueSum,desc',
        size: 1
      }
      const { data } = await bfs.get('/contractors/customers', { params })
      this.topContractorData = data
    },
    async fetchTopContract () {
      const params = {
        'contractingAuthorities.cin': this.$globals.userOrganization.ico,
        sort: 'finalValueAmount,desc',
        size: 1
      }
      const { data } = await bfs.get('/contracts', { params })
      this.topContractData = data
    },
    async fetchTopApplicant () {
      const params = {
        'contractingAuthority.country.codeAlpha2': 'SK',
        'contractingAuthority.cin': this.$globals.userOrganization.ico,
        year: 'empty()',
        rejected: 'empty()',
        awarded: 'empty()',
        sort: 'contracts,desc',
        size: 1
      }
      const { data } = await bfs.get('/contractors/offer-statistics', { params })
      this.topApplicantData = data
    },
    async fetchReferences () {
      const params = {
        'contractingAuthority.country.codeAlpha2': 'SK',
        'contractingAuthority.cin': this.$globals.userOrganization.ico,
        year: 'empty()'
      }
      const { data } = await bfs.get('/contracting-authorities/references', { params })
      this.referencesData = data
    },
    async fetchCpvStats () {
      const params = {
        'contractingAuthority.country.codeAlpha2': 'SK',
        'contractingAuthority.cin': this.$globals.userOrganization.ico,
        'cpvCode.id': 'empty()',
        'size': 100
      }
      const { data } = await bfs.get('/contracting-authorities/cpv-statistics', { params })
      this.cpvStatsData = data
    },
    async fetchContracts () {
      const { data } = await this.$http.post('/order/list', this.fetchParams)
      this.contracts = data.orders || []
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .card.info {
    font-size: 14px;
  }
}
.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  &.grid-border {
    background-color: #D6D6D6;
    row-gap: 1px;
    column-gap: 1px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.grid-tindex {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 15px;
  @media (min-width: 801px) {
    grid-template-columns: 1fr 1fr;
  }

  .val-card {
    border-left: 4px solid #000;
    &.primary {
      border-color: var(--color-primary);
    }
    &.green {
      border-color: var(--color-green);
    }
    &.red {
      border-color: var(--color-red);
    }
    &.orange {
      border-color: var(--color-orange);
    }
  }
  .rating {
    display: flex;
    align-items: center;
  }
}
.layout-1 {
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 801px) {
    grid-template-columns: 1fr 1fr;
    ::v-deep .t-index {
      grid-row: 1 / 3;
    }
  }
}
.layout-col2, .layout-col3 {
  display: grid;
}
@media (min-width: 801px) {
  .layout-col2 {
    grid-template-columns: 1fr 1fr;
  }
  .layout-col3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .info.last {
    grid-area: 4 / 2 / 6 / 3;
  }
}
</style>
