<template>
  <multiselect
    v-model="order"
    :options="orders"
    label="name"
    track-by="id"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="true"
    :custom-label="customLabel"
    :loading="isLoading"
    :show-labels="false"
    placeholder="Vyberte zakázku"
  >
    <template slot="singleLabel" slot-scope="props"><span class="text-bold">{{ props.option.name}}</span><span>&nbsp;({{ $options.filters.formatProcedureType(props.option) }})</span><div><span>{{ props.option.organization.name }}</span></div></template>
    <template slot="option" slot-scope="props"><span class="text-bold">{{ props.option.name}}</span><span>&nbsp;({{ $options.filters.formatProcedureType(props.option) }})</span>
      <div><span>{{ props.option.organization.name }}</span></div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'OrderSelect',
  components: { Multiselect },
  props: {
    value: String
  },
  data () {
    return {
      order: null,
      orders: [],
      isLoading: false
    }
  },
  methods: {
    customLabel (item) {
      return `${item.name} (${this.$options.filters.formatProcedureType(item)}) ${item.organization.name}`
    }
  },
  async mounted () {
    this.isLoading = true
    let { data: { orders } } = await this.$http.post('/order/list', { limit: 1000, sort: '-createdAt', role: 'buyer' })
    this.orders = orders.filter(order => order.procedureType !== 'dns')
    this.isLoading = false
  },
  watch: {
    order () {
      if (this.order === null) {
        this.$emit('input', '')
      } else {
        this.$emit('input', this.order)
      }
    }
  }
}
</script>
