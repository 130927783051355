<template>
  <div class="card" id="order-documents">
    <div class="card-heading">
      <h4 class="card-title ">Dokumenty</h4>
      <div class="actions">
        <router-link v-if="order._canEdit" class="btn btn-tenderia-blue" :to="{name: `${routePrefix}admin-documents-add`, params: {orderId: order.id}}"><i class="icon-add font-size-14"></i> Pridať dokument</router-link>
        <button v-if="order.attachments && order.attachments.length > 0" class="btn btn-tenderia-grey-blue" @click.prevent="downloadDocuments()"><i class="icon-download"></i> Stiahnuť všetky dokumenty</button>
      </div>
    </div>
    <v-table
      :columns="documentsColumns"
      :items="order.attachments"
      :selected="selectedRows"
      item-key="id"
      class="light"
      @row-click="toggleRowDetail"
    >
      <template v-slot:cell(name)="{ _item, value }">
        <div class="row no-gutters align-items-center attachment-name">
          <i class="icon-doc-1 mr-2"></i>
          <span v-text="value"/>
        </div>
      </template>
      <template v-slot:cell(type)="{ item }">
        {{ item.type | formatDocumentType }}
      </template>
      <template v-slot:cell(date)="{ item }">
        {{ item.createdAt | formatDate }}
      </template>
      <template v-slot:cell(actions)="{ item, row }">
        <a
          v-if="order._canEdit"
          @click.stop="removeDocumentModal(item.id, item.name, row)"
          class="text-danger"
          title="Zmazať"
        >
          <i class="icon-delete"></i>
        </a>
        <a
          v-if="order._canEdit"
          @click.stop="editDocument(item)"
          class="text-primary"
          title="Upraviť"
        >
          <i class="icon-pencil"></i>
        </a>
        <a
          @click.stop="downloadDocument(item.id)"

          title="Stiahnuť všetky súbory"
        >
          <i class="icon-download"></i>
        </a>
      </template>
      <template v-slot:item-detail="{ item }">
        <div class="attachments-list row no-gutters">
          <!-- nested padding container for better resize animation -->
          <div>
            <div
              v-for="attachment in attachments[item.id]"
              :key="attachment.id"
              class="attachment row no-gutters align-items-center"
            >
              <div class="row no-gutters align-items-center">
                <div class="file-icon">
                  <i :class="fileClass(attachment)" class="fa"></i>
                </div>
                <a
                  class="icon row align-items-center no-gutters"
                  :href="$globals.backendHost + attachment.link + '?token=' + $globals.authToken"
                  target="_blank"
                >
                  {{attachment.name}}
                  <i class="icon-download ml-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-table>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'

var db = require('mime-db')
var mime = require('mime-type')(db)

export default {
  name: 'AdminDocuments',
  inject: ['order', 'routePrefix'],
  components: { VTable },
  data () {
    return {
      authToken: localStorage.getItem('token'),
      selectedRows: [],
      attachments: {}
    }
  },
  computed: {
    documentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'date',
          label: 'Dátum'
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  methods: {
    async toggleRowDetail (item) {
      if (!this.attachments[item.id]) {
        await this.fetchFiles(item.id)
      }
      this.selectedRows = this.selectedRows.includes(item.id)
        ? this.selectedRows.filter(i => i !== item.id)
        : [...this.selectedRows, item.id]
    },
    fileExt (file) {
      return mime.extension(file.mime)
    },
    fileClass (file) {
      const fileExt = this.fileExt(file)
      return {
        'icon-pdf text-danger': fileExt === 'pdf',
        'icon-doc text-primary': fileExt === 'doc' || fileExt === 'docx' || fileExt === 'odt' || fileExt === 'rtf',
        'icon-excel text-success': fileExt === 'xls' || fileExt === 'xlsx' || fileExt === 'ods',
        'icon-archive': fileExt === 'zip' || fileExt === 'rar',
        'icon-doc-1': fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif'
      }
    },
    async fetchFiles (id) {
      const fetchParams = {
        parent: id,
        limit: 1000
      }
      const { data } = await this.$http.post('/file/list', fetchParams)
      this.attachments[id] = data.files
    },
    showLoading () {
      $('#order-documents .card-block').LoadingOverlay('show')
    },
    hideLoading () {
      $('#order-documents .card-block').LoadingOverlay('hide')
    },
    editDocument (item) {
      this.$router.push({ name: `${this.routePrefix}admin-documents-edit`, params: { id: item.id } })
    },
    removeDocumentModal (id, name, index) {
      this.YesNoAlert({
        title: 'Skutočne chcete zmazať dokument?',
        text: 'Dokument ' + name + ' sa po zmazaní už nebude dať obnoviť.',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeDocument(id, index)
        }
      })
    },
    removeDocument (id, index) {
      this.showLoading()
      var data = {
        order: {
          id: this.$route.params.orderId
        },
        attachment: {
          id: id
        }
      }
      var vm = this
      this.$http.post('/order/attachment/remove', data).then(function () {
        vm.order.attachments.splice(index, 1)
        vm.hideLoading()
        setTimeout(function () {
          vm.OkAlert({ text: '' })
        }, 500)
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(function () {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }, 500)
      })
    },
    async downloadDocument (id) {
      this.$emit('epm:new', { title: 'Pripravujem súbory na stiahnutie', type: 'default' })
      var data = {
        file: {
          id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.$emit('epm:close')
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.$globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.$globals.authToken}`)
          }
        })
      } catch (error) {
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error?.response?.data.error)
        this.$emit('epm:close')
      }
    },
    async downloadDocuments () {
      this.$emit('epm:new', { title: 'Pripravujem dokumenty na stiahnutie', type: 'default' })
      var data = {
        order: {
          id: this.order.id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.$emit('epm:close')
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.$globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.$globals.authToken}`)
          }
        })
      } catch (error) {
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error?.response?.data.error)
        this.$emit('epm:close')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.attachments-list {
  background-color: rgba(62, 151, 239, 0.05); // transparent bg to keep shadow under it visible
  border-top: 1px solid #E0E0E0;
}
.attachment {
  margin-left: 36px;
}
.attachment-name {
  cursor: pointer;
  color: #3e97ef;
  font-weight: bold;
}
</style>
