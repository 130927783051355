<template>
  <div>
    <component :is="overviewComponent"/>
  </div>
</template>

<script>
import UserOverviewSsseDivided from '../common/views/UserOverviewSsseDivided.vue'
import UserOverviewDns from '../common/views/UserOverviewDns.vue'
import UserOverviewMarket from '../MarketResearch/views/UserOverview.vue'
import UserOverviewPtk from '../Ptk/views/UserOverview.vue'

export default {
  name: 'FreeOrderHome',
  inject: ['order'],
  data () {
    return {}
  },
  computed: {
    overviewComponent () {
      if (this.order.type === 'nh' && this.order.subtype === 'nh') {
        return UserOverviewMarket
      } else if (this.order.type === 'ptk' && this.order.subtype === 'ptk') {
        return UserOverviewPtk
      } else if (this.order.procedureType === 'dns') {
        return UserOverviewDns
      } else {
        return UserOverviewSsseDivided
      }
    }
  },
}
</script>
