<template>
  <div class="card" id="order-settings-commision">
    <div class="card-heading">
      <h4 class="card-title ">Členovia komisie</h4>
    </div>
    <div class="card-block">
      <table v-if="order.commission && order.commission.length > 0" class="table">
        <thead>
          <tr>
            <th>Meno</th>
            <th>Priezvisko</th>
            <th>Mesto</th>
            <th>Akademický titul</th>
            <th>Vedecká hodnosť</th>
            <th>Funkcia</th>
            <th>Stav</th>
            <th>Dátum menovania</th>
            <th>Dátum odvolania</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(member, index) in order.commission" v-if="member.state === 'active'" :key="member._id">
            <td>{{ member.firstname }}</td>
            <td>{{ member.lastname }}</td>
            <td>{{ member.city }}</td>
            <td>{{ member.title1 }}</td>
            <td>{{ member.title2 }}</td>
            <td>{{ member.type | formatMemberType}}</td>
            <td>Menovaný</td>
            <td>{{ member.history[0].validFrom | formatDate }}</td>
            <td></td>
            <td>
              <a v-if="member.attachments && member.attachments.length > 0" :href="$globals.backendHost + member.attachments[0].link + '?token=' + authToken" target="_blank"><i class="icon-download"></i></a>&nbsp;&nbsp;
              <a v-if="member.state === 'active' && order._canEdit" @click.prevent="removeMemberModal(index)" href=""><i class="icon-delete"></i></a></td>
          </tr>
          <tr v-for="member in order.commission" v-if="member.state === 'deleted'" :key="member._id">
            <td>{{ member.firstname }}</td>
            <td>{{ member.lastname }}</td>
            <td>{{ member.city }}</td>
            <td>{{ member.title1 }}</td>
            <td>{{ member.title2 }}</td>
            <td>{{ member.type | formatMemberType}}</td>
            <td>Odvolaný</td>
            <td>{{ member.history[0].validFrom | formatDate }}</td>
            <td><span data-toggle="tooltip" :title="member.history[1].note" v-if="member.history.length > 1">{{ member.history[1].validTo | formatDate }}&nbsp;<i class="icon-info"></i></span></td>
            <td><a v-if="member.attachments && member.attachments.length > 0" :href="$globals.backendHost + member.attachments[0].link + '?token=' + authToken" target="_blank"><i class="icon-download"></i></a>&nbsp;&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <p class="text-bold text-dark text-center pdd-vertical-50" v-else>
        V prípade ak vypíšete mená členov komisie, budú tieto mená automaticky premietnuté do jednotlivých šablón dokumentov používaných v procese VO (zápisnice, čestné vyhlásenie člena komisie a pod.). Zároveň budú tieto mená automaticky kontrolované v rámci kontroly konfliktu záujmov. Odporúčame Vám mená členov komisie vypísať.
      </p>
    </div>
    <div class="card-footer border top">
      <div v-if="order._canEdit" class="text-right">
        <router-link class="btn btn-tenderia-grey-blue" :to="{name: `${routePrefix}admin-commission-add`, params: {orderId: order.id}}"><i class="icon-add font-size-14"></i> Pridať nového člena</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CommissionMemberRemove from '../components/CommissionMemberRemove.vue'

export default {
  name: 'AdminCommission',
  inject: ['order', 'routePrefix', 'eventBus'],
  data: function () {
    return {
      authToken: localStorage.getItem('token')
    }
  },
  filters: {
    formatMemberType: function (value) {
      var types = {
        member: 'Člen komisie',
        chairman: 'Preseda komisie'
      }
      return types[value]
    }
  },
  methods: {
    showLoading: function () {
      $('#order-settings-commision .card-block').LoadingOverlay('show')
    },
    hideLoading: function () {
      $('#order-settings-commision .card-block').LoadingOverlay('hide')
    },
    addCommisionMember: function () {
      this.$router.push({ name: 'orderAddCommisionMember', params: { orderId: this.order.id } })
    },
    removeMemberModal: function (index) {
      var vm = this
      this.$emit('vuedals:new', {
        name: 'remove-commission-member',
        escapable: true,
        title: 'Odobrať člena komisie ' + this.order.commission[index].firstname + ' ' + this.order.commission[index].lastname,
        props: {
          member: this.order.commission[index]
        },
        component: CommissionMemberRemove,
        onClose: function (data) {
          if (data === 'removed') {
            vm.order.commission.splice(index, 1)
            vm.eventBus.$emit('order-updated')
          }
        }
      })
    },
    removeMember: function (index) {
      var data = {
        order: {
          id: this.order.id
        },
        member: {
          id: this.order.commission[index]._id
        }
      }
      var vm = this
      this.$http.post('/order/member/remove', data).then(function () {
        vm.order.commission.splice(index, 1)
        vm.hideLoading()
        setTimeout(function () {
          vm.OkAlert({ text: '' })
        }, 500)
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(function () {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }, 500)
      })
    }
  }
}
</script>
