import axios from 'axios'

const createService = (name) => axios.create({
  baseURL: `${process.env.VUE_APP_TX_BACKEND_URL}/${name}/api`,
  headers: {
    common: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
})

const rpo = createService('rpo')
const rpu = createService('rpu')
const ruz = createService('ruz')
const bfs = createService('bfs')
const vvo = createService('vvo')
const rpvs = createService('rpvs')

// cz services
const rpoCz = createService('rpo-cz')

export {
  rpo,
  rpu,
  ruz,
  bfs,
  vvo,
  rpvs,
  rpoCz
}
