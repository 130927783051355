<template>
  <div class="container-fluid">
    <ul class="order-main-menu mb-3">
      <li>
        <router-link :to="{ name: 'sd-user-overview', params: {orderId: $route.params.orderId}}">Prehľad</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-user-communication', params: {orderId: $route.params.orderId}}">Komunikácia</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-user-applications', params: {orderId: $route.params.orderId}}">Žiadosť o účasť</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-user-tenders', params: {orderId: $route.params.orderId}}">Výzvy na PP</router-link>
      </li>
    </ul>
    <router-view v-if="order.id"></router-view>
  </div>
</template>

<script>

export default {
  name: 'SdUser',
  inject: ['eventBus'],
  data () {
    return {
      order: {
        id: null,
        state: '',
        isApplicant: false,
        isInterested: false
      }
    }
  },
  provide () {
    return {
      order: this.order,
      routePrefix: 'sd-'
    }
  },
  methods: {
    async loadOrder () {
      try {
        let { data: { order, user } } = await this.$http.post('/order/read', { order: { id: this.$route.params.orderId } })
        Object.assign(this.order, order, {
          isApplicant: user.isApplicant,
          isInterested: user.isInterested
        })
        this.order.attachments = this.order.attachments.reverse()
        this.eventBus.$emit('order-data', this.order)
      } catch (error) {
        this.ErrorAlert({ text: 'Chyba pri načítavaní údajov zákazky.' }, error?.response?.data.error)
      }
    }
  },
  mounted () {
    this.eventBus.$on('order-updated', () => {
      this.loadOrder()
    })
    this.loadOrder()
  },
  destroyed () {
    this.eventBus.$off('order-updated')
  }
}
</script>
