<template>
  <span
    class="order-state"
    :class="{'active': application.stage === 'accepted', 'canceled': application.stage === 'excluded'}">
    {{ title }}
  </span>
</template>

<script>
export default {
  name: 'SdUserApplicationState',
  props: ['application'],
  computed: {
    selector () {
      return `${this.application.state}-${this.application.stage}`
    },
    title () {
      let stages = {
        'new-unopened': 'Nová',
        'sent-unopened': 'Odoslaná',
        'seen-opened': 'Otvorená',
        'seen-excluded': 'Neschválená',
        'seen-accepted': 'Schválená',
        'sent-excluded': 'Neschválená',
        'sent-accepted': 'Schválená'
      }
      return stages[this.selector]
    }
  }
}
</script>
