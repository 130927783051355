<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">Údaje organizácie</h4>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="text-dark text-bold">Názov spoločnosti <small>*</small></label>
              <input id ="org-name" type="text" v-model="organization.name" class="form-control" :class="{ error: organization.getField('name').hasErrors() }" />
              <label v-if="organization.getField('name').hasErrors()" class="error">
                {{ organization.getField('name').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="text-dark text-bold">IČO <small>*</small></label>
              <input id="ic" type="text" v-model="organization.ico" class="form-control" disabled/>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="text-dark text-bold">IČ DPH</label>
              <input id="icdph" type="text" v-model="organization.icdph" class="form-control" :class="{ error: organization.getField('icdph').hasErrors() }" />
              <label v-if="organization.getField('icdph').hasErrors()" class="error">
                {{ organization.getField('icdph').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label class="text-dark text-bold">Ulica <small>*</small></label>
              <input id="address" type="text" v-model="organization.address" class="form-control" :class="{ error: organization.getField('address').hasErrors() }" />
              <label v-if="organization.getField('address').hasErrors()" class="error">
                {{ organization.getField('address').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="text-dark text-bold">Číslo domu <small>*</small></label>
              <input id="address2" type="text" v-model="organization.address2" class="form-control" :class="{ error: organization.getField('address2').hasErrors() }" />
              <label v-if="organization.getField('address2').hasErrors()" class="error">
                {{ organization.getField('address2').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-dark text-bold">Mesto <small>*</small></label>
              <input id="city" type="text" v-model="organization.city" class="form-control" :class="{ error: organization.getField('city').hasErrors() }" />
              <label v-if="organization.getField('city').hasErrors()" class="error">
                {{ organization.getField('city').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="text-dark text-bold">PSČ <small>*</small></label>
              <input id="zip" type="text" v-model="organization.zip" class="form-control" :class="{ error: organization.getField('zip').hasErrors() }" />
              <label v-if="organization.getField('zip').hasErrors()" class="error">
                {{ organization.getField('zip').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label class="text-dark text-bold">Krajina</label>
              <country-select v-model="organization.country" disabled></country-select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button
            id="save-org"
            @click="save"
            :disabled="organization.hasErrors()"
            class="btn btn-success">
            <i class="icon-save"></i> Aktualizovať organizáciu
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from '@/components/forms/CountrySelect.vue'
export default {
  name: 'UserOrganizationEdit',
  components: { CountrySelect },
  data () {
    return {
      organization: new this.$models.Organization(Object.assign({
        vm: this,
        dataKey: 'organization',
        mode: 'edit'
      }, this.$globals.userOrganization))
    }
  },
  methods: {
    async updateOrganization (organization) {
      let data = {
        organization: organization
      }
      try {
        await this.$http.post('/organization/set', data)
        localStorage.setItem('userOrganization', JSON.stringify(this.$globals.userOrganization))
        this.OkAlert({ title: 'Hotovo', text: 'Údaje o vašej organizácii boli zmenené.' })
      } catch (error) {
        this.ErrorAlert({ title: 'Chyba', text: 'Nepodarilo sa aktualizovať údaje' }, error?.response?.data.error)
      }
    },
    save () {
      try {
        this.organization.validate()
        this.updateOrganization(Object.assign(this.$globals.userOrganization, this.organization))
      } catch (error) {
        if (error.code && error.code === 422) {
          this.organization.$validate()
        } else {
          console.dir(error)
        }
      }
    }
  },
  watch: {
    organization: {
      handler (organization) {organization.$validate({ debounce: 500 })},
      deep: true,
      immediate: false
    }
  }
}
</script>
<style lang="scss" scoped>
i {
  font-size: 18px;
  line-height: 14px;
}
</style>
