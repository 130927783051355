<template>
  <div class="print-page email-content text-black">
    <div class="email-content-wrapper">
      <div class="email-content-detail">
        <div class="header-title">
          <h2>Zákazka: {{ message.order.name }}</h2>
        </div>

        <div class="detail-head">
          <ul class="list-unstyled list-info">
            <li>
              <div>
                <div class="info">
                  <span class="title text-black">{{ from }}</span>
                  <span class="subject text-black text-bold">{{ message.subject }}</span>
                  <span class="sub-title text-black">
                    <span class="text-bold">Typ správy:</span> {{ message.type | formatMessageType }}<br />
                    <span class="text-bold">Pre:</span> {{ to }}<br />
                    <span class="text-bold">Zákazka:</span> <a href="" @click.prevent="openOrder()" class="text-semibold">{{ message.order.name }}</a><br />
                    <span class="text-bold">Obstarávateľ:</span> {{ message.order.organization.name}}
                    <template v-if="message.validTo">
                      <br />Nutné odpovedať do: <span class="text-bold">{{ message.validTo | formatDateTime}}</span>
                    </template>
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <ul class="tools">
            <li class="font-size-16 text-black">{{ message.createdAt | formatDateTime}}</li>
          </ul>
        </div>
        <div class="detail-body text-black">
          <div v-html="message.body"></div>
          <template v-if="message.validTo">
            <p class="mrg-top-15">Upozorňujeme Vás, že na túto správu ste povinní doručiť odpoveď do <span class="text-danger">{{ message.validTo | formatDateTime}}</span></p>
          </template>
        </div>
        <div class="detail-foot">
          <ul class="attachments">
            <li v-for="attachment in message.attachments" :key="attachment.id">
              <file-icon-link :file="attachment" />
            </li>
          </ul>
          <div class="mrg-top-20 pdd-top-10" v-if="showTable">
            <p class="no-mrg text-black">Stav prečítania</p>
            <message-state-table :message="message" :user-stats="userStats" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileIconLink from '../utils/FileIconLink.vue'
import EmailHelper from '../utils/EmailHelper.js'
import MessageStateTable from './MessageStateTable.vue'
export default {
  name: 'PrintMessage',
  components: { FileIconLink, MessageStateTable },
  data: function () {
    return {
      message: {
        order: {
          organization: {}
        },
        attachments: []
      },
      userStats: [],
      from: '',
      to: ''
    }
  },
  methods: {
    showLoading: function () {
      $('.email-content').LoadingOverlay('show')
    },
    hideLoading: function () {
      $('.email-content').LoadingOverlay('hide')
    }
  },
  mounted: function () {
    var vm = this

    vm.mode = this.$route.params.mode
    document.title = 'tenderia - Schránka správ'

    this.showLoading()
    this.$http.post('/message/get', { message: { id: this.$route.params.messageid } }).then(function (response) {
      let emailHelper = new EmailHelper(response.data.message, vm.mode)
      let messageBuild = emailHelper.buildMetadata()

      vm.message = emailHelper.message
      vm.from = messageBuild.from
      vm.to = messageBuild.to.slice(0, -2)

      if (response.data.userStats && response.data.userStats.length > 0) {
        vm.userStats = response.data.userStats
      }

      if (response.data.order && response.data.order.parentId) {
        vm.message.order.id = response.data.order.parentId
        vm.message.order.name = `${response.data.order.parentName} / ${response.data.message.order.name}`
      }

      vm.hideLoading()
      setTimeout(function () {
        window.print()
      }, 600)
    }).catch((error) => {
      vm.hideLoading()
      setTimeout(function () {
        vm.ErrorAlert({ text: 'Nepodarilo sa načítať správu.' }, error?.response?.data.error)
      }, 500)
    })
  },
  computed: {
    showTable () {
      if (this.$route.params.mode === 'sent' && this.userStats.length > 0) {
        return true
      }

      return false
    }
  }
}
</script>
