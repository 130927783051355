import { permissions } from './permissions'

const TenderiaRouterPlugin = {
  install (Vue) {
    Vue.prototype.$orderRoutePrefix = function (type, subtype) {
      let map = {
        'nh-nh': 'mr-',
        'pz-vs': 'pt-',
        'nz-vs': 'pt-',
        'nz-us': 'us-',
        'nk-us': 'us-',
        'ob-us': 'us-',
        'us-vyz': 'usv-',
        'ob-kd': 'kd-',
        'nk-kd': 'kd-',
        'kd-vyz': 'kdv-',
        'nz-rkz': 'rkz-',
        'nk-rkz': 'rkz-',
        'ob-rkz': 'rkz-',
        'rkz-vyz': 'rkzv-',
        'nz-prk': 'prk-',
        'ob-prk': 'prk-',
        'nz-ip': 'ip-',
        'nk-vs': 'pt-',
        'dns-dns': 'dns-',
        'dns-vyz': 'dnsv-',
        'ptk-ptk': 'ptk-',
        'nz-sd': 'sd-',
        'nk-sd': 'sd-',
        'sd-vyz': 'sdv-',
        'nk-ovs': 'ovs-',
        'sn-sn': 'sn-',
        'pk-pk': 'pk-'
      }
      return map[`${type}-${subtype}`]
    }

    Vue.prototype.$openOrder = function (id, type, subtype) {
      let userType = this.$globals.user.permissions && this.$globals.user.permissions.includes(permissions.ORDER_ORDER_CREATE_0) ? 'admin' : 'user'
      let routePrefix = this.$orderRoutePrefix(type, subtype)
      if (routePrefix) {
        this.$router.push({ name: `${routePrefix}${userType}-overview`, params: { orderId: id } })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    }
  }
}

export default TenderiaRouterPlugin
