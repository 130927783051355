import { render, staticRenderFns } from "./RegistrationBanner.vue?vue&type=template&id=56dc0ffe&scoped=true"
import script from "./RegistrationBanner.vue?vue&type=script&lang=js"
export * from "./RegistrationBanner.vue?vue&type=script&lang=js"
import style0 from "./RegistrationBanner.vue?vue&type=style&index=0&id=56dc0ffe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56dc0ffe",
  null
  
)

export default component.exports