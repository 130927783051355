<template>
  <div>
    <order-settings-log-detail :row="row"></order-settings-log-detail>
    <div class="row" v-if="entity.type === 'person'">
      <div class="col-md-4 control-label">
        Meno:
      </div>
      <div class="col">
        {{ `${entity.title1} ${entity.firstname} ${entity.lastname} ${entity.title2}` }}
      </div>
    </div>
    <template v-else>
      <div class="row">
        <div class="col-md-4 control-label">
          Obchodné meno:
        </div>
        <div class="col">
          {{ entity.businessName }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 control-label">
          Ičo:
        </div>
        <div class="col">
          {{ entity.cin }}
        </div>
      </div>
    </template>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close()"><i class="icon-close"></i> Zavrieť</button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOrderEntities',
  components: { OrderSettingsLogDetail },
  props: ['row'],
  data: function () {
    return {
      entity: null
    }
  },
  created: function () {
    this.entity = this.row.action === 'remove' ? this.row.data.entity : this.row.data.relatedEntity
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    }
  }
}
</script>

<style>

</style>
