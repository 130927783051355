<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Verejný obstarávateľ</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Názov</b>
                </div>
                <div class="col-md-7">
                  <b>{{ order.organization.name }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IČO</b>
                </div>
                <div class="col-md-7">
                  {{ order.organization.ico }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Adresa</b>
                </div>
                <div class="col-md-7">
                  {{ order.organization.address }} {{ order.organization.address2 }}<br />
                  {{ order.organization.zip }} {{ order.organization.city }}<br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zodpovedná osoba</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.title1 }} {{ order.user.firstname }} {{ order.user.lastname }} {{ order.user.title2 }}<br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>E-mail</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.email }}<br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefónne číslo</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.phone }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <order-overview-charts :order="order" />
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Informácie o zákazke</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Názov</b>
                </div>
                <div class="col-md-7">
                  <b>{{ order.name }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Číslo zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.identifier }}
                </div>
              </div>
              <div class="row" v-if="order.announcementUrl">
                <div class="col-md-5">
                  <b>URL v tenderia</b>
                </div>
                <div class="col-md-7">
                  <a :href="order.announcementUrl" target="_blank">{{ order.announcementUrl }}</a>
                </div>
              </div>
              <div class="row" v-if="order.journal">
                <div class="col-md-5">
                  <b>Číslo vestníka</b>
                </div>
                <div class="col-md-7">
                  {{ order.journal }}
                </div>
              </div>
              <div class="row" v-if="order.announcement">
                <div class="col-md-5">
                  <b>Číslo oznámenia/výzvy</b>
                </div>
                <div class="col-md-7">
                  {{ order.announcement }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Opis predmetu zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.description }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Podmienky účasti</b>
                </div>
                <div class="col-md-7">
                  {{ order.participationConditions }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Druh zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.subjectType | formatOrderSubject }}
                </div>
              </div>
              <div class="row" v-if="order.mainDeliveryPlace">
                <div class="col-md-5">
                  <b>Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác</b>
                </div>
                <div class="col-md-7">
                  {{ order.mainDeliveryPlace }}
                </div>
              </div>
              <div class="row" v-if="order.cpvCodeText">
                <div class="col-md-5">
                  <b>Hlavný CPV kód</b>
                </div>
                <div class="col-md-7">
                  {{ order.cpvCodeText.texts.SK }} ({{ order.cpvCodeText.code }})
                </div>
              </div>
              <div class="row" v-if="order.cpvCodesText && order.cpvCodesText.length > 0">
                <div class="col-md-5">
                  <b>Doplnkové CPV kódy</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li v-for="cpvCode in order.cpvCodesText" :key="`${order.id}-${cpvCode.code}`">{{ cpvCode.texts.SK }} ({{ cpvCode.code }})</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav</b>
                </div>
                <div class="col-md-7">
                  <order-state :state="order.state" display="text"></order-state>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Predpokladaná hodnota v &euro; bez DPH</b>
                </div>
                <div class="col-md-7">
                  {{ order.estimatedPrice ? (order.estimatedPrice | formatPrice) : 'N/A'}} &euro;
                </div>
              </div>
              <div class="row" v-if="order.fundingByEu">
                <div class="col-md-5">
                  <b>Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie</b>
                </div>
                <div class="col-md-7">
                  {{ order.fundingByEu | formatYesNo }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum vytvorenia</b>
                </div>
                <div class="col-md-7">
                  {{ order.createdAt | formatDate }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum aktualizácie</b>
                </div>
                <div class="col-md-7">
                  {{ order.updatedAt | formatDate }}
                </div>
              </div>
              <div class="row" v-if="order.state !== 'planned'">
                <div class="col-md-5">
                  <b>Dátum vyhlásenia súťaže</b>
                </div>
                <div class="col-md-7">
                  {{ order.journalAnnouncementDate | formatDate }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Postup</b>
                </div>
                <div class="col-md-7">
                  {{ order | formatProcedureType }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <parts-list v-if="order.divided" :order="order" />
    <admin-logs />
  </div>
</template>

<script>
import OrderState from '@/components/OrderState.vue'
import AdminLogs from '@/components/order/common/views/AdminLogs.vue'
import OrderOverviewCharts from '@/components/order/common/components/OrderOverviewCharts.vue'

export default {
  name: 'PtkAdminOverview',
  inject: ['order'],
  components: { OrderState, AdminLogs, OrderOverviewCharts },
  data () {
    return {}
  },
  computed: {
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'date',
          label: 'Dátum'
        }
      ]
    }
  }
}
</script>
