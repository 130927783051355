<template>
  <div>
    <template v-if="saveDisabled">
      <div class="row">
        <div class="col-md-12">
          <div class="pdd-vertical-60 text-center">
            <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="55" color="#1288b5" style="margin: 0 auto"/>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div v-if="order.divided" class="col-md-12 text-bold">
          Vyberte si časti, do ktorých sa chcete zaradiť ako záujemca:
        </div>
        <div v-else class="col-md-12 text-bold">
          Chcete odoslať žiadosť o zaradenie do zoznamu záujemcov v zákazke {{order.name}}?
        </div>
      </div>
      <div class="row" v-for="part in parts" :key="part.id">
        <div class="col-md-12">
          <div class="checkbox">
            <input v-if="!part.isInterested" v-model="selectedParts" :id="`part-${part.id}`" name="selected-parts" :value="part.id" type="checkbox" />
            <input v-else :id="`part-${part.id}`" name="selected-parts" :value="part.id" type="checkbox" disabled checked />
            <label :for="`part-${part.id}`" :class="{'text-dark': !part.isInterested}">Časť {{ part.part}} - {{ part.name }} <i v-if="part.isInterested" class="icon-info font-size-11" title="Vaša žiadosť o zaradenie medzi známych záujemcov už bola zaregistrovaná."></i></label>
          </div>
        </div>
      </div>
    </template>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-success" @click.prevent="save()" :disabled="saveDisabled">Odoslať</button>
    </div>
  </div>
</template>

<script>
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'
export default {
  name: 'InterestedModal',
  components: { FulfillingBouncingCircleSpinner },
  props: {
    order: Object
  },
  data () {
    return {
      parts: [],
      selectedParts: [],
      current: 0,
      saveDisabled: false
    }
  },
  methods: {
    save () {
      if (this.selectedParts.length > 0) {
        this.saveDisabled = true
        this.current = 0
        this.sendRequest()
      } else {
        this.InfoAlert({ title: 'Musíte zvoliť aspoň jednu časť zákazky.' })
      }
    },
    async sendRequest () {
      if (this.current < this.selectedParts.length) {
        let messageBody = `Týmto žiadame o zaradenie medzi známych záujemcov. Identifikácia záujemcu:<br />
                           ${this.$globals.userOrganization.name}<br />
                           ${this.$globals.userOrganization.address} ${this.$globals.userOrganization.address2}<br />
                           ${this.$globals.userOrganization.zip} ${this.$globals.userOrganization.city}<br />
                           IČO: ${this.$globals.userOrganization.ico}<br />
                           <br />
                           Kontaktná osoba: ${this.$globals.user.title1}${this.$globals.user.firstname} ${this.$globals.user.lastname}${this.$globals.user.title2}<br />
                           Mail: ${this.$globals.user.email}<br />
                           Telefónne číslo: ${this.$globals.user.phone}`
        let data = {
          message: {
            type: 'zu',
            subject: 'Žiadosť o zaradenie medzi záujemcov',
            body: messageBody,
            order: this.selectedParts[this.current],
            organization: this.$globals.userOrganization.id,
            access: 'user',
            to: this.order.user.id,
            attachments: []
          }
        }

        try {
          const response = await this.$http.post('/message/set', data)
          await this.$http.post('/message/send', { message: { id: response.data.id } })
          this.current++
          this.sendRequest()
        } catch (error) {
          this.saveDisabled = false
          this.$emit('vuedals:close')
          this.ErrorAlert({ text: 'Pri spracovaní vašej žiadosti nastala chyba.' }, error?.response?.data.error)
        }
      } else {
        this.saveDisabled = false
        this.$emit('vuedals:close', 'update')
        this.OkAlert({ text: 'Vaša žiadosť o zaradenie medzi záujemcov bola odoslaná.' })
      }
    }
  },
  created () {
    this.saveDisabled = false
    if (this.order.divided) {
      this.parts = this.order._parts
    } else {
      this.selectedParts.push(this.order.id)
    }
  }
}
</script>
