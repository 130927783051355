<template>
  <div style="display:none;">
    <portal :to="portal">
      <template v-if="open">
        <div
          v-if="backdrop"
          class="popup-backdrop"
          v-backdrop-scroll
        >
          <div/>
        </div>
        <document-listener
          v-if="interactive"
          stacked
          :key="_uid"
          :src="origin"
          @click="onDocumentClick"
          @keydown="$emit('keydown', $event)"
          @scroll.stop="$emit('scroll', $event)"
        />
        <back-button-listener
          v-if="backHandlerEnabled"
          @back="$emit('update:open', false)"
        />
        <document-listener src="popup-resize" @resize="onResize"/>
      </template>
      <transition
        :name="transitionName"
        v-on="transitionHooks"
        @enter="enter"
        @after-leave="leave"
      >
        <div
          v-if="open"
          ref="menuEl"
          class="popup-container"
          :class="popupClasses"
          :style="[popupStyle, contentStyle]"
          :tabindex="tabindex"
          @click="onClick"
        >
          <div
            v-if="arrowStyle"
            class="anchor"
            :style="anchorStyle"
          >
            <svg width="20" height="10" viewBox="0 0 12 6">
              <path d="M4.627.902L0 6h12L7.373.902c-.75-.807-1.97-.83-2.746 0z"/>
            </svg>
          </div>
          <slot/>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
import { Wormhole } from 'portal-vue'
// import throttle from 'lodash/throttle'
import DocumentListener from './DocumentListener.js'
import { popupFixedPosition, pxObj, elementBounds } from './utils/popup'

function transformBounds (bounds, anchorInit, anchorCurrent) {
  const offsetY = anchorCurrent.top - anchorInit.top
  const { top, left, bottom, right, width, height } = bounds
  return {
    top: top + offsetY,
    bottom: bottom + offsetY,
    left,
    right,
    width,
    height
  }
}

function anchorLeftAlignmentStyle (offset) {
  return {
    alignSelf: 'flex-start',
    marginLeft: `${Math.max(0, offset - 10)}px`
  }
}

function anchorRightAlignmentStyle (offset) {
  return {
    alignSelf: 'flex-end',
    marginRight: `${Math.max(0, offset - 10)}px`
  }
}

const BackButtonListener = {
  mounted () {
    if (history.backhandler) {
      const backHandler = () => this.$emit('back')
      history.backhandler.addHandler(backHandler)
      this.$once('hook:beforeDestroy', () => history.backhandler.removeHandler(backHandler))
    }
  },
  render: () => null
}

export default {
  components: {
    BackButtonListener,
    DocumentListener
  },
  directives: {
    backdropScroll: {
      inserted (el) {
        el.scrollTop = 1
        el.addEventListener('scroll', () => {
          el.scrollTop = 1
        })
      }
    }
  },
  props: {
    align: String,
    arrowStyle: Boolean,
    backdrop: Boolean,
    backhandler: Boolean,
    bounds: Object,
    interactive: {
      type: Boolean,
      default: true
    },
    origin: String,
    open: {
      type: Boolean,
      default: undefined
    },
    popupClass: {
      type: [String, Array, Object],
      default: 'light'
    },
    popupStyle: [Object, String],
    portal: {
      type: String,
      default: 'popup'
    },
    tabindex: [Number, String],
    transition: {
      type: [String, Object],
      default: 'fade'
    },
    type: String
  },
  data () {
    return {
      contentStyle: {},
      alignment: null,
      popupBounds: null
    }
  },
  computed: {
    backHandlerEnabled () {
      return this.backhandler && !!history.backhandler
    },
    transitionName () {
      return typeof this.transition === 'string' ? this.transition : undefined
    },
    transitionHooks () {
      return typeof this.transition === 'object' ? this.transition : {}
    },
    popupClasses () {
      if (this.alignment) {
        const [h, v] = this.alignment.split('-')
        return [this.popupClass, 'align-' + h, 'align-' + v]
      }
      return this.popupClass
    },
    targetBounds () {
      let bounds = this.bounds
      if (this.arrowStyle && bounds.width < 36) {
        bounds = { ...bounds }
        const gap = 36 - bounds.width
        bounds.left -= gap / 2
        bounds.right += gap / 2
        bounds.width += gap
      }
      return bounds
    },
    anchorStyle () {
      if (this.arrowStyle && this.alignment) {
        const [h] = this.alignment.split('-')
        if (h === 'c') {
          return {
            alignSelf: 'center'
          }
        } else if (h === 'sr') {
          const offset = this.targetBounds.width / 2 + window.innerWidth - this.targetBounds.right
          return anchorRightAlignmentStyle(offset)
        } else if (h === 'sl') {
          const offset = this.targetBounds.width / 2 + this.targetBounds.left
          return anchorLeftAlignmentStyle(offset)
        } else {
          const offset = Math.min(this.targetBounds.width / 2, 0.33 * this.popupBounds.width)
          if (h === 'rr') {
            return anchorRightAlignmentStyle(offset)
          } else if (h === 'll') {
            return anchorLeftAlignmentStyle(offset)
          }
        }
      }
      return null
    }
  },
  mounted () {
    const containers = []
    let el = this.$el.parentElement
    while (el) {
      if (el.classList.contains('scrollable')) {
        containers.push(el)
      }
      el = el.parentElement
    }
    // const onScroll = throttle(() => this._updatePosition(this.$refs.menuEl), 20)
    const onScroll = () => this._updatePosition(this.$refs.menuEl)
    ;[window].concat(containers).forEach(el => {
      el.addEventListener('scroll', onScroll)
    })
    this.$once('hook:beforeDestroy', () => {
      [window].concat(containers).forEach(el => {
        el.removeEventListener('scroll', onScroll)
      })
    })
    this._containers = containers
  },
  methods: {
    _updatePosition (el, recalculate = false) {
      if (!el || !this.bounds) {
        return
      }
      let bounds
      if (recalculate) {
        this.alignment = null
        bounds = this.targetBounds
      } else {
        bounds = transformBounds(this.targetBounds, this._openBounds, this.$el.getBoundingClientRect())
      }

      // const popupContainer = document.scrollingElement
      const containerBox = Wormhole.targets.popup[0].$el.getBoundingClientRect()
      const elBox = el.getBoundingClientRect()

      const { key, position, info } = popupFixedPosition(containerBox, bounds, elBox, this.align)
      if (!this.alignment || this.alignment !== key) {
        Object.assign(position, info)
        this.contentStyle = pxObj(position)
      } else {
        Object.assign(this.contentStyle, pxObj(position))
        // this.contentStyle = { ...this.contentStyle, ...pxObj(position)}
      }
      this.alignment = key
    },
    enter (el) {
      this._openBounds = this.$el.getBoundingClientRect()
      this._updatePosition(el)
      this.popupBounds = elementBounds(el)
      this.$emit('opened', { target: el })
    },
    leave () {
      this.contentStyle = {}
      this.alignment = null
      this.$emit('closed')
    },
    onDocumentClick (e) {
      // e.composedPath().includes(this.$el)
      if (!this.$refs.menuEl || !this.$refs.menuEl.contains(e.target)) {
        this.$emit('click:out', e)
      } else {
        // this.$emit('click:in', e)
      }
    },
    onClick (e) {
      this.$emit('click:in', e)
    },
    onResize () {
      this.alignment = null
      this._updatePosition(this.$refs.menuEl)
    },
    updatePosition () {
      this._updatePosition(this.$refs.menuEl, true)
    }
  }
}
</script>

<style lang="scss">
.popup-container {
  position: absolute;
  z-index: 2;
  outline: 0;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  overscroll-behavior: contain;
  .popup-content {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
    outline: none;

    &.popup-list {
      // background-color: #fff;
      color: #393E46;
      overflow: auto;
      overscroll-behavior: contain;
      min-width: inherit;
      font-size: 14px;
      padding: 3px 0;
      @media (min-width: 600px) {
        max-height: 70vh;
        max-width: 500px;
      }
      .item {
        padding: 0 12px;
        min-height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        // font-weight: bold;
        color: inherit;
        outline: none;
        &:not(:first-child):not(.highlighted) {
          border-top-color: rgba(#D3D3D3, 0.25);
        }
        .icon-box {
          display: flex;
          align-items: center;
          background-color: var(--color-primary);
          border-radius: 3px;
          height: 32px;
        }
      }
    }
  }

  .anchor {
    display: flex;
    align-self: flex-end;
    z-index: 1;
    overflow: hidden;
    padding: 3px 3px 0 3px;
    margin: -1px;
    flex: 0 0;
    svg {
      margin: -1px;
    }
  }
  &.align-tt {
    flex-direction: column-reverse;
    .anchor {
      transform: scale(1, -1);
    }
  }
}
.popup-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: -20px;
  bottom: 0;
  z-index: 1;
  overflow: auto;
  pointer-events: auto;
  overscroll-behavior: contain;
  > div {
    width: 5px;
    height: 110%;
  }
}
</style>
