<template>
  <form id="exclude-offer-form">
    <div class="row form-group">
      <div class="col-md-3 text-dark">Dôvod</div>
      <div class="col-md-9">
        <textarea id="reason-of-appeal" v-model="decision"  class="form-control"></textarea>
        Počet zostávajúcich znakov: {{ decisionTextRemaining }}
        <label v-if="form.decision.error" class="error" for="reason-of-appeal">Dôvod musí byť vyplnený.</label>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close(null)"><i class="icon-close"></i> Zavrieť</button>
      <button class="btn btn-success" @click.prevent="save()"><i class="icon-save"></i> Uložiť</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'DnsExcludeApplicationModal',
  props: ['offer'],
  data () {
    return {
      decision: '',
      legalBasis: '',
      form: {
        decision: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    decisionTextRemaining () {
      return 4000 - this.decision.length
    }
  },
  methods: {
    validateDecision () {
      var state = this.form.decision
      state.error = state.valid = false
      if (this.decision.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    },
    close (data) {
      this.$emit('vuedals:close', data)
    },
    async save () {
      if (!this.validateDecision()) {
        return
      }
      this.ShowLoading()
      let data = {
        offer: {
          id: this.offer.id,
          stage: 'excluded',
          decision: this.decision
        }
      }
      try {
        await this.$http.post('/offer/stage/set', data)
        this.CloseLoading()
        this.OkAlert({ text: 'Žiadosť bola vylúčená.' })
        this.close(data.offer)
      } catch (error) {
        this.CloseLoading()
        this.close()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
      }
    }
  }
}
</script>
