<template>
  <div>
    <h1>Žiadosť o zaradenie do DNS</h1>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Identifikácia dodávateľa</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row" v-if="offer.applicantDetails.groupName">
                <div class="col-md-5">
                  <b>Názov skupiny dodávateľov</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName }}
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-5">
                  <b>Obchodné meno alebo názov uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.businessName }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Sídlo alebo miesto podnikania uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.address }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IČO</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li v-for="(ico, index) in offer.applicantDetails.ico" :key="`ico-${index}`">{{ ico }}</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Platca DPH</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.vatPayer | formatYesNo}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Štát</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.country }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Meno a priezvisko kontaktnej osoby</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.contactPerson }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefón</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.phone }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Fax</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.fax }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Email</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Systémové informácie</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row" v-if="offer.state !== 'new'">
                <div class="col-md-5">
                  <b>Dátum odoslania uchádzačom</b>
                </div>
                <div class="col-md-7">
                  {{ offer.sentAt | formatDateTime }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IP adresa uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.userIP }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav žiadosti</b>
                </div>
                <div class="col-md-7">
                  <stage :application="offer" />
                </div>
              </div>
              <div class="row" v-if="offer.stage === 'excluded'">
                <div class="col-md-5">
                  <b>Dôvod vylúčenia žiadosti</b>
                </div>
                <div class="col-md-7">
                  {{ offer.decision }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Doklady vyžadované výzvou</h4>
          </div>
          <v-table
            :items="offer.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
      <button v-if="canExclude && order._canEdit" class="btn btn-danger" @click="excludeApplicationModal()">Vylúčiť žiadosť</button>
      <button v-if="canAccept && order._canEdit" class="btn btn-success" @click="acceptApplicationModal()">Akceptovať žiadosť</button>
    </div>
  </div>
</template>

<script>
import BaseOfferView from '@/components/order/common/components/BaseOfferView'
import ExcludeApplicationModal from '../components/ExcludeApplicationModal'
import Stage from '../components/AdminApplicationStage'
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'AdminApplicationsView',
  extends: BaseOfferView,
  components: { Stage, VTable },
  computed: {
    canExclude () {
      return this.offer.stage !== 'excluded'
    },
    canAccept () {
      return this.offer.stage !== 'accepted'
    },
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
  async created () {
    try {
      if (this.$globals.user.type === 'observer') {
        let { data: { offer } } = await this.$http.post('/offer/observe', { offer: { id: this.$route.params.applicationId } })
        this.offer = offer
      } else {
        let { data: { offer } } = await this.$http.post('/offer/open', { offer: { id: this.$route.params.applicationId } })
        this.offer = offer
      }
    } catch (error) {
      this.ErrorAlert({ text: 'Pri načítavaní ponuky nastala chyba.' }, error?.response?.data.error)
    }
  },
  methods: {
    excludeApplicationModal () {
      this.$emit('vuedals:new', {
        name: 'exclude-offer',
        escapable: true,
        title: 'Vylúčiť ponuku',
        props: {
          offer: this.offer
        },
        component: ExcludeApplicationModal,
        onClose: (data) => {
          if (data) {
            this.offer.stage = data.stage
            this.offer.decision = data.decision
          }
        }
      })
    },
    acceptApplicationModal () {
      this.YesNoAlert({
        title: 'Skutočne chcete akceptovať túto žiadosť?',
        text: 'Uchádzač bude zaradený do zoznamu kvalifikovaných dodávateľov',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Akceptovať'
      }).then(async (result) => {
        if (result.value) {
          this.ShowLoading()
          let data = {
            offer: {
              id: this.offer.id,
              stage: 'accepted'
            }
          }
          try {
            await this.$http.post('/offer/stage/set', data)
            this.offer.stage = 'accepted'
            this.CloseLoading()
            this.OkAlert({ text: 'Žiadosť bola akceptovaná.' })
          } catch (error) {
            this.CloseLoading()
            this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
          }
        }
      })
    }
  }
}
</script>
