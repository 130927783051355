<template>
  <multiselect
    v-model="selectedUsers"
    :options="users"
    track-by="id"
    label="email"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    placeholder="Vyberte si používateľov"
  >
    <template #option="{option}">
      {{ option.firstname }} {{ option.lastname }} ({{ option.email }})
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'RequestApproverSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      selectedUsers: [],
      users: []
    }
  },
  computed: {
    fetchParams () {
      return {
        organization: {
          id: this.$globals.userOrganization.id
        }
      }
    }
  },
  async mounted () {
    let { data } = await this.$http.post('/organization/approver/list', this.fetchParams)
    this.users = data.approvers
    if (this.value) {
      this.selectedUsers = this.users.filter(user => this.value.find(val => val.id === user.id))
    }
  },
  watch: {
    selectedUsers (val) {
      if (!val) {
        this.$emit('input', [])
      } else {
        this.$emit('input', val)
      }
    },
    value (val) {
      if (!val) {
        this.selectedUsers = []
      }
    }
  }
}
</script>
