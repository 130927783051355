import { ReactiveModel } from 'vue-rawmodel'
import validator from '../components/validator'

export default class RequestItem extends ReactiveModel {
  constructor (data = {}) {
    super(data)

    this.defineValidator('numeric', async (value) => {
      return validator.validateNumeric(value)
    })

    this.defineField('id', {
      type: 'String'
    })

    this.defineField('name', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('amount', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'numeric',
          message: 'Pole musí byť číslo'
        }
      ]
    })

    this.defineField('unit', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('unitPrice', {
      type: 'Number',
      defaultValue: 0,
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'numeric',
          message: 'Pole musí byť číslo'
        }
      ]
    })

    this.defineField('estimatedPrice', {
      type: 'Number',
      defaultValue: 0,
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'numeric',
          message: 'Pole musí byť číslo'
        }
      ]
    })

    this.defineField('estimatedPriceWithVat', {
      type: 'Number',
      defaultValue: 0,
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'numeric',
          message: 'Pole musí byť číslo'
        }
      ]
    })

    this.defineField('vat', {
      type: 'Number',
      defaultValue: 0,
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'numeric',
          message: 'Pole musí byť číslo'
        }
      ]
    })

    this.defineField('description', {
      type: 'String'
    })

    this.defineField('budget', {
      type: 'String'
    })

    this.populate(data)
    this.commit()
  }
}
