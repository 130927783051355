<template>
  <form>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="nazov-jednotky" class="text-dark text-bold">Rozpočty <small>*</small></label>
          <budget-select
            v-model="budgets"
            :class="{ error: form.budgets.error, valid: form.budgets.valid }"
            aria-required="true"
          />
          <label v-if="form.budgets.error" class="error" for="nazov-jednotky">Toto pole je povinné.</label>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close">Zrušiť</button>
      <button class="btn btn-success" @click.prevent="addBudgets">Pridať</button>
    </div>
  </form>
</template>

<script>
import BudgetSelect from '@/components/forms/BudgetSelect.vue'
export default {
  name: 'AddBudgetBudgetModal',
  components: { BudgetSelect },
  data () {
    return {
      budgets: [],
      form: {
        budgets: {
          error: false,
          valid: false
        }
      },
      validators: [
        this.validateBudgets
      ]
    }
  },
  methods: {
    showLoading () {
      $('.vuedal').LoadingOverlay('show')
    },
    hideLoading () {
      $('.vuedal').LoadingOverlay('hide')
    },
    close () {
      this.$emit('vuedals:close', null)
    },
    async addBudgets () {
      if (!this.validateBudget()) {
        return
      }
      this.showLoading()
      let budgetsData = {
        unit: {
          id: this.$route.params.departmentId
        },
        budget: {
          id: this.budgets.map(budget => budget.id)
        }
      }
      try {
        await this.$http.post('/orgunit/budget/add', budgetsData)
        this.hideLoading()
        this.close()
      } catch (error) {
        this.hideLoading()
        this.ErrorAlert({ title: 'Chyba', text: 'Rozpočty sa nepodarilo pridať' }, error?.response?.data.error)
      }
    },
    validateBudget () {
      let state = this.form.budgets
      state.valid = state.error = false
      if (this.budgets.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    }
  },
  watch: {
    budgets () {
      this.validateBudget()
    }
  }
}
</script>
