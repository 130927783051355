<template>
  <div class="state-badge request-state" :class="[stateClass, display !== 'text' ? 'icon-only' : '']">
    <i :class="stateIconClass" class="font-size-18"></i><span v-if="display === 'text'">{{ state | requestState }}</span>
  </div>
</template>

<script>
export default {
  name: 'RequestState',
  props: ['state', 'display'],
  computed: {
    stateClass: function () {
      var classes = {
        planned: {
          'planned': true
        },
        approving: {
          'approving': true
        },
        approved: {
          'approved': true
        },
        rejected: {
          'rejected': true
        }
      }
      return classes[this.state]
    },
    stateIconClass: function () {
      var classes = {
        planned: {
          'icon-pending': true
        },
        approving: {
          'icon-calendar': true
        },
        approved: {
          'icon-check': true
        },
        rejected: {
          'icon-ended': true
        }
      }
      return classes[this.state]
    },
    buttonClass: function () {
      var classes = {
        planned: 'btn-warning',
        approving: 'btn-tenderia-blue',
        approved: 'btn-success',
        rejected: 'btn-danger'
      }
      return classes[this.state]
    }
  },
  filters: {
    requestState: function (value) {
      var requestStates = {
        planned: 'V príprave',
        approving: 'Čakajúca',
        approved: 'Schválená',
        rejected: 'Neschválená'
      }
      return requestStates[value]
    }
  }
}
</script>
