<template>
  <div class="email-content">
  </div>
</template>

<script>
export default {
  props: ['currentMessage', 'mode', 'canEdit'],
  name: 'EmailContentLoader',
  methods: {
    showLoading () {
      $('.email-content').LoadingOverlay('show')
    },
    hideLoading () {
      $('.email-content').LoadingOverlay('hide')
    }
  },
  mounted () {
    this.showLoading()
  }
}
</script>
