<template>
  <div class="input-group date">
    <input type="text"
           :id="name"
           :name="name"
           :disabled="disabled"
           v-model="formatedDate"
           autocomplete="off"
           class="form-control"
           :class="{ error: error }"
           :placeholder="placeholder"
    />
    <button v-if="formatedDate && !disabled" class="input-group-addon clear" @click="clearDate">
      <i class="icon-close font-size-18"></i>
    </button>
  </div>
</template>

<script>
import moment from 'moment'
require('bootstrap-datetime-picker')
export default {
  name: 'DateInput',
  props: ['name', 'value', 'disabled', 'minDate', 'maxDate', 'error', 'placeholder'],
  data: function () {
    return {
      formatedDate: '',
      date: new Date()
    }
  },
  methods: {
    clearDate () {
      this.formatedDate = ''
      this.$emit('input', null)
    }
  },
  mounted () {
    if (this.value) {
      this.date = new Date(this.value)
      this.formatedDate = moment(this.date).format('DD. MM. YYYY')
    } else {
      this.formatedDate = null
    }
    var vm = this
    var dateTimePicker = $(`#${vm.name}`).datetimepicker({
      format: 'dd. mm. yyyy',
      fontAwesome: true,
      language: 'sk',
      autoclose: true,
      todayHighlight: true,
      weekStart: 1,
      initialDate: this.date,
      timezone: 2,
      minView: 2,
      startDate: this.minDate || false,
      endDate: this.maxDate || false
    })
    dateTimePicker.on('change', function () {
      let date = $(`#${this.name}`).datetimepicker('getDate')
      vm.$emit('input', date ? date.toISOString() : '')
    })
  }
}
</script>
