import Home from '@/components/requests/views/Home.vue'
import Overview from '@/components/requests/views/Overview.vue'
import Settings from '@/components/requests/views/Settings.vue'

export default [
  {
    path: 'myRequest/add',
    name: 'request-add',
    redirect: { name: 'request-new' },
    component: Home,
    children: [
      {
        path: '',
        name: 'request-new',
        component: Settings
      }
    ]
  },
  {
    path: 'requests/:requestId',
    name: 'request',
    redirect: { name: 'request-overview' },
    component: Home,
    children: [
      {
        path: 'overview',
        name: 'request-overview',
        component: Overview
      },
      {
        path: 'settings',
        name: 'request-settings',
        component: Settings
      }
    ]
  }
]
