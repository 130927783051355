<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title"><span v-if="order.divided && order.parent && order.part">Časť {{ order.part }} - </span>Otváranie ponúk</h4>
      <div v-if="order._canEdit && openingEnabled" class="actions">
        <button :disabled="loading" class="btn btn-success" @click.prevent="downloadProtocolTemplate()">
          <div v-if="loading" class="spinner-border spinner-border-sm text-light mr-2" role="status"></div>
          <i v-else class="icon-doc-1"></i>Zápisnica z otvárania
        </button>
      </div>
    </div>
    <div class="card-block">
      <p class="py-3">
        Termín otvárania ponúk: <span v-if="openingDate" class="text-bold">{{ openingDateStr | formatDateTime }}</span><span class="text-bold" v-else>Termín nebol stanovený</span>
      </p>
      <p class="py-3 text-center" v-if="openingDate && !openingEnabled">
        <countdown :deadline="openingDate" :server-time="serverTime"></countdown>
      </p>
    </div>
    <v-table
      :items="offers"
      :columns="tableColumns"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(index)="{ _item, row }">
        {{ row + 1 }}.
      </template>
      <template v-slot:cell(applicant)="{ item }">
        <span v-if="openingEnabled && $globals.user.type === 'observer' && item.stage === 'unopened'">{{ item | formatApplicantName }}</span>
        <router-link v-else-if="openingEnabled" :to="{ name: `${routePrefix}admin-offers-view`, params: {orderId: order.id, offerId: item.id}}">{{ item | formatApplicantName }}</router-link>
        <span v-else>{{ item | formatApplicantName }}</span>
      </template>
      <template v-slot:cell(sentAt)="{ item }">
        {{ item.sentAt | formatDateTime }}
      </template>
      <template v-slot:cell(state)="{ item }">
        {{ formatStage(item.stage) }}
      </template>
    </v-table>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import saveAs from 'file-saver'
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'OffersOpeningWidget',
  components: { Countdown, VTable },
  inject: ['routePrefix'],
  props: {
    order: {
      type: Object,
      required: true
    },
    round: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data () {
    return {
      offers: [],
      openingDate: '',
      openingDateStr: '',
      loading: false
    }
  },
  watch: {
    round () {
      this.loadOffers()
    }
  },
  computed: {
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    tableColumns () {
      return [
        {
          key: 'index',
          label: 'Por. č.'
        },
        {
          key: 'applicant',
          label: 'Uchádzač'
        },
        {
          key: 'sentAt',
          label: 'Dátum doručenia'
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    }
  },
  filters: {
    formatApplicantName (offer) {
      let name = offer.applicantDetails.businessName || offer.organization.name
      return offer.applicantDetails.groupName || name
    }
  },
  methods: {
    formatStage (value) {
      var stages = {
        unopened: 'Neotvorená',
        opened: (this.round === 1 ? 'Otvorená' : 'Neotvorená'),
        opened2: 'Otvorená',
        excluded: 'Vylúčená'
      }
      return stages[value]
    },
    async loadOffers () {
      this.offers = []
      this.openingDateStr = this.round === 1 ? this.order.bidsOpenAt : this.order.bidsOpenAt2
      this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
      let { data: { offers } } = await this.$http.post('/offer/list', { order: this.order.id, limit: 100, sort: 'sentAt' })
      offers.forEach(offer => {
        if (this.round === 1 || (this.round === 2 && offer.stage !== 'excluded')) {
          this.offers.push(offer)
        }
      })
    },
    async downloadProtocolTemplate () {
      this.loading = true
      try {
        let url = '/template/@bids-open?order=' + this.order.id
        let { data } = await this.$http({ method: 'GET', url, responseType: 'blob' })
        const blob = new Blob([ data ])
        saveAs(blob, 'Zápisnica z otvárania ponúk.docx')
      } catch (error) {
        this.ErrorAlert({ text: 'Pri sťahovaní zápisnice nastala chyba.' }, error?.response?.data.error)
      }
      this.loading = false
    }
  },
  created () {
    this.loadOffers()
  }
}
</script>
