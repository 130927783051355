<template>
  <div
    class="statistic-info"
  >
    <div class="title">
      <i class="icon" :class="icon" />
      <label v-text="label" :title="label"/>
    </div>
    <span class="value" v-text="value"/>

    <div v-if="gain" class="gain">
      <component
        :is="$options.components.StatNumber"
        :format="format.perc"
        :value="gain"
      />
      <span class="ml-2" v-text="gainText"/>
    </div>
  </div>
</template>

<script>
import StatNumber from '@/components/ui/StatNumber.vue'

function allFinite (...values) {
  return values.every(Number.isFinite)
}

export function statsData (label, data, format, emptyValue = 'N/A') {
  const currentYear = new Date().getFullYear()
  const lastYear = Math.max(...data.map(d => d[0]).filter(y => y < currentYear))
  const v1 = data.find(i => i[0] === lastYear - 1) && data.find(i => i[0] === lastYear - 1)[1]
  const v2 = data.find(i => i[0] === lastYear) && data.find(i => i[0] === lastYear)[1]

  const gainText = `${lastYear}/${lastYear - 1}`
  const total = data.find(i => i[0] === null) // check item with year === null (sum value)
  let value = total ? total[1] : v2
  if (!Number.isFinite(value)) {
    value = emptyValue
  }
  return {
    label,
    value: Number.isFinite(value) ? format(value) : emptyValue,
    gain: allFinite(v2, v1) ? (v2 - v1) / Math.abs(v1) : null,
    gainText
  }
}

export default {
  components: { StatNumber },
  props: {
    icon: String,
    gain: Number,
    gainText: String,
    label: String,
    value: {},
    format: Object
  }
}
</script>

<style lang="scss">
.statistic-info {
  padding: 6px 12px;
  display: grid;
  background-color: #fff;
  min-width: 0px;
  // grid-template-rows: 1fr auto minmax(24px, auto);
  grid-template-rows: 1fr auto 24px;
  .title {
    display: flex;
    .icon {
      margin-top: 2px;
      margin-right: 3px;
      font-size: 12px;
    }
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      color: #7E95B7;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .value {
    display: flex;
    font-size: 22px;
    font-weight: 300;
  }
  .gain {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
  }
}
</style>
