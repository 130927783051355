<template>
  <div class="layout free-order">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <div class="public-form-container p-3">
          <div class="registration-header">
            <span class="registration-title">
              <span class="text-primary">Zabudnuté</span>
              heslo<span class="text-primary">.</span>
            </span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">Email <small>*</small></label>
                      <input  id="email" type="email" v-model="user.email" placeholder="Email" :class="{ error: form.email.error, valid: form.email.valid }" class="form-control form-control-light" required="required" aria-required="true">
                      <label v-if="form.email.error1" id="email-error" class="error" for="email">Email musí byť uvedený v tvare  napr. meno@domena.sk.</label>
                      <label v-if="form.email.error2" id="email-error" class="error" for="email">Používateľ s takýmto emailom v systéme neexistuje.</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row no-gutters justify-content-center w-100">
              <router-link to="/login" class="btn btn-tenderia-grey-blue btn-inverse"><i class="icon-arrow-left"></i> Prihlásenie</router-link>
              <button type="submit" @click.prevent="resetPassword" class="btn btn-tenderia-blue">Odoslať</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'PasswordReset',
  components: {
    AppLoginHeader,
    AppFooter
  },
  data () {
    return {
      user: {
        email: null
      },
      form: {
        email: {
          error: false,
          error1: false,
          error2: false,
          valid: false
        }
      }
    }
  },
  methods: {
    validate: function () {
      var state = this.form.email
      state.error = false
      state.error1 = false
      state.error2 = false
      state.valid = false

      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regex.test(String(this.user.email).toLowerCase())) {
        state.valid = true
      } else {
        state.error = true
        state.error1 = true
      }
      return state.valid
    },
    async resetPassword () {
      if (!this.validate()) {
        return
      }
      this.ShowLoading()
      var data = {
        user: {
          email: this.user.email
        }
      }
      try {
        await this.$http.post('/user/password/request', data)
        this.CloseLoading()
        this.OkAlert({ title: 'Reset hesla', text: 'Na váš email sme odoslali ďalšie inštrukcie k nastaveniu hesla.' })
        this.$router.push('/')
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba. Zadali ste správny email?' }, error?.response?.data.error)
      }
    }
  }
}
</script>
