<template>
  <div class="pdd-vertical-60 text-center">
    <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="55" color="#1288b5"/>
  </div>
</template>

<script>
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'

export default {
  name: 'TenderiaProgress',
  components: { FulfillingBouncingCircleSpinner }
}
</script>

<style scoped>
  .fulfilling-bouncing-circle-spinner {
    margin: 0 auto;
  }
</style>
