<template>
  <div class="container-fluid">
    <router-view ref="child" />
  </div>
</template>

<script>

function initialState () {
  return {
    approvalRound: 0,
    name: '',
    description: '',
    value: 0,
    valueWithVat: 0,
    approvers: [],
    budgets: [],
    subjectType: '',
    cpvCode: '',
    justification: '',
    deliveryDate: null,
    note: '',
    applicantInfo: '',
    state: '',
    items: null,
    suppliers: [],
    approvalStances: null,
    id: ''
  }
}

export default {
  name: 'RequestHome',
  inject: ['eventBus'],
  data () {
    return {
      request: initialState()
    }
  },
  provide () {
    return {
      request: this.request
    }
  },
  methods: {
    async loadRequest () {
      this.ShowLoading()
      try {
        const { data: { request } } = await this.$http.post('/request/get', { request: { id: this.$route.params.requestId } })
        Object.assign(this.request, request)
        this.CloseLoading()
        this.eventBus.$emit('request-data', this.request)
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o žiadanke.' }, error?.response?.data.error)
      }
    },
    publishRequestModal () {
      this.YesNoAlert({
        title: 'Odoslať žiadanku na schválenie',
        text: 'Skutočne chcete odoslať žiadanku na schválenie?',
        focusCancel: true,
        cancelButtonText: 'Nie',
        confirmButtonText: 'Áno'
      }).then((result) => {
        if (result.value) {
          this.publishRequest()
        }
      })
    },
    async publishRequest () {
      this.ShowLoading()
      try {
        await this.$http.post('/request/submit', { request: { id: this.$route.params.requestId } })
        this.loadRequest()
        this.CloseLoading()
        this.OkAlert({ text: 'Žiadanka bola odoslaná na schválenie.' })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error?.response?.data.error)
      }
    }
  },
  mounted () {
    this.eventBus.$on('load-request', () => {
      this.loadRequest()
    })
    this.eventBus.$on('publish-request', () => {
      this.publishRequestModal()
    })
    if (this.$route.params.requestId) {
      this.loadRequest()
    }
  },
  destroyed () {
    this.eventBus.$off('load-request')
    this.eventBus.$off('publish-request')
  }
}
</script>
