<template>
  <li :class="{'active': active}" class="email-item">
    <div class="email-tick">
      <div class="checkbox">
        <input type="checkbox" :id="'email-' + message.id" :value="message.id" @change="onSelectedChange" name="selected-messages" />
        <label :for="'email-' + message.id"></label>
      </div>
    </div>
    <div class="open-mail" @click.prevent="$emit('item-clicked', message.id)">
      <div class="email-detail">
        <p class="from">{{ message.to }}</p>
        <p class="subject">{{ message.subject }}</p>
        <p class="type">{{ message.type | formatMessageType }}</p>
        <span class="datetime">{{ message.createdAt | formatDateTime}}</span>
        <span v-if="message.attachments.length > 0" class="attachments"><i class="icon-attach"></i>&nbsp;{{ message.attachments.length }}</span>
      </div>
    </div>
  </li>
</template>

<script>
import BaseItem from './BaseItem.vue'
export default {
  name: 'ItemDraft',
  extends: BaseItem
}
</script>
