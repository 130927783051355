<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: 'rkz-admin-settings', params: {orderId: $route.params.orderId, action: 'edit'}}">Nastavenia</router-link>
      <router-link :to="{ name: 'rkz-admin-documents', params: $route.params}">Dokumenty</router-link>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <router-view v-if="order.id" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RkzAdminHome',
  inject: ['order']
}
</script>
