<template>
  <div>
    <v-chart :style="`min-height: ${minHeight}px`" class="bar-chart-echart" :option="chartOptions" autoresize />
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import {
  TooltipComponent,
  LegendComponent,
  GridComponent
} from 'echarts/components'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import VChart, { THEME_KEY } from 'vue-echarts'

use([
  TooltipComponent,
  LegendComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LabelLayout
])

export default
{
  name: 'BarChartEcharts',
  components: { VChart },
  provide: {
    [THEME_KEY]: 'light',
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    series: {
      type: Object,
      required: true
    },
    formatLabel: {
      type: Function,
      default: null
    },
    xFormat: {
      type: Function,
      default: null
    },
    yFormat: {
      type: Function,
      default: null
    },
    tooltipFormat: {
      type: [String, Function],
      default: ''
    },
    minHeight: {
      type: Number,
      default: 210
    },
    stackStrategy: {
      type: String,
      default: 'samesign'
    },
    labelParam: {
      type: String,
      default: 'value'
    }
  },
  computed: {
    // Prepare data from the input grouped by series name
    preparedData() {
      // Extract all unique x-axis values from all series
      let xAxisValues = [
        ...new Set(Object.values(this.data).flat().map(item => item.xAxis)),
      ]

      // Prepare series data for each series, and assign their color, labels, and bar properties
      const seriesData = Object.keys(this.data).map(seriesName => {
        const seriesConfig = this.series[seriesName] || {}
        return {
          name: seriesConfig.label || seriesName, // Use label if available, otherwise fallback to series name
          type: 'bar', // Change to bar for bar chart
          stack: seriesConfig.stack || null, // Allow stacking if specified
          stackStrategy: seriesConfig.stack && this.stackStrategy,
          itemStyle: {
            color: seriesConfig.color || '#3E97EF', // Assign color if provided
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            fontWeight: 'bold',
            formatter: (params) => {
              return this.formatLabel ? this.formatLabel(params.data[this.labelParam]) : params.data[this.labelParam]
            },
            textBorderColor: '#FFFFFF',
            textBorderWidth: 2,
          },
          data: xAxisValues.map(x => {
            // Find the corresponding value for the x-axis or default to 0 if not found
            const found = this.data[seriesName].find(
              item => item.xAxis === x
            )
            return found || { value: 0 }
          }),
        }
      })

      // Get all labels for the legend, fallback to the series name if no label is provided
      const seriesLabels = Object.keys(this.data).map(
        seriesName => this.series[seriesName]?.label || seriesName
      )

      return {
        xAxisValues,
        seriesData,
        seriesLabels,
      }
    },

    // Calculate splitNumber dynamically based on data range
    dynamicSplitNumber () {
      const allValues = Object.values(this.data).flat().map((item) => item.value)
      const min = Math.min(...allValues)
      const max = Math.max(...allValues)
      const range = max - min

      // Basic rule: split into roughly 5 segments, adjust if needed
      const magnitude = Math.floor(Math.log10(range)) // Determine the range magnitude
      let splitNumber = 5 // Default value

      // Adjust splitNumber based on the magnitude of the range
      if (magnitude >= 6) {
        splitNumber = 3 // Larger ranges like millions
      } else if (magnitude >= 3) {
        splitNumber = 4 // Thousands range
      } else {
        splitNumber = 3 // Smaller ranges
      }

      // Further adjust if necessary; keep between 3 and 10
      return splitNumber
    },
    defaultTooltipFormatter () {
      return (params) => {
        if (Array.isArray(params) && params.length === 1) {
          return `<span class="text-bold" style="color: ${params[0].color}">${params[0].name}</span> <br/><span class="text-bold">${params[0].seriesName}:</span> ${this.formatLabel ? this.formatLabel(params[0].data[this.labelParam]) : params[0].data[this.labelParam]}`
        } else {
          const tooltip = params.map((item) => {
            return `<span class="text-bold">${item.name}</span> <br/><span class="text-bold">${item.seriesName}:</span> ${this.formatLabel ? this.formatLabel(item.data[this.labelParam]) : item.data[this.labelParam]}`
          })
          return tooltip.join('<br/>')
        }
      }
    },
    defaultLabelFormatter () {
      return (params) => {
        return this.formatLabel ? this.formatLabel(params.value) : params.value
      }
    },
    // Generate the chart options dynamically
    chartOptions() {
      const { xAxisValues, seriesData, seriesLabels } = this.preparedData

      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          borderColor: Object.keys(this.series).length === 1
            ? this.series[Object.keys(this.series)[0]].color
            : null,
          formatter: this.tooltipFormat || this.defaultTooltipFormatter
        },
        legend: {
          data: seriesLabels,
          bottom: 'bottom',
          type: 'scroll',
          pageIcons: {
            horizontal: [
              'M 0.8163 -3.7594 L 3.5781 -1.2538 C 4.2207 -0.6707 5.25 -1.1267 5.25 -1.9944 L 5.25 -7.0056 C 5.25 -7.8733 4.2207 -8.3293 3.5781 -7.7463 L 0.8163 -5.2406 C 0.3789 -4.8438 0.3789 -4.1563 0.8163 -3.7594 Z',
              'M -0.8163 3.7594 L -3.5781 1.2538 C -4.2207 0.6707 -5.25 1.1267 -5.25 1.9944 L -5.25 7.0056 C -5.25 7.8733 -4.2207 8.3293 -3.5781 7.7463 L -0.8163 5.2406 C -0.3789 4.8438 -0.3789 4.1563 -0.8163 3.7594 Z',
            ]
          },
        },
        grid: {
          top: 28,
          left: '3%',
          right: 24,
          bottom: 28,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: xAxisValues,
          axisLabel: {
            fontWeight: 'bold',
            color: '#000',
            fontSize: 13,
            formatter: (value) => {
              return this.xFormat ? this.xFormat(value) : value
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontWeight: 'bold',
            color: '#000',
            fontSize: 13,
            formatter: (value) => {
              return this.yFormat ? this.yFormat(value) : value
            },
          },
          splitNumber: this.dynamicSplitNumber
        },
        series: seriesData,
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bar-chart-echart {
  display: flex;
  min-width: 0;
  svg {
    left: unset;
  }
}
</style>
