<template>
  <span :class="textClass">{{ title }}</span>
</template>

<script>
export default {
  name: 'SdAdminApplicationStage',
  props: ['application'],
  computed: {
    textClass () {
      return `application-stage-${this.application.stage}`
    },
    title () {
      let stages = {
        unopened: 'Nevyhodnotená',
        opened: 'Nevyhodnotená',
        excluded: 'Neschválená',
        accepted: 'Schválená'
      }
      return stages[this.application.stage]
    }
  }
}
</script>
