<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="pull-left card-title ">Žiadosti o účasť</h4>
      <div class="actions">
        <button
          v-if="offers.length > 0"
          class="btn btn-success"
          @click.prevent="exportToXsl()"
        >
          <i class="icon-excel"></i>Exportovať do excelu
        </button>
      </div>
    </div>
    <div class="card-block">
      <p class="text-dark pdd-vertical-25">
        Termín otvárania žiadostí: <span v-if="openingDate" class="text-bold">{{ order.bidsOpenAt | formatDateTime }}</span><span class="text-bold" v-else>Termín nebol stanovený</span>
      </p>
      <p class="text-dark text-center pdd-vertical-25" v-if="openingDate && !openingEnabled">
        <countdown :deadline="openingDate" :server-time="serverTime"></countdown>
      </p>
    </div>
    <v-table
      :items="offers"
      :columns="tableColumns"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(index)="{ _item, row }">
        {{ row + 1 }}
      </template>
      <template v-slot:cell(applicant)="{ item }">
        <span v-if="openingEnabled && $globals.user.type === 'observer' && item.stage === 'unopened'">{{ item | formatApplicantName }}</span>
        <router-link
          v-else-if="openingEnabled"
          :to="{ name: `${routePrefix}admin-applications-view`, params: {orderId: order.id, applicationId: item.id}}"
        >
          {{ item | formatApplicantName }}
        </router-link>
        <span v-else>{{ item | formatApplicantName }}</span>
      </template>
      <template v-slot:cell(sentAt)="{ item }">
        {{ item.sentAt | formatDateTime }}
      </template>
      <template v-slot:cell(state)="{ item }">
        <stage :application="item" ref="stage"/>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <button
          v-if="openingEnabled && item.stage !== 'excluded' && order._canEdit"
          class="btn btn-xs btn-danger"
          @click="excludeApplicationModal(item)"
        >
          Vylúčiť
        </button>
        <button
          v-if="openingEnabled && item.stage !== 'accepted' && order._canEdit"
          class="btn btn-xs btn-success"
          @click="acceptApplicationModal(item)"
        >
          Akceptovať
        </button>
      </template>
    </v-table>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import Stage from '../components/AdminApplicationStage'
import ExcludeApplicationModal from '../components/ExcludeApplicationModal'
import VTable from '@/components/ui/Table.vue'
import XLSX from 'xlsx'

export default {
  name: 'KdAdminApplications',
  components: { Countdown, Stage, VTable },
  inject: ['order', 'routePrefix'],
  data () {
    return {
      offers: []
    }
  },
  computed: {
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    openingDate () {
      return new Date(this.order.bidsOpenAt)
    },
    tableColumns () {
      return [
        {
          key: 'index',
          label: 'Por. č.'
        },
        {
          key: 'applicant',
          label: 'Uchádzač'
        },
        {
          key: 'sentAt',
          label: 'Dátum doručenia'
        },
        {
          key: 'state',
          label: 'Stav'
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  filters: {
    formatApplicantName (offer) {
      let name = offer.applicantDetails.businessName || offer.organization.name
      return offer.applicantDetails.groupName || name
    }
  },
  async created () {
    let { data: { offers } } = await this.$http.post('/offer/list', { order: this.order.id, sort: 'sentAt' })
    this.offers = offers
  },
  methods: {
    exportToXsl () {
      let vm = this
      let aplicants = XLSX.utils.json_to_sheet(this.offers.map((item, index) => {
        return {
          index: index + 1,
          name: vm.$options.filters.formatApplicantName(item),
          sentAt: vm.$options.filters.formatDateTime(item.sentAt),
          state: vm.$refs.stage._computedWatchers.title.value
        }
      }))
      delete aplicants['A1'].w; aplicants['A1'].v = 'Por. č.'
      delete aplicants['B1'].w; aplicants['B1'].v = 'Uchádzač'
      delete aplicants['C1'].w; aplicants['C1'].v = 'Dátum doručenia'
      delete aplicants['D1'].w; aplicants['D1'].v = 'Stav'
      aplicants['!cols'] = [
        { wpx: 50 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 }
      ]
      let document = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(document, aplicants, 'Žiadosti o účasť')
      XLSX.writeFile(document, 'zoznam_ziadosti.xlsx')
    },
    excludeApplicationModal (application) {
      this.$emit('vuedals:new', {
        name: 'exclude-offer',
        escapable: true,
        title: 'Vylúčiť ponuku',
        props: {
          offer: application
        },
        component: ExcludeApplicationModal,
        onClose: (values) => {
          if (values) {
            application.stage = values.stage
          }
        }
      })
    },
    acceptApplicationModal (application) {
      this.YesNoAlert({
        title: 'Skutočne chcete akceptovať túto žiadosť?',
        text: 'Uchádzač bude zaradený do zoznamu kvalifikovaných dodávateľov',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Akceptovať'
      }).then(async (result) => {
        if (result.value) {
          this.ShowLoading()
          let data = {
            offer: {
              id: application.id,
              stage: 'accepted'
            }
          }
          try {
            await this.$http.post('/offer/stage/set', data)
            application.stage = 'accepted'
            this.CloseLoading()
            this.OkAlert({ text: 'Žiadosť bola akceptovaná.' })
          } catch (error) {
            this.CloseLoading()
            this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
          }
        }
      })
    }
  }
}
</script>
