<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <template v-if="order.divided">
          <organizations-list v-for="part in order._parts" :key="part.id" :organizations="part.applicants" :title="`Zoznam uchádzačov - ${part.name}`" />
        </template>
        <template v-else>
          <organizations-list :organizations="order.applicants" title="Zoznam uchádzačov" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationsList from '@/components/order/common/components/OrganizationsList.vue'

export default {
  name: 'PrkAdminApplicants',
  components: { OrganizationsList },
  inject: ['order']
}
</script>
