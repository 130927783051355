<template>
  <multiselect
    v-model="selectedRoles"
    :options="roles"
    track-by="id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    placeholder="Vyberte si role"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'RolesSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      selectedRoles: [],
      roles: []
    }
  },
  watch: {
    selectedRoles (val) {
      if (!val) {
        this.$emit('input', [])
      } else {
        this.$emit('input', val.map(role => role.id))
      }
    }
  },
  async mounted () {
    const response = await this.$http.post('/organization/role/list', {
      /*  limit: 10000, fields: ['name', 'id'], */
      organization: {
        id: this.$globals.userOrganization.id
      }
    })
    this.roles = response.data.roles
    if (this.value) {
      this.selectedRoles = this.roles.filter(role => this.value.includes(role.id))
    }
  }
}
</script>
