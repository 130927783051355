<script>
import BaseOffer from './BaseOffer.vue'

export default {
  name: 'BaseUserOfferView',
  extends: BaseOffer,
  inject: ['routePrefix'],
  data () {
    return {
      bidsLimitTo: false,
      criteriaOpeningDate: false
    }
  },
  computed: {
    canSendOffer () {
      return this.bidsLimitTo && this.offer.id && this.offer.state === 'new' && this.serverTime < this.bidsLimitTo
    },
    canCreateOffer () {
      return this.bidsLimitTo && this.serverTime < this.bidsLimitTo
    },
    canRevokeOffer () {
      return this.bidsLimitTo && this.offer.id && this.offer.state === 'sent' && this.serverTime < this.bidsLimitTo
    }
  },
  methods: {
    addOffer () {
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.routePrefix}user-offers-add-parts`, params: { orderId: this.order.parent, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.routePrefix}user-offers-add`, params: { orderId: this.order.id } })
      }
    },
    sendOffer () {
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.routePrefix}user-offers-add-parts`, params: { orderId: this.order.parent, partId: this.order.id, action: 'send' } })
      } else {
        this.$router.push({ name: `${this.routePrefix}user-offers-add`, params: { orderId: this.order.id, action: 'send' } })
      }
    },
    prepareCriteria () {
      for (let proposal of this.offer.proposals) {
        for (let criterion of this.order.criteria) {
          if (criterion._id === proposal.criterion && proposal.value) {
            criterion.unit = criterion.type === 'price' ? 'EUR' : criterion.unit
            this.criteria.push(Object.assign({}, criterion, { value: proposal.value }))
          }
        }
      }
      this.priceCriterion = this.findPriceCriterion()
      // Pre pripad, ze zakazka bude vytvorena bez hodnotiacich kriterii. Ano aj take sa objavili ....
      if (this.priceCriterion) {
        this.decimalCount = this.priceCriterion.decimalCount
      }
    }
  }
}
</script>
