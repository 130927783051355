import { ReactiveModel } from 'vue-rawmodel'

export default class Invoice extends ReactiveModel {
  constructor (data = {}) {
    super(data)

    this.defineField('id', {
      type: 'String'
    })

    this.defineField('name', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('testText', {
      type: 'String'
    })
    this.populate(data)
    this.commit()
  }
}
