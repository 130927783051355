<template>
  <div>
    <div class="row">
      <template v-if="order.divided">
        <div class="col-md-12" v-for="part in order._parts" :key="part.id">
          <user-offers-opening-widget  :order="part"  />
          <user-offers-evaluation-widget v-if="evaluationEnabled" :order="part"/>
        </div>
      </template>
      <div class="col-md-12" v-else>
        <user-offers-opening-widget :order="order" />
        <user-offers-evaluation-widget v-if="evaluationEnabled" :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import UserOffersOpeningWidget from '@/components/order/common/components/UserOffersOpeningWidget.vue'
import UserOffersEvaluationWidget from '@/components/order/common/components/UserOffersEvaluationWidget.vue'

export default {
  name: 'UserOffersOpening',
  components: { UserOffersOpeningWidget, UserOffersEvaluationWidget },
  inject: ['order'],
  data () {
    return {
      openingDate: null,
      dateNotBefore: process.env.VUE_APP_ENBL_OFFER_EVAL_FROM,
    }
  },
  computed: {
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    evaluationEnabled () {
      return this.openingEnabled && this.order.biddingMethod !== 'costEffectiveness' && !this.order.divided && this.orderCreatedAt > this.evaluationEnabledFrom
    },
    orderCreatedAt () {
      return new Date(this.order.createdAt)
    },
    evaluationEnabledFrom () {
      return new Date(this.dateNotBefore)
    }
  },
  created () {
    if (this.order.procedureType === 'tste' && this.order.bidsOpenAt2) {
      this.openingDate = new Date(this.order.bidsOpenAt2)
    }
    if (this.order.procedureType === 'ssse' && this.order.bidsOpenAt) {
      this.openingDate = new Date(this.order.bidsOpenAt)
    }
  }
}
</script>
