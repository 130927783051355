<template>
  <div class="containter">
    <div class="row">
      <div class="col-md-4 control-label">Kategória:</div>
      <div class="col">{{ row.category | formatLogsCategory}}</div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">Názov:</div>
      <div class="col">{{ row.action | formatLogsActions(row) }}</div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">Dátum:</div>
      <div class="col">{{ row.createdAt | formatDateTime }}</div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">Používateľ:</div>
      <div class="col">{{ row.user | formatUserName }}</div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">Výsledok:</div>
      <div class="col">{{ result }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderSettingsLogDetail',
  props: ['row'],
  data () {
    return {
      result: ''
    }
  },
  created () {
    this.result = this.row.status === '200' ? 'Úspech' : this.$options.filters.formatLogErrorMessage(this.row.category, this.row.data.permission)
  },
  filters: {
    formatUserName (user) {
      return `${user.title1} ${user.firstname} ${user.lastname} ${user.title2}`
    },
    formatLogErrorMessage (category, message) {
      let errors = {
        'order:general': {
          '': ''
        },
        'order:parts': {
          '': ''
        },
        'order:documents': {
          '': ''
        },
        'order:communication': {
          '': ''
        },
        'order:criteria': {
          '': ''
        },
        'order:commision': {
          '': ''
        },
        'order:offers': {
          'REQUEST_ERROR_TIMELIMIT_CONSTRAINT': 'Vypršanie času',
          'REQUEST_ERROR_ACCESS_DENIED': 'Prístup zamietnutý'
        },
        'order:items': {
          '': ''
        },
        'order:persons': {
          '': ''
        },
        'order:suppliers': {
          '': ''
        }
      }
      return errors[category][message] || 'Chyba'
    }
  }
}
</script>
