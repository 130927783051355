<template>
  <multiselect
    v-model="cpvCode"
    :disabled="disabled"
    :options="cpvCodes"
    track-by="code"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="true"
    :custom-label="customLabel"
    :placeholder="placeholder"
    :show-labels="false"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'MainCpvCodeSelect',
  components: { Multiselect },
  props: {
    value: String,
    disabled: false,
    placeholder: {
      required: false,
      default: 'Vyberte CPV kód'
    }
  },
  data: function () {
    return {
      loaded: false,
      cpvCode: null,
      cpvCodes: []
    }
  },
  watch: {
    cpvCode (value) {
      if (value && value.code) {
        this.$emit('input', value.code)
      } else {
        this.$emit('input', '')
      }
    },
    value (val) {
      if (!val) {
        this.cpvCode = null
      }
    }
  },
  methods: {
    customLabel ({ text, code }) {
      return `${text} - (${code})`
    }
  },
  async mounted () {
    var vm = this
    var codes = JSON.parse(localStorage.getItem('CPV_CODES_MAIN'))
    if (codes != null) {
      vm.cpvCodes = codes
      vm.loaded = true
    } else {
      await this.$http.post('/enum/cpvmain' + '/list', { limit: 10000 }).then(function (response) {
        localStorage.setItem('CPV_CODES_MAIN', JSON.stringify(response.data.enum))
        vm.cpvCodes = response.data.enum
        vm.loaded = true
      })
    }
    this.cpvCode = this.cpvCodes.find(cpvCode => cpvCode.code === this.value)
  }
}
</script>
