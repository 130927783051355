<template>
  <div class="layout">
    <app-login-header :order="order" />
    <app-sub-header
      :back-link="backLink"
    />
    <!-- Page Container START -->
    <div class="page-container">
      <registration-banner class="pdd-top-15"/>
      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <ul class="order-main-menu mb-3">
            <li>
              <router-link :to="{ name: 'freeOrderHome', params: {orderId: this.$route.params.orderId}}">Prehľad</router-link>
            </li>
          </ul>
          <router-view v-if="order.id"></router-view>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->

    <!-- Footer START -->
    <footer class="content-footer">
      <app-footer />
    </footer>
    <!-- Footer END -->
  </div>
  <!-- Page Container END -->
</template>

<script>

import RegistrationBanner from '@/components/ui/RegistrationBanner.vue'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppSubHeader from '@/components/order/AppSubHeader.vue'

export default {
  name: 'FreeOrder',
  inject: ['eventBus'],
  components: { AppLoginHeader, AppFooter, RegistrationBanner, AppSubHeader },
  data () {
    return {
      backLink: this.$route.fullPath,
      order: {
        id: null,
        _parts: []
      }
    }
  },
  provide () {
    return {
      order: this.order
    }
  },
  beforeRouteEnter (to, from, next) {
    // Access the previous route from the 'from' argument
    next(vm => {
      vm.backLink = from.fullPath
    })
  },
  methods: {
    setInterestedModal () {
      this.YesNoAlert({
        text: 'Musíte byť zaregistrovaný.',
        type: 'info',
        confirmButtonText: 'Zaregistrovať sa',
        cancelButtonText: 'Zrušiť'
      }).then((result) => {
        if (result.value) {
          if (this.$globals.authToken && this.$globals.user.type === 'contractor') {
            this.$openOrder(this.order.id, this.order.type, this.order.subtype)
          } else {
            this.$router.push({ path: '/' })
          }
        }
      })
    }
  },
  async created () {
    try {
      let { data: { order } } = await this.$http.post('/order/read', { order: { id: this.$route.params.orderId } })
      Object.assign(this.order, order)
      if (this.order.divided) {
        let { data: { orders: parts } } = await this.$http.post('/order/catalog', { parent: this.$route.params.orderId, limit: 100 })
        this.order._parts = parts
      }
      this.eventBus.$emit('order-data', this.order)
    } catch (error) {
      this.ErrorAlert({ title: 'Chyba', text: `Zákazku nie je možné zobraziť. Chyba ${error?.response?.data.error.code}` }, error?.response?.data.error)
    }
  }
}
</script>
