<template>
  <multiselect
    v-model="contractor"
    :options="contractors"
    track-by="name"
    label="name"
    openDirection="bottom"
    placeholder="Vyberte si obstarávateľa"
    :show-labels="false"
    :disabled="disabled"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'ContractorSelect',
  components: { Multiselect },
  props: {
    value: String,
    disabled: Boolean
  },
  data () {
    return {
      contractor: null,
      contractors: []
    }
  },
  mounted () {
    this.contractors = this.$globals.user.organizations
    if (this.value) {
      this.contractor = this.contractors.find(contractor => contractor.id === this.value)
    }
  },
  watch: {
    contractor () {
      if (this.contractor === null) {
        this.$emit('input', '')
      } else {
        this.$emit('input', this.contractor.id)
      }
    }
  }
}
</script>
