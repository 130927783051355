<template>
  <multiselect
    v-model="type"
    :options="options"
    track-by="code"
    label="text"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="true"
    :show-labels="false"
    :class="{ error: error }"
    placeholder="Vyberte si typ organizačnej jednotky"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'DepartmentTypeSelect',
  components: { Multiselect },
  props: {
    value: '',
    error: Boolean
  },
  data () {
    return {
      type: null,
      options: [
        { code: 'department', text: 'Oddelenie' },
        { code: 'division', text: 'Odbor' },
        { code: 'unit', text: 'Úsek' },
        { code: 'section', text: 'Referát' },
        { code: 'headquarters', text: 'Ústredie' }
      ]
    }
  },
  watch: {
    type (value) {
      if (value === null) {
        this.$emit('input', '')
      } else {
        this.$emit('input', value.code)
      }
    }
  },
  mounted () {
    if (this.value) {
      this.type = this.options.find(option => option.code === this.value)
    }
  }
}
</script>
