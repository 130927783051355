<template>
  <div class="authentication">
    <div class="sign-in">
      <router-view/>
    </div>
  </div>
</template>

<script>
/* import router from '../router' */
export default {
  name: 'TenderiaAppIndex',
  beforeCreate () {
    // registracia/prihlasenie dodavatela z pozvanky. Rusime existujucu session, pretoze potrebujeme aby sa nam prihlasil/registroval a vytvorilo sa prepojenie dodavatela s pouzivatelom
    if (this.$http.defaults.headers.common['Authorization'] && this.$route.params.supplierToken) {
      localStorage.clear()
      window.location.reload()
    }

    // ak je pouzivatel uz prihlaseny presmerujeme ho na dashboard. V pripade ze session exspirovala, prve volanie backendu, ktore skonci chybou 401 ho presmeruje na login
    /* if (localStorage.getItem('token') && localStorage.getItem('user') && this.$route.name === 'HomePage') {
      router.push({path: '/app/dashboard'})
    } */
  }
}
</script>
