<template>
  <div class="homepage-layout">
    <app-login-header />
    <div class="page-container">
      <div class="main-content">
        <div class="section-title row no-gutters justify-content-between">
          <span><span class="text-primary">Zoznam </span>vyhlásených zákaziek</span>
          <button @click="showFilter = !showFilter" class="btn btn-tenderia-blue filter-btn">
            <i class="icon-filter"></i><span>{{showFilter ? 'Skryť' : 'Zobraziť'}} filter</span>
          </button>
        </div>
        <v-collapsible>
          <div v-if="showFilter">
            <public-order-filters v-model="filter" />
          </div>
        </v-collapsible>
        <div class="card dark">
          <div class="card-heading">
            <h1 class="card-title">Verejné obstarávania tenderia<span class="text-primary">.</span></h1>
          </div>
          <v-table
            :items="contracts"
            :columns="contractsColumns"
            :sort.sync="tableSort"
            item-key="id"
            class="dark contracts-table"
          >
            <template v-slot:cell(name)="{ item }">
              <router-link :to="`/public/order/${item.id}`">{{ item.name }}</router-link>
            </template>
            <template v-slot:cell(organizationName)="{ item }">
              <router-link :to="`/public/organization/${item.organization.ico}`">{{ item.organization.name }}</router-link>
            </template>
            <template v-slot:cell(journalAnnouncementDate)="{ item }">
              {{ item.journalAnnouncementDate | formatDateTime }}
            </template>
            <template v-slot:cell(procedureType)="{ item }">
              {{ item | formatProcedureType }}
            </template>
            <template v-slot:cell(state)="{ item }">
              <order-state :state="item.state" display="text"></order-state>
            </template>
          </v-table>
        </div>
        <div class="row justify-content-center">
          <router-link to="/public/orders" class="btn btn-default">Ďalšie verejné obstarávania</router-link>
        </div>
      </div>
      <div class="functions-background">
        <div class="page-container functions-content">
          <div class="section-title">
            <span><span class="text-primary text-bold">Výhody a funkcionality </span> Prečo sa rozhodnúť pre tenderia?</span>
          </div>
          <div class="functions">
            <div data-aos="fade-right" class="card img1">
              <span class="card-title">Všetky druhy zákaziek</span>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Zákazky vrátane všetkých druhov postupov</span>
              </div>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Prieskum trhu a priame oslovenia</span>
              </div>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Vlastná databáza dodávateľov</span>
              </div>
            </div>
            <div data-aos="fade-right" class="card img2">
              <span class="card-title">Intuitívne prostredie</span>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Jednoduché a rýchle ovládanie</span>
              </div>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Vlastná správa používateľov organizácie</span>
              </div>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Efektívny dizajn a rýchla navigácia</span>
              </div>
            </div>
            <div data-aos="fade-left" class="card img3">
              <span class="card-title">Automatické hodnotenie</span>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Overenie osobného a ekonomického postavenia uchádzačov</span>
              </div>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Overenie zápisu uchádzačov  v RPVS, ZHS, zozname osôb so zákazom</span>
              </div>
            </div>
            <div data-aos="fade-left" class="card img4">
              <span class="card-title">Konflikt záujmov</span>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Overenie konfliktu záujmov</span>
              </div>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Overenie kolúzneho správania sa uchádzačov</span>
              </div>
              <div class="row no-gutters align-items-center flex-nowrap">
                <i class="icon-arrow-left"></i>
                <span>Generovanie protokolu vo formáte pdf a xlsx</span>
              </div>
            </div>
          </div>

          <div data-aos="fade-down" class="card laptop-card">
            <span class="card-title">
              Ako funguje tenderia?
            </span>
            <div class="content">
              <span>
                Systém navrhnutý profesionálmi z oblasti verejného obstarávania, v ktorom nájdete všetko čo potrebujete.
                Jednoduchá navigácia, intuitívne prostredie a prepracovaný dizajn.
                Prepojenie s informačným systémom TRANSPAREX zaručuje okamžité vyhodnocovanie osobného a ekonomického  postavenia a overenie konfliktu záujmov.
              </span>
            </div>
            <a href="mailto:podpora@tenderia.sk" class="btn btn-success mt-4" target="_blank">
              Mám záujem
            </a>
          </div>
        </div>
      </div>
      <div class="blue-background">
        <div class="blue-background-content">
          <div class="section-title">
            <span class="text-bold dark-blue">Vyskúšajte systém na</span>
            <span class="text">Elektronizáciu verejného obstarávania</span>
          </div>
          <a href="mailto:podpora@tenderia.sk" class="btn btn-default" target="_blank">
            Kontaktný formulár
          </a>
        </div>
      </div>
      <footer class="content-footer">
        <app-footer />
      </footer>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import OrderState from '@/components/OrderState.vue'
import VTable from '@/components/ui/Table.vue'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import VCollapsible from '@/components/ui/Collapsible.vue'
import PublicOrderFilters from '@/components/PublicOrderFilters.vue'

export default {
  name: 'HomePage',
  components: { OrderState, VTable, AppLoginHeader, PublicOrderFilters, VCollapsible, AppFooter },
  data () {
    return {
      contracts: [],
      showFilter: true,
      filter: {
        fulltext: '',
        ico: '',
        cpvCode: '',
        updatedAt: '',
        biddingMethod: '',
        fundingByEu: '',
        type: '',
        subjectType: ''
      },
      tableSort: {
        sortBy: 'journalAnnouncementDate',
        sort: 'desc'
      }
    }
  },
  computed: {
    contractsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov zákazky',
          sortable: true
        },
        {
          key: 'organizationName',
          label: 'Názov obstarávateľa',
          sortable: true
        },
        {
          key: 'journalAnnouncementDate',
          label: 'Dátum založenia',
          sortable: true
        },
        {
          key: 'procedureType',
          label: 'Druh Postupu',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    updatedAtValue () {
      const { updatedAt } = this.filter
      return updatedAt && updatedAt.code && moment().subtract(parseInt(updatedAt.code), 'd')
    },
    fetchParams () {
      const { fulltext, ico, cpvCode, updatedAt, biddingMethod, fundingByEu, type, subjectType } = this.filter
      return {
        limit: 10,
        sort: this.$format.sortString(this.tableSort),
        filter: fulltext,
        ico: ico,
        cpvCode: cpvCode && cpvCode.code,
        updatedFrom: this.updatedAtValue && this.updatedAtValue.toISOString(),
        updatedTo: updatedAt && updatedAt.code && moment().toISOString(),
        biddingMethod: biddingMethod && biddingMethod.code,
        fundingByEu: fundingByEu && fundingByEu.code,
        type: type && type.code,
        subjectType: subjectType && subjectType.code
      }
    }
  },
  methods: {
    async fetchContracts () {
      const { data } = await this.$http.post('/order/catalog', this.fetchParams)
      this.contracts = data.orders
    }
  },
  mounted () {
    this.fetchContracts()
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    }
  }
}
</script>
<style scoped lang="scss">
:deep(.contracts-table) {
  tbody {
    tr {
      &:hover {
        background-color: #3E97EF;
        td a {
          color: #fff;
        }
      }
    }
  }
}
</style>
