<template>
  <div class="layout">
    <app-login-header :order="order" />
    <app-sub-header
      :back-link="backLink"
    />
    <!-- Page Container START -->
    <div class="page-container">
      <registration-banner class="pdd-top-15"/>
      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <ul class="order-main-menu mb-3">
            <li>
              <router-link :to="{ name: 'publicMarketResearchHome', params: $route.params}">Prehľad</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'publicMarketResearchOffer', params: $route.params}">Moja ponuka</router-link>
            </li>
          </ul>
          <router-view v-if="order.id" ref="child"></router-view>
        </div>
      </div>
      <!-- Content Wrapper END -->

      <!-- Footer START -->

      <!-- Footer END -->
    </div>
    <!-- Page Container END -->
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
import RegistrationBanner from '@/components/ui/RegistrationBanner.vue'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppSubHeader from '@/components/order/AppSubHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'PublicMarketResearch',
  inject: ['eventBus'],
  components: { AppFooter, AppLoginHeader, RegistrationBanner, AppSubHeader },
  data () {
    return {
      backLink: this.$route.fullPath,
      order: {
        id: null
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    // Access the previous route from the 'from' argument
    next(vm => {
      vm.backLink = from.fullPath
    })
  },
  provide () {
    return {
      order: this.order
    }
  },
  methods: {
    setUserDefaultOrganization (organizations, relations) {
      let homeOrganizationId = ''
      for (let i = 0; i < relations.length; i++) {
        if (relations[i].type === 'home') {
          homeOrganizationId = relations[i].organization

          this.$globals.user = {
            ...this.$globals.user,
            roles: relations[i].roles,
            permissions: relations[i].permissions.map(permission => `${permission.group}/${permission.object}/${permission.action}/${permission.level}`)
          }
          break
        }
      }
      for (let i = 0; i < organizations.length; i++) {
        if (organizations[i].id === homeOrganizationId) {
          this.$globals.userOrganization = organizations[i]
          localStorage.setItem('userOrganization', JSON.stringify(organizations[i]))
          break
        }
      }
    },
    logout () {
      this.$http.post('/auth/logout')
      localStorage.clear()
    }
  },
  async mounted () {
    window.addEventListener('beforeunload', this.logout)
    try {
      const response = await this.$http.post('/auth/login', { supplierToken: this.$route.params.token })
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('sessionType', response.data.sessionType)
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
      this.$globals.sessionType = response.data.sessionType
      this.$globals.authToken = response.data.token
      this.$globals.user = response.data.user
      this.$globals.user.authLevel = response.data.authLevel
      this.$globals.user.relations = response.data.relations
      this.$tenderiaService.syncCountries()
      this.$tenderiaService.syncCpvCodes()
      this.$globals.user.organizations = response.data.organizations
      this.setUserDefaultOrganization(response.data.organizations, response.data.relations)
      localStorage.setItem('user', JSON.stringify(this.$globals.user))
      let { data: { order } } = await this.$http.post('/order/read', { order: { id: this.$route.params.orderId } })
      Object.assign(this.order, order)
      this.eventBus.$emit('order-data', this.order)
    } catch (error) {
      this.ErrorAlert({ text: 'Neplatná pozvánka' }, error?.response?.data.error).then(() => { this.$router.push({ path: '/' }) })
    }
  },
  destroyed () {
    this.logout()
  }
}
</script>
<style type="text/css">
  .free-order .page-container {
    padding-left: 16px;
  }
  .user-info {
    min-width: 150px;
  }
</style>
