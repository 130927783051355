<template>
  <div class="container-fluid" id="settings-budget-edit">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">{{ $route.params.budgetId ? 'Upraviť rozpočet' : 'Vytovriť nový rozpočet'}} </h4>
      </div>
      <div class="card-block">
        <form novalidate="novalidate" v-if="!$route.params.budgetId || this.dataLoaded">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name" class="text-bold text-dark">Názov <small>*</small></label>
                <input type="text" v-model="budget.name" class="form-control" placeholder="Názov" :class="{ error:  budget.getField('name').hasErrors() }" />
                <label v-if=" budget.getField('name').hasErrors()" class="error">
                  {{  budget.getField('name').errors | firstMessage }}
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="type" class="text-bold text-dark">Výška <small>*</small></label>
                <!-- <input type="text" v-model="budget.value" class="form-control" placeholder="Výška" :class="{ error:  budget.getField('value').hasErrors() }" /> -->
                <vue-numeric
                  class="form-control"
                  currency="€"
                  separator="space"
                  decimal-separator=","
                  :precision="2"
                  :empty-value="0"
                  v-model="budget.value"
                  :class="{ error:  budget.getField('value').hasErrors() }"
                />
                <label v-if=" budget.getField('value').hasErrors()" class="error">
                  {{  budget.getField('value').errors | firstMessage }}
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="type" class="text-bold text-dark">Dátum od</label>
                <date-input
                  name="validFrom"
                  v-model="budget.validFrom"
                  placeholder="Dátum od"
                  :error="budget.getField('validFrom').hasErrors()"
                ></date-input>
                <label v-if="budget.getField('validFrom').hasErrors()" class="error">
                  {{  budget.getField('validFrom').errors | firstMessage }}
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="type" class="text-bold text-dark">Dátum do</label>
                <date-input
                  name="validTo"
                  v-model="budget.validTo"
                  placeholder="Dátum do"
                  :error="budget.getField('validTo').hasErrors()"
                ></date-input>
                <label v-if=" budget.getField('validTo').hasErrors()" class="error">
                  {{  budget.getField('validTo').errors | firstMessage }}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="goBack"><i class="icon-arrow-left"></i> Späť</button>
          <button class="btn btn-success" @click.prevent="save" :disabled="budget.hasErrors()"><i class="icon-save"></i> Uložiť</button>
        </div>
      </div>
    </div>
    <template v-if="$route.params.budgetId">
      <div class="card">
        <div class="card-heading">
          <h4 class="card-title">Schvaľovatelia</h4>
          <div class="actions">
            <button
              class="btn btn-tenderia-blue"
              @click="addUsersModal"
            >
              <i class="icon-add font-size-14"></i> Pridať
            </button>
          </div>
        </div>
        <v-table
          :items="usersItems"
          :columns="usersColumns"
          :sort.sync="tableSort"
          item-key="id"
          class="light"
        >
          <template v-slot:cell(actions)="{ item }">
            <a
              @click.stop="removeApproverModal(item)"
              class="text-danger"

              title="Zmazať"
            >
              <i class="icon-delete"></i>
            </a>
          </template>
        </v-table>
        <div class="card-footer">
          <v-pagination
            v-if="usersTotal"
            :url-navigation="false"
            :page.sync="page"
            :per-page.sync="perPage"
            :total-elements="usersTotal"
          />
        </div>
      </div>

      <div class="card">
        <div class="card-heading">
          <h4 class="card-title">Organizačné jednotky</h4>
          <div class="actions">
            <button
              class="btn btn-tenderia-blue"
              @click="addDepartmentsModal"
            >
              <i class="icon-add font-size-14"></i> Pridať
            </button>
          </div>
        </div>
        <v-table
          :items="departmentsItems"
          :columns="departmentsColumns"
          :sort.sync="tableSort"
          item-key="id"
          class="light"
        >
          <template v-slot:cell(actions)="{ item }">
            <a
              @click.stop="removeDepartmentModal(item)"
              class="text-danger"

              title="Zmazať"
            >
              <i class="icon-delete"></i>
            </a>
          </template>
        </v-table>
        <div class="card-footer">
          <v-pagination
            v-if="departmentsTotal"
            :url-navigation="false"
            :page.sync="page"
            :per-page.sync="perPage"
            :total-elements="departmentsTotal"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import AddDepartmentModal from '@/components/budgets/AddDepartmentModal.vue'
import DateInput from '@/components/forms/DateInput.vue'
import AddApproverModal from './AddApproverModal.vue'
import VueNumeric from 'vue-numeric'
export default {
  name: 'SettingsAddBudget',
  components: { VPagination, VTable, DateInput, VueNumeric },
  data () {
    return {
      budget: new this.$models.Budget({
        vm: this,
        dataKey: 'budget'
      }),
      approvers: [],
      orgunits: [],
      page: 1,
      perPage: 10,
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      },
      dataLoaded: false
    }
  },
  computed: {
    usersTotal () {
      return this.approvers && this.approvers.length
    },
    usersItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.approvers && this.approvers.slice(from, to)
    },
    usersColumns () {
      return [
        {
          key: 'firstname',
          label: 'Meno',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Priezvisko',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    },
    departmentsTotal () {
      return this.orgunits && this.orgunits.length
    },
    departmentsItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.orgunits && this.orgunits.slice(from, to)
    },
    departmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        /* {
          key: 'type',
          label: 'Typ',
          sortable: true
        }, */
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'settingsBudgets' })
    },
    removeApproverModal (user) {
      this.YesNoAlert({
        title: 'Odstrániť schvaľovateľa z rozpočtu?',
        text: `Skutočne odstrániť schvaľovateľa ${user.firstname} ${user.lastname}?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odstrániť',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeApprover(user)
        }
      })
    },
    async removeApprover (user) {
      try {
        let data = {
          budget: {
            id: this.$route.params.budgetId
          },
          approver: user.id
        }
        await this.$http.post('/budget/approver/remove', data)
        this.OkAlert({ text: `Schvaľovateľ ${user.firstname} ${user.lastname} bol odstránený.` })
        this.fetchBudget()
      } catch (error) {
        this.ErrorAlert({ text: 'Schvaľovateľa sa nepodarilo odstrániť.' }, error?.response?.data.error)
      }
    },
    removeDepartmentModal (department) {
      this.YesNoAlert({
        title: 'Odstrániť organizačnú jednotku z rozpočtu?',
        text: `Skutočne odstrániť organizačnú jednotku ${department.name}?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odstrániť',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeDepartment(department)
        }
      })
    },
    async removeDepartment (department) {
      try {
        let data = {
          budget: {
            id: this.$route.params.budgetId
          },
          unit: department.id
        }
        await this.$http.post('/budget/orgunit/remove', data)
        this.OkAlert({ text: `Organizačná jednotka ${department.name} bola odstránená.` })
        this.fetchBudget()
      } catch (error) {
        this.ErrorAlert({ text: 'Organizačnú jednotku sa nepodarilo odstrániť.' }, error?.response?.data.error)
      }
    },
    async save () {
      this.budget.$validate()
      if (this.budget.hasErrors()) {
        return
      }
      this.ShowLoading()
      let budgetData = {
        budget: {
          ...this.budget
        }
      }

      if (this.$route.params.budgetId) {
        budgetData.budget.id = this.$route.params.budgetId
      }
      try {
        await this.$http.post('/budget/set', budgetData)
        this.CloseLoading()
        let message = 'Nový rozpočet bol vytvorený.'
        if (this.$route.params.budgetId) {
          message = 'Údaje boli uložené.'
        }
        this.OkAlert({ title: 'Hotovo', text: message })
        this.goBack()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa uložiť informácie o rozpočte.' }, error?.response?.data.error)
      }
    },
    addUsersModal () {
      this.$emit('vuedals:new', {
        name: 'add-users',
        escapable: true,
        title: 'Pridať používateľov',
        component: AddApproverModal,
        onClose: () => {
          this.fetchBudget()
        }
      })
    },
    addDepartmentsModal () {
      this.$emit('vuedals:new', {
        name: 'add-departments',
        escapable: true,
        title: 'Pridať organizačné jednotky',
        component: AddDepartmentModal,
        onClose: () => {
          this.fetchBudget()
        }
      })
    },
    async fetchBudget () {
      if (!this.$route.params.budgetId) {
        return
      }
      this.dataLoaded = false
      this.ShowLoading()

      try {
        const { data: { budget } } = await this.$http.post('/budget/get', { budget: { id: this.$route.params.budgetId } })
        this.budget.name = budget.name
        this.budget.value = budget.value
        this.budget.validFrom = budget.validFrom
        this.budget.validTo = budget.validTo
        this.approvers = budget.approvers
        this.orgunits = budget.orgunits
        this.dataLoaded = true
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o rozpočte.' }, error?.response?.data.error)
      }
    }
  },
  watch: {
    budget: {
      handler (budget) {budget.$validate({ debounce: 1000 })},
      deep: true,
      immediate: false
    }
  },
  async mounted () {
    this.fetchBudget()
  }
}
</script>
