<template>
  <div>
    <div class="card mb-0">

      <!-- eforms notice data from db -->
      <div v-if="eformsData && eformsData.link">
        <div class="card-block">
          <div class="column">
            <div class="col-md-12 text-center">

              <div class="card-body">
                <h5>{{ eformsData.name }}</h5>
                <p>{{ eformsData.state }}</p>
                <div class="d-flex justify-content-center">
                  <div class="btn-group" role="group" aria-label="Button group">
                    <button v-if="isEditable" type="button" class="btn btn-primary mx-1" @click="executeNoticeAction('fill-notice')"><i class="icon-synch" ></i>Vyplniť oznámenie v eForms</button>
                    <a :href="eformsData.link" target="_blank" class="btn btn-outline-info mx-1"><i class="icon-doc" ></i> Otvoriť oznámenie v eForms</a>
                  </div>
                </div>
                <div>
                  <button v-if="isEditable" type="button" class="btn btn-link btn-block mx-1 text-danger" style="font-weight: normal;" @click="showWarnings = !showWarnings">{{ showWarnings ? 'Skryť nesprávne vyplnené polia v eForms' : 'Zobraziť nesprávne vyplnené polia v eForms' }} <span v-if="eformsData.errors" class="mx-2 badge badge-danger">{{ eformsData.errors.length }}</span></button>
                </div>

                <div v-if="isEditable && showWarnings" style="background: rgb(247, 247, 247); padding: 8px 0px; box-shadow: 8px 8px 16px -18px inset;">
                  <ul class="text-left" style="list-style-type: square; font-size: small; color: #500;">
                    <li v-for="(warning, index) in eformsData.errors" :key="`err-${index}`">
                      {{ warning.message }}
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- error -->
      <div v-else-if="isError">
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <!--<div><i class="icon-warning" style="font-size: 4rem; color: #500"></i></div>-->
              <h4 style="color: #300;">Chyba pri spracovaní oznámenia</h4>
              <div style="color: #500">{{ progressText }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- loading -->
      <div v-else>
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <div>{{ progressText }}</div>
              <div v-if="isLoading" class="progress" style="height: 8px;"><div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" :style="{ width: progressValue + '%' }" :aria-valuenow="progressValue" aria-valuemin="0" aria-valuemax="100"></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default
{
  name: 'EformsModal',
  data ()
  {
    return {
      eformsData: null,
      isLoading: false,
      isError: false,
      isEditable: false,
      showWarnings: false,
      progressJob: null,
      progressValue: 0,
      progressText: 'Čakajte prosím...'
    }
  },
  methods:
  {
    showLoading ()
    {
      $('.vuedal').LoadingOverlay('show')
    },
    hideLoading ()
    {
      $('.vuedal').LoadingOverlay('hide')
    },
    close ()
    {
      this.$emit('vuedals:close', null)
    },
    async executeNoticeAction (act)
    {
      this.isError = false
      this.showWarnings = false
      this.isEditable = false
      this.showLoading()
      try
      {
        const params = { order: this.$route.params.orderId, action: act }
        const { data } = await this.$http.post('/order/service/execute', params)
        this.hideLoading()
        this.progressJob = data.jobId
        this.eformsData = data.noticeData
        if (this.progressJob)
        {
          this.startCheckingProgress()
        }
        if (this.eformsData)
        {
          if (['Rozpracované', ''].includes(this.eformsData.state)) this.isEditable = true
        }
        else
        {
          if (act === 'get-notice') this.showError('Nepodarilo sa načítať dáta zo systému eForms')
        }
      }
      catch (error)
      {
        this.hideLoading()
        this.showError('Nepodarilo sa načítať dáta zo systému eForms...')
      }
    },
    startCheckingProgress ()
    {
      if (this.isLoading) return
      this.isLoading = true
      this.showWarnings = false
      this.checkProgress()
    },
    async checkProgress ()
    {
      try
      {
        const params = { order: this.$route.params.orderId, job: this.progressJob }
        const { data } = await this.$http.post('/order/service/status', params)
        const isRunning = data.running
        const progress = data.progress
        const details = data.details
        if (progress === -1) this.showError()
        this.progressValue = progress
        this.progressText = details
        if (isRunning) setTimeout(() => { this.checkProgress() }, 2500)
        else this.stopCheckingProgress()
        if (this.progressValue === 100) this.executeNoticeAction('get-notice')
      }
      catch (error)
      {
        this.showError('Nepodarilo sa načítať dáta zo systému eForms')
      }
    },
    stopCheckingProgress ()
    {
      this.isLoading = false
    },
    showError (msg)
    {
      this.isLoading = false
      this.isError = true
      this.showWarnings = false
      if (msg) this.progressText = msg
      this.stopCheckingProgress()
    }
  },
  beforeDestroy ()
  {
    this.stopCheckingProgress()
  },
  mounted ()
  {
    this.executeNoticeAction('check-notice')
  }
}
</script>
