<template>
  <div>
    <admin-offers-submenu  v-if="order.procedureType === 'tste'" />
    <div class="row mt-3">
      <template v-if="order.divided">
        <div class="col-md-12" v-for="part in order._parts" :key="part.id">
          <offers-opening-widget  :order="order" :round="round" />
          <offers-evaluation-widget v-if="evaluationEnabled" :order="order" :round="round" />
        </div>
      </template>
      <div class="col-md-12" v-else>
        <offers-opening-widget  :order="order" :round="round" />
        <offers-evaluation-widget v-if="evaluationEnabled" :order="order" :round="round" />
      </div>
    </div>
  </div>
</template>

<script>
import OffersOpeningWidget from '@/components/order/common/components/OffersOpeningWidget.vue'
import OffersEvaluationWidget from '@/components/order/common/components/OffersEvaluationWidget.vue'
import AdminOffersSubmenu from '@/components/order/common/components/AdminOffersSubmenu.vue'

export default {
  name: 'AdminOffers',
  components: { OffersOpeningWidget, OffersEvaluationWidget, AdminOffersSubmenu },
  inject: ['order'],
  props: {
    round: Number
  },
  data () {
    return {
      openingDateStr: '',
      openingDate: null,
      dateNotBefore: process.env.VUE_APP_ENBL_OFFER_EVAL_FROM,
    }
  },
  computed: {
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    evaluationEnabled () {
      return this.openingEnabled && this.order.biddingMethod !== 'costEffectiveness' && !this.order.divided && this.orderCreatedAt > this.evaluationEnabledFrom
    },
    orderCreatedAt () {
      return new Date(this.order.createdAt)
    },
    evaluationEnabledFrom () {
      return new Date(this.dateNotBefore)
    }
  },
  created () {
    this.openingDateStr = this.round === 1 ? this.order.bidsOpenAt : this.order.bidsOpenAt2
    this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
  }
}
</script>
