<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: 'kdv-admin-settings', params: {orderId: $route.params.orderId}}">Nastavenia</router-link>
      <router-link :to="{ name: 'kdv-admin-documents', params: $route.params}">Dokumenty</router-link>
      <router-link :to="{ name: 'kdv-admin-items', params: $route.params}">Položky</router-link>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KdvAdminHome',
  inject: ['order']
}
</script>
