<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-heading">
          <h4 class="card-title ">Kritéria - {{ order.name }}</h4>
          <div class="actions" v-if="order.biddingMethod !== 'lowestPrice' && order.biddingMethod !== 'n/a' && canEdit ">
            <button class="btn btn-success" @click.prevent="addCriterion()"><i class="icon-add font-size-14"></i>Pridať nové kritérium</button>
          </div>
        </div>
        <v-table
          :items="order.criteria"
          :columns="criteriaColumns"
          item-key="id"
          class="light"
        >
          <template v-slot:cell(name)="{ item }">
            {{ item.name }} {{ item.taxInclusion ? 's DPH' : 'bez DPH' }}
          </template>
          <template v-slot:cell(type)="{ item }">
            {{ item.type | formatType }}
          </template>
          <template v-slot:cell(nature)="{ item }">
            {{ item.nature | formatNature }}
          </template>
          <template v-slot:cell(interval)="{ item }">
            &lt;
            {{ item.minimalValue === Number.MIN_SAFE_INTEGER ? "-&infin;" : formatNumber(item.minimalValue, item.decimalCount) }}
            ;
            {{ item.maximalValue === Number.MAX_SAFE_INTEGER ? "&infin;" : formatNumber(item.maximalValue, item.decimalCount) }}
            &gt;
          </template>
          <template v-slot:cell(actions)="{ _item, row }">
            <a
              v-if="canEdit"
              href=""
              @click.prevent="updateCriterion(row)"
              title="Upraviť"
            >
              <i class="icon-pencil"></i>
            </a>
            <a
              v-if="order.biddingMethod !== 'lowestPrice' && canEdit"
              @click.prevent="removeCriterionModal(row)"
              href=""
              title="Zmazať"
            >
              <i class="icon-delete text-danger"></i>
            </a>
          </template>
        </v-table>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
var sFormatNumber = require('simple-format-number')

export default {
  name: 'CriteriaListWidget',
  props: ['order', 'parent'],
  inject: ['routePrefix', 'eventBus'],
  components: { VTable },
  data () {
    return {
      canEdit: this.parent ? this.parent._canEdit : this.order._canEdit
    }
  },
  computed: {
    criteriaColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'nature',
          label: 'Povaha'
        },
        {
          key: 'interval',
          label: 'Interval'
        },
        {
          key: 'maximumPoints',
          label: 'Maximálny počet bodov'
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  mounted () {
    this.eventBus.$on('order-data', () => {
      this.$forceUpdate()
    })
  },
  destroyed () {
    this.eventBus.$off('order-data')
  },
  filters: {
    formatNature: function (value) {
      var nature = {
        'ascending': 'Stúpajúce',
        'descending': 'Klesajúce'
      }
      return nature[value]
    },
    formatType: function (value) {
      var types = {
        'price': 'Cenové',
        'noprice': 'Necenové'
      }
      return types[value]
    }
  },
  methods: {
    showLoading: function () {
      $('#order-criteria .card-block').LoadingOverlay('show')
    },
    hideLoading: function () {
      $('#order-criteria .card-block').LoadingOverlay('hide')
    },
    formatNumber: function (value, decimalCount) {
      return sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } })
    },
    addCriterion: function () {
      if (this.parent) {
        this.$router.push({ name: `${this.routePrefix}admin-criteria-add-part`, params: { orderId: this.parent.id, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.routePrefix}admin-criteria-add`, params: { orderId: this.order.id } })
      }
    },
    updateCriterion: function (index) {
      if (this.parent) {
        this.$router.push({ name: `${this.routePrefix}admin-criteria-update-part`, params: { orderId: this.parent.id, partId: this.order.id, index: index } })
      } else {
        this.$router.push({ name: `${this.routePrefix}admin-criteria-update`, params: { orderId: this.order.id, index: index } })
      }
    },
    removeCriterionModal: function (index) {
      this.YesNoAlert({
        title: 'Skutočne chcete odstrániť kritérium?',
        text: 'Kritérium ' + this.order.criteria[index].name + ' sa po zmazaní už nebude dať obnoviť.',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeCriterion(index)
        }
      })
    },
    removeCriterion: function (index) {
      this.showLoading()
      var data = {
        order: {
          id: this.order.id
        },
        criterion: {
          id: this.order.criteria[index]._id
        }
      }
      var vm = this
      this.$http.post('/order/criterion/remove', data).then(function () {
        vm.order.criteria.splice(index, 1)
        vm.hideLoading()
        setTimeout(function () {
          vm.OkAlert({ text: '' })
        }, 500)
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(function () {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }, 500)
      })
    }
  }
}
</script>
