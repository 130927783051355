import axios from 'axios'

const service = axios.create({
  baseURL: `${process.env.VUE_APP_TX_BACKEND_URL}/search`,
  headers: {
    common: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
})

service.search = (text, country, params = {}) => {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const query = `.*${text}.*`
  const data = {
    from: 0,
    size: 10,
    sort: [
      { is_terminated: { order: 'asc' } },
      '_score'
    ],
    ...params,
    query: {
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  match_phrase_prefix: {
                    name_soft_legal_form_edge_ngram: {
                      query,
                      boost: 0.01
                    }
                  }
                },
                {
                  multi_match: {
                    query: `${text}`,
                    type: 'cross_fields',
                    fields: [
                      'name^2',
                      'name_str^5',
                      'name_classic',
                      'name_soft_2^0.01',
                      'name_soft_legal_form_edge_ngram^5',
                      'names_str^0.1',
                      'municipality',
                      'cin',
                      'cin_ngram^0.8'
                    ],
                    operator: 'and',
                    tie_breaker: 1
                  }
                }
              ]
            }
          }
        ]
      }
    }
  }
  if (country) {
    data.query.bool.must.push({
      match: {
        country
      }
    })
  }
  const req = service
    .post('/rpo', data, { cancelToken: source.token })
    .catch(err => {
      if (axios.isCancel(err)) {
        return null
      }
      throw err
    })
  req.cancel = source.cancel
  return req
}

export default service
