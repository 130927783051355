<template>
  <form>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="nazov-zakazky" class="text-dark text-bold">Dodávatelia <small>*</small></label>
          <request-suppliers-select
            v-model="users"
            :class="{ error: form.users.error, valid: form.users.valid }"
            aria-required="true"
          />
          <label v-if="form.users.error" class="error" for="nazov-zakazky">Toto pole je povinné.</label>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close">Zrušiť</button>
      <button class="btn btn-success" @click.prevent="addUsers">Pridať</button>
    </div>
  </form>
</template>

<script>
import RequestSuppliersSelect from '@/components/forms/RequestSuppliersSelect.vue'
export default {
  name: 'AddDepartmentUserModal',
  components: { RequestSuppliersSelect },
  data () {
    return {
      users: [],
      form: {
        users: {
          error: false,
          valid: false
        }
      }
    }
  },
  methods: {
    showLoading () {
      $('.vuedal').LoadingOverlay('show')
    },
    hideLoading () {
      $('.vuedal').LoadingOverlay('hide')
    },
    close () {
      this.$emit('vuedals:close', null)
    },
    async addUsers () {
      if (!this.validateUser()) {
        return
      }
      this.showLoading()
      if (this.$route.params.requestId) {
        let suppliersData = {
          request: {
            id: this.$route.params.requestId
          },
          supplier: {
            id: this.users.map(user => user._id)
          }
        }
        try {
          await this.$http.post('/request/supplier/add', suppliersData)
          this.hideLoading()
          this.close()
        } catch (error) {
          this.ErrorAlert({ title: 'Chyba', text: 'Užívateľov sa nepodarilo pridať' }, error?.response?.data.error)
          this.hideLoading()
        }
      } else {
        this.$emit('vuedals:close', this.users)
      }
    },
    validateUser () {
      let state = this.form.users
      state.valid = state.error = false
      if (this.users.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    }
  },
  watch: {
    users () {
      this.validateUser()
    }
  }
}
</script>
