<template>
  <div class="card" id="order-settings-add-item">
    <div class="card-heading">
      <h4 class="card-title">Pridať novú položku</h4>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label" for="item-name">Názov položky <small>*</small></label>
            <input id="subject" type="text" v-model.lazy="item.name" class="form-control" :class="{ error: form.name.error, valid: form.name.valid }" required="required" aria-required="true">
            <label v-if="form.name.error" class="error" for="item-name">Názov položky musí byť vyplnený a môže obsahovať max. 250 znakov.</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="amount">Množstvo <small>*</small></label>
            <input id="amount" type="number" v-model.lazy="item.amount" class="form-control" :class="{ error: form.amount.error, valid: form.amount.valid }" required="required" aria-required="true">
            <label v-if="form.amount.error" class="error" for="amount">Množstvo musí byť vyplnené.</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label" for="unit">Jednotka <small>*</small></label>
            <input id="unit" type="text" v-model.lazy="item.unit" class="form-control" :class="{ error: form.unit.error, valid: form.unit.valid }" required="required" aria-required="true">
            <label v-if="form.unit.error" class="error" for="unit">Jednotka musí byť vyplnená.</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Podrobný popis</label>
            <textarea id="description" v-model="item.description" class="form-control"></textarea>
            Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br />
            <label v-if="form.description.error" class="error" for="description">Popis môže obsahovať max. 4000 znakov.</label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <button class="btn btn-tenderia-grey-blue btn-inverse" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
        <button class="btn btn-success" @click="addItem"><i class="icon-save"></i> Uložiť</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AdminItemsAdd',
  inject: ['order', 'eventBus'],
  data () {
    return {
      item: {
        type: 'common',
        priceAffect: true,
        name: '',
        amount: '',
        unit: '',
        description: ''
      },
      form: {
        name: {
          error: false,
          valid: false
        },
        amount: {
          error: false,
          valid: false
        },
        unit: {
          error: false,
          valid: false
        },
        description: {
          error: false,
          valid: false
        }
      }
    }
  },
  created () {
    if (!isNaN(this.$route.params.index)) {
      if (this.$route.params.partId) {
        const part = this.order._parts.filter(part => part.id === this.$route.params.partId)
        this.item = part[0].items[this.$route.params.index]
      } else {
        this.item = this.order.items[this.$route.params.index]
      }
      this.item.id = this.item._id
    }
  },
  computed: {
    descriptionTextRemaining () {
      return 4000 - this.item.description.length
    }
  },
  methods: {
    goBack () {
      window.history.go(-1)
    },
    showLoading () {
      $('#order-settings-add-item').LoadingOverlay('show')
    },
    hideLoading () {
      $('#order-settings-add-item').LoadingOverlay('hide')
    },
    addItem () {
      this.showLoading()
      var data = {
        order: {
          id: this.$route.params.partId ? this.$route.params.partId : this.order.id
        },
        item: this.item
      }
      var vm = this
      this.$http.post('/order/item/set', data).then(function () {
        vm.eventBus.$emit('order-updated')
        vm.hideLoading()
        vm.OkAlert({ text: 'Položka bola uložená' })
        vm.goBack()
      }).catch((error) => {
        vm.hideLoading()
        vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba' }, error?.response?.data.error)
      })
    }
  }
}
</script>
