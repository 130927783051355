<template>
  <user-offer-form v-if="offerOrder.id" />
</template>

<script>
import UserOfferForm from '../components/UserOfferForm.vue'

export default {
  name: 'PtUserOffersAdd',
  components: { UserOfferForm },
  inject: ['order'],
  data () {
    return {
      offerOrder: {}
    }
  },
  provide () {
    return {
      order: this.offerOrder
    }
  },
  created () {
    if (this.$route.params.partId) {
      for (let i = 0; i < this.order._parts.length; i++) {
        if (this.order._parts[i].id === this.$route.params.partId) {
          Object.assign(this.offerOrder, this.order._parts[i])
          break
        }
      }
    } else {
      Object.assign(this.offerOrder, this.order)
    }
  }
}
</script>
