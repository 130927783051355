<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">Moji dodávatelia</h4>
        <div class="actions">
          <router-link
            class="btn btn-tenderia-blue"
            to="/app/settings/suppliers/add"
          >
            <i class="icon-add font-size-14"/> Pridať dodávateľa
          </router-link>
        </div>
      </div>
      <div class="card-block">
        <div class="row align-items-baseline">
          <div class="col-md-4 form-group">
            <label class="text-dark text-bold">Hľadať dodávateľa podľa názvu alebo IČO:</label>
            <input v-model="fulltext" class="form-control">
          </div>
          <div class="col-md-8 form-group"></div>
        </div>
      </div>
      <v-table
        :items="suppliers.data"
        :columns="suppliersColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template v-slot:cell(actions)="{ item }">
          <router-link
            :to="`/app/settings/suppliers/edit/${item._id}`"
            title="Upraviť dodávateľa"
          >
            <i class="icon-pencil"></i>
          </router-link>
          <a
            href=""
            @click.prevent="removeSupplierModal(item)"
            title="Odstrániť dodávateľa"
          >
            <i class="icon-delete text-danger"></i>
          </a>
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="suppliers.total"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="suppliers.total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'

export default {
  name: 'MySuppliersPage',
  components: { VPagination, VTable },
  inject: ['eventBus'],
  data: function () {
    return {
      suppliers: {},
      fulltext: '',
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    suppliersColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'ico',
          label: 'IČO',
          sortable: true
        },
        {
          key: 'residence',
          label: 'Sídlo',
          sortable: true
        },
        {
          key: 'email',
          label: 'E-mail',
          sortable: true
        },
        {
          key: 'phone',
          label: 'Tel. č.',
          sortable: true
        },
        {
          key: 'contactPerson',
          label: 'Kontaktná osoba',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie',
          header: {
            width: 90
          }
        }
      ]
    },
    fetchParams () {
      return {
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        'filter': this.fulltext,
        sort: this.$format.sortString(this.tableSort)

      }
    }
  },
  mounted () {
    this.fetchSuppliers()
  },
  watch: {
    fetchParams () {
      this.fetchSuppliers()
    }
  },
  methods: {
    async fetchSuppliers () {
      const { data } = await this.$http.post('/organization/supplier/list', this.fetchParams)
      let { suppliers, total } = data
      suppliers.map(supplier => {
        supplier.residence = `${supplier.address} ${supplier.address2}, ${supplier.zip} ${supplier.city}, ${supplier.country}`
        return supplier
      })
      this.suppliers = { data: suppliers, total }
    },
    removeSupplierModal (supplier) {
      this.YesNoAlert({
        title: 'Zmazať dodávateľa',
        text: `Skutočne chcete zmazať dodávateľa ${supplier.name} (${supplier.ico})?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeSupplier(supplier)
        }
      })
    },
    async removeSupplier (supplier) {
      try {
        await this.$http.post('/organization/supplier/remove', { supplier: { id: supplier._id } })
        this.OkAlert({ text: `Dodávateľ ${supplier.name} (${supplier.ico}) bol zmazaný.` })
        this.fetchSuppliers()
      } catch (error) {
        this.ErrorAlert({ text: 'Dodávateľa sa nepodarilo zmazať.' }, error?.response?.data.error)
      }
    }
  }
}
</script>
