import Swal from 'sweetalert2'
let FileSaver = require('file-saver')

const b64EncodeUnicode = (str) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode('0x' + p1),
    ),
  )
}
export default {
  methods: {
    YesNoAlert: function (settings) {
      let defaultSettings = {
        type: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'Nie',
        confirmButtonText: 'Áno'
      }
      return Swal.fire(Object.assign(defaultSettings, settings))
    },
    PopupAlert (settings) {
      let defaultSettings = {
        position: 'top-end',
        type: 'success',
        showConfirmButton: false,
        timer: 1600
      }
      Swal.fire(Object.assign(defaultSettings, settings))
    },
    ShowLoading (settings) {
      let defaultSettings = {
        onBeforeOpen: () => {
          Swal.showLoading()
        }
      }
      Swal.fire(Object.assign(defaultSettings, settings))
    },
    CloseLoading () {
      Swal.close()
    },
    BasicAlert: function (settings) {
      return Swal.fire(settings)
    },
    OkAlert: function (settings) {
      let defaultSettings = {
        type: 'success'
      }
      return this.BasicAlert(Object.assign(defaultSettings, settings))
    },
    ErrorAlert: function (settings, e) {
      console.log(e)
      if (e && e.display) {
        const defaultSettings = {
          type: 'error',
          title: 'Chyba',
          text: e.display
        }
        return this.BasicAlert(defaultSettings)
      } else {
        const defaultSettings = {
          type: 'error'
        }
        return this.BasicAlert(Object.assign(defaultSettings, settings))
      }
    },
    ErrorAlertReport: function (settings, exception, error) {
      let defaultSettings = {}
      if (error && error.display) {
        defaultSettings = {
          type: 'error',
          confirmButtonText: 'Zavrieť',
          cancelButtonText: 'Stiahnuť log',
          showCancelButton: true,
          title: 'Chyba',
          text: error.display
        }
        return this.BasicAlert(defaultSettings)
      } else {
        defaultSettings = {
          type: 'error',
          confirmButtonText: 'Zavrieť',
          cancelButtonText: 'Stiahnuť log',
          showCancelButton: true,
          title: 'Chyba',
          text: 'Chcete odoslať hlásenie ?'
        }
      }
      console.log(JSON.stringify(exception))
      const encodedString = b64EncodeUnicode(JSON.stringify(exception, Object.getOwnPropertyNames(exception)))
      let blob = new Blob([encodedString], { type: 'text/plain;charset=utf-8' })
      this.YesNoAlert(Object.assign(defaultSettings, settings)).then((result) => { if (!result.value) { FileSaver.saveAs(blob, 'report.log') } })
    },
    InfoAlert: function (settings) {
      let defaultSettings = {
        type: 'info'
      }
      return this.BasicAlert(Object.assign(defaultSettings, settings))
    }
  }
}
