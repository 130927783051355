<template>
  <div class="card calendar-widget">
    <div class="calendar-block">
      <span class="time">Serverový čas: {{ serverTime }}</span>
      <div class="card-header-image" :style="`background: transparent url(${createImageUrl()}) no-repeat center/100%`">
        <div class="date">{{ serverDate }}</div>
      </div>
      <div id="calendar">
        <div class="calendar-parent">
          <resize-observer @notify="windowChanged()" />
          <vue-cal id="vuecal"
                   :events="events"
                   :selected-date="selectedDate"
                   locale="sk"
                   :min-cell-width="minCellWidth"
                   :disable-views="['years', 'year','day', 'week']"
                   :time="false"
                   :split-days="[{ class: 'nosplitday' , label:' '}]"
                   defaultView="month"
                   small
                   hide-weekends
                   hide-view-selector
                   @event-focus="onEventFocus($event)"
                   @event-mouse-enter="onEventMouseEnter($event)"
                   @event-mouse-leave="onEventMouseLeave($event)"
          >
            <div slot-scope="{ events }" slot="events-count-month-view">
              <div class="calendar-tooltip">
                <div class="tooltip__initiator">
                  <div class="events-count offersDeadlineCount" v-if="countOffersDeadlineEventsMonthView(events)">
                  </div>
                  <div class="events-count offersOpeningCount" v-if="countOffersOpeningEventsMonthView(events)">
                  </div>
                  <div class="events-count orderDocsExplRequestCount" v-if="countOrderDocsExplRequestEventsMonthView(events)">
                  </div>
                  <div class="events-count exclNoticeCount" v-if="countExclNoticeEventsMonthView(events)">
                  </div>
                  <div class="events-count offersOpeningReportCount" v-if="countOffersOpeningReportEventsMonthView(events)">
                  </div>
                  <div class="events-count messageCount" v-if="countMessageEventsMonthView(events)">
                  </div>
                </div>
                <div class="tooltip__item">
                  <div v-if="countOffersDeadlineEventsMonthView(events)">
                    <div class="offersDeadline event-dot"></div>{{ countOffersDeadlineEventsMonthView(events) }} x Lehota na predkladanie ponúk
                  </div>
                  <div v-if="countOffersOpeningEventsMonthView(events)">
                    <div class="offersOpening event-dot"></div>{{ countOffersOpeningEventsMonthView(events) }} x Otváranie ponúk
                  </div>
                  <div v-if="countOrderDocsExplRequestEventsMonthView(events)">
                    <div class="orderDocsExplRequest event-dot"></div>{{ countOrderDocsExplRequestEventsMonthView(events) }} Posledný deň zákonnej lehoty na doručenie vysvetlenia
                  </div>
                  <div v-if="countExclNoticeEventsMonthView(events)">
                    <div class="exclNotice event-dot"></div>{{ countExclNoticeEventsMonthView(events) }} Zábezpeka
                  </div>
                  <div v-if="countOffersOpeningReportEventsMonthView(events)">
                    <div class="offersOpeningReport event-dot"></div>{{ countOffersOpeningReportEventsMonthView(events) }} x Povinnosť odoslať zápisnicu z otvárania ponúk
                  </div>
                  <div v-if="countMessageEventsMonthView(events)">
                    <div class="message event-dot"></div>{{ countMessageEventsMonthView(events) }} x Povinnosť odpovede na správu
                  </div>
                </div>
              </div>
            </div>
          </vue-cal>
        </div>
        <span class="to-do-events">Najbližšie udalosti</span>
        <div id="my-tasks">
          <div class="task-card" v-for="event in todoEvents" :key="event.id + event.classes" @click="event.more =! event.more">
            <div class="task">
              <div :class="event.classes" class="event-dot"></div>
              <div class="content">
                <span>{{ event.title }}</span>
                <span>{{ event.startDate | formatDate }} </span>
              </div>
              <div>
                <i class="icon-dropdown" :class="{opened: event.more}"></i>
              </div>
            </div>
            <v-collapsible>
              <div class="info-text" v-if="event.more">
                <span v-html="event.infoText"></span>
              </div>
            </v-collapsible>
          </div>
          <div v-if="todoEvents.length === 0">
            <span>Nemáte žiadne nadchádzajúce udalosti</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-cal/dist/vuecal.css'
import VueCal from 'vue-cal'
import 'vue-resize/dist/vue-resize.css'
import { ResizeObserver } from 'vue-resize'
import moment from 'moment'
import VCollapsible from './ui/Collapsible.vue'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'
moment.locale('sk')

export default {
  name: 'CalendarWidget',
  components: { VueCal, ResizeObserver, VCollapsible },
  data () {
    return {
      tabName: 'calendar',
      selectedDate: moment().startOf('today').format(DATE_FORMAT),
      startingDayOfWeek: 1,
      disablePast: false,
      disableFuture: false,
      displayPeriodUom: 'month',
      displayPeriodCount: 1,
      showEventTimes: false,
      minCellWidth: 0,
      events: [],
      todoEvents: [],
      selectedEvent: {
        title: '',
        description: '',
        startDate: null
      },
      calendarWidth: window.innerWidth,
      currentView: 'month'
    }
  },
  computed: {
    serverTime () {
      return moment(this.$store.state.stats.currentTime).format('HH:mm')
    },
    serverDate () {
      return moment(this.$store.state.stats.currentTime).format('Do MMMM YYYY')
    }
  },
  mounted () {
    const from = moment().startOf('month').toISOString()
    const to = moment().endOf('month').toISOString()
    this.updateEvents(from, to)
    const fromNow = moment().toISOString()
    const toNextYear = moment().year(moment().year() + 1).toISOString()
    this.updateTodoEvents(fromNow, toNextYear)
    window.addEventListener('resize', this.windowChanged())
  },
  methods: {
    createImageUrl: function () {
      const dayNum = moment().date()
      return require('../assets/images/others/calendar_random_images/' + dayNum + '.jpg')
    },
    updateEvents (from, to) {
      this.events = []
      let vm = this
      let interval = {
        event: {
          from: from,
          to: to
        }
      }
      vm.$http.post('/user/event/list', interval).then(function (response) {
        for (var eventIndex in response.data.events) {
          var event = response.data.events[eventIndex]
          vm.events.push({
            id: event.content.id,
            startDate: event.date,
            endDate: event.date,
            description: event.content.description,
            classes: event.eventType,
            name: event.content.subject || event.title,
            start: moment(event.date).format(DATE_FORMAT),
            end: moment(event.date).format(DATE_FORMAT),
            class: event.eventType,
            split: 1
          })
        }
      })
    },
    updateTodoEvents (from, to) {
      this.todoEvents = []
      let vm = this
      let interval = {
        event: {
          from: from,
          to: to
        }
      }
      vm.$http.post('/user/event/list', interval).then(function (response) {
        let infoText = ''
        let title = ''
        for (var eventIndex in response.data.events) {
          var todoEvent = response.data.events[eventIndex]
          switch (todoEvent.eventType) {
          case 'offersDeadline':
            title = 'Lehota na predkladanie ponúk'
            infoText = `Lehota na predkladanie ponúk na predmet zákazky  <strong>${todoEvent.title}</strong>`
            break
          case 'offersOpening':
            title = 'Otváranie ponúk'
            infoText = `Otváranie ponúk v rámci predmetu zákazky  <strong>${todoEvent.title}</strong> o  <strong>${moment(event.date).format(TIME_FORMAT)}</strong>`
            break
          case 'orderDocsExplRequest':
            title = 'Posledný deň zákonnej lehoty na doručenie vysvetlenia'
            infoText = `Posledný deň zákonnej lehoty na doručenie vysvetlenia všetkým známym záujemcom
                          v rámci predmetu zákazky <strong>${todoEvent.title}</strong>.`
            break
          case 'exclNotice':
            title = 'Uvoľnenie zábezpeky'
            infoText = `V prípade ak bola v rámci predmetu zákazky
              <strong>${todoEvent.content.order.name}
                           požadovaná zábezpeka, je nevyhnutné ju uvoľniť uchádzačovi
                           <strong>${todoEvent.content.from.firstname} ${todoEvent.content.from.lastname}</strong>.`
            break
          case 'offersOpeningReport':
            title = 'Povinnosť odoslať Zápisnicu'
            infoText = `Povinnosť odoslať Zápisnicu z otvárania ponúk predložených v rámci zákazky
              <strong>${todoEvent.title || todoEvent.content.subject}</strong>, v prípade, že uchádzač nedoručil námietky.`
            break
          case 'message':
            title = 'Povinnosť odpovede na správu'
            infoText = `Pozor, povinnosť odpovede na správu  <strong>${todoEvent.content.subject}</strong>
                          od  <strong>${todoEvent.content.organization.name}</strong>`
            break
          default:
            infoText = 'Bez popisu udalosti.'
          }
          vm.todoEvents.push({
            id: todoEvent.content.id,
            startDate: todoEvent.date,
            endDate: todoEvent.date,
            title: title,
            infoText: infoText,
            classes: todoEvent.eventType,
            name: todoEvent.content.subject || todoEvent.title,
            more: false
          })
        }
      })
    },
    countOffersDeadlineEventsMonthView (events) {
      return events ? events.filter(e => e.class === 'offersDeadline').length : 0
    },
    countOffersOpeningEventsMonthView (events) {
      return events ? events.filter(e => e.class === 'offersOpening').length : 0
    },
    countOrderDocsExplRequestEventsMonthView (events) {
      return events ? events.filter(e => e.class === 'orderDocsExplRequest').length : 0
    },
    countExclNoticeEventsMonthView (events) {
      return events ? events.filter(e => e.class === 'exclNotice').length : 0
    },
    countOffersOpeningReportEventsMonthView (events) {
      return events ? events.filter(e => e.class === 'offersOpeningReport').length : 0
    },
    countMessageEventsMonthView (events) {
      return events ? events.filter(e => e.class === 'message').length : 0
    },
    windowChanged () {
      if (this.currentView === 'month') {
        this.minCellWidth = 0
      }
    }
  }
}
</script>

<style>
.hide {
  display: none;
}
</style>
