<template>
  <div></div>
</template>

<script>
import OrderSettingsLogOrderGeneral from './OrderSettingsLogOrderGeneral.vue'
import OrderSettingsLogOrderParts from './OrderSettingsLogOrderParts.vue'
import OrderSettingsLogOrderDocuments from './OrderSettingsLogOrderDocuments.vue'
import OrderSettingsLogOrderCommunication from './OrderSettingsLogOrderCommunication.vue'
import OrderSettingsLogOrderCriteria from './OrderSettingsLogOrderCriteria.vue'
import OrderSettingsLogOrderCommission from './OrderSettingsLogOrderCommission.vue'
import OrderSettingsLogOrderOffers from './OrderSettingsLogOrderOffers.vue'
import OrderSettingsLogOrderItems from './OrderSettingsLogOrderItems.vue'
import OrderSettingsLogOrderPersons from './OrderSettingsLogOrderPersons.vue'
import OrderSettingsLogOrderSuppliers from './OrderSettingsLogOrderSuppliers.vue'
import OrderSettingsLogOrderEntities from './OrderSettingsLogOrderEntities.vue'

export default {
  name: 'OrderSettingsLogDetailSwitch',
  inject: ['eventBus'],
  created: function () {
    let vm = this
    this.eventBus.$on('open-log-detail', data => { vm.openDetail(data) })
  },
  beforeDestroy: function () {
    this.eventBus.$off('open-log-detail')
  },
  methods: {
    openDetail: function (data) {
      let component
      switch (data.category) {
      case 'order':
        component = OrderSettingsLogOrderGeneral
        break
      case 'order:parts':
        component = OrderSettingsLogOrderParts
        break
      case 'order:documents':
        component = OrderSettingsLogOrderDocuments
        break
      case 'order:communication':
        component = OrderSettingsLogOrderCommunication
        break
      case 'order:criteria':
        component = OrderSettingsLogOrderCriteria
        break
      case 'order:commision':
        component = OrderSettingsLogOrderCommission
        break
      case 'offer':
        component = OrderSettingsLogOrderOffers
        break
      case 'order:items':
        component = OrderSettingsLogOrderItems
        break
      case 'order:persons':
        component = OrderSettingsLogOrderPersons
        break
      case 'order:suppliers':
        component = OrderSettingsLogOrderSuppliers
        break
      case 'order:entities':
        component = OrderSettingsLogOrderEntities
      }
      this.$emit('vuedals:new', {
        name: 'log-detail',
        escapable: true,
        title: 'Detail záznamu',
        props: {
          row: data
        },
        component: component
      })
    }
  }
}
</script>

<style>
</style>
