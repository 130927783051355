/* eslint-disable */
const validator = {

  firstnameMinLength: 1,
  firstnameMaxLength: 255,
  lastnameMinLength: 1,
  lastnameMaxLength: 255,
  cityMinLength: 1,
  cityMaxLenght: 255,

  validateFirstname: function (value) {
    return this.validateString(value, this.firstnameMaxLength, this.firstnameMinLength)
  },
  validateLastname: function (value) {
    return this.validateString(value, this.lastnameMaxLength, this.lastnameMinLength)
  },
  validateCity: function (value) {
    return this.validateString(value, this.cityMaxLenght, this.cityMinLength)
  },
  validateNumeric: function (value) {
    return !/^\s*$/.test(value) && !isNaN(value)
  },
  validateString: function (value, maxLength, minLength) {
    return (value && value.length > minLength && value.length < maxLength)
  },
  validateEmail: function (value) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(value).toLowerCase())
  },
  validateUrl: function (value) {
    var regex = /^(((http|https|ftp|ftps|sftp):\/\/)|((w|W){3}(\d)?\.))[\w\?!\./:;,\-_=#+*%@"\(\)&]+\S$/
    return regex.test(String(value).toLowerCase())
  },
  validateIco: function (value) {
    var regex = /^[0-9a-zA-Z]{6,14}$/
    return regex.test(value)
  },
  validatePhone: function (value) {
    var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/
    return regex.test(value)
  }
}

export default validator
