<template>
  <div id="app">
    <router-view />
    <vuedal></vuedal>
    <popup-layer class="light"/>
  </div>
</template>

<script>
import { Component as Vuedal } from 'vuedals'
import PopupLayer from '@/components/ui/PopupLayer.vue'
export default {
  name: 'App',
  components: { Vuedal, PopupLayer }
}
</script>
