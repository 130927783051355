import { ReactiveModel } from 'vue-rawmodel'

/* export class SupplierContact extends ReactiveModel {
  constructor (data = {}) {
    super(data)

    this.defineField('email', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'stringEmail',
          message: 'Email musí byť uvedený v tvare  napr. meno@domena.sk'
        }
      ],
      defaultValue: ''
    })

    this.defineField('name', {
      type: 'String',
      defaultValue: ''
    })

    this.defineField('phone', {
      type: 'String',
      emptyValue: '',
      defaultValue: '',
      validate: [
        {
          validator: 'stringMatch',
          regexp: /^(\s*|\+(?:[0-9] ?){6,14}[0-9])$/,
          message: 'Telefónne čislo musí byť uvedené v tvare napr. +421123456789'
        }
      ]
    })

    this.populate(data)
    this.commit()
  }
} */
export class Supplier extends ReactiveModel {
  constructor (data = {}) {
    super(data)

    this.defineField('id', {
      type: 'String'
    })

    this.defineField('name', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('ico', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        /* {
          validator: 'stringMatch',
          regexp: /^[0-9]{8,10}$/,
          message: 'IČO musí obsahovať presne 8 číslic.'
        } */
      ]
    })

    this.defineField('icdph', {
      type: 'String',
      validate: [
        {
          validator: 'stringMatch',
          regexp: /^[a-zA-Z]{2,2}[0-9]{10,10}$/,
          message: 'IČ DPH musí byť zadané v tvare SK0123456789.',
          condition () {
            return !!this.value
          }
        }
      ]
    })

    this.defineField('address', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('address2', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('city', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('country', {
      type: 'String',
      emptyValue: '',
      defaultValue: 'SK',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('zip', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'stringMatch',
          regexp: /^[0-9]{4,5}$/,
          message: 'PSČ musí byť zadané v tvare 12345'
        }
      ]
    })

    this.defineField('email', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        },
        {
          validator: 'stringEmail',
          message: 'Email musí byť uvedený v tvare  napr. meno@domena.sk'
        }
      ]
    })

    this.defineField('contactPerson', {
      type: 'String'
    })

    this.defineField('phone', {
      type: 'String',
      emptyValue: '',
      defaultValue: '',
      validate: [
        {
          validator: 'stringMatch',
          regexp: /^(\s*|\+(?:[0-9] ?){6,14}[0-9])$/,
          message: 'Telefónne čislo musí byť uvedené v tvare napr. +421123456789'
        }
      ]
    })

    /* this.defineField('contacts', {
      type: [SupplierContact]
    }) */

    this.populate(data)
    this.commit()
  }
}

export default Supplier
