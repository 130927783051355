<template>
  <div>
    <order-settings-log-detail :row="row"></order-settings-log-detail>
    <div class="row">
      <div class="col-md-4 control-label">
        Meno:
      </div>
      <div class="col">
        {{ supplierData.name }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Adresa:
      </div>
      <div class="col">
        {{ `${supplierData.address} ${supplierData.address2}, ${supplierData.city}, ${supplierData.zip}` }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Ičo:
      </div>
      <div class="col">
        <span> {{  supplierData.ico }} </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Kontaktná osoba:
      </div>
      <div class="col">
        {{  supplierData.contactPerson }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Telefónne číslo:
      </div>
      <div class="col">
        {{  supplierData.phone }}
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close()"><i class="icon-close"></i> Zavrieť</button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOrderSuppliers',
  props: ['row'],
  components: { OrderSettingsLogDetail },
  data: function () {
    return {
      supplierData: null
    }
  },
  created: function () {
    this.supplierData = this.row.action === 'remove' ? this.row.data.removed[0] : this.row.data.request.supplier
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    }
  }
}
</script>

<style>

</style>
