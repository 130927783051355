<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title"><span v-if="order.divided && order.parent && order.part">Časť {{ order.part}} - </span>Otváranie ponúk</h4>
          </div>
          <template v-if="openingEnabledBySettings">
            <div class="card-block">
              <p class="text-dark text-center pdd-vertical-25">
                Termín otvárania ponúk: <span v-if="openingDate" class="text-bold">{{ openingDate | formatDateTime }}</span><span class="text-bold" v-else>Termín nebol stanovený</span>
              </p>
              <p class="text-dark text-center pdd-vertical-25" v-if="openingDate && !openingEnabled">
                <countdown :deadline="openingDate" :server-time="serverTime"></countdown>
              </p>
            </div>
            <v-table
              :items="offers"
              :columns="tableColumns"
              item-key="id"
              class="light"
            >
              <template v-slot:cell(applicant)="{ item }">
                <span>{{ item.offer.applicantDetails.businessName }}</span>
              </template>
              <template v-slot:cell(criteria)="{ item }">
                <div v-for="criterion in criteria" :key="criterion._id">
                  <label class="text-bold text-dark">{{ criterion.name}}: </label>
                  {{ formatNumber(item.proposals[criterion._id], criterion.decimalCount) }}
                  <template v-if="criterion.type === 'price'">
                    &euro;
                  </template>
                  <template v-else>
                    {{ criterion.unit }}
                  </template>
                </div>
              </template>
            </v-table>
          </template>
          <div class="card-block" v-else>
            <p class="text-bold text-center pdd-vertical-25 font-size-20">Otváranie ponúk je neverejné.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import VTable from '@/components/ui/Table.vue'
var sFormatNumber = require('simple-format-number')

export default {
  name: 'UserOffersOpeningWidget',
  components: { Countdown, VTable },
  props: {
    order: Object
  },
  data: function () {
    return {
      criteria: [],
      offers: [],
      openingDate: false,
      openingEnabledBySettings: false
    }
  },
  computed: {
    serverTime: function () {
      return new Date(this.$store.state.stats.currentTime)
    },
    openingEnabled: function () {
      return this.openingEnabledBySettings && this.order.isApplicant && this.openingDate && this.serverTime > this.openingDate
    },
    tableColumns () {
      return [
        {
          key: 'applicant',
          label: 'Uchádzač'
        },
        {
          key: 'criteria',
          label: 'Plnenie kritérií'
        }
      ]
    }
  },
  watch: {
    openingEnabled: function (value) {
      if (value) {
        this.loadOffers()
      }
    }
  },
  methods: {
    formatNumber: function (value, decimalCount) {
      return sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } })
    },
    async loadOffers (retryCount = 3) {
      try {
        let { data: { offers } } = await this.$http.post('/offer/catalog', { order: this.order.id, sort: '-sentAt' })
        this.offers = offers.map((offer) => {
          let data = {
            offer: offer,
            proposals: {}
          }
          offer.proposals.forEach(function (proposal) {
            data.proposals[proposal.criterion] = proposal.value ? proposal.value : ''
          })
          return data
        })
      } catch (error) {
        if (error.response && error?.response?.status === 423 && retryCount > 0) {
          await new Promise(resolve => setTimeout(resolve, 500)) // wait for 500ms
          return this.loadOffers(retryCount - 1) // retry the function
        } else {
          this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }
      }
    }
  },
  created () {
    if (this.order.procedureType === 'tste' && this.order.bidsOpenAt2) {
      this.openingDate = new Date(this.order.bidsOpenAt2)
    }
    if (this.order.procedureType === 'ssse' && this.order.bidsOpenAt) {
      this.openingDate = new Date(this.order.bidsOpenAt)
    }
    for (let i = 0; i < this.order.criteria.length; i++) {
      this.criteria.push(Object.assign({}, this.order.criteria[i], { value: '' }))
    }
    if (!this.order.auction && this.order.offerOpeningType === 'distant') {
      this.openingEnabledBySettings = true
    }
  }
}
</script>
