import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import KdAdmin from '../views/Admin'
import KdAdminApplications from '../views/AdminApplications'
import KdAdminApplicationsView from '../views/AdminApplicationsView'
import KdAdminHome from '../views/AdminHome'
import AdminOverviewDns from '@/components/order/common/views/AdminOverviewDns'
import AdminSettingsDns from '../../common/views/AdminSettingsDns.vue'
import KdAdminSuppliers from '../views/AdminSuppliers'
import KdAdminInterested from '../views/AdminInterested'
import KdAdminTenders from '../views/AdminTenders'
import User from '../views/User'
import UserApplications from '../views/UserApplications'
import UserApplicationsAdd from '../views/UserApplicationsAdd'
import UserApplicationsView from '../views/UserApplicationsView'
import UserOverviewDns from '@/components/order/common/views/UserOverviewDns'
import KdUserTenders from '../views/UserTenders'

export default [
  {
    path: '/kd/admin/:orderId',
    name: 'kd-admin',
    redirect: { name: 'kd-admin-overview' },
    component: KdAdmin,
    children: [
      {
        path: 'home',
        name: 'kd-admin-home',
        redirect: { name: 'kd-admin-overview' },
        component: KdAdminHome,
        children: [
          {
            path: 'overview',
            name: 'kd-admin-overview',
            component: AdminOverviewDns
          },
          {
            path: 'settings',
            name: 'kd-admin-settings',
            component: AdminSettingsDns
          },
          {
            path: 'documents',
            name: 'kd-admin-documents',
            component: AdminDocuments
          },
          {
            path: 'documents/add',
            name: 'kd-admin-documents-add',
            component: AdminDocumentsAdd
          },
          {
            path: 'documents/edit/:id',
            name: 'kd-admin-documents-edit',
            component: AdminDocumentsAdd
          },
        ]
      },
      {
        path: 'communication',
        name: 'kd-admin-communication',
        component: AdminCommunication
      },
      {
        path: 'communication/add',
        name: 'kd-admin-communication-add',
        component: AdminCommunicationAdd
      },
      {
        path: 'interested',
        name: 'kd-admin-interested',
        component: KdAdminInterested
      },
      {
        path: 'tenders',
        name: 'kd-admin-tenders',
        component: KdAdminTenders
      },
      {
        path: 'applications',
        name: 'kd-admin-applications',
        component: KdAdminApplications
      },
      {
        path: 'suppliers',
        name: 'kd-admin-suppliers',
        component: KdAdminSuppliers
      },
      {
        path: 'applications/view/:applicationId',
        name: 'kd-admin-applications-view',
        component: KdAdminApplicationsView
      }
    ]
  },
  {
    path: 'kd/user/:orderId',
    name: 'kd-user',
    redirect: { name: 'kd-user-overview' },
    component: User,
    children: [
      {
        path: 'overview',
        name: 'kd-user-overview',
        component: UserOverviewDns
      },
      {
        path: 'tenders',
        name: 'kd-user-tenders',
        component: KdUserTenders
      },
      {
        path: 'communication',
        name: 'kd-user-communication',
        component: UserCommunication
      },
      {
        path: 'communication/add',
        name: 'kd-user-communication-add',
        component: UserCommunicationAdd
      },
      {
        path: 'applications',
        name: 'kd-user-applications',
        component: UserApplications
      },
      {
        path: 'applications/add',
        name: 'kd-user-applications-add',
        component: UserApplicationsAdd
      },
      {
        path: 'applications/view',
        name: 'kd-user-applications-view',
        component: UserApplicationsView
      }
    ]
  }
]
