<template>
  <div class="card">
    <div class="card-heading">
      <h3 class="card-title ">Výzvy</h3>
    </div>
    <div class="card-block">
      <div class="row align-items-baseline tabel-filters">
        <div class="col-md-4 form-group">
          <label class="text-bold text-dark">Hľadaj v názve alebo popise:</label>
          <input v-model="fulltext" class="form-control" />
        </div>
        <div class="col-md-3 form-group">
          <label class="text-bold text-dark">Dátum vyhlásenia od:</label>
          <date-input
            name="journalAnnouncementDateFrom"
            v-model="journalAnnouncementDateFrom"
            :min-date="new Date()"
          />
        </div>
        <div class="col-md-3 form-group">
          <label class="text-bold text-dark">Dátum vyhlásenia do:</label>
          <date-input
            name="journalAnnouncementDateTo"
            v-model="journalAnnouncementDateTo"
            :min-date="new Date()"
          />
        </div>
      </div>
    </div>
    <v-table
      :items="contracts.orders"
      :columns="contractsColumns"
      :sort.sync="tableSort"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(name)="{ item }">
        <order-link :user-type="'user'" :order="item" />
      </template>
      <template v-slot:cell(journalAnnouncementDate)="{ item }">
        {{ item.journalAnnouncementDate | formatDateTime }}
      </template>
      <template v-slot:cell(bidsOpenAt)="{ item }">
        {{ item.bidsOpenAt | formatDateTime }}
      </template>
      <template v-slot:cell(state)="{ item }">
        <order-state :state="item.state" display="text"></order-state>
      </template>
    </v-table>
    <div class="card-footer">
      <v-pagination
        v-if="contracts.total"
        :page="page"
        :per-page="perPage"
        :per-page-options="pageSizeOptions"
        :total-elements="contracts.total"
      />
    </div>
  </div>
</template>

<script>
import OrderLink from '@/components/order/OrderLink.vue'
import OrderState from '@/components/OrderState.vue'
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import DateInput from '@/components/forms/DateInput.vue'

export default {
  name: 'KdUserTenders',
  components: {
    OrderState,
    OrderLink,
    VPagination,
    VTable,
    DateInput
  },
  inject: ['order', 'routePrefix'],
  data () {
    return {
      contracts: {},
      fulltext: '',
      journalAnnouncementDateFrom: '',
      journalAnnouncementDateTo: '',
      tableSort: {
        sortBy: 'journalAnnouncementDate',
        sort: 'desc'
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [1, 5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    contractsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov zákazky',
          sortable: true
        },
        {
          key: 'organization.name',
          label: 'Názov obstarávateľa',
          sortable: true
        },
        {
          key: 'journalAnnouncementDate',
          label: 'Dátum vyhlásenia súťaže',
          sortable: true
        },
        {
          key: 'bidsOpenAt',
          label: 'Dátum otvárania ponúk',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav',
          sortable: true
        }
      ]
    },
    fetchParams () {
      return {
        'parent': this.$router.currentRoute.params.orderId,
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        'journalAnnouncementFrom': this.journalAnnouncementDateFrom,
        'journalAnnouncementTo': this.journalAnnouncementDateTo,
        'filter': this.fulltext

      }
    }
  },
  methods: {
    async fetchContracts () {
      const { data } = await this.$http.post('/order/catalog', this.fetchParams)
      this.contracts = data
    }
  },
  mounted () {
    this.fetchContracts()
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    }
  }
}
</script>
