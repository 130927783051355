<template>
  <div class="card chart-card">
    <div class="card-heading">
      <h4 class="card-title">Zákazky používateľa</h4>
    </div>
    <div v-if="statesChartData" class="p-2">
      <pie-chart-echart
        :data="statesChartData"
        :tooltipFormat="tooltipFormat"
        :color="statesChartColors"
      />
    </div>

    <div v-else class="card-body">
      <span>Momentálne neobsahuje žiadne záznamy</span>
    </div>
  </div>
</template>

<script>
import PieChartEchart from '@/components/ui/PieChartEchart.vue'

export default
{
  name: 'UserOrderCountsChart',
  components: { PieChartEchart },
  data () {
    return {
      orderStateStats: null,
      orderStateTotal: 0
    }
  },
  computed: {
    tooltipFormat () {
      return (params) => {
        return `<span class="text-bold">${params.name}:</span> ${params.value} (${params.percent}%)`
      }
    },
    statesChartColors () {
      const colors = ['#FF9D3B', '#1FCB7C', '#3E97EF', '#EB5757'] // Colors correspond to desiredOrder
      const desiredOrder = ['planned', 'active', 'finished', 'cancelled']
      const stats = this.orderStateStats

      if (!stats || stats.length === 0) {
        return null
      }

      // Filter desiredOrder by states with a value > 0 in stats
      const availableStates = desiredOrder.filter(state => stats[state] > 0)

      // Return the colors corresponding to the available states
      return availableStates.map(state => {
        const stateIndex = desiredOrder.indexOf(state)
        return colors[stateIndex]
      })
    },
    statesChartData () {
      const desiredOrder = ['planned', 'active', 'finished', 'cancelled']
      const stats = this.orderStateStats
      if (!stats || stats?.length === 0) {
        return null
      }

      // Prepare the data structure based on the fetched object
      let data = desiredOrder
        .map((state) => {
          return {
            state,
            name: this.$format.orderState(state),
            value: stats[state] || 0
          }
        })

      return data
    }
  },
  methods: {
    async fetchStatistics () {
      const { data: { stats } } = await this.$http.post('/stats/generate', {
        object: 'users',
        type: 'user-stats'
      })
      this.orderStateTotal = stats?.total || 0
      this.orderStateStats = stats[0]
    },
  },
  mounted () {
    this.fetchStatistics()
  }
}
</script>
