<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: 'mr-admin-settings', params: {orderId: order.id, action: 'edit'} }">Nastavenia</router-link>
      <router-link :to="{ name: 'mr-admin-documents', params: {orderId: order.id} }">Dokumenty</router-link>
      <router-link :to="{ name: 'mr-admin-items', params: {orderId: order.id} }">Položky</router-link>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MrAdminHome',
  inject: ['order']
}
</script>
