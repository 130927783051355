import { ReactiveModel } from 'vue-rawmodel'

export default class Request extends ReactiveModel {
  constructor (data = {}) {
    super(data)

    this.defineField('id', {
      type: 'String'
    })

    this.defineField('name', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('description', {
      type: 'String'
    })

    this.defineField('value', {
      type: 'Number',
      defaultValue: 0,
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('valueWithVat', {
      type: 'Number',
      defaultValue: 0,
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('approvers', {
      /* type: ['String'], */
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné',
          condition () {
            return Array.isArray(this.owner.budgetApprovers) && this.owner.budgetApprovers.length === 0
          }
        }
      ]
    })

    this.defineField('budgetApprovers', {
      defaultValue: [],
      emptyValue: []
    })

    this.defineField('subjectType', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('cpvCode', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('justification', {
      type: 'String'
    })

    this.defineField('deliveryDate', {
      type: 'String',
      validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ]
    })

    this.defineField('note', {
      type: 'String'
    })

    this.defineField('suppliers', {
      defaultValue: [],
      emptyValue: []
      /* type: 'String' */
    })

    this.defineField('budgets', {
      /* type: ['String'], */
      /* validate: [
        {
          validator: 'presence',
          message: 'Toto pole je povinné'
        }
      ] */
    })

    this.defineField('items', {
      defaultValue: [],
      emptyValue: []
    })

    this.populate(data)
    this.commit()
  }
}
