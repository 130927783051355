<template>
  <div class="p-3">
    <p>Celkovo {{ status.currentIndex }} z {{ status.total }}</p>
    <progress-bar size="medium" :val="totalPercent" :text="`${totalPercent}%`"></progress-bar>
    <p>Nahrávam {{ status.currentName }}</p>
    <progress-bar size="medium" :val="status.currentPercent" :text="`${status.currentPercent}%`"></progress-bar>
  </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress'
import Bus from './bus'

export default {
  name: 'TenderiaUploadProgress',
  components: { ProgressBar },
  data () {
    return {
      status: {
        currentIndex: 1,
        currentPercent: 0,
        currentName: '',
        total: 1
      }
    }
  },
  computed: {
    totalPercent: function () {
      return Math.round(((this.status.currentIndex - 1) + (this.status.currentPercent / 100)) / this.status.total * 100)
    }
  },
  created () {
    Bus.$on('upload:status', status => {
      this.status = Object.assign(this.status, status)
    })
  }
}
</script>
