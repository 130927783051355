import { ReactiveModel } from 'vue-rawmodel'
import Organization from './Organization'
import User from './User'

export default class Profile extends ReactiveModel {
  constructor (data = {}) {
    super(data)

    this.defineField('organization', {
      type: Organization
    })

    this.defineField('user', {
      type: User
    })

    this.defineField('representative', {
      type: Boolean,
      defaultValue: true
    })

    this.populate(data)
    this.commit()
  }
}
