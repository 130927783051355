<template>
  <div class="email-content">
    <div class="email-content-wrapper" v-if="message.id">
      <div class="email-content-tools">
        <ul class="tools">
          <a class="back d-md-none" href="" title="Späť" @click.prevent="$emit('back-to-list')"><i class="icon-arrow-left font-size-30"></i></a>
          <span class="time text-secondary">{{ message.createdAt | formatDateTime}}</span>
          <div class="actions">
            <li class="d-none d-sm-block"><a href="" title="Vytlačiť" @click.prevent="print()"><i class="icon-print"></i></a></li>
            <li v-if="mode === 'junk' && this.canEdit"><a href="" title="Obnoviť" @click.prevent="$emit('undelete-message')"><i class="icon-synch"></i></a></li>
          </div>
        </ul>
        <div class="title">
          <span class="subject text-bold">{{ message.subject }}</span>
          <span v-if="from === 'Portál tenderia <spravca@tenderia.sk>'" class="from">Portál tenderia</span>
          <span v-else class="from">{{ from }}</span>
        </div>
      </div>
      <div class="email-content-detail">
        <div class="detail-head">
          <ul class="list-unstyled list-info">
            <li>
              <div>
                <div class="info">
                  <span class="sub-title text-dark">
                    <span class="text-bold">Typ správy:</span> {{ message.type | formatMessageType }}<br />
                    <span  v-if="to" class="text-bold">Pre:</span> {{ to }}<br />
                    <template v-if="message.validTo">
                      <br />Nutné odpovedať do: <span class="text-bold">{{ message.validTo | formatDateTime}}</span>
                    </template>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="detail-body text-dark">
          <div v-html="message.body"></div>
          <template v-if="message.validTo">
            <p class="mrg-top-15"><span class="text-dark">Upozorňujeme Vás, že na túto správu ste povinní doručiť odpoveď do</span> <span class="text-danger">{{ message.validTo | formatDateTime}}</span></p>
          </template>
        </div>
        <div class="detail-foot">
          <span class="text-bold" v-if="message.attachments && message.attachments.length > 0">Prílohy:</span>
          <span class="float-right"><a href="" title="Stiahnuť všetky prílohy" @click.prevent="downloadAttachments()"  v-if="message.attachments && message.attachments.length > 1"><i class="icon-download"></i> Stiahnuť všetky</a></span>
          <ul class="attachments">
            <li v-for="attachment in message.attachments" :key="attachment.id" :class="attachmentExtension(attachment.mime)">
              <file-icon-link :file="attachment" />
            </li>
          </ul>
          <div class="mrg-top-20 pdd-top-10" v-if="mode === 'sent' && userStats.length > 0">
            <p class="no-mrg font-weight-light">Stav prečítania</p>
            <message-state-table :message="message" :user-stats="userStats" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileIconLink from '../utils/FileIconLink.vue'
import EmailHelper from '../utils/EmailHelper.js'
import MessageStateTable from './MessageStateTable.vue'

export default {
  name: 'EmailContentDefault',
  components: { FileIconLink, MessageStateTable },
  props: ['currentMessage', 'mode', 'canEdit'],
  data () {
    return {
      message: {},
      userStats: [],
      from: '',
      to: '',
      authToken: localStorage.getItem('token')
    }
  },
  methods: {
    loadMessage () {
      let emailHelper = new EmailHelper(this.currentMessage, this.mode)
      let messageBuild = emailHelper.buildMetadata()

      this.message = emailHelper.message
      this.from = messageBuild.from
      this.to = messageBuild.to.slice(0, -2)

      if (this.currentMessage.userStats && this.currentMessage.userStats.length > 0) {
        this.userStats = this.currentMessage.userStats
      }
    },
    attachmentExtension (mime) {
      return mime.split('/')[1]
    },
    print () {
      let routeData = this.$router.resolve({ name: 'printMessage', params: { messageid: this.message.id, mode: this.mode } })
      window.open(routeData.href, '_blank')
    },
    async downloadAttachments () {
      this.$emit('epm:new', { title: 'Pripravujem prílohy na stiahnutie', type: 'default' })
      let data = {
        message: {
          id: this.message.id
        }
      }

      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.$emit('epm:close')
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.$globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.$globals.authToken}`)
          }
        })
      } catch (error) {
        this.$emit('epm:close')
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error?.response?.data.error)
      }
    }
  },
  mounted () {
    this.loadMessage()
  }
}
</script>
