<template>
  <div class="card" id="my-offer">
    <div class="card-heading">
      <h4 class="card-title"><span v-if="order.parent && order.part">Časť {{ order.part}} - </span>{{ order.name }}</h4>
      <div class="actions">
        <!-- <button v-if="offer.id" class="btn btn-tenderia-blue" @click="openOffer()">
          <span>Zobraziť ponuku</span>
        </button>
        <button v-if="canSendOffer" class="btn btn-success" @click="sendOffer()">
          <i class="icon-send"></i> <span>Odoslať ponuku</span>
        </button>
        <button v-if="offer.id && offer.state === 'new'" class="btn btn-danger" @click="removeOfferModal()">
          <i class="icon-delete"></i> <span>Zmazať ponuku</span>
        </button>
        <button v-if="canRevokeOffer" class="btn btn-warning" @click="revokeOfferModal()">
          <i class="icon-close"></i> Odvolať ponuku
        </button> -->
        <button v-if="canCreateOffer && !canRevokeOffer" :disabled="!canCreateOffer" class="btn btn-tenderia-blue" @click="addOffer()">
          <template v-if="offer.id"><i class="icon-pencil"></i> Upraviť ponuku</template><template v-else><i class="icon-add font-size-14"></i> Vytvoriť ponuku</template>
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <p class="py-3">
            Koniec lehoty na predkladanie ponúk: <span v-if="order.bidsLimitTo" class="text-bold">{{ order.bidsLimitTo | formatDateTime }}</span><span class="text-bold" v-else>Termín nebol stanovený</span>
          </p>
          <p class="text-dark text-center pdd-vertical-25" v-if="canCreateOffer">
            <countdown :deadline="bidsLimitTo" :server-time="serverTime"></countdown>
          </p>
          <p class="text-dark text-center text-danger lead" v-if="!authLevelComplied">
            Pre predloženie alebo odvolanie ponuky je vyžadované prihlásenie pomocou eID!
          </p>
        </div>
      </div>
    </div>
    <v-table
      v-if="offer.id"
      :items="[offer]"
      :columns="offerColumns"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(createdAt)="{ item }">
        {{ item.createdAt | formatDateTime }}
      </template>
      <template v-slot:cell(updatedAt)="{ item }">
        {{ item.updatedAt | formatDateTime }}
      </template>
      <template v-slot:cell(sentAt)="{ item }">
        <span v-if="item.state !== 'new'">{{ item.sentAt | formatDateTime }}</span>
      </template>
      <template v-slot:cell(state)="{ item }">
        <v-badge class="m-0" :color="item.state !== 'new' ? 'green' : 'red'" glow>
          {{ item.state | formatOfferState}}
        </v-badge>
      </template>
    </v-table>
    <p class="text-bold text-dark text-center pdd-vertical-50" v-else>
      Nemáte vytvorenú žiadnu ponuku
    </p>
    <div class="card-footer">
      <div class="row">
        <button v-if="offer.id" class="btn btn-tenderia-blue" @click="openOffer()">
          <span>Zobraziť ponuku</span>
        </button>
        <button v-if="canSendOffer" class="btn btn-success" @click="sendOffer()">
          <i class="icon-send"></i> <span>Odoslať ponuku</span>
        </button>
        <button v-if="offer.id && offer.state === 'new'" class="btn btn-danger" @click="removeOfferModal()">
          <i class="icon-delete"></i> <span>Zmazať ponuku</span>
        </button>
        <button v-if="canRevokeOffer" class="btn btn-warning" @click="revokeOfferModal()">
          <i class="icon-close"></i> Odvolať ponuku
        </button>
        <!-- <button v-if="canCreateOffer && !canRevokeOffer" :disabled="!canCreateOffer" class="btn btn-tenderia-blue" @click="addOffer()">
          <template v-if="offer.id"><i class="icon-pencil"></i> Upraviť ponuku</template><template v-else><i class="icon-add font-size-14"></i> Vytvoriť ponuku</template>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import VTable from '@/components/ui/Table.vue'
import VBadge from '@/components/ui/Badge.vue'

export default {
  name: 'OfferWidget',
  components: { Countdown, VTable, VBadge },
  props: ['order'],
  inject: ['eventBus', 'routePrefix'],
  data () {
    return {
      offer: {
        id: null
      }
    }
  },
  computed: {
    offerColumns () {
      return [
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia'
        },
        {
          key: 'updatedAt',
          label: 'Dátum poslednej aktualizácie'
        },
        {
          key: 'sentAt',
          label: 'Dátum odoslania ponuky'
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    bidsLimitTo () {
      return this.order.bidsLimitTo ? new Date(this.order.bidsLimitTo) : false
    },
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    canSendOffer () {
      return this.offer.id && this.offer.state === 'new' && this.canCreateOffer && this.authLevelComplied
    },
    canCreateOffer () {
      return this.bidsLimitTo && this.serverTime < this.bidsLimitTo && this.authLevelComplied
    },
    canRevokeOffer () {
      return this.offer.id && this.offer.state === 'sent' && this.canCreateOffer && this.authLevelComplied
    },
    authLevelComplied () {
      return this.$globals.user.authLevel >= this.order.requiredAuthLevel
    }
  },
  filters: {
    formatOfferState (value) {
      var states = {
        new: 'Neodoslaná',
        sent: 'Odoslaná',
        seen: 'Otvorená'
      }
      return states[value]
    }
  },
  methods: {
    addOffer () {
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.routePrefix}user-offers-add-parts`, params: { orderId: this.order.parent, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.routePrefix}user-offers-add`, params: { orderId: this.order.id } })
      }
    },
    sendOffer () {
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.routePrefix}user-offers-add-parts`, params: { orderId: this.order.parent, partId: this.order.id, action: 'send' } })
      } else {
        this.$router.push({ name: `${this.routePrefix}user-offers-add`, params: { orderId: this.order.id, action: 'send' } })
      }
    },
    openOffer () {
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.routePrefix}user-offers-view-parts`, params: { orderId: this.order.parent, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.routePrefix}user-offers-view`, params: { orderId: this.order.id } })
      }
    },
    revokeOfferModal () {
      this.YesNoAlert({
        text: 'Skutočne chcete odvolať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odvolať'
      }).then((result) => {
        if (result.value) {
          this.revokeOffer()
        }
      })
    },
    revokeOffer () {
      this.$emit('epm:new', { title: 'Odvolávam ponuku', type: 'default' })
      var vm = this
      this.$http.post('/offer/set', { offer: { organization: this.$globals.userOrganization.id, order: this.order.id, id: this.offer.id, state: 'new' } }).then(function () {
        vm.offer.state = 'new'
        vm.eventBus.$emit('order-updated')
        vm.$emit('epm:close')
      }).catch((error) => {
        vm.$emit('epm:close')
        setTimeout(function () {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }, 500)
      })
    },
    removeOfferModal () {
      this.YesNoAlert({
        text: 'Skutočne chcete zmazať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeOffer()
        }
      })
    },
    removeOffer () {
      this.$emit('epm:new', { title: 'Ponuka sa odstraňuje', type: 'default' })
      var vm = this
      this.$http.post('/offer/remove', { offer: { order: this.order.id } }).then(function () {
        vm.offer = {
          id: null
        }
        vm.$emit('epm:close')
      }).catch((error) => {
        vm.$emit('epm:close')
        setTimeout(function () {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }, 500)
      })
    }
  },
  async created () {
    let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.order.id } })
    if (offer.id) {
      this.offer = offer
    }
  }
}
</script>
