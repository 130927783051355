<template>
  <div class="column">
    <div class="row">
      <div class="col-md-12">
        <email-client class="px-0" :order="order" v-if="order.id">
          <template #append>
            <router-link
              class="btn btn-success m-0 ml-auto"
              :to="{name: `${routePrefix}user-communication-add`,params: {orderId: order.id}}"
            >
              <i class="icon-messages"></i> Nová správa</router-link>
          </template>
        </email-client>
      </div>
    </div>
  </div>
</template>

<script>
import EmailClient from '@/components/emailClient/EmailClient.vue'
export default {
  name: 'UserCommunication',
  components: { EmailClient },
  inject: ['order', 'routePrefix']
}
</script>
