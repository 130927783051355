<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: `${routePrefix}admin-suppliers-search` }">Vyhľadať dodávateľa</router-link>
      <router-link :to="{ name: `${routePrefix}admin-suppliers-add` }">Pridať dodávateľa</router-link>
    </div>
    <div class="row mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminSupplierInvite',
  inject: ['order', 'routePrefix']
}
</script>
