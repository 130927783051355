<template>
  <organizations-list :organizations="order.applicants" :order="order" title="Zoznam uchádzačov" />
</template>

<script>
import OrganizationsList from '@/components/order/common/components/OrganizationsList.vue'

export default {
  name: 'SdvAdminApplicants',
  components: { OrganizationsList },
  inject: ['order']
}
</script>
