<template>
  <div>
    <h1>Vaša žiadosť o zaradenie do DNS</h1>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Identifikácia dodávateľa</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row" v-if="offer.applicantDetails.groupName">
                <div class="col-md-5">
                  <b>Názov skupiny dodávateľov</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName }}
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-5">
                  <b>Obchodné meno alebo názov uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.businessName }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Sídlo alebo miesto podnikania uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.address }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IČO</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li v-for="(ico, index) in offer.applicantDetails.ico" :key="`ico-${index}`">{{ ico }}</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Platca DPH</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.vatPayer | formatYesNo}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Štát</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.country }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Meno a priezvisko kontaktnej osoby</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.contactPerson }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefón</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.phone }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Fax</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.fax }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Email</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Systémové informácie</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row" v-if="offer.state !== 'new'">
                <div class="col-md-5">
                  <b>Dátum odoslania uchádzačom</b>
                </div>
                <div class="col-md-7">
                  {{ offer.sentAt | formatDateTime }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IP adresa uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.userIP }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav žiadosti</b>
                </div>
                <div class="col-md-7">
                  <state :application="offer" />
                </div>
              </div>
              <div class="row" v-if="offer.stage === 'excluded'">
                <div class="col-md-5">
                  <b>Dôvod vylúčenia ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ offer.decision }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Doklady vyžadované výzvou</h4>
          </div>
          <v-table
            :items="offer.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-blue" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
      <button v-if="canSendApplication" class="btn btn-success" @click="sendOffer()">
        <i class="icon-send"></i> <span>Odoslať žiadosť</span>
      </button>
      <button v-if="canEditApplication" class="btn btn-tenderia-blue" @click="editOffer()">
        <i class="icon-pencil"></i> Upraviť žiadosť
      </button>
    </div>
  </div>
</template>

<script>
import BaseOffer from '@/components/order/common/components/BaseOffer.vue'
import State from '../components/UserApplicationState'
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'DnsApplicationsView',
  extends: BaseOffer,
  components: { State, VTable },
  inject: ['routePrefix'],
  computed: {
    canSendApplication () {
      return this.basicRequirements && this.offer.state === 'new'
    },
    canEditApplication () {
      return this.basicRequirements && this.offer.state === 'new'
    },
    basicRequirements () {
      return this.offer.id && this.order.state === 'active' && this.authLevelComplied
    },
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
  methods: {
    editOffer () {
      this.$router.push({ name: `${this.routePrefix}user-applications-add`, params: { orderId: this.order.id } })
    },
    sendOffer () {
      this.$router.push({ name: `${this.routePrefix}user-applications-add`, params: { orderId: this.order.id, action: 'send' } })
    }
  },
  async created () {
    try {
      let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.order.id } })
      Object.assign(this.offer, offer)
      this.bidsLimitTo = this.order.bidsLimitTo ? new Date(this.order.bidsLimitTo) : false
      this.ready = true
    } catch (error) {
      this.ErrorAlert({ text: 'Pri načítavaní ponuky nastala chyba.' }, error?.response?.data.error)
    }
  }
}
</script>
