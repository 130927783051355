<template>
  <div>
    <h1><span v-if="offerOrder.parent && offerOrder.part && offerOrder.subtype !== 'vyz'">Ponuka pre časť č. {{ offerOrder.part}}</span></h1>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Identifikácia dodávateľa</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Uchádzač je členom skupiny dodávateľov, ktorá predkladá ponuku</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName ? 'Áno' : 'Nie' }}
                </div>
              </div>
              <div class="row" v-if="offer.applicantDetails.groupName">
                <div class="col-md-5">
                  <b>Názov skupiny dodávateľov</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName }}
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-5">
                  <b>Obchodné meno alebo názov uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.businessName }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Sídlo alebo miesto podnikania uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.address }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IČO</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li v-for="(ico, index) in offer.applicantDetails.ico" :key="`ico-${index}`">{{ ico }}</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Platca DPH</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.vatPayer | formatYesNo}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Štát</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.country }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zoznam osôb oprávnených konať v mene uchádzača</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li v-for="(authorizedPerson, index) in offer.applicantDetails.authorizedPersons" :key="`authorized-person-${index}`">{{ authorizedPerson }}</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Meno a priezvisko kontaktnej osoby</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.contactPerson }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefón</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.phone }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Fax</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.fax }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Email</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Systémové informácie</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum odoslania uchádzačom</b>
                </div>
                <div class="col-md-7">
                  {{ offer.sentAt | formatDateTime }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IP adresa uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.userIP }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ offer.state | formatOfferState }}
                </div>
              </div>
              <div class="row" v-if="offer.stage === 'excluded'">
                <div class="col-md-5">
                  <b>Dôvod vylúčenia ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ offer.decision }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="offerOrder.items.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Položky</h4>
          </div>
          <items-table v-if="ready" :order="order" :offer="offer" :decimal-count="decimalCount" :editable="false" />
        </div>
      </div>
    </div>
    <div class="row" v-if="Array.isArray(criteria) && criteria.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Návrh na plnenie kritérií</h4>
          </div>
          <div class="card-block">
            <div class="row form-group" v-for="criterion in criteria" :key="criterion._id">
              <div class="col-md-9">
                <label :for="criterion._id" class="text-bold text-dark">{{ criterion.name}}</label>
              </div>
              <div class="col-md-2 text-dark">
                {{ formatNumber(criterion.value, criterion.decimalCount) }}
              </div>
              <div class="col-md-1 text-dark">
                {{ criterion.unit }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="Array.isArray(criteria) && criteria.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Prílohy kritérií</h4>
          </div>
          <v-table
            :items="offer.attachments2"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row" v-if="offerOrder.procedureType === 'tste'">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Sprievodný text ponuky</h4>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-md-12" v-if="offer.body">
                {{ offer.body }}
              </div>
              <p class="text-dark text-center pdd-vertical-25" v-else>
                Sprievodný text ponuky uchádzač nevyplnil.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="offerOrder.procedureType === 'tste'">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Prílohy ponuky</h4>
          </div>
          <v-table
            :items="offer.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-blue" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
      <button v-if="canSendOffer" class="btn btn-success" @click="sendOffer()">
        <i class="icon-send"></i> <span>Odoslať ponuku</span>
      </button>
      <button v-if="canCreateOffer && !canRevokeOffer" :disabled="!canCreateOffer" class="btn btn-tenderia-grey-blue" @click="addOffer()">
        <i class="icon-pencil"></i> Upraviť ponuku
      </button>
    </div>
  </div>
</template>

<script>
import ItemsTable from '@/components/order/common/components/ItemsTable.vue'
import BaseUserOfferView from '@/components/order/common/components/BaseUserOfferView.vue'
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'UserOfferView',
  extends: BaseUserOfferView,
  components: { ItemsTable, VTable },
  data () {
    return {
      offerOrder: {}
    }
  },
  async created () {
    if (this.$route.params.partId) {
      for (let i = 0; i < this.order._parts.length; i++) {
        if (this.order._parts[i].id === this.$route.params.partId) {
          Object.assign(this.offerOrder, this.order._parts[i])
          break
        }
      }
    } else {
      Object.assign(this.offerOrder, this.order)
    }
    try {
      let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.offerOrder.id } })
      this.offer = offer
      this.bidsLimitTo = this.offerOrder.bidsLimitTo ? new Date(this.offerOrder.bidsLimitTo) : false
      this.prepareCriteria()
      this.ready = true
    } catch (error) {
      this.ErrorAlert({ text: 'Pri načítavaní ponuky nastala chyba.' }, error?.response?.data.error)
    }
  },
  computed: {
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
}
</script>
