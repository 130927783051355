<template>
  <form>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-dark text-bold">Zákazka, ktorú chcete skopírovať <small>*</small></label>
          <order-select v-if="showSourceContractSelect" v-model="sourceContract" />
          <div v-else style="padding: 5px;">
            <span class="text-dark">{{sourceContract.name}}</span> ({{ sourceContract | formatProcedureType}})<br />
            <span>{{sourceContract.organization.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label :for="`nazov-zakazky`" class="text-dark text-bold">Názov novej zákazky <small>*</small></label>
          <input :id="`nazov-zakazky`" type="text" v-model="orderName" :class="{ error: form.orderName.error, valid: form.orderName.valid }" class="form-control" required="required" aria-required="true">
          <label v-if="form.orderName.error" class="error" :for="`nazov-zakazky`">Toto pole je povinné. Môžete zadať max. 200 znakov</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-dark text-bold">Obstarávateľ</label>
          <contractor-select disabled :value="contractor" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-dark text-bold">Chcete skopírovať aj dokumenty zákazky ?</label>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <div class="radio radio-inline">
            <input type="radio" :value="true" v-model="cloneDocuments" id="clone-docs-true" /> <label for="clone-docs-true">Áno</label>
          </div>
          <div class="radio radio-inline">
            <input type="radio" :value="false" v-model="cloneDocuments" id="clone-docs-fasle" /> <label for="clone-docs-fasle">Nie</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close">Zrušiť</button>
      <button class="btn btn-success" @click.prevent="createContract">Klonovať zákazku</button>
    </div>
  </form>
</template>

<script>
import ContractorSelect from '@/components/forms/ContractorSelect.vue'
import OrderSelect from '@/components/forms/OrderSelect.vue'

export default {
  name: 'CloneOrderForm',
  components: { ContractorSelect, OrderSelect },
  props: {
    config: Object
  },
  data () {
    return {
      orderName: '',
      contractor: '',
      sourceContract: null,
      cloneDocuments: false,
      form: {
        orderName: {
          error: false,
          valid: false
        },
        sourceContract: {
          error: false,
          valid: false
        }
      },
      validators: [
        this.validateOrderName,
        this.validatesourceContract
      ]
    }
  },
  computed: {
    showSourceContractSelect () {
      return !(this.config.data && this.config.data.order)
    }
  },
  created () {
    if (!this.showSourceContractSelect) {
      this.sourceContract = this.config.data.order
      this.orderName = this.sourceContract.name
    }
    this.contractor = this.$globals.userOrganization.id
  },
  methods: {
    close () {
      this.$emit('vuedals:close', null)
    },
    async createContract () {
      if (!this.validate()) {
        return
      }
      try {
        this.ShowLoading({ title: 'Vytváram zákazku' })
        let id = await this.$tenderiaService.cloneContract({
          order: {
            id: this.sourceContract.id,
            organization: this.contractor,
            name: this.orderName,
            cloneDocuments: this.cloneDocuments
          }
        })
        this.CloseLoading()
        this.close()
        this.$openOrder(id, this.sourceContract.type, this.sourceContract.subtype)
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ title: 'Chyba', text: 'Zákazku sa nepodarilo vytvoriť' }, error?.response?.data.error)
      }
    },
    validate () {
      return this.validators.reduce((accumulator, validator) => validator() && accumulator, true)
    },
    validateOrderName () {
      let titleLength = this.orderName.replace(/\s/g, '').length
      let state = this.form.orderName
      state.valid = state.error = false
      if (titleLength > 0 && titleLength <= 200) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    },
    validatesourceContract () {
      let state = this.form.sourceContract
      state.error = state.valid = false
      if (this.sourceContract.id && this.sourceContract.id.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    }
  },
  watch: {
    orderName () {
      this.validateOrderName()
    },
    sourceContract () {
      this.validatesourceContract()
    }
  }
}
</script>
