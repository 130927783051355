<template>
  <ul class="vuejs-countdown" :class="colorClass">
    <li v-if="days > 0">
      <p class="digit">{{ days | twoDigits }}</p>
      <p class="text">D</p>
    </li>
    <li>
      <p class="digit">{{ hours | twoDigits }}</p>
      <p class="text">hod</p>
    </li>
    <li>
      <p class="digit">{{ minutes | twoDigits }}</p>
      <p class="text">min</p>
    </li>
    <li>
      <p class="digit">{{ seconds | twoDigits }}</p>
      <p class="text">sec</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Countdown',
  props: ['deadline', 'stop', 'serverTime'],
  data () {
    return {
      now: Math.trunc((new Date()).getTime() / 1000),
      date: null,
      diff: 0,
      interval: null
    }
  },
  created () {
    if (!this.deadline && !this.serverTime) {
      throw new Error('Missing props \'deadline\' or \'serverTime\'')
    }
    this.date = Math.trunc(this.deadline / 1000)

    if (!this.date) {
      throw new Error('Invalid props value, correct the \'deadline\'')
    }
    this.now = Math.trunc(this.serverTime.getTime() / 1000)
    this.diff = this.date - this.now
    this.interval = setInterval(() => {
      this.now = Math.trunc(this.serverTime.getTime() / 1000)
    }, 1000)
  },
  computed: {
    seconds () {
      return Math.trunc(this.diff) % 60
    },
    minutes () {
      return Math.trunc(this.diff / 60) % 60
    },
    hours () {
      return Math.trunc(this.diff / 60 / 60) % 24
    },
    days () {
      return Math.trunc(this.diff / 60 / 60 / 24)
    },
    colorClass () {
      let colors = { green: true, orange: false, red: false }

      if (this.days === 0) {
        if (this.hours <= 24 && this.hours >= 2) {
          colors.green = false
          colors.orange = true
          colors.red = false
        } else if (this.hours < 2) {
          colors.green = false
          colors.orange = false
          colors.red = true
        }
      }

      return colors
    }
  },
  watch: {
    now () {
      this.diff = this.date - this.now
      if (this.diff <= 0 || this.stop) {
        this.diff = 0
        // Remove interval
        clearInterval(this.interval)
      }
    }
  },
  filters: {
    twoDigits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
</script>
<style>
.vuejs-countdown {
  padding: 0;
  margin: 0;
}
.vuejs-countdown li {
  display: inline-block;
  margin: 0 8px;
  text-align: center;
  position: relative;
}
.vuejs-countdown li p {
    margin: 0;
}
.vuejs-countdown li > p:first-child:after {
  content: ":";
  position: absolute;
  top: 0;
  right: -13px;
  font-size: 64px;
}
.vuejs-countdown li:first-of-type {
  margin-left: 0;
}
.vuejs-countdown li:last-of-type {
  margin-right: 0;
}
.vuejs-countdown li:last-of-type > p:first-child:after {
  content: "";
}
.vuejs-countdown .digit {
  font-size: 64px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
.vuejs-countdown.green .digit {
  color: #1FCB7C !important;
}
.vuejs-countdown.orange .digit {
  color: #FF9D3B !important;
}
.vuejs-countdown.red .digit {
  color: #EB5757 !important;
}

.vuejs-countdown .text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 15px;
}
</style>
