<template>
  <multiselect
    v-model="selectedBudgets"
    :options="budgets"
    track-by="id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="multiple"
    placeholder="Vyberte si rozpočty"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'BudgetSelect',
  components: { Multiselect },
  props: {
    value: [Array, String],
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedBudgets: [],
      budgets: []
    }
  },
  computed: {
    fetchParams () {
      return {
        id: this.$globals.userOrganization.id
      }
    }
  },
  async mounted () {
    let { data } = await this.$http.post('/budget/list', this.fetchParams)
    this.budgets = data.budgets
    if (this.value) {
      this.selectedBudgets = this.budgets.filter(budget => this.value.find(val => val.id === budget.id))
    }
  },
  watch: {
    selectedBudgets (val) {
      if (!val) {
        this.$emit('input', [])
      } else {
        this.$emit('input', Array.isArray(val) ? val : [val])
      }
    },
    value (val) {
      if (!val) {
        this.selectedUsers = []
      }
    }
  }
}
</script>
