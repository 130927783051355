<template>
  <div class="row" v-if="order.divided">
    <div class="col-md-12">
      <offer-widget v-for="part in order._parts" :order="part" :key="part.id" />
    </div>
  </div>
  <div class="row" v-else>
    <div class="col-md-12">
      <offer-widget :order="order" />
    </div>
  </div>
</template>

<script>
import OfferWidget from '@/components/order/common/components/OfferWidget.vue'

export default {
  name: 'PtUserOffer',
  components: { OfferWidget },
  inject: ['order']
}
</script>
