<template>
  <div id="order-items">
    <template v-if="!order.divided">
      <items-list-widget :order="order" :parent="null" />
    </template>
    <template v-else>
      <items-list-widget v-for="part in order._parts" :order="part" :parent="order" :key="part.id" />
    </template>
  </div>
</template>

<script>
import ItemsListWidget from '../components/ItemsListWidget.vue'
export default {
  name: 'AdminItems',
  components: { ItemsListWidget },
  inject: ['order']
}
</script>
