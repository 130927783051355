<template>
  <organizations-list :organizations="order.qualifiedApplicants" :order="order" title="Uchádzači" />
</template>

<script>
import OrganizationsList from '@/components/order/common/components/OrganizationsList.vue'

export default {
  name: 'UsAdminSuppliers',
  components: { OrganizationsList },
  inject: ['order']
}
</script>
