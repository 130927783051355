<template>
  <div class="homepage-layout free-order">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <router-view/>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
  <!-- Page Container END -->
</template>

<script>
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
export default {
  name: 'PublicOrders',
  components: { AppLoginHeader, AppFooter }
}
</script>
