<template>
  <div class="container-fluid">
    <ul class="order-main-menu" :class="{'mb-3': !$route.fullPath.includes('/home/')}">
      <li>
        <router-link :to="{ name: 'sd-admin-home', params: {orderId: $route.params.orderId}}">Prehľad</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-admin-interested', params: {orderId: $route.params.orderId}}">Záujemcovia</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-admin-communication', params: {orderId: $route.params.orderId}}">Komunikácia</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-admin-applications', params: {orderId: $route.params.orderId}}">Žiadosti o účasť</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-admin-suppliers', params: {orderId: $route.params.orderId}}">Uchádzači</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'sd-admin-tenders', params: {orderId: $route.params.orderId}}">Výzvy na PP</router-link>
      </li>
    </ul>
    <router-view v-if="order.id" />
  </div>
</template>

<script>
import { isEqual } from 'lodash'

export default {
  name: 'SdAdmin',
  inject: ['eventBus'],
  data () {
    return {
      loading: false,
      order: {
        id: null,
        state: null,
        name: null,
        attachments: [],
        announcementUrl: '',
        qualifiedApplicants: [],
        estimatedPrice: null,
        description: null,
        journalAnnouncementDate: null,
        bidsLimitTo: null,
        organization: null,
        privateNote: null,
        offerOpeningType: 'nonpublic',
        subjectType: null,
        cpvCode: null,
        cpvCodes: [],
        procedureReverse: null,
        private: false,
        requiredAuthLevel: null,
        bidsEvaluateAt: null,
        mainDeliveryPlace: null,
        fundingByEu: null,
        participationConditions: null,
        bidsOpenAt: null,
        biddingMethod: 'dns',
        auction: false,
        _canEdit: this.$globals.user.type !== 'observer'
      },
      orderChanged: {
        value: false
      },
      oldOrder: {}
    }
  },
  provide () {
    return {
      order: this.order,
      routePrefix: 'sd-',
      orderChanged: this.orderChanged
    }
  },
  watch: {
    order: {
      handler (order) {
        if (!this.loading && !isEqual({ ...order }, this.oldOrder)) {
          this.orderChanged.value = true
          this.oldOrder = { ...order }
        }
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    async loadOrder () {
      this.loading = true
      let order = await this.$tenderiaService.getOrder(this.$route.params.orderId, this.$globals.user)
      Object.assign(this.order, order)
      this.order.announcementUrl = this.order.announcementUrl || `${this.$globals.backendHost}/public/order/${this.$route.params.orderId}`
      this.eventBus.$emit('order-data', this.order)
      this.oldOrder = { ...this.order }
      this.loading = false
    }
  },
  mounted () {
    this.eventBus.$on('order-updated', () => {
      this.loadOrder()
    })
    this.loadOrder()
  },
  destroyed () {
    this.eventBus.$off('order-updated')
  }
}
</script>
