import Home from '@/components/invoices/views/Home.vue'
import Overview from '@/components/invoices/views/Overview.vue'
import Settings from '@/components/invoices/views/Settings.vue'

export default [
  {
    path: 'invoice/add',
    name: 'invoice-add',
    redirect: { name: 'invoice-new' },
    component: Home,
    children: [
      {
        path: '',
        name: 'invoice-new',
        component: Settings
      }
    ]
  },
  {
    path: 'invoice/:invoiceId',
    name: 'invoice',
    redirect: { name: 'invoice-overview' },
    component: Home,
    children: [
      {
        path: 'overview',
        name: 'invoice-overview',
        component: Overview
      },
      {
        path: 'settings',
        name: 'invoice-settings',
        component: Settings
      }
    ]
  }
]
