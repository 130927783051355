import { TenderiaHttp as http } from '@/services/TenderiaHttp.js'

const state = {
  currentTime: 0,
  newMessages: 0
}

const getters = {
  getCurrentTime (state) {
    return state.currentTime
  },
  getNewMessages (state) {
    return state.newMessages
  }
}

const actions = {
  setCurrentTime ({ commit }, time) {
    commit('setCurrentTime', time)
  },
  async setNewMessages ({ commit }) {
    const response = await http.post('/message/count', { mode: 'inbox-order' })
    commit('setNewMessages', response.data.unreadMessages)
  }
}

const mutations = {
  setCurrentTime (state, time) {
    state.currentTime = time
  },
  setNewMessages (state, messages) {
    state.newMessages = messages
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
