<template>
  <div class="organizations">
    <div class="form-group">
      <div
        class="radio"
        v-for="organization in organizations"
        :key="organization.id"
      >
        <input
          type="radio"
          :value="organization"
          v-model="selectedOrganization"
          :id="organization.id"
        />
        <label :for="organization.id">{{ organization.name }}</label>
      </div>
    </div>
    <div class="row justify-content-end">
      <button class="btn btn-tenderia-blue" @click.prevent="confirmSelectedOrganization">Vybrať</button>
    </div>
  </div>
</template>

<script>
import STORE from '@/store'

export default {
  name: 'ProcedureSelect',
  props: {
    organizations: Array,
    relations: Array
  },
  data () {
    return {
      selectedOrganization: this.$globals.userOrganization
    }
  },
  methods: {
    async setUserOrganization (organization) {
      try {
        await this.$http.post('/user/organization/select', { organization: { id: organization.id } })
        const relation = this.relations.find((item) => item.organization === organization.id)
        this.$globals.user = {
          ...this.$globals.user,
          roles: relation && relation.roles,
          permissions: relation && relation.permissions.map(permission => `${permission.group}/${permission.object}/${permission.action}/${permission.level}`)
        }
        localStorage.setItem('user', JSON.stringify(this.$globals.user))
        this.$globals.userOrganization = organization
        localStorage.setItem('userOrganization', JSON.stringify(organization))
        if (this.$route.name !== 'freeOrderHome' && this.$route.name !== 'dashboard') {
          this.$router.push({ name: 'dashboard' })
        }
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
      }
    },
    confirmSelectedOrganization () {
      this.setUserOrganization(this.selectedOrganization)
      STORE.dispatch('setNewMessages')
      this.$emit('vuedals:close', null)
    }
  }
}
</script>
<style lang="scss" scoped>
.organizations {
  max-width: 700px;
  .radio {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 15px;
    min-height: 50px;
    font-size: 18px;
    font-weight: bold;
    &:nth-child(odd) {
      background-color: #EFF6FC;
    }
  }
}
</style>
