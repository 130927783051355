<template>
  <div class="carousel">
    <div
      class="swiper"
      :class="{'swipe-active': swipeActive}"
      :style="swiperStyle"
      @mousedown="swipeStart"
      @touchstart="swipeStart"
    >
      <div
        v-for="(item, i) in items"
        :key="i"
        class="item"
        :style="item.image && {backgroundImage: `url(${item.image})`}"
      >
        <slot :name="`item(${i + 1})`" :item="item">
          <slot :item="item"/>
        </slot>
      </div>
    </div>
    <div class="bullets row m-2">
      <div
        v-for="(item, i) in items"
        :key="i"
        class="item"
        :class="{active: activeIndex === i}"
        @click="setActive(i)"
      >
        <div class="bullet"/>
      </div>
    </div>
  </div>
</template>

<script>
const Events = {
  pointerdown: {
    move: 'pointermove',
    end: 'pointerup',
    xPos: e => e.pageX,
    yPos: e => e.pageY
  },
  mousedown: {
    move: 'mousemove',
    end: 'mouseup',
    xPos: e => e.pageX,
    yPos: e => e.pageY
  },
  touchstart: {
    move: 'touchmove',
    end: 'touchend',
    xPos: e => e.touches[0].pageX,
    yPos: e => e.touches[0].pageY
  }
}

function perc (v) {
  return v.toFixed(2) + '%'
}

function signNum (n) {
  return n >= 0 ? `+ ${n}` : `- ${Math.abs(n)}`
}

export default {
  props: {
    items: Array,
    timeout: {
      type: [Number, String],
      default: 5000
    }
  },
  data () {
    return {
      activeIndex: 0,
      swipeOffset: 0,
      swipeActive: false
    }
  },
  computed: {
    swiperStyle () {
      const activeIndexTranslate = perc(-this.activeIndex * (100 / this.items.length))
      return {
        width: perc(100 * this.items.length),
        transform: `translate3d(calc(${activeIndexTranslate} ${signNum(this.swipeOffset)}px), 0, 0)`
      }
    }
  },
  mounted () {
    this.setupTimer()
  },
  beforeDestroy () {
    this.cancelTimer()
  },
  methods: {
    cancelTimer () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    setupTimer () {
      this.timer = setTimeout(() => {
        this.activeIndex = (this.activeIndex + 1) % this.items.length
        this.timer = null
        this.setupTimer()
      }, this.timeout)
    },
    setActive (index) {
      this.cancelTimer()
      this.activeIndex = index
      this.setupTimer()
    },
    swipeStart (e) {
      this.cancelTimer()
      const Event = Events[e.type]
      const originX = Event.xPos(e)

      let drag = false
      const onMove = evt => {
        this.swipeOffset = Event.xPos(evt) - originX
        if (!drag) {
          drag = true
          this.swipeActive = true
        }
      }
      const onEnd = evt => {
        document.removeEventListener(evt.type, onEnd)
        document.removeEventListener(Event.move, onMove)
        if (!drag) {
          return
        }
        this.swipeActive = false
        if (this.activeIndex > 0 && this.swipeOffset > 50) {
          this.activeIndex -= 1
        }
        if (this.activeIndex < this.items.length - 1 && this.swipeOffset < -50) {
          this.activeIndex += 1
        }
        this.swipeOffset = 0
        this.setupTimer()
      }
      document.addEventListener(Event.move, onMove)
      document.addEventListener(Event.end, onEnd)
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100px;
  overflow: hidden;
  background-color: #222831;

  .swiper {
    height: 100%;
    display: flex;
    will-change: transform;
    user-select: none;
    position: relative;
    &:not(.swipe-active) {
      transition: transform .5s ease;
    }
    .item {
      padding-bottom: 24px;
      width: 100%;
      height: 100%;
      background-size: cover;
      user-select: none;
    }
  }
  .bullets {
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    .item {
      margin: 2px;
      padding: 4px;
      cursor: pointer;
      .bullet {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #fff;
      }
      &.active {
        .bullet {
          background-color: #1FCB7C;
        }
      }
    }
  }
}
</style>
