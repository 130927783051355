import ProfileView from '@/components/UserProfile/ProfileView'
import UserProfile from '@/components/UserProfile/UserProfile'
import UserOrganizations from '@/components/UserProfile/UserOrganizations'

export default [
  {
    path: 'profile',
    name: 'profile',
    redirect: { name: 'user-profile' },
    component: ProfileView,
    children: [
      {
        path: 'eidlink/:operationStatus',
        name: 'eidLink',
        props: {
          operation: 'eid'
        },
        component: UserProfile
      },
      {
        path: 'user',
        name: 'user-profile',
        props: {
          operation: 'profile'
        },
        component: UserProfile
      },
      {
        path: 'organization',
        name: 'user-organization',
        component: UserOrganizations
      }
    ]
  }
]
