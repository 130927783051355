<template>
  <form>
    <div class="row">
      <div class="col-md-12 form-group">
        <label class="text-dark text-bold">Stanovisko</label>
        <div>
          <div class="radio radio-inline">
            <input
              id="approve"
              type="radio"
              name="reject"
              v-model="stance"
              value="approved"
            />
            <label for="approve">Schvaľujem</label>
          </div>
          <div class="radio radio-inline">
            <input
              id="reject"
              type="radio"
              name="reject"
              v-model="stance"
              value="rejected"
            />
            <label for="reject">Nechvaľujem</label>
          </div>
          <label v-if="form.stance.error" class="error">Toto pole je povinné.</label>
        </div>
      </div>
      <div class="col-md-12 form-group">
        <label for="private-note" class="text-dark text-bold">Poznámka</label>
        <textarea id="private-note" v-model="note"  class="form-control"></textarea>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close">Zrušiť</button>
      <button class="btn btn-success" @click.prevent="saveApproverStance"><i class="icon-save"></i> Uložiť</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ApproveRequestModal',
  props: {
    stanceProp: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      stance: null,
      note: '',
      form: {
        stance: {
          error: false,
          valid: false
        }
      }
    }
  },
  mounted () {
    this.stance = this.stanceProp
  },
  methods: {
    showLoading () {
      $('.vuedal').LoadingOverlay('show')
    },
    hideLoading () {
      $('.vuedal').LoadingOverlay('hide')
    },
    close () {
      this.$emit('vuedals:close', null)
    },
    validateStance () {
      let state = this.form.stance
      state.valid = state.error = false
      if (this.stance) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    },
    async saveApproverStance () {
      if (!this.validateStance()) {
        return
      }
      this.showLoading()
      let formData = {
        request: {
          id: this.$route.params.requestId
        },
        approver: {
          stance: this.stance,
          note: this.note
        }
      }
      try {
        await this.$http.post('/request/approver/stance', formData)
        this.hideLoading()
        this.close()
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error?.response?.data.error)
        this.hideLoading()
      }
    }
  }
}
</script>
