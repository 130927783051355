<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" id="order-settings-general">
          <div class="card-heading">
            <h4 class="card-title">Základné údaje</h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="contract-name" class="control-label text-dark text-bold">Názov zákazky <small>*</small></label>
                  <div>
                    <input id="contract-name" type="text" v-model="order.name" class="form-control" required="required" disabled="disabled" aria-required="true">
                  </div>
                </div>

                <div class="form-group">
                  <label for="estimated-price" class="control-label">Predpokladaná hodnota v &euro; bez DPH</label>
                  <div>
                    <vue-numeric class="form-control" currency="€" separator="space" decimal-separator="," :precision="2" :empty-value="0" :disabled="!order._canEdit" v-model="order.estimatedPrice"></vue-numeric>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie</label>
                  <div>
                    <div class="radio radio-inline">
                      <input id="funding-by-eu-yes" type="radio" v-model="order.fundingByEu" value="yes" :disabled="!order._canEdit" /><label for="funding-by-eu-yes">Áno</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="funding-by-eu-no" type="radio" v-model="order.fundingByEu" value="no" :disabled="!order._canEdit" /><label for="funding-by-eu-no">Nie</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="journal-jnnouncement-date" class="control-label">Dátum vyhlásenia súťaže</label>
                  <div>
                    <date-input name="journal-jnnouncement-date" v-model="order.journalAnnouncementDate" :value="order.journalAnnouncementDate"  :disabled="!order._canEdit"></date-input>
                    <label for="journal-jnnouncement-date" v-if="form.journalAnnouncementDate.error" class="error">{{ form.journalAnnouncementDate.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Druh zákazky <small>*</small></label>
                  <div>
                    <div class="radio radio-inline">
                      <input id="subject-type-goods" type="radio" name="order-subject-type" v-model="order.subjectType" value="goods" :disabled="!order._canEdit" /> <label for="subject-type-goods">Tovary</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="subject-type-services" type="radio" name="order-subject-type" v-model="order.subjectType" value="services" :disabled="!order._canEdit" /> <label for="subject-type-services">Služby</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="subject-type-work" type="radio" name="order-subject-type" v-model="order.subjectType" value="work" :disabled="!order._canEdit" /> <label for="subject-type-work">Práce</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Hlavný CPV kód</label>
                  <div>
                    <main-cpv-code-select v-if="order.id" v-model="order.cpvCode" :value="order.cpvCode" :disabled="!order._canEdit"></main-cpv-code-select>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Doplnkové CPV kódy</label>
                  <div>
                    <suppl-cpv-code-select v-if="order.id" v-model="order.cpvCodes" :value="order.cpvCodes" :disabled="!order._canEdit"></suppl-cpv-code-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="description" class="control-label">Opis predmetu zákazky <small>*</small></label>
                  <div>
                    <textarea id="description" v-model="order.description" class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br />
                    <label for="description" v-if="form.description.error" class="error">{{ form.description.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="announcement" class="control-label">Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác</label>
                  <div>
                    <input id="announcement" type="text" v-model="order.mainDeliveryPlace" class="form-control" :disabled="!order._canEdit">
                    <label for="announcement" v-if="form.mainDeliveryPlace.error" class="error">{{ form.mainDeliveryPlace.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="private-note" class="control-label">Poznámka</label>
                  <div>
                    <textarea id="private-note" v-model="order.privateNote"  class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ privateNoteTextRemaining }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Viditeľnosť <small>*</small></label>
                  <div>
                    <div class="radio">
                      <input id="private-yes" type="radio" v-model="order.private" :value="true" :disabled="!order._canEdit" /><label for="private-yes">Neverejná (zákazka sa nebude zobrazovať medzi verejne dostupnými zákazkami – len pre pozvaných uchádzačov)<template v-if="order.type !== 'nh'"> (PRK)</template></label>
                    </div>
                    <div class="radio">
                      <input id="private-no" type="radio" v-model="order.private" :value="false" :disabled="!order._canEdit" /><label for="private-no">Verejná (zákazka sa zobrazí medzi verejnými zákazkami – dostupná pre pozvaných aj ostatných uchádzačov)</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Ponuky a termíny</h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="bids-limit-to" class="control-label">Koniec lehoty na predkladanie ponúk <small>*</small></label>
                  <div>
                    <date-time-input name="bids-limit-to" v-model="order.bidsLimitTo" :value="order.bidsLimitTo"  :disabled="!order._canEdit"></date-time-input>
                    <label for="bids-limit-to" v-if="form.bidsLimitTo.error" class="error">{{ form.bidsLimitTo.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="bids-open-at" class="control-label">Dátum a čas otvárania ponúk <small>*</small></label>
                  <div>
                    <date-time-input name="bids-open-at" v-model="order.bidsOpenAt" :value="order.bidsOpenAt"  :disabled="!order._canEdit"></date-time-input>
                    <label for="bids-open-at" v-if="form.bidsOpenAt.error" class="error">{{ form.bidsOpenAt.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group" v-if="isAfterOffersOpening">
                  <label for="bids-evaluate-at" class="control-label">Dátum a čas vyhodnotenia ponúk</label>
                  <div>
                    <date-time-input name="bids-evaluate-at" v-model="order.bidsEvaluateAt" :value="order.bidsEvaluateAt" :disabled="!order._canEdit"></date-time-input>
                    <label for="bids-evaluate-at" v-if="form.bidsEvaluateAt.error" class="error">{{ form.bidsEvaluateAt.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Spôsob vyhodnotenia ponúk <small>*</small></label>
                  <div>
                    <div class="radio">
                      <input id="bidding-method-1" type="radio" v-model="order.biddingMethod" value="lowestPrice" :disabled="!order._canEdit" /><label for="bidding-method-1">Najnižšej ceny</label>
                    </div>
                    <div class="radio">
                      <input id="bidding-method-2" type="radio" v-model="order.biddingMethod" value="priceQualityRatio" :disabled="!order._canEdit" /><label for="bidding-method-2">Najlepšieho pomeru ceny  a kvality</label>
                    </div>
                    <div class="radio">
                      <input id="bidding-method-3" type="radio" v-model="order.biddingMethod" value="costEffectiveness" :disabled="!order._canEdit" /><label for="bidding-method-3">Nákladov použitím prístupu nákladovej efektívnosti najmä nákladov počas životného cyklu</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Minimálne požiadavky na overenie identity uchádzačov pri predkladaní ponuky <small>*</small></label>
                  <div>
                    <div class="radio">
                      <input id="req-auth-level-eid" type="radio" v-model="order.requiredAuthLevel" :value="30" :disabled="!order._canEdit" /><label for="req-auth-level-eid">eID (ponuku je možné predložiť len registrovanému uchádzačovi prostredníctvom eID)</label>
                    </div>
                    <div class="radio">
                      <input id="req-auth-level-password" type="radio" v-model="order.requiredAuthLevel" :value="20" :disabled="!order._canEdit" /><label for="req-auth-level-password">meno/heslo (ponuku je možné predložiť len po registrácii/prihlásení)</label>
                    </div>
                    <div class="radio" v-if="order.type === 'nh'">
                      <input id="req-auth-level-invitation" type="radio" v-model="order.requiredAuthLevel" :value="10" :disabled="!order._canEdit" /><label for="req-auth-level-invitation">pozvánka (ponuka je predkladaná cez webový formulár bez prihlásenia – s neregistrovaným používateľom nie je možné ďalej komunikovať – zápisnice, oznámenia ai.)</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="description" class="control-label">Podmienky účasti</label>
                  <div>
                    <textarea id="description" v-model="order.participationConditions" class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ participationConditionsTextRemaining }}<br />
                    <label for="announcement" v-if="form.participationConditions.error" class="error">{{ form.participationConditions.errorMessage }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center no-gutters justify-content-between">
      <div class="row no-gutters">
        <button v-if="order.state === 'active' && order._canEdit"
                @click="changeStateModal('finished')"
                class="btn btn-tenderia-blue"
        >
          <i class="icon-check"></i> Ukončiť zákazku
        </button>
        <button v-if="order.state === 'active' && order._canEdit"
                @click="changeStateModal('cancelled')"
                class="btn btn-danger"
        >
          <i class="icon-close"></i> Zrušiť zákazku
        </button>
        <div v-if="order.state === 'planned' && order._canEdit">
          <button class="btn btn-danger" @click="removeOrderModal()" href=""><i class="icon-delete"></i>Zmazať zákazku</button>
        </div>
      </div>
      <div class="row no-gutters">
        <button v-if="order.integration.eforms.enabled" class="btn btn-success" @click="openEformsModal">Vyplniť v eForms</button>
        <div v-if="order._canEdit">
          <button class="btn btn-success" @click="saveSettings()" href=""><i class="icon-save"></i>Uložiť</button>
        </div>
      </div>
    </div>
    <span>Pred zmenou kritérií je potrebné uložiť údaje zákazky</span>
    <admin-criteria></admin-criteria>
  </div>
</template>

<script>
import AdminOrder from '@/components/order/common/components/AdminOrder'
import AdminCriteria from '@/components/order/common/views/AdminCriteria.vue'
import DateInput from '@/components/forms/DateInput.vue'
import DateTimeInput from '@/components/forms/DateTimeInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import SupplCpvCodeSelect from '@/components/forms/SupplCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'

export default {
  name: 'MrAdminSettings',
  extends: AdminOrder,
  components: { DateInput, DateTimeInput, MainCpvCodeSelect, SupplCpvCodeSelect, VueNumeric, AdminCriteria },
  inject: ['order', 'orderChanged', 'eventBus'],
  data () {
    return {}
  },
  methods: {
    validateBeforePublish () {
      this.validateDescription()
      this.validateBidsOpenAt()
      this.validateBidsLimitTo()
      this.validateBidsEvaluateAt()
      this.validateMainDeliveryPlace()
      this.validateParticipationConditions()
      this.validateJournalAnnouncementDate()

      return this.form.description.valid &&
        this.form.journalAnnouncementDate.valid &&
        this.form.bidsOpenAt.valid &&
        this.form.bidsLimitTo &&
        this.form.bidsEvaluateAt.valid &&
        this.form.mainDeliveryPlace.valid &&
        this.form.participationConditions.valid
    },
    saveSettings (state) {
      let orderIsValid = this.validateBeforePublish()
      if (this.order.state === 'active' && !orderIsValid) {
        return
      }
      this.ShowLoading({ title: 'Ukladám nastavenia zákazky' })
      var data = {
        order: {
          id: this.order.id,
          name: this.order.name,
          estimatedPrice: this.order.estimatedPrice.toString().replaceAll(' ', '').replaceAll(',', '.'),
          description: this.order.description,
          journalAnnouncementDate: this.order.journalAnnouncementDate,
          bidsLimitTo: this.order.bidsLimitTo,
          organization: this.order.organization.id,
          privateNote: this.order.privateNote,
          offerOpeningType: this.order.auction ? 'nonpublic' : 'distant',
          subjectType: this.order.subjectType,
          cpvCode: this.order.cpvCode,
          cpvCodes: this.order.cpvCodes,
          procedureReverse: this.order.procedureReverse,
          private: this.order.private,
          requiredAuthLevel: this.order.requiredAuthLevel,
          bidsEvaluateAt: this.order.bidsEvaluateAt,
          mainDeliveryPlace: this.order.mainDeliveryPlace,
          fundingByEu: this.order.fundingByEu,
          participationConditions: this.order.participationConditions,
          bidsOpenAt: this.order.bidsOpenAt,
          biddingMethod: this.order.biddingMethod,
          auction: this.order.auction
        }
      }

      var vm = this
      this.$http.post('/order/set', data).then(function () {
        vm.eventBus.$emit('order-updated')
        vm.CloseLoading()
        if (state === 'active') {
          vm.changeState(state)
        } else {
          setTimeout(function () {
            vm.PopupAlert({ text: 'Zmeny boli uložené.' })
          }, 500)
        }
      }).catch((error) => {
        vm.CloseLoading()
        setTimeout(function () {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }, 500)
      })
    }
  }
}
</script>
