<template>
  <div class="table-container">
    <table>
      <slot :name="'header'" :columns="columns" :headers-styles="headersStyles">
        <thead v-if="!noHeader">
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="column.key"
              role="columnheader"
              :aria-label="column.label"
              :style="headersStyles[index]"
              :class="headersClasses[index]"
              @click="column.sortable && toggleSort(column)"
            >
              <slot :name="`header(${column.key})`" :column="column">
                <div class="f-row-ac">
                  <span v-text="labels[index]"/>
                  <sort-control
                    v-if="column.sortable"
                    class="ml-2"
                    :sort="sort && sort.sortBy === column.key ? sort.sort : null"
                  />
                </div>
              </slot>
            </th>
          </tr>
        </thead>
      </slot>
      <slot v-if="Array.isArray(renderItems) && renderItems.length === 0" name="noData">
        <tbody>
          <tr>
            <td class="p-4" :colspan="columns.length">
              <span>Momentálne neobsahuje žiadne záznamy</span>
            </td>
          </tr>
        </tbody>
      </slot>
      <slot :name="'body'">
        <tbody v-if="!error && renderItems">
          <template v-for="(item, row) in renderItems">
            <slot
              v-if="item.$slot"
              :name="`row(${item.$slot})`"
              :columns="columns"
              :item="item"
              :row="row"
              :values="values"
              :renderer="cellSlot"
            >
              <tr
                :key="row"
                :class="item.$slot"
              >
                <td :colspan="columns.length">
                  <span v-text="item.title"/>
                </td>
              </tr>
            </slot>
            <tr
              v-else
              :key="row"
              :class="{even: row % 2, selected: selectedLookup[item[itemKey]]}"
              @click="$emit('row-click', item)"
            >
              <td
                v-for="(col, index) in columns"
                :key="col.key"
                :class="columnsClasses[index]"
              >
                <slot :name="`cell(${col.key})`" v-bind="values[row][col.key]">
                  <span v-text="values[row][col.key].value"/>
                </slot>
              </td>
            </tr>

            <tr
              v-if="selectedLookup[item[itemKey]] || closing[item[itemKey]]"
              :key="`detail:${row}`"
              class="detail"
            >
              <td :colspan="columns.length">
                <slot v-if="selectedLookup[item[itemKey]]" name="item-detail" :item="item" :row="row"/>
              </td>
            </tr>

          </template>
        </tbody>
      </slot>
    </table>
  </div>
</template>

<script>
import difference from 'lodash/difference'
import SortControl from './SortControl.vue'

function toPx (val) {
  return isNaN(val) ? val : `${val}px`
}

function lookupTable (items) {
  // eslint-disable-next-line no-return-assign, no-sequences
  return items.reduce((obj, val) => (obj[val] = true, obj), {})
}

export default {
  components: { SortControl },
  props: {
    /**
     * - key: String
     * - label: String, Array
     * - type: String (data format)
     * - transform: Function (transformation before formatting)
     * - sortable: Boolean
     * - info: String
     * - align: String (left,center,right)
     * - header: Object
     *   - class: String, Object, Array
     *   - align: String
     *   - width: Number
     * - tdClass: String, Object, Array
     * - mapValues: Object (values lookup table)
     */
    columns: Array,
    categorizedItems: Array,
    error: [String, Boolean, Object],
    items: Array,
    itemKey: String,
    colors: {
      type: Array,
      default: () => ['#00B569', '#EB5E4A', '#E35C7C', '#3E97EF']
    },
    noHeader: Boolean,
    selected: {
      default: null
    },
    sort: Object
  },
  data () {
    return {
      appearAnim: false,
      closing: {}
    }
  },
  computed: {
    cellSlot () {
      const _this = this
      return {
        functional: true,
        props: {
          column: Object,
          row: Number
        },
        render (h, ctx) {
          const { row, column } = ctx.props
          const slot = _this.$scopedSlots[`cell(${column.key})`]
          const data = _this.values[row][column.key]
          return slot ? slot(data) : h('span', data.value)
        }
      }
    },
    renderItems () {
      if (this.categorizedItems) {
        const items = []
        this.categorizedItems.forEach(cat => {
          items.push({ title: cat.title, $slot: 'subheader' })
          items.push(...cat.items)
        })
        return items
      }
      return this.items
    },
    headersStyles () {
      const size = this.colors.length
      return this.columns.map((v, i) => ({
        borderBottomColor: this.colors[i % size],
        width: v.header && toPx(v.header.width)
      }))
    },
    headersClasses () {
      return this.columns.map(col => {
        const { header } = col
        return [header && header.class, (header && header.align) || col.align, { sortable: col.sortable }]
      })
    },
    columnsClasses () {
      return this.columns.map(col => [col.type, col.align, col.tdClass])
    },
    values () {
      return this.renderItems.map(this.itemData)
    },
    labels () {
      return this.columns.map(col => Array.isArray(col.label) ? col.label.join('/\n') : col.label)
    },
    selectedLookup () {
      if (Array.isArray(this.selected)) {
        return lookupTable(this.selected)
      }
      return { [this.selected]: true }
    },
    errorText () {
      return !!this.error && (typeof this.error === 'string' ? this.error : 'Chyba')
    }
  },
  watch: {
    selected (n, o) {
      if (Array.isArray(o)) {
        this.closing = lookupTable(difference(o, n))
      } else {
        this.closing = { [o]: true }
      }
      setTimeout(() => {
        this.closing = {}
      }, 500)
    },
  },
  mounted () {
    this.appearAnim = true
  },
  methods: {
    keyValue (item, key) {
      // todo: consider to use lodash/get
      const props = key.split('.')
      let value = item[props[0]]
      for (const prop of props.slice(1)) {
        if (!value) {
          break
        }
        value = value[prop]
      }
      return value
    },
    itemData (item, row) {
      if (this.categorizedItems && item.$slot === 'subheader') {
        return null
      }
      const data = {}
      this.columns.forEach(col => {
        let value = this.keyValue(item, col.key)
        if (col.transform) {
          value = col.transform(value)
        }
        if (col.mapValues) {
          value = col.mapValues[value]
        }
        data[col.key] = { row, item, value }
      })
      return data
    },
    toggleSort (column) {
      if (this.sort && this.sort.sortBy === column.key) {
        const newValue = {
          sortBy: this.sort.sortBy,
          sort: this.sort.sort === 'asc' ? 'desc' : 'asc'
        }
        this.$emit('update:sort', newValue)
      } else {
        this.$emit('update:sort', { sortBy: column.key, sort: 'desc' })
      }
    }
  }
}
</script>

<style lang="scss">

.light {
  .table-container, &.table-container {
    background-color: #fff;
    tbody {
      tr {
        &.even {
          background: #E0E0E099;
        }
        &:not(.detail) {
          border-top: 1px solid #E0E0E0;
        }
        &.subheader {
          background-color: rgba(48, 79, 125, 0.3);
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    th, td {
      &:not(:last-child) {
        border-right: 1px solid #E0E0E0;
      }
    }
    &.outlined {
      table {
        border: 1px solid #E0E0E0;
      }
    }
  }
}
.dark {
  .table-container, &.table-container {
    background-color: #2B3442;
    border-bottom: 1px solid #222A37;
    tbody {
      color: #fff;
      tr {
        &.even {
          background: #303A49;
        }
        &:not(.detail) {
          border-top: 1px solid #222A37;
        }
      }
    }
    th, td {
      &:not(:last-child) {
        border-right: 1px solid #222A37;
      }
    }

    a {
      font-weight: bold;
    }

    th {
      color: #7E95B7;
    }
  }
}

.table-container {
  max-width: 100%;
  overflow: auto;
  border-bottom: 1px solid #E0E0E0;
  table {
    border-collapse: collapse;
    min-width: 100%;
  }
  thead {
    text-transform: uppercase;
    // color: #7E95B7;
    text-align: left;
    th {
      padding: 8px 12px;
      border-bottom: 4px solid;
      height: 52px;
      user-select: none;
      position: relative;
      white-space: pre-line;
      &.sortable {
        cursor: pointer;
      }
      &.center > .f-row-ac {
        justify-content: center;
        text-align: center;
      }
      &.right > .f-row-ac {
        justify-content: flex-end;
        text-align: right;
      }
      .info-tooltip {
        margin: 3px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  tbody {
    tr {
      &.selected {
        box-shadow: 0 3px 6px rgba(0,0,0,0.1);
        td:first-child {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 4px;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: #3E97EF;
          }
        }
      }
      &.detail {
        // display: flex;
        // overflow: hidden;
        >td {
          // display: flex;
          height: auto;
          padding: 0;
        }
      }
    }
    td {
      padding: 8px 12px;
      height: 52px;
      &.datetime {
        word-spacing: 16px;
      }
      &.right {
        text-align: right;
        > .f-row-ac {
          justify-content: flex-end;
        }
      }
      &.center {
        text-align: center;
        > .f-row-ac {
          justify-content: center;
        }
      }
      img {
        vertical-align: middle;
      }

      >a {
        color: #3E97EF;
        font-weight: bold;
        cursor: pointer;
      }

      i {
        font-size: 25px;
        margin-right: 5px;
      }
    }
  }
}
</style>
