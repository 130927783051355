<template>
  <div class="tenderia-submenu">
    <router-link :to="{ name: `${$orderRoutePrefix(order.type, order.subtype)}admin-offers`, params: $route.params}">1. kolo (ostatné)</router-link>
    <router-link :to="{ name: `${$orderRoutePrefix(order.type, order.subtype)}admin-offers-round2`, params: $route.params}">2. kolo (kritériá)</router-link>
  </div>
</template>

<script>
export default {
  name: 'AdminOffersSubmenu',
  inject: ['order']
}
</script>
