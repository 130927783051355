<template>
  <div class="card" id="my-offer">
    <div class="card-heading">
      <h4 class="card-title"><span v-if="order.parent && order.part">Časť {{ order.part}} - </span>{{ order.name }}</h4>
      <div class="actions">
        <button v-if="canCreateOffer && !canRevokeOffer" :disabled="!canCreateOffer" class="btn btn-tenderia-blue" @click="addOffer()">
          <template><i class="icon-add font-size-14"></i> Vytvoriť ponuku</template>
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <p class="py-3">
            Koniec lehoty na predkladanie ponúk: <span v-if="order.bidsLimitTo" class="text-bold">{{ order.bidsLimitTo | formatDateTime }}</span><span class="text-bold" v-else>Termín nebol stanovený</span>
          </p>
          <p class="text-dark text-center pdd-vertical-25" v-if="canCreateOffer">
            <countdown :deadline="bidsLimitTo" :server-time="serverTime"></countdown>
          </p>
        </div>
      </div>
    </div>
    <v-table
      v-if="offer.id"
      :items="[offer]"
      :columns="offerColumns"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(createdAt)="{ item }">
        {{ item.createdAt | formatDateTime }}
      </template>
      <template v-slot:cell(updatedAt)="{ item }">
        {{ item.updatedAt | formatDateTime }}
      </template>
      <template v-slot:cell(sentAt)="{ item }">
        <span v-if="item.state !== 'new'">{{ item.sentAt | formatDateTime }}</span>
      </template>
      <template v-slot:cell(state)="{ item }">
        <v-badge class="m-0" :color="item.state !== 'new' ? 'green' : 'red'" glow>
          {{ item.state | formatOfferState}}
        </v-badge>
      </template>
    </v-table>
    <p class="text-bold text-dark text-center pdd-vertical-50" v-else>
      Nemáte vytvorenú žiadnu ponuku
    </p>
    <div class="card-footer">
      <div class="row">
        <button v-if="offer.id" class="btn btn-tenderia-blue" @click="openOffer()">
          <span>Zobraziť ponuku</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import VTable from '@/components/ui/Table.vue'
import VBadge from '@/components/ui/Badge.vue'

export default {
  name: 'PublicMarketReserachOffer',
  components: { Countdown, VTable, VBadge },
  inject: ['order', 'eventBus'],
  data: function () {
    return {
      bidsLimitTo: false,
      offer: {
        id: null
      }
    }
  },
  computed: {
    offerColumns () {
      return [
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia'
        },
        {
          key: 'updatedAt',
          label: 'Dátum poslednej aktualizácie'
        },
        {
          key: 'sentAt',
          label: 'Dátum odoslania ponuky'
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    serverTime: function () {
      return new Date(this.$store.state.stats.currentTime)
    },
    canCreateOffer: function () {
      return this.bidsLimitTo && this.serverTime < this.bidsLimitTo
    },
    canRevokeOffer: function () {
      return this.bidsLimitTo && this.offer.id && this.offer.state === 'sent' && this.serverTime < this.bidsLimitTo
    }
  },
  filters: {
    formatOfferState: function (value) {
      var states = {
        new: 'Neodoslaná',
        sent: 'Odoslaná',
        seen: 'Otvorená'
      }
      return states[value]
    }
  },
  methods: {
    addOffer: function () {
      this.$router.push({ name: 'publicMarketResearchOfferForm', params: { orderId: this.order.id, token: this.$route.params.token } })
    },
    openOffer: function () {
      this.$router.push({ name: 'publicMarketResearchOfferView', params: { orderId: this.order.id, token: this.$route.params.token } })
    }
  },
  created () {
    var vm = this
    this.bidsLimitTo = this.order.bidsLimitTo ? new Date(this.order.bidsLimitTo) : false
    this.$http.post('/offer/get', { offer: { order: this.order.id } }).then(function (offerResponse) {
      vm.bidsLimitTo = vm.order.bidsLimitTo ? new Date(vm.order.bidsLimitTo) : false
      if (offerResponse.data.offer.id) {
        vm.offer = offerResponse.data.offer
      }
    })
  }
}
</script>
