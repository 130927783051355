import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminCriteria from '@/components/order/common/views/AdminCriteria'
import AdminCriteriaAdd from '@/components/order/common/views/AdminCriteriaAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import AdminItems from '@/components/order/common/views/AdminItems'
import AdminItemsAdd from '@/components/order/common/views/AdminItemsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import Admin from '../views/Admin'
import AdminHome from '../views/AdminHome'
import AdminOffers from '@/components/order/common/views/AdminOffers'
import AdminOfferView from '../views/AdminOfferView'
import AdminOverview from '../views/AdminOverview'
import AdminSettings from '../views/AdminSettings'
import AdminSuppliers from '@/components/order/common/views/AdminSuppliers'
import AdminInviteSupplier from '@/components/order/common/views/AdminInviteSupplier'
import User from '../views/User'
import UserOffers from '../views/UserOffers'
import UserOffersAdd from '../views/UserOffersAdd'
import UserOfferView from '@/components/order/common/views/UserOfferView.vue'
import UserOverview from '../views/UserOverview'
import SupplierForm from '@/components/order/common/components/SupplierForm'
import SupplierSearch from '@/components/order/common/components/SupplierSearch'

export default [
  {
    path: 'mr/:orderId',
    name: 'mr-admin',
    redirect: { name: 'mr-admin-overview' },
    component: Admin,
    children: [
      {
        path: 'home',
        name: 'mr-admin-home',
        redirect: { name: 'mr-admin-overview' },
        component: AdminHome,
        children: [
          {
            path: 'overview',
            name: 'mr-admin-overview',
            component: AdminOverview
          },
          {
            path: 'settings',
            name: 'mr-admin-settings',
            component: AdminSettings
          },
          {
            path: 'documents',
            name: 'mr-admin-documents',
            component: AdminDocuments
          },
          {
            path: 'documents/add',
            name: 'mr-admin-documents-add',
            component: AdminDocumentsAdd
          },
          {
            path: 'documents/edit/:id',
            name: 'mr-admin-documents-edit',
            component: AdminDocumentsAdd
          },
          {
            path: 'items',
            name: 'mr-admin-items',
            component: AdminItems
          },
          {
            path: 'items/add',
            name: 'mr-admin-items-add',
            component: AdminItemsAdd
          },
          {
            path: 'items/update/:index',
            name: 'mr-admin-items-update',
            component: AdminItemsAdd
          },
          {
            path: 'criteria',
            name: 'mr-admin-criteria',
            component: AdminCriteria
          },
          {
            path: 'criteria/add',
            name: 'mr-admin-criteria-add',
            component: AdminCriteriaAdd
          },
          {
            path: 'criteria/add/part/:partId',
            name: 'mr-admin-criteria-add-part',
            component: AdminCriteriaAdd
          },
          {
            path: 'criteria/update/:index',
            name: 'mr-admin-criteria-update',
            component: AdminCriteriaAdd
          }
        ]
      },
      {
        path: 'suppliers',
        name: 'mr-admin-suppliers',
        component: AdminSuppliers
      },
      {
        path: 'suppliers/invite',
        name: 'mr-admin-suppliers-invite',
        component: AdminInviteSupplier,
        redirect: {
          name: 'mr-admin-suppliers-search'
        },
        children: [
          {
            path: 'add',
            name: 'mr-admin-suppliers-add',
            component: SupplierForm
          },
          {
            path: 'edit/:id',
            name: 'mr-admin-suppliers-edit',
            component: SupplierForm
          },
          {
            path: 'search',
            name: 'mr-admin-suppliers-search',
            component: SupplierSearch
          }
        ]
      },
      {
        path: 'communication',
        name: 'mr-admin-communication',
        component: AdminCommunication
      },
      {
        path: 'communication/add',
        name: 'mr-admin-communication-add',
        component: AdminCommunicationAdd
      },
      {
        path: 'offers',
        name: 'mr-admin-offers',
        component: AdminOffers
      },
      {
        path: 'offers/:offerId',
        name: 'mr-admin-offers-view',
        component: AdminOfferView
      }
    ]
  },
  {
    path: 'mr/user/:orderId',
    name: 'mr-user',
    redirect: { name: 'mr-user-overview' },
    component: User,
    children: [
      {
        path: 'overview',
        name: 'mr-user-overview',
        component: UserOverview
      },
      {
        path: 'communication',
        name: 'mr-user-communication',
        component: UserCommunication
      },
      {
        path: 'communication/add',
        name: 'mr-user-communication-add',
        component: UserCommunicationAdd
      },
      {
        path: 'offers',
        name: 'mr-user-offers',
        component: UserOffers
      },
      {
        path: 'offers/view',
        name: 'mr-user-offers-view',
        component: UserOfferView
      },
      {
        path: 'offers/add',
        name: 'mr-user-offers-add',
        component: UserOffersAdd
      }
    ]
  }
]
