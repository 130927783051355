export default class EmailHelper {
  constructor (message, mode) {
    this.message = message
    this.mode = mode
  }

  buildMetadata () {
    var recipients = []
    var metadata = {}

    recipients = this.message.recipients.map((recipient, index) => {
      return {
        user: recipient,
        organization: this.message.recipientsOrgs[index]
      }
    })

    metadata.from = this.buildSender(this.message.from, this.message.organization)
    metadata.to = this.buildRecipient(recipients)

    return metadata
  }

  buildSender (from, organization) {
    if (from) {
      let sender = `${from.firstname}  ${from.lastname}`

      if (organization) {
        sender = organization.name
      }

      sender += ' <' + from.email + '>'

      return sender
    }
    return ''
  }

  buildRecipient (data) {
    let recipient = ''

    for (var t = 0; t < data.length; t++) {
      if (data[t].organization && data[t].organization.name) {
        recipient += data[t].organization.name
      } else {
        recipient += data[t].user.firstname + ' ' + data[t].user.lastname
      }

      recipient += ' <' + data[t].user.email + '>' + ', '
    }

    return recipient
  }
}
