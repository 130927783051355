<template>
  <div
    v-if="visible"
    class="info-box"
    :style="colorVars"
  >
    <i :class="`${icon} font-size-18 mr-2`" />
    <slot>
      <span v-text="text"/>
    </slot>
  </div>
</template>

<script>
import { colorVars } from './utils/colors'

export default {
  props: {
    color: String,
    icon: String,
    status: String,
    text: String
  },
  computed: {
    visible () {
      return this.text === undefined || !!this.text
    },
    colorVars () {
      return this.color && colorVars(this.color)
    }
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  display: flex;
  align-items: center;
  color: var(--color);
  border: 1px solid var(--color);
  background-color: #fff;
  background-image: linear-gradient(to right, rgba(var(--color-rgb), 0.05), rgba(var(--color-rgb), 0.05));
  font-weight: bold;
  margin: 6px 0px;
  padding: 16px;
  border-radius: 3px;
  transition: all .4s cubic-bezier(.25,.8,.25,1);
  position: relative;
  .icon {
    margin-top: 2px;
    margin-right: 12px;
  }
}
</style>
