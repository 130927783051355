<template>
  <div class="row">
    <div class="col-md-12">
      <div v-if="!editForm" class="card" :id="`request-settings-items-${request.id}`">
        <div class="card-heading">
          <h4 class="card-title">Položky</h4>
          <div class="actions">
            <button
              v-if="editable"
              class="btn btn-tenderia-blue"
              @click.prevent="addItem()"
            >
              <i class="icon-add font-size-14"></i> Pridať novú položku
            </button>
          </div>
        </div>
        <v-table
          :items="request.items"
          :columns="itemsColumns"
          item-key="name"
          class="light"
        >
          <template v-slot:cell(estimatedPrice)="{ item }">
            {{ $format.shortCurrencyFixed2(item.estimatedPrice) }}
          </template>
          <template v-slot:cell(estimatedPriceWithVat)="{ item }">
            {{ $format.shortCurrencyFixed2(item.estimatedPrice + (item.estimatedPrice * (item.vat / 100))) }}
          </template>
          <template v-slot:cell(vat)="{ item }">
            {{ item.vat }}%
          </template>
          <template v-slot:cell(actions)="{ item, row }">
            <a
              href=""
              @click.prevent="updateItem(item)"
              title="Upraviť"
            >
              <i class="icon-pencil"></i>
            </a>
            <a
              @click.prevent="removeItemModal(row)"
              href=""
              title="Zmazať"
            >
              <i class="icon-delete text-danger"></i>
            </a>
          </template>
        </v-table>
      </div>
      <div v-else class="card" id="order-settings-add-item">
        <div class="card-heading">
          <h4 class="card-title">Pridať novú položku</h4>
        </div>
        <div class="card-block">
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="text-dark text-bold">Názov<small>*</small></label>
              <input type="text" v-model="requestItem.name" class="form-control" :class="{ error: requestItem.getField('name').hasErrors() }" />
              <label v-if="requestItem.getField('name').hasErrors()" class="error">
                {{ requestItem.getField('name').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">Množstvo<small>*</small></label>
              <input
                type="number"
                v-model="requestItem.amount"
                class="form-control"
                :class="{ error: requestItem.getField('amount').hasErrors() }"
                @input="updateOnAmount"
              />
              <label v-if="requestItem.getField('amount').hasErrors()" class="error">
                {{ requestItem.getField('amount').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">MJ<small>*</small></label>
              <input type="text" v-model="requestItem.unit" class="form-control" :class="{ error: requestItem.getField('unit').hasErrors() }" />
              <label v-if="requestItem.getField('unit').hasErrors()" class="error">
                {{ requestItem.getField('unit').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">Cena/MJ bez DPH<small>*</small></label>
              <vue-numeric
                class="form-control"
                currency="€"
                separator="space"
                decimal-separator=","
                :precision="2"
                :empty-value="0"
                v-model="requestItem.unitPrice"
                :class="{ error: requestItem.getField('unitPrice').hasErrors() }"
                @input="updateOnUnitPrice"
              />
              <label v-if="requestItem.getField('unitPrice').hasErrors()" class="error">
                {{ requestItem.getField('unitPrice').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">DPH<small>*</small></label>
              <vue-numeric
                class="form-control"
                currency="%"
                :precision="0"
                :empty-value="0"
                v-model="requestItem.vat"
                :class="{ error: requestItem.getField('vat').hasErrors() }"
                @input="updateOnVat"
              />
              <label v-if="requestItem.getField('vat').hasErrors()" class="error">
                {{ requestItem.getField('vat').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">Spolu bez DPH<small>*</small></label>
              <vue-numeric
                class="form-control"
                currency="€"
                separator="space"
                decimal-separator=","
                :precision="2"
                :empty-value="0"
                v-model="requestItem.estimatedPrice"
                :class="{ error: requestItem.getField('estimatedPrice').hasErrors() }"
                @input="updateOnEstimatedPrice"
              />
              <label v-if="requestItem.getField('estimatedPrice').hasErrors()" class="error">
                {{ requestItem.getField('estimatedPrice').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">Spolu s DPH<small>*</small></label>
              <vue-numeric
                class="form-control"
                currency="€"
                separator="space"
                decimal-separator=","
                :precision="2"
                :empty-value="0"
                v-model="requestItem.estimatedPriceWithVat"
                :class="{ error: requestItem.getField('estimatedPriceWithVat').hasErrors() }"
                @input="updateOnEstimatedPriceWithVat"
              />
              <label v-if="requestItem.getField('estimatedPriceWithVat').hasErrors()" class="error">
                {{ requestItem.getField('estimatedPriceWithVat').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="text-dark text-bold">Opis</label>
              <textarea id="description" v-model="requestItem.description" class="form-control"></textarea>
              Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br />
              <label v-if="requestItem.getField('description').hasErrors()" class="error">
                {{ requestItem.getField('description').errors | firstMessage }}
              </label>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <button class="btn btn-tenderia-grey-blue btn-inverse" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
            <button class="btn btn-success" @click="save">Uložiť</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import VueNumeric from 'vue-numeric'
export default {
  name: 'ItemsListWidget',
  inject: ['eventBus'],
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    request: Object
  },
  components: { VTable, VueNumeric },
  data () {
    return {
      requestItem: new this.$models.RequestItem(Object.assign(
        {
          vm: this,
          dataKey: 'requestItem'
        }
      )),
      editForm: false
    }
  },
  computed: {
    descriptionTextRemaining () {
      if (this.requestItem.description && this.requestItem.description.length) {
        return 4000 - this.requestItem.description.length
      } else {
        return 4000
      }
    },
    itemsColumns () {
      const columns = [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'amount',
          label: 'Množstvo'
        },
        {
          key: 'unit',
          label: 'Jednotka'
        },
        {
          key: 'vat',
          label: 'DPH'
        },
        {
          key: 'estimatedPrice',
          label: 'Predp. hodnota bez DPH'
        },
        {
          key: 'estimatedPriceWithVat',
          label: 'Predp. hodnota s DPH'
        }
      ]

      if (this.editable) {
        columns.push(
          {
            key: 'actions',
            label: 'Akcie'
          })
      }

      return columns
    }
  },
  watch: {
    requestItem: {
      handler (requestItem) { requestItem.$validate({ debounce: 1000 })},
      deep: true,
      immediate: false
    }
  },
  methods: {
    updateOnVat (val) {
      this.requestItem.estimatedPriceWithVat = this.requestItem.estimatedPrice + this.requestItem.estimatedPrice * (val / 100)
    },
    updateOnEstimatedPriceWithVat (val) {
      this.requestItem.estimatedPrice = val / (1 + (this.requestItem.vat / 100))
      if (this.requestItem.estimatedPrice > 0) {
        this.requestItem.unitPrice = this.requestItem.estimatedPrice / this.requestItem.amount
      }
    },
    updateOnEstimatedPrice (val) {
      this.requestItem.estimatedPriceWithVat = val * (this.requestItem.vat / 100) + val
      this.requestItem.unitPrice = val / this.requestItem.amount
    },
    updateOnUnitPrice (val) {
      this.requestItem.estimatedPrice = val * this.requestItem.amount
      this.requestItem.estimatedPriceWithVat = this.requestItem.estimatedPrice + this.requestItem.estimatedPrice * (this.requestItem.vat / 100)
    },
    updateOnAmount (val) {
      this.requestItem.estimatedPrice = val.target.value * this.requestItem.unitPrice
      this.requestItem.estimatedPriceWithVat = this.requestItem.estimatedPrice + this.requestItem.estimatedPrice * (this.requestItem.vat / 100)
    },
    goBack () {
      this.requestItem.$reset()
      this.editForm = false
    },
    addItem () {
      this.requestItem.$reset()
      this.editForm = true
    },
    updateItem (item) {
      this.editForm = true
      this.requestItem.id = item.id
      this.requestItem.name = item.name
      this.requestItem.amount = item.amount
      this.requestItem.unit = item.unit
      this.requestItem.estimatedPrice = item.estimatedPrice
      this.requestItem.description = item.description
      this.requestItem.vat = item.vat
      this.requestItem.unitPrice = item.unitPrice
    },
    async save () {
      if (this.$route.params.requestId) {
        this.requestItem.$validate()
        if (this.requestItem.hasErrors()) {
          return
        }
        this.ShowLoading()
        let requestItemData = {
          request: this.$route.params.requestId,
          item: {
            ...this.requestItem
          }
        }
        try {
          await this.$http.post('/request/item/set', requestItemData)
          this.CloseLoading()
          this.OkAlert({ title: 'Hotovo', text: 'Údaje boli uložené.' })
          this.goBack()
          this.eventBus.$emit('load-request')
        } catch (error) {
          this.CloseLoading()
          this.ErrorAlert({ text: 'Nepodarilo sa uložiť informácie.' }, error?.response?.data.error)
        }
      } else {
        this.request.items.push({
          id: this.requestItem.id,
          name: this.requestItem.name,
          amount: this.requestItem.amount,
          unit: this.requestItem.unit,
          estimatedPrice: this.requestItem.estimatedPrice,
          description: this.requestItem.description,
          vat: this.requestItem.vat,
          unitPrice: this.requestItem.unitPrice
        })
        this.goBack()
      }
    },
    removeItemModal (index) {
      this.YesNoAlert({
        title: 'Skutočne chcete odstrániť položku?',
        text: 'Položka ' + this.request.items[index].name + ' sa po zmazaní už nebude dať obnoviť.',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeItem(index)
        }
      })
    },
    async removeItem (index) {
      if (this.$route.params.requestId) {
        this.ShowLoading()
        var data = {
          request: {
            id: this.request.id
          },
          item: this.request.items[index].id
        }
        try {
          await this.$http.post('/request/item/remove', data)
          this.CloseLoading()
          this.OkAlert({ text: 'Položka bola zmazaná' })
          this.eventBus.$emit('load-request')
        } catch (error) {
          this.CloseLoading()
          this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }
      } else {
        this.request.items.splice(index, 1)
      }
    }
  }
}
</script>
