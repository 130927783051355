<template>
  <div class="row">
    <div class="col-md-12">
      <offer-widget :order="order" />
    </div>
  </div>
</template>

<script>
import OfferWidget from '@/components/order/common/components/OfferWidget.vue'

export default {
  name: 'MrUserOffers',
  components: { OfferWidget },
  inject: ['order']
}
</script>
