import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import DnsAdmin from '../views/Admin'
import DnsAdminApplications from '../views/AdminApplications'
import DnsAdminApplicationsView from '../views/AdminApplicationsView'
import DnsAdminHome from '../views/AdminHome'
import AdminOverviewDns from '@/components/order/common/views/AdminOverviewDns'
import AdminSettingsDns from '../../common/views/AdminSettingsDns.vue'
import DnsAdminSuppliers from '../views/AdminSuppliers'
import DnsAdminTenders from '../views/AdminTenders'
import DnsAdminInterested from '../views/AdminInterested'
import User from '../views/User'
import UserApplications from '../views/UserApplications'
import UserApplicationsAdd from '../views/UserApplicationsAdd'
import UserApplicationsView from '../views/UserApplicationsView'
import UserOverviewDns from '@/components/order/common/views/UserOverviewDns'
import DnsUserTenders from '../views/UserTenders'

export default [
  {
    path: '/dns/admin/:orderId',
    name: 'dns-admin',
    redirect: { name: 'dns-admin-overview' },
    component: DnsAdmin,
    children: [
      {
        path: 'home',
        name: 'dns-admin-home',
        redirect: { name: 'dns-admin-overview' },
        component: DnsAdminHome,
        children: [
          {
            path: 'overview',
            name: 'dns-admin-overview',
            component: AdminOverviewDns
          },
          {
            path: 'settings',
            name: 'dns-admin-settings',
            component: AdminSettingsDns
          },
          {
            path: 'documents',
            name: 'dns-admin-documents',
            component: AdminDocuments
          },
          {
            path: 'documents/add',
            name: 'dns-admin-documents-add',
            component: AdminDocumentsAdd
          },
          {
            path: 'documents/edit/:id',
            name: 'dns-admin-documents-edit',
            component: AdminDocumentsAdd
          },
        ]
      },
      {
        path: 'communication',
        name: 'dns-admin-communication',
        component: AdminCommunication
      },
      {
        path: 'communication/add',
        name: 'dns-admin-communication-add',
        component: AdminCommunicationAdd
      },
      {
        path: 'interested',
        name: 'dns-admin-interested',
        component: DnsAdminInterested
      },
      {
        path: 'tenders',
        name: 'dns-admin-tenders',
        component: DnsAdminTenders
      },
      {
        path: 'applications',
        name: 'dns-admin-applications',
        component: DnsAdminApplications
      },
      {
        path: 'suppliers',
        name: 'dns-admin-suppliers',
        component: DnsAdminSuppliers
      },
      {
        path: 'applications/view/:applicationId',
        name: 'dns-admin-applications-view',
        component: DnsAdminApplicationsView
      }
    ]
  },
  {
    path: 'dns/user/:orderId',
    name: 'dns-user',
    redirect: { name: 'dns-user-overview' },
    component: User,
    children: [
      {
        path: 'overview',
        name: 'dns-user-overview',
        component: UserOverviewDns
      },
      {
        path: 'tenders',
        name: 'dns-user-tenders',
        component: DnsUserTenders
      },
      {
        path: 'communication',
        name: 'dns-user-communication',
        component: UserCommunication
      },
      {
        path: 'communication/add',
        name: 'dns-user-communication-add',
        component: UserCommunicationAdd
      },
      {
        path: 'applications',
        name: 'dns-user-applications',
        component: UserApplications
      },
      {
        path: 'applications/add',
        name: 'dns-user-applications-add',
        component: UserApplicationsAdd
      },
      {
        path: 'applications/view',
        name: 'dns-user-applications-view',
        component: UserApplicationsView
      }
    ]
  }
]
