<template>
  <v-table
    :items="items"
    :columns="itemsColumns"
    item-key="id"
    class="light"
  >
    <template #body>
      <tbody>
        <tr v-for="item in items" :key="item._id">
          <td>
            <span v-if="item.description" :title="item.description">{{item.name}}</span>
            <span v-else>{{item.name}}</span>
          </td>
          <td>{{item.amount}}</td>
          <td>{{item.unit}}</td>
          <td><input size="5" :id="item._id" type="text" v-model="item.userInput" @change="validateItem(item)" :disabled="!editable" class="form-control" /></td>
          <td class="text-right">{{formatDecimal(item.sum)}}</td>
        </tr>
        <tr class="text-bold border top">
          <td :colspan="4" align="right">Cena v EUR spolu</td>
          <td align="right">{{formatDecimal(total)}}</td>
        </tr>
      </tbody>
    </template>
  </v-table>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import { Decimal } from 'decimal.js'
var sFormatNumber = require('simple-format-number')

export default {
  name: 'ItemsTable',
  props: {
    order: Object,
    offer: Object,
    decimalCount: Number,
    editable: Boolean
  },
  inject: ['eventBus'],
  components: { VTable },
  data () {
    return {
      items: [],
      ready: false
    }
  },
  computed: {
    total () {
      return this.items.reduce((accumulator, currentValue) => currentValue.sum.plus(accumulator), 0)
    },
    itemsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov položky'
        },
        {
          key: 'amount',
          label: 'Počet'
        },
        {
          key: 'unit',
          label: 'MJ'
        },
        {
          key: 'userInput',
          label: 'Cena v EUR za MJ'
        },
        {
          key: 'sum',
          label: 'Cena v EUR spolu'
        }
      ]
    }
  },
  created () {
    for (let orderItem of this.order.items) {
      let item = Object.assign({}, orderItem, { value: new Decimal(0), userInput: '', vat: new Decimal(0), sum: new Decimal(0) })
      if (this.offer.items) {
        for (let offerItem of this.offer.items) {
          if (orderItem._id === offerItem.item) {
            item.userInput = this.formatNumber(offerItem.value, this.decimalCount)
            break
          }
        }
      }

      this.validateItem(item)
      this.items.push(item)
    }
    this.ready = true
  },
  methods: {
    formatDecimal (value) {
      return sFormatNumber(value.toNumber(), { fractionDigits: this.decimalCount, symbols: { decimal: ',', grouping: ' ' } })
    },
    formatNumber (value, decimalCount) {
      return (value ? sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } }) : '')
    },
    validateItem (item) {
      try {
        item.value = new Decimal(item.userInput.replace(/\s+/g, '').replace(',', '.'))
      } catch (error) {
        item.value = new Decimal(0)
      }

      item.sum = item.value.times(item.amount)

      if (this.ready) {
        this.eventBus.$emit('offer:items', {
          items: this.items.map(item => { return { item: item._id, value: item.value.toFixed(this.decimalCount) } }),
          total: this.total.toFixed(this.decimalCount)
        })
      }
    }
  }
}
</script>
