<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <user-order-counts-chart />
      </div>
      <div class="col-md-6 col-lg-4">
        <order-states-chart />
      </div>
      <div class="col-md-6 col-lg-4">
        <order-savings-chart />
      </div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <order-values-chart />
      </div>
      <div class="col-md-6">
        <budgets-values-chart />
      </div>
      <div class="col-md-3">
        <div class="card stats-grid grid-border">
          <statistic-info icon="icon-home" v-bind="stats?.organizationSuppliers" :format="$format"/>
          <statistic-info icon="icon-edit-sheet" v-bind="stats?.orderSuppliers" :format="$format"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderSavingsChart from './statisticsCharts/OrderSavingsChart.vue'
import OrderStatesChart from './statisticsCharts/OrderStatesChart.vue'
import OrderValuesChart from './statisticsCharts/OrderValuesChart.vue'
import BudgetsValuesChart from './statisticsCharts/BudgetsValuesChart.vue'
import UserOrderCountsChart from './statisticsCharts/UserOrderCountsChart.vue'

import StatisticInfo from '@/components/ui/StatisticInfo.vue'

export default
{
  name: 'StatisticsPage',
  components: {
    OrderStatesChart,
    OrderValuesChart,
    OrderSavingsChart,
    StatisticInfo,
    BudgetsValuesChart,
    UserOrderCountsChart
  },
  data () {
    return {
      suppliersCountStats: null
    }
  },
  computed: {
    stats () {
      if (this.suppliersCountStats) {
        return {
          organizationSuppliers: {
            value: this.suppliersCountStats.organization.suppliers,
            label: 'Počet dodávateľov v organizácii'
          },
          orderSuppliers: {
            value: this.suppliersCountStats.orders.suppliers,
            label: 'Počet dodávateľov v zákazkach'
          }
        }
      }
      return null
    },
  },
  methods: {
    async fetchStatistics () {
      const { data: { stats } } = await this.$http.post('/stats/generate', {
        object: 'suppliers',
        type: 'supplier-stats'
      })
      this.suppliersCountStats = stats
    },
  },
  mounted () {
    this.fetchStatistics()
  }
}
</script>
<style lang="scss" scoped>
.card {
  min-height: 241px;
}
.stats-grid {
  display: grid;
  grid-template-columns:1fr;
  &.grid-border {
    background-color: #D6D6D6;
    row-gap: 1px;
    column-gap: 1px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
</style>
