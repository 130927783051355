<script>
import { copyToClipboard } from '@/utils/helpers.js'
import EformsModal from '@/components/order/common/components/EformsModal.vue'
export default {
  name: 'AdminOrder',
  inject: ['order', 'orderChanged', 'eventBus'],
  data () {
    return {
      form: {
        journalAnnouncementDate: {
          errorMessage: '',
          error: false,
          valid: false
        },
        journal: {
          errorMessage: '',
          error: false,
          valid: false
        },
        announcement: {
          errorMessage: '',
          error: false,
          valid: false
        },
        cpvCode: {
          errorMessage: '',
          error: false,
          valid: false
        },
        description: {
          errorMessage: '',
          error: false,
          valid: false
        },
        bidsOpenAt: {
          errorMessage: '',
          error: false,
          valid: false
        },
        bidsOpenAt2: {
          errorMessage: '',
          error: false,
          valid: false
        },
        bidsEvaluateAt: {
          errorMessage: '',
          error: false,
          valid: false
        },
        bidsLimitTo: {
          errorMessage: '',
          error: false,
          valid: false
        },
        estimatedPrice: {
          errorMessage: '',
          error: false,
          valid: false
        },
        mainDeliveryPlace: {
          errorMessage: '',
          error: false,
          valid: false
        },
        fundingByEu: {
          errorMessage: '',
          error: false,
          valid: false
        },
        participationConditions: {
          errorMessage: '',
          error: false,
          valid: false
        },
        parts: {
          errorMessage: '',
          error: false,
          valid: false
        },
        offerOpeningType: {
          errorMessage: '',
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    participationConditionsTextRemaining () {
      return 4000 - this.order.participationConditions.length
    },
    descriptionTextRemaining () {
      return 4000 - this.order.description.length
    },
    privateNoteTextRemaining () {
      return 4000 - this.order.privateNote.length
    },
    bidsOpenAt () {
      return this.order.bidsOpenAt ? new Date(this.order.bidsOpenAt) : false
    },
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    isAfterOffersOpening () {
      return this.bidsOpenAt ? this.serverTime > this.bidsOpenAt : false
    },
    combinationInfoTextRemaining () {
      return 4000 - this.order.partDetails.combinationInfo.length
    },
    canEditBidsOpenAt2 () {
      return this.order.procedureType === 'tste' && this.bidsOpenAt && this.serverTime > this.bidsOpenAt
    }
  },
  methods: {
    copyToClipboard: copyToClipboard,
    openEformsModal () {
      this.$emit('vuedals:new', {
        name: 'eforms-modal',
        closeOnBackdrop: false,
        title: 'Informácie zo systému eForms',
        component: EformsModal
      })
    },
    validateParticipationConditions () {
      var state = this.form.participationConditions
      state.error = state.valid = false
      state.errorMessage = ''
      var length = this.order.participationConditions.length
      if (length >= 0 && length <= 4000) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Presiahli ste povolený počet znakov (max. 4000 znakov).'
      }
    },
    validateFundingByEu () {
      let state = this.form.fundingByEu
      let value = this.order.fundingByEu
      state.error = state.valid = false
      state.errorMessage = ''
      if (value === 'yes' || value === 'no') {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Musíte si vybrať jednu z možností.'
      }
    },
    validateMainDeliveryPlace () {
      var state = this.form.mainDeliveryPlace
      state.error = state.valid = false
      state.errorMessage = ''
      var length = this.order.mainDeliveryPlace.length
      if (length >= 0 && length <= 1000) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Presiahli ste povolený počet znakov (max. 1000 znakov).'
      }
    },
    validateBidsOpenAt () {
      let bidsOpenAt = new Date(this.order.bidsOpenAt)
      let bidsLimitTo = new Date(this.order.bidsLimitTo)
      let state = this.form.bidsOpenAt

      state.error = state.valid = false
      state.errorMessage = ''

      if (this.order.divided) {
        this.order.bidsOpenAt = ''
        state.valid = true
        return
      }

      if ((this.order.procedureType === 'dns' || this.order.subType === 'vyz') && this.order.bidsOpenAt == null) {
        state.valid = true
        return
      }
      if (bidsOpenAt > bidsLimitTo) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage =
      this.order.procedureType === 'dns'
        ? 'Dátum otvárania žiadostí nesmie byť skorší dátum ako koniec lehoty na predkladanie žiadostí.'
        : 'Dátum otvárania ponúk nesmie byť skorší dátum ako koniec lehoty na predkladanie ponúk.'
      }
    },
    validateBidsLimitTo () {
      let bidsLimitTo = new Date(this.order.bidsLimitTo)
      let state = this.form.bidsLimitTo
      state.error = state.valid = false
      state.errorMessage = ''
      if (this.order.divided) {
        state.valid = true
        return
      }
      let bidsOpenAt = new Date(this.order.bidsOpenAt)
      if (bidsOpenAt > bidsLimitTo) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = this.order.procedureType === 'dns'
          ? 'Dátum lehoty na predkladanie žiadostí musí byť vyplnený a nesmie byť neskorší ako dátum otvárania žiadostí.'
          : 'Dátum lehoty na predkladanie ponúk musí byť vyplnený a nesmie byť neskorší ako dátum otvárania ponúk.'
      }
    },
    validateBidsEvaluateAt () {
      let bidsEvaluateAt = new Date(this.order.bidsEvaluateAt)
      let bidsOpenAt = new Date(this.order.bidsOpenAt)
      let state = this.form.bidsEvaluateAt
      state.error = state.valid = false
      state.errorMessage = ''
      if (!this.order.bidsEvaluateAt || bidsEvaluateAt > bidsOpenAt) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Dátum a čas vyhodnotenia ponúk nesmie byť skorší dátum ako dátum a čas otvárania ponúk.'
      }
    },
    validateJournalAnnouncementDate () {
      var journalAnnouncementDate = new Date(this.order.journalAnnouncementDate)
      var bidsLimitTo = new Date(this.order.bidsLimitTo)
      var state = this.form.journalAnnouncementDate
      state.error = state.valid = false
      state.errorMessage = ''
      if (bidsLimitTo > journalAnnouncementDate) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Dátum vyhlásenia súťaže nemôže byť neskorší ako dátum lehoty na predkladania ponúk.'
      }
    },
    validatecpvCode () {
      var cpvCode = this.order.cpvCode
      var state = this.form.cpvCode
      state.error = state.valid = false
      state.errorMessage = ''
      if (cpvCode && cpvCode.length > 0) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Hlavný CPV kód musí byť vyplnený.'
      }
    },
    validateJournal () {
      var state = this.form.journal
      state.error = state.valid = false
      state.errorMessage = ''
      var length = this.order.journal.length
      if (length >= 0 && length <= 256) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Číslo vestníka musí byť v rozsahu 0 až 256 znakov.'
      }
    },
    validateAnnouncement () {
      var state = this.form.announcement
      state.error = state.valid = false
      state.errorMessage = ''
      var length = this.order.announcement.length
      if (length >= 0 && length <= 256) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Číslo oznámenia/výzvy musí byť v rozsahu 0 až 256 znakov.'
      }
    },
    validateDescription () {
      var description = this.order.description
      var state = this.form.description
      state.error = state.valid = false
      state.errorMessage = ''
      if (description && description.length > 0) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Opis predmetu zákazky musí byť vyplnený.'
      }
    },
    validateEstimatedPrice () {
      var state = this.form.estimatedPrice
      state.error = state.valid = false
      state.errorMessage = ''
      if (this.order.divided || this.order.estimatedPrice > 0 || (this.order.type === 'pz' && this.order.private)) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Predpokladaná hodnota musí byť zadaná.'
      }
    },
    validateParts () {
      var state = this.form.parts
      state.error = state.valid = false
      state.errorMessage = ''
      if (this.order.divided) {
        if (this.order.parts > 0) {
          state.valid = true
        } else {
          state.error = true
          state.errorMessage = ''
        }
      } else {
        state.valid = true
      }
    },
    validateBidsOpenAt2 () {
      let bidsOpenAt = new Date(this.order.bidsOpenAt)
      let state = this.form.bidsOpenAt2
      state.error = state.valid = false
      if (this.order.divided) {
        this.order.bidsOpenAt2 = ''
        state.valid = true
        return
      }
      // pred otvaranim ponuk ostatne
      if (this.serverTime < bidsOpenAt) {
        this.order.bidsOpenAt2 = ''
        state.valid = true
      // po otvarani ponuk ostatne
      } else {
        if (!this.order.bidsOpenAt2) {
          state.valid = true
          return
        }
        let bidsOpenAt2 = new Date(this.order.bidsOpenAt2)
        if (this.order.bidsOpenAt2 && bidsOpenAt2 > bidsOpenAt) {
          state.valid = true
        } else {
          state.error = true
          state.errorMessage = 'Dátum otvárania ponúk (kritériá) nesmie byť skorší dátum ako dátum a čas otvárania ponúk (ostatné).'
        }
      }
    },
    validateOfferOpeningType () {
      let state = this.form.offerOpeningType
      state.error = state.valid = false
      state.errorMessage = ''
      if (this.order.offerOpeningType) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Spôsob otvárania ponúk musí byť zvolený.'
      }
    },
    changeStateModal (state) {
      this.YesNoAlert({
        title: 'Zmena stavu zákazky',
        text: 'Skutočne chcete zmeniť stav zákazky? Táto zmena je nevratná!',
        focusCancel: true,
        cancelButtonText: 'Nie',
        confirmButtonText: 'Áno'
      }).then((result) => {
        if (result.value) {
          if (state === 'active') {
            this.saveSettings('active')
          } else {
            this.changeState(state)
          }
        }
      })
    },
    async changeState (state) {
      this.ShowLoading()
      try {
        await this.$http.post('/order/state/set', { order: { id: this.order.id, state: state } })
        this.CloseLoading()
        this.order.state = state
        this.PopupAlert({ text: 'Zmeny boli uložené.' })
      } catch (error) {
        this.CloseLoading()
        if (error?.response?.data.error.code === 500 && error?.response?.data.error.message === 'REQUEST_ERROR_SERVICE_ISEPVO_FAILED') {
          this.ErrorAlert({ title: 'Nepodarilo sa odoslať pozvánky', text: 'Aktualizujte si prihlasovacie údaje do IS EPVO a skúste znovu.' }, error?.response?.data.error)
        } else {
          this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
        }
      }
    },
    publishModal () {
      if (this.order.integration.epvo.needAuth !== '' && this.$globals.user.credentials?.isepvo.login === '') {
        this.ErrorAlert({ text: 'Pred spustením musíte vyplniť prihlasovacie údaje do IS EPVO v nastaveniach profilu.' })
        return
      }
      if (!this.validateBeforePublish()) {
        if (this.order.divided) {
          this.ErrorAlert({ text: 'Pred spustením musíte zákazku uložiť aj s vyplnenými všetkými povinnými poliami. Zákazka musí obsahovať aspoň jednu časť.' })
        } else {
          this.ErrorAlert({ text: 'Pred spustením musíte zákazku uložiť aj s vyplnenými všetkými povinnými poliami.' })
        }
        return
      }
      this.changeStateModal('active')
    },
    removeOrderModal () {
      if (this.order.divided && this.order.childs.length > 0) {
        this.ErrorAlert({ text: 'Pred zmazaním zákazky musíte zmazať všetky jej časti.' })
      } else {
        this.YesNoAlert({
          title: 'Skutočne chcete zmazať túto zákazku?',
          text: 'Zákazka sa po zmazaní už nebude dať obnoviť.',
          focusCancel: true,
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Zmazať'
        }).then(async (result) => {
          if (result.value) {
            let data = {
              order: {
                id: this.order.id,
                state: 'deleted'
              }
            }
            var vm = this
            this.$http.post('/order/state/set', data).then(function () {
              vm.CloseLoading()
              vm.OkAlert({ text: 'Zákazka bola zmazaná.' }).then(() => { vm.$router.push({ path: '/app/my-orders/buyer/all' }) })
            }).catch((error) => {
              vm.CloseLoading()
              vm.ErrorAlert({ text: vm.order.divided ? 'Pred zmazaním zákazky musíte zmazať všetky jej časti.' : 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
            })
          }
        })
      }
    },
    async setOrderChanged () {
      if (this.orderChanged.value) {
        await this.saveSettings()
        this.orderChanged.value = false
      }
    }
  },
  watch: {
    'order.partDetails.combinationAssign' (value) {
      if (!value) {
        this.order.partDetails.combinationInfo = ''
      }
    }
  },
  mounted () {
    if (!this.order.journalAnnouncementDate) {
      this.order.journalAnnouncementDate = (new Date()).toISOString()
    }
    this.eventBus.$on('publish-order', () => {
      this.publishModal()
    })
    if (this.$route.params.action === 'publish') {
      this.publishModal()
    }
    if (this.$route.params.action === 'finish') {
      this.changeStateModal('finished')
    }
    if (this.$route.params.action === 'cancel') {
      this.changeStateModal('cancelled')
    }
    if (this.$route.params.action === 'cancel') {
      this.changeStateModal('cancelled')
    }
    if (this.$route.params.action === 'remove') {
      this.removeOrderModal()
    }
  },
  destroyed () {
    this.eventBus.$off('publish-order')
  },
  beforeRouteLeave (to, from, next) {
    if (to.params.orderId) {
      this.setOrderChanged()
      next()
    } else {
      next()
    }
  }
}
</script>
