<template>
  <div>
    <div class="form-group mb-0">
      <div class="radio">
        <input type="radio" :value="{code: 'nzvs'}" v-model="procedure" :id="`order-nzvs`" /> <label :for="`order-nzvs`">Nadlimitná zákazka - verejná súťaž</label>
      </div>
      <div class="radio">
        <input type="radio" :value="{code: 'pzvs'}" v-model="procedure" :id="`order-pzvs`" /> <label :for="`order-pzvs`">Podlimitná zákazka</label>
      </div>
      <div class="radio">
        <input type="radio" :value="{code: 'nh'}" v-model="procedure" :id="`order-nh`" /> <label :for="`order-nh`">Prieskum trhu</label>
      </div>
      <div class="radio">
        <input type="radio" :value="true" v-model="showSelect" :id="`show-select`" /> <label :for="`show-select`">Iné</label>
      </div>
    </div>
    <multiselect
      v-if="showSelect"
      v-model="procedure"
      :options="procedureOptions"
      track-by="code"
      label="text"
      openDirection="bottom"
      :clear-on-select="false"
      :close-on-select="true"
      :searchable="true"
      :show-labels="false"
      placeholder="Vyberte si ..."
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'ProcedureSelect',
  components: { Multiselect },
  data () {
    return {
      typesMap: {
        nzvs: {
          type: 'nz',
          subtype: 'vs'
        },
        pzvs: {
          type: 'pz',
          subtype: 'vs'
        },
        nzus: {
          type: 'nz',
          subtype: 'us'
        },
        nkus: {
          type: 'nk',
          subtype: 'us'
        },
        obus: {
          type: 'ob',
          subtype: 'us'
        },
        nzrkz: {
          type: 'nz',
          subtype: 'rkz'
        },
        nkrkz: {
          type: 'nk',
          subtype: 'rkz'
        },
        obrkz: {
          type: 'ob',
          subtype: 'rkz'
        },
        nzprk: {
          type: 'nz',
          subtype: 'prk'
        },
        obprk: {
          type: 'ob',
          subtype: 'prk'
        },
        nzip: {
          type: 'nz',
          subtype: 'ip'
        },
        obkd: {
          type: 'ob',
          subtype: 'kd'
        },
        nkkd: {
          type: 'nk',
          subtype: 'kd'
        },
        nkvs: {
          type: 'nk',
          subtype: 'vs'
        },
        dns: {
          type: 'dns',
          subtype: 'dns'
        },
        ptk: {
          type: 'ptk',
          subtype: 'ptk'
        },
        nzsd: {
          type: 'nz',
          subtype: 'sd'
        },
        nksd: {
          type: 'nk',
          subtype: 'sd'
        },
        nkovs: {
          type: 'nk',
          subtype: 'ovs'
        },
        sn: {
          type: 'sn',
          subtype: 'sn'
        },
        pk: {
          type: 'pk',
          subtype: 'pk'
        },
        nh: {
          type: 'nh',
          subtype: 'nh'
        }
      },
      procedure: null,
      showSelect: false,
      procedureOptions: [
        { code: 'nzprk', text: 'Priame rokovacie konanie' },
        { code: 'nzus', text: 'Nadlimitné zákazky - užšia súťaž' },
        { code: 'nzrkz', text: 'Nadlimitné zákazky - rokovacie konanie so zverejnením' },
        { code: 'nzip', text: 'Nadlimitné zákazky - inovatívne partnerstvo' },
        { code: 'nzsd', text: 'Nadlimitné zákazky - súťažný dialóg' },
        { code: 'nkvs', text: 'Nadlimitná koncesia - verejná súťaž' },
        { code: 'nkovs', text: 'Nadlimitná koncesia - obchodná verejná súťaž' },
        { code: 'nkus', text: 'Nadlimitná koncesia - užšia súťaž' },
        { code: 'nkkd', text: 'Nadlimitná koncesia - koncesný dialóg' },
        { code: 'nkrkz', text: 'Nadlimitná koncesia - rokovacie konanie so zverejnením' },
        { code: 'nksd', text: 'Nadlimitná koncesia - súťažný dialóg' },
        { code: 'obus', text: 'Zákazka v oblasti obrany a bezpečnosti - užšia súťaž' },
        { code: 'obkd', text: 'Zákazka v oblasti obrany a bezpečnosti - koncesný dialóg' },
        { code: 'obrkz', text: 'Zákazka v oblasti obrany a bezpečnosti - rokovacie konanie so zverejnením' },
        { code: 'obprk', text: 'Zákazka v oblasti obrany a bezpečnosti - priame rokovacie konanie' },
        { code: 'dns', text: 'Dynamický nákupný systém' },
        { code: 'ptk', text: 'Predbežné trhové konzultácie' },
        { code: 'sn', text: 'Súťaž návrhov' },
        { code: 'pk', text: 'Podlimitná koncesia' }
      ]
    }
  },
  watch: {
    procedure (value) {
      if (value === null) {
        this.$emit('input', '')
      }
      if (value) {
        this.$emit('input', this.typesMap[value.code])

        if (value.code === 'nzvs' || value.code === 'nh') {
          this.showSelect = false
        }
      }
    },
    showSelect (newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.procedure = null
      }
    }
  }
}
</script>
