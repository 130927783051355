<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title"><span v-if="order.divided && order.parent && order.part">Časť {{ order.part }} - </span>Vyhodnotenie ponúk</h4>
      <div v-if="order._canEdit" class="actions">
        <button :disabled="loading" class="btn btn-success" @click.prevent="downloadProtocolTemplate()">
          <div v-if="loading" class="spinner-border spinner-border-sm text-light mr-2" role="status"></div>
          <i v-else class="icon-doc-1"></i>Zápisnica z vyhodnotenia
        </button>
      </div>
    </div>
    <v-table
      :items="offers"
      :columns="tableColumns"
      item-key="id"
      class="light"
    >
      <template #header="{headersStyles}">
        <thead>
          <col>
          <col>
          <colgroup :span="order.criteria.length * 2"></colgroup>
          <col>
          <col>
          <tr>
            <th :style="headersStyles[4]" rowspan="2" class="valign-middle text-center">Por. č.</th>
            <th :style="headersStyles[1]" rowspan="2" class="valign-middle text-center">Uchádzač</th>
            <th :colspan="order.criteria.length * 2" scope="colgroup" class="text-center border right">Návrhy na plnenie kritérií na vyhodnotenie ponúk</th>
            <th :style="headersStyles[3]" rowspan="2" class="valign-middle text-center">Celkový počet bodov</th>
            <th :style="headersStyles[4]" rowspan="2" class="valign-middle text-center">Poradie</th>
          </tr>
          <tr>
            <th :style="headersStyles[2]" scope="col" colspan="2" v-for="criterion in order.criteria" :key="criterion.id" class="text-center second-row">
              <div class="two-row-header">
                <div>{{ criterion.name }} <span v-if="criterion.type === 'price'">(EUR)</span><span v-else>({{ criterion.unit }})</span></div>
                <span class="text-secondary">Max. počet bodov: {{ criterion.maximumPoints }}</span>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template #body>
        <tbody>
          <tr v-for="offer in offers" :key="offer.id">
            <td>{{offer.rankByDate}}.</td>
            <td>
              <span v-if="openingEnabled && $globals.user.type === 'observer' && offer.stage === 'unopened'">{{ offer | formatApplicantName }}</span>
              <router-link v-else :to="{ name: `${routePrefix}admin-offers-view`, params: {orderId: order.id, offerId: offer.id}}">{{ offer | formatApplicantName }}</router-link>
            </td>
            <template v-for="criterion in order.criteria">
              <td class="text-right" :key="`${offer.id}-${criterion._id}-value`">
                <span v-if="proposals[offer.id][criterion._id]">{{ formatProposalValue(proposals[offer.id][criterion._id]) }}</span>
              </td>
              <td class="text-right" :key="`${offer.id}-${criterion._id}-points`">
                <span v-if="proposals[offer.id][criterion._id]">{{ proposals[offer.id][criterion._id].points | formatNumber }}</span>
              </td>
            </template>
            <td class="text-center">{{offer.totalPoints | formatNumber}}</td>
            <td class="text-center">{{offer.rankByPoints}}.</td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </div>
</template>

<script>
import saveAs from 'file-saver'
import VTable from '@/components/ui/Table.vue'
let sFormatNumber = require('simple-format-number')

export default {
  name: 'OffersEvaluationWidget',
  components: { VTable },
  inject: ['routePrefix'],
  props: {
    order: {
      type: Object,
      required: true
    },
    round: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data () {
    return {
      offers: [],
      openingDate: '',
      openingDateStr: '',
      proposals: {},
      criteria: {},
      loading: false
    }
  },
  watch: {
    round () {
      this.loadOffers()
    }
  },
  computed: {
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    tableColumns () {
      return [
        {
          key: 'rankByDate'
        },
        {
          key: 'applicant'
        },
        {
          key: 'criteria'
        },
        {
          key: 'totalPoints'
        },
        {
          key: 'rankByPoints'
        }
      ]
    }
  },
  filters: {
    formatApplicantName (offer) {
      let name = offer.applicantDetails.businessName || offer.organization.name
      return offer.applicantDetails.groupName || name
    },
    formatNumber (value) {
      return value ? sFormatNumber(value, { fractionDigits: 2, symbols: { decimal: ',', grouping: '' } }) : ''
    }
  },
  methods: {
    formatProposalValue (proposal) {
      return proposal
        ? sFormatNumber(proposal.value, { fractionDigits: this.criteria[proposal.criterion].decimalCount, symbols: { decimal: ',', grouping: ' ' } })
        : ''
    },
    prepareOfferProposals (offer) {
      let proposals = {}
      offer.proposals.forEach(proposal => {
        proposals[proposal.criterion] = proposal
      })
      this.proposals[offer.id] = proposals
    },
    prepareCriteria () {
      this.order.criteria.forEach(criterion => {
        this.criteria[criterion._id] = criterion
      })
    },
    async loadOffers () {
      this.prepareCriteria()
      this.offers = []
      this.openingDateStr = this.round === 1 ? this.order.bidsOpenAt : this.order.bidsOpenAt2
      this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
      let { data: { offers } } = await this.$http.post('/offer/list', { order: this.order.id, limit: 100, sort: 'sentAt', stage: '!excluded' })
      offers.forEach(offer => {
        if (this.round === 1 || (this.round === 2 && offer.stage !== 'excluded')) {
          this.offers.push(offer)
          this.prepareOfferProposals(offer)
        }
      })
    },
    async downloadProtocolTemplate () {
      this.loading = true
      try {
        let url = '/template/@bids-evaluate?order=' + this.order.id
        let { data } = await this.$http({ method: 'GET', url, responseType: 'blob' })
        const blob = new Blob([ data ])
        saveAs(blob, 'Zápisnica z vyhodnotenia ponúk.docx')
      } catch (error) {
        this.ErrorAlert({ text: 'Pri sťahovaní zápisnice nastala chyba.' }, error?.response?.data.error)
      }
      this.loading = false
    }
  },
  created () {
    this.loadOffers()
  }
}
</script>
<style lang="scss" scoped>
.second-row {
  border-right: 1px solid #E0E0E0;
  .two-row-header {
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;
  }
}
</style>
