<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title">Zoznam dodávateľov</h4>
      <div class="actions">
        <button
          v-if="editable"
          class="btn btn-tenderia-blue"
          @click.prevent="addSuppliersModal()"
        >
          <i class="icon-add font-size-14"></i> Pridať dodávateľa
        </button>
      </div>
    </div>
    <v-table
      :items="invoice.suppliers"
      :columns="suppliersColumns"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(residence)="{ item }">
        {{item.address}} {{item.address2}}, {{item.zip}} {{item.city}}, {{item.country}}
      </template>
      <template v-slot:cell(actions)="{ _item, row }">
        <a
          @click.prevent="removeItemModal(row)"
          href=""
          title="Zmazať"
        >
          <i class="icon-delete text-danger"></i>
        </a>
      </template>
    </v-table>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import AddSuppliersModal from '@/components/requests/components/AddSuppliersModal.vue'

export default {
  name: 'InvoiceSuppliersList',
  inject: ['eventBus'],
  components: {
    VTable
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    invoice: Object
  },
  data () {
    return {}
  },
  computed: {
    suppliersColumns () {
      const columns = [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'ico',
          label: 'IČO'
        },
        {
          key: 'residence',
          label: 'Sídlo'
        },
        {
          key: 'email',
          label: 'E-mail'
        },
        {
          key: 'phone',
          label: 'Tel. č.'
        },
        {
          key: 'contactPerson',
          label: 'Kontaktná osoba'
        }
      ]

      if (this.editable) {
        columns.push(
          {
            key: 'actions',
            label: 'Akcie'
          })
      }

      return columns
    }
  },
  methods: {
    addSuppliersModal () {
      this.$emit('vuedals:new', {
        name: 'add-users',
        escapable: true,
        title: 'Pridať dodávateľov',
        component: AddSuppliersModal,
        onClose: (val) => {
          if (this.$route.params.invoiceId) {
            this.eventBus.$emit('load-invoice')
          } else {
            this.invoice.suppliers = this.invoice.suppliers.concat(val)
          }
        }
      })
    },
    removeItemModal (index) {
      this.YesNoAlert({
        title: 'Skutočne chcete odstrániť dodávateľa zožiadanky?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeItem(index)
        }
      })
    },
    async removeItem (index) {
      if (this.$route.params.invoiceId) {
        this.ShowLoading()
        var data = {
          invoice: {
            id: this.invoice.id
          },
          supplier: this.invoice.suppliers[index].id
        }
        try {
          await this.$http.post('/invoice/supplier/remove', data)
          this.CloseLoading()
          this.OkAlert({ text: 'Dodávateľ bol odstránený zo žiadanky' })
          this.eventBus.$emit('load-invoice')
        } catch (error) {
          this.CloseLoading()
          this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error?.response?.data.error)
        }
      } else {
        this.invoice.suppliers.splice(index, 1)
      }
    }
  }
}
</script>
