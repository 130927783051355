<template>
  <div class="pagination">
    <div class="buttons">
      <component
        :is="urlNavigation ? 'router-link' : 'button'"
        class="btn first"
        :class="{disabled: isOnFirstPage}"
        :to="urlNavigation ? pageLink(1) : null"
        @click="onClickFirstPage"
      >
        Začiatok
      </component>
      <component
        :is="urlNavigation ? 'router-link' : 'button'"
        class="btn prev"
        :class="{disabled: isOnFirstPage}"
        :to="urlNavigation ? pageLink(page - 1) : null"
        @click="onClickPreviousPage"
      >
        <i class="icon-dropdown" />
      </component>
      <div class="pages">
        <template v-for="(pageNum, i) in pageButtons">
          <component
            :is="urlNavigation ? 'router-link' : 'button'"
            v-if="pageNum"
            :key="`p-${i}`"
            class="btn page"
            :class="{ active: pageNum === page }"
            :color="pageNum === page ? 'primary' : ''"
            :to="pageLinks[i]"
            @click="onClickPage(pageNum)"
          >
            {{ pageNum }}
          </component>
          <span v-else :key="i" class="separator text--secondary">...</span>
        </template>
      </div>
      <component
        :is="urlNavigation ? 'router-link' : 'button'"
        class="btn next"
        :class="{disabled: isOnLastPage}"
        :to="urlNavigation ? pageLink(page + 1) : null"
        @click="onClickNextPage"
      >
        <i class="icon-dropdown" />
      </component>
      <component
        :is="urlNavigation ? 'router-link' : 'button'"
        class="btn last"
        :class="{disabled: isOnLastPage}"
        :to="urlNavigation ? pageLink(totalPages) : null"
        @click="onClickLastPage"
      >
        Koniec
      </component>
      <slot name="info" :info="info">
        <span v-if="info" class="info m-2">
          Záznamy {{ info.first }} až {{ info.last }} z {{ info.total }}
        </span>
      </slot>
    </div>
    <label class="per-page m-2">
      <strong class="mr-2">Záznamov na stranu</strong>
      <per-page :items="perPageOptions" v-model="perPage" />
    </label>
  </div>
</template>

<script>
import _range from 'lodash/range'
import PerPage from '../forms/PerPageSelect.vue'

export default {
  components: { PerPage },
  props: {
    maxVisibleButtons: {
      type: Number,
      default: 5
    },
    perPage: {
      type: Number,
      required: true
    },
    perPageOptions: Array,
    page: {
      type: Number,
      required: true
    },
    totalElements: Number,
    urlNavigation: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    totalPages () {
      if (Number.isFinite(this.totalElements)) {
        return Math.ceil(this.totalElements / this.perPage)
      }
      return 0
    },
    isOnFirstPage () {
      return this.page === 1
    },
    isOnLastPage () {
      return this.page >= this.totalPages
    },
    overflow () {
      return this.page > 1 && this.page > this.totalPages
    },
    info () {
      return this.totalElements > 0 && {
        first: (this.page - 1) * this.perPage + 1,
        last: Math.min(this.page * this.perPage, this.totalElements),
        total: this.totalElements
      }
    },
    /** list of page numbers (null value is separator) */
    pageButtons () {
      let n = Math.max(3, this.maxVisibleButtons)
      // check if all buttons fit without splitting
      if (this.totalPages <= n) {
        return _range(1, this.totalPages + 1)
      }
      // split into 2 parts (1 + (n-1))
      n -= 1
      if (this.page <= n) {
        return [..._range(1, n + 1), null, this.totalPages]
      } else if (this.page >= this.totalPages - n + 1) {
        return [1, null, ..._range(this.totalPages - n + 1, this.totalPages + 1)]
      }
      // split into 3 parts (1 + (n-2) + 1)
      n -= 1
      const start = this.page - parseInt((n - 1) / 2)
      const end = start + n - 1
      return [1, null, ..._range(start, end + 1), null, this.totalPages]
    },
    pageLinks () {
      return this.urlNavigation ? this.pageButtons.map(this.pageLink) : this.pageButtons.map(() => null)
    }
  },
  watch: {
    overflow: {
      immediate: true,
      handler (overflow) {
        if (overflow) {
          if (this.urlNavigation) {
            this.$router.push(this.pageLink(1))
          } else {
            this.$emit('update:page', 1)
          }
        }
      }
    },
    perPage (val) {
      this.updatePerPage(val)
    }
  },
  methods: {
    onClickFirstPage () {
      this.$emit('update:page', 1)
    },
    onClickLastPage () {
      this.$emit('update:page', this.totalPages)
    },
    onClickPreviousPage () {
      this.$emit('update:page', this.page - 1)
    },
    onClickPage (page) {
      this.$emit('update:page', page)
    },
    onClickNextPage () {
      this.$emit('update:page', this.page + 1)
    },
    pageLink (page) {
      const { path, query } = this.$route
      return ({ path, query: { ...query, page } })
    },
    updateQueryParam (name, vlaue) {
      const { path, query } = this.$route
      this.$router.push({ path, query: { ...query, [name]: vlaue } })
    },
    updatePerPage (value) {
      if (this.urlNavigation) {
        this.updateQueryParam('size', value)
      }
      this.$emit('update:perPage', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  padding: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  .per-page {
    margin-left: auto;
  }

  label {
    text-transform: uppercase;
    min-width: 100px;
  }
  .select {
    height: 30px;
  }
  .separator {
    line-height: 1;
    color: #565E6A;
  }
  .buttons {
    display: flex;
    align-items: center;
    margin-right: auto;
    .pages {
      display: flex;
      align-items: center;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 36px;
      padding: 4px;
      margin: 3px;
      color: #565E6A;
      border: 1px solid #565E6A;
      line-height: 1;
      text-align: center;
      font-weight: bold;
      height: 37px;
      min-height: unset;

      &:hover {
        color: #FFF;
        background-color: lighten(#565E6A, 5%);
        border-color: lighten(#565E6A, 5%);
      }

      &:active {
        background-color: darken(#565E6A, 5%);
        border-color: darken(#565E6A, 5%);
      }

      &.active {
        background-color: darken(#565E6A, 5%);
        border-color: darken(#565E6A, 5%);

        &:hover {
          background-color: darken(#565E6A, 5%);
          border-color: darken(#565E6A, 5%);
        }
      }

      &.prev {
        i {
          rotate: 90deg;
          padding-left: 1px;
        }
      }

      &.next {
        i {
          rotate: -90deg;
          padding-left: 1px;
          padding-top: 6px;
        }
      }

      &.first, &.last {
        padding: 0px 10px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      &.page {
        &.active {
          background-color: #3E97EF;
          color: #FFF;
          border: 1px solid #3E97EF;
        }
        &:not(.active) {
          color: #565E6A;
          border: 1px solid #565E6A;

          &:hover,
          &:focus {
            color: #FFF;
          }
        }
      }
    }
  }
  @media (max-width: 640px) {
    label > strong, .info {
      display: none!important;
    }

    .btn {
      &.first, &.last {
        display: none;
      }
    }
  }

  &.dark {
    .btn {
      color: #fff;
      border: 1px solid #fff;

      &.page {
        &:not(.active) {
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }
}
</style>
