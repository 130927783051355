<template>
  <div class="card" id="order-settings-suppliers">
    <div class="card-heading">
      <h4 class="card-title">Zoznam dodávateľov</h4>
      <div class="actions">
        <router-link
          v-if="canEditSuppliers"
          class="btn btn-tenderia-blue"
          :to="{ name: `${routePrefix}admin-suppliers-invite`}"
        >
          <i class="icon-add font-size-14"/> Pridať dodávateľa
        </router-link>
        <button
          :disabled="order.suppliers.length < 1"
          class="btn btn-success"
          @click.prevent="exportToXsl()"
        >
          <i class="icon-excel"></i>Exportovať do excelu
        </button>
      </div>
    </div>
    <v-table
      :items="suppliersItems"
      :columns="suppliersColumns"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(date)="{ item }">
        {{ item.date | formatDate }}
      </template>
      <template v-slot:cell(actions)="{ item }">
        <router-link
          v-if="canEditSuppliers"
          :to="{ name: `${routePrefix}admin-suppliers-edit`, params: {id: item._id}}"
          title="Upraviť dodávateľa"
        >
          <i class="icon-pencil"></i>
        </router-link>
        <a
          v-if="canEditSuppliers"
          href=""
          @click.prevent="removeSupplierModal(item)"
          title="Zmazať"
        >
          <i class="icon-delete text-danger"></i>
        </a>
      </template>
    </v-table>
    <div class="card-footer">
      <v-pagination
        v-if="suppliersTotal"
        :url-navigation="false"
        :page.sync="page"
        :per-page.sync="perPage"
        :per-page-options="pageSizeOptions"
        :total-elements="suppliersTotal"
      />
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import moment from 'moment'
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'

export default {
  name: 'AdminSuppliers',
  inject: ['order', 'eventBus', 'routePrefix'],
  components: {
    VTable,
    VPagination
  },
  data () {
    return {
      suppliers: [],
      suppliersTotal: 0,
      page: 1,
      perPage: 10
    }
  },
  computed: {
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    bidsLimitTo () {
      return this.order.bidsLimitTo ? new Date(this.order.bidsLimitTo) : false
    },
    canEditSuppliers () {
      return this.order._canEdit && (this.order.state === 'planned' || (this.bidsLimitTo && this.serverTime < this.bidsLimitTo))
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    suppliersColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'ico',
          label: 'IČO'
        },
        {
          key: 'residence',
          label: 'Sídlo'
        },
        {
          key: 'email',
          label: 'E-mail'
        },
        {
          key: 'phone',
          label: 'Tel. č.'
        },
        {
          key: 'contactPerson',
          label: 'Kontaktná osoba'
        },
        {
          key: 'date',
          label: 'Dátum zaradenia'
        },
        {
          key: 'actions',
          label: 'Akcie',
          header: {
            width: 90
          }
        }
      ]
    },
    suppliersItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.suppliers && this.suppliers.slice(from, to)
    }
  },
  mounted () {
    this.convertSuppliers()
    this.eventBus.$on('order-data', () => {
      this.convertSuppliers()
    })
  },
  destroyed () {
    this.eventBus.$off('order-data')
  },
  methods: {
    convertSuppliers () {
      this.suppliers = []
      this.order.suppliers.forEach(supplier => {
        supplier.residence = `${supplier.address} ${supplier.address2}, ${supplier.zip} ${supplier.city}, ${supplier.country}`
        supplier.date = moment(supplier.addedAt)
        this.suppliers.push(supplier)
      })
    },
    exportToXsl () {
      let suppliers = XLSX.utils.json_to_sheet(this.suppliers.map(supplier => {
        return {
          name: supplier.name,
          ico: supplier.ico,
          residence: `${supplier.address} ${supplier.address2}, ${supplier.zip} ${supplier.city}, ${supplier.country}`,
          email: supplier.email,
          phone: supplier.phone,
          contactPerson: supplier.contactPerson,
          date: supplier.date.format('DD. MM. YYYY')
        }
      }))
      delete suppliers['A1'].w
      suppliers['A1'].v = 'Obchodné meno'
      delete suppliers['B1'].w
      suppliers['B1'].v = 'IČO'
      delete suppliers['C1'].w
      suppliers['C1'].v = 'Sídlo'
      delete suppliers['D1'].w
      suppliers['D1'].v = 'Email'
      delete suppliers['E1'].w
      suppliers['E1'].v = 'Telefón'
      delete suppliers['F1'].w
      suppliers['F1'].v = 'Kontaktná osoba'
      delete suppliers['G1'].w
      suppliers['G1'].v = 'Dátum zaradenia'
      suppliers['!cols'] = [
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 100 },
        { wpx: 100 }
      ]
      let document = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(document, suppliers, 'Zoznam dodávateľov')
      XLSX.writeFile(document, 'zoznam.xlsx')
    },
    removeSupplierModal (supplier) {
      this.YesNoAlert({
        title: 'Zmazať dodávateľa',
        text: `Skutočne chcete zmazať dodávateľa ${supplier.name} (${supplier.ico})?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeSupplier(supplier)
        }
      })
    },
    async removeSupplier (supplier) {
      try {
        let data = {
          order: {
            id: this.order.id
          },
          supplier: {
            organization: supplier.organization
          }
        }
        await this.$http.post('/order/supplier/remove', data)
        this.eventBus.$emit('order-updated')
        this.OkAlert({ text: `Dodávateľ ${supplier.name} (${supplier.ico}) bol zmazaný.` })
      } catch (error) {
        this.ErrorAlert({ text: 'Dodávateľa sa nepodarilo zmazať.' }, error?.response?.data.error)
      }
    }
  }
}
</script>
