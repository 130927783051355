export const permissions = {
  ORDER_MENU_BUYER_LIST: 'orders/menu-buyer/list/1',
  ORDER_MENU_CONTRACTOR_LIST: 'orders/menu-contractor/list/1',
  BUDGET_MENU_LIST: 'budgets/menu/list/1',
  REQUEST_MENU_LIST: 'requests/menu/list/1',

  FILE_READ: 'files/file/*/1',
  MESSAGE_READ: 'messages/message/*/1',
  ENUM_LIST: 'enums/enum/list/1',
  TEMPLATE_LIST_GET: 'templates/template/list+get/1',
  TEMPLATE_SET: 'templates/template/set/6',

  ORGANIZATION_USER_LIST: 'organizations/user/list/1',
  ORGANIZATION_USER_CREATE: 'organizations/user/create/1',
  ORGANIZATION_USER_GRANT: 'organizations/user/grant/1',
  ORGANIZATION_USER_SET: 'organizations/user/set/1',

  ORGANIZATION_SET: 'organizations/organization/set/1',

  ORGANIZATION_UNIT_LIST_GET: 'organizations/unit/list+get/6',
  ORGANIZATION_UNIT_SET: 'organizations/unit/set/6',
  ORGANIZATION_UNIT_CREATE: 'organizations/unit/create/1',
  ORGANIZATION_UNIT_DELETE: 'organizations/unit/delete/6',
  ORGANIZATION_UNIT_GRANT: 'organizations/unit/grant/6',

  ORGANIZATION_SUPPLIER_LIST_GET: 'organizations/supplier/list+get/6',
  ORGANIZATION_SUPPLIER_CREATE: 'organizations/supplier/create/1',
  ORGANIZATION_SUPPLIER_SET: 'organizations/supplier/set/6',
  ORGANIZATION_SUPPLIER_DELETE: 'organizations/supplier/delete/6',

  ORDER_OFFER_SET: 'orders/offer/set/1',
  ORDER_OFFER_LIST_GET_1: 'orders/offer/list+get/1',
  ORDER_OFFER_LIST_GET_3: 'orders/offer/list+get/3',
  ORDER_OFFER_LIST_GET_6: 'orders/offer/list+get/6',
  ORDER_OFFER_OPEN_1: 'orders/offer/open/1',
  ORDER_OFFER_OPEN_3: 'orders/offer/open/3',
  ORDER_OFFER_OPEN_6: 'orders/offer/open/6',

  ORDER_MESSAGE_LIST_1: 'orders/message/list/1',
  ORDER_MESSAGE_LIST_3: 'orders/message/list/3',
  ORDER_MESSAGE_LIST_6: 'orders/message/list/6',
  ORDER_MESSAGE_SEND_1: 'orders/message/send/1',
  ORDER_MESSAGE_SEND_3: 'orders/message/send/3',
  ORDER_MESSAGE_SEND_6: 'orders/message/send/6',

  ORDER_AUCTION_BID_1: 'orders/auction/bid/1',
  ORDER_AUCTION_BID_6: 'orders/auction/bid/6',
  ORDER_AUCTION_CREATE_1: 'orders/auction/create/1',
  ORDER_AUCTION_CREATE_3: 'orders/auction/create/3',
  ORDER_AUCTION_CREATE_6: 'orders/auction/create/6',
  ORDER_AUCTION_LIST_GET_1: 'orders/auction/list+get/1',
  ORDER_AUCTION_LIST_GET_3: 'orders/auction/list+get/3',
  ORDER_AUCTION_LIST_GET_6: 'orders/auction/list+get/6',
  ORDER_AUCTION_SET_1: 'orders/auction/set/1',
  ORDER_AUCTION_SET_3: 'orders/auction/set/3',
  ORDER_AUCTION_SET_6: 'orders/auction/set/6',

  ORDER_ORDER_CREATE_0: 'orders/order/create/1',
  ORDER_ORDER_LIST_GET_1: 'orders/order/list+get/1',
  ORDER_ORDER_LIST_GET_3: 'orders/order/list+get/3',
  ORDER_ORDER_LIST_GET_6: 'orders/order/list+get/6',
  ORDER_ORDER_SET_1: 'orders/order/set/1',
  ORDER_ORDER_SET_3: 'orders/order/set/3',
  ORDER_ORDER_SET_6: 'orders/order/set/6',

  ORDER_TEMPLATE_GENERATE_1: 'orders/template/generate/1',

  ORDER_PROTOCOL_LIST_1: 'orders/protocol/list/1',
  ORDER_PROTOCOL_LIST_3: 'orders/protocol/list/3',
  ORDER_PROTOCOL_LIST_6: 'orders/protocol/list/6',

  BUDGET_BUDGET_LIST_GET: 'budgets/budget/list+get/6',
  BUDGET_BUDGET_CREATE: 'budgets/budget/create/1',
  BUDGET_BUDGET_SET_1: 'budgets/budget/set/1',
  BUDGET_BUDGET_SET_6: 'budgets/budget/set/6',

  REQUEST_REQUEST_CREATE: 'requests/request/create/1',
  REQUEST_REQUEST_LIST_GET_1: 'requests/request/list+get/1',
  REQUEST_REQUEST_LIST_GET_2: 'requests/request/list+get/2',
  REQUEST_REQUEST_LIST_GET_6: 'requests/request/list+get/6',
  REQUEST_REQUEST_SET_1: 'requests/request/set/1',
  REQUEST_REQUEST_SET_2: 'requests/request/set/2',
  REQUEST_REQUEST_SET_6: 'requests/request/set/6',
  REQUEST_REQUEST_APPROVE_2: 'requests/request/approve/2',
  REQUEST_REQUEST_APPROVE_6: 'requests/request/approve/6'
}
