<template>
  <div class="container-fluid">
    <ul class="order-main-menu">
      <li>
        <router-link :to="{ name: 'user-profile'}">Profil</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'user-organization'}">Organizácie</router-link>
      </li>
    </ul>
    <router-view ref="child" />
  </div>
</template>

<script>

export default {
  name: 'ProfileView'
}
</script>
