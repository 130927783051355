<template>
  <div class="order-header">
    <!-- ORDER SUBHEADER HEADER -->
    <div class="inner" v-if="orderId">
      <button
        v-if="$globals.sessionType !== 'supplier' && $globals.user && $globals.user.type !== 'observer'"
        @click="goBack"
        class="btn btn-default back-button"
      >
        <i class="icon-arrow-left"></i> Späť
      </button>
      <div v-else></div>
      <div class="state">
        <order-state :state="order.state"/>
        <h4 class="title">{{ order.name }}  <span v-if="order.orderParent" class="font-size-14">( <order-link :user-type="$route.path.includes('user') ? 'user' : 'admin'" :order="order.orderParent" /> )</span></h4>
      </div>
      <div class="actions">
        <interested-button v-if="showInterestedButton" :order="order" />
        <button
          v-if="order.state === 'planned' && order._canEdit"
          @click="publishOrder()"
          class="btn btn-success"
        >
          <i class="icon-send"></i> Spustiť zákazku
        </button>
      </div>
    </div>
    <!-- REQUEST SUBHEADER HEADER -->
    <div class="inner" v-else-if="requestId">
      <button
        v-if="this.$route.name !== 'requestEmailOverview'"
        @click="goBack"
        class="btn btn-default back-button"
      >
        <i class="icon-arrow-left"></i> Späť
      </button>
      <div class="state">
        <request-state :state="request.state" />
        <h4 class="title">{{ request.name }}</h4>
      </div>
      <div class="actions">
        <button
          v-if="canEditRequest"
          class="btn btn-tenderia-blue"
          @click="editRequest"
        >
          {{ this.$route.name === 'request-settings' ? 'Zrušiť' : 'Upraviť' }}
        </button>
        <button
          v-if="canEditRequest && this.$route.name === 'request-overview'"
          @click="publishRequest()"
          class="btn btn-success"
        >
          <i class="icon-send"></i> Odoslať na schválenie
        </button>
        <button
          v-if="canApproveRequest"
          @click="approveRequestModal('approved')"
          class="btn btn-success"
        >
          <i class="icon-check"></i> Schvaľujem
        </button>
        <button
          v-if="canApproveRequest"
          @click="approveRequestModal('rejected')"
          class="btn btn-danger"
        >
          <i class="icon-close"></i> Neschvaľujem
        </button>
        <!-- <button
          v-if="canTransformToOrder"
          @click="openTransformRequestToOrder()"
          class="btn btn-success"
        >
          <i class="icon-edit-sheet"></i> Vyhlásiť zákazku
        </button> -->
      </div>
    </div>
    <!-- PUBLIC ORGANIZATION SUBHEADER HEADER -->
    <div class="inner" v-else-if="$route.params.ico || $route.name === 'messages'">
      <button
        @click="goBack"
        class="btn btn-default back-button"
      >
        <i class="icon-arrow-left"></i> Späť
      </button>
      <div class="state">
        <h4 class="title">{{ headerOrganizationName }}</h4>
      </div>
    </div>
    <!-- SUBHEADER HEADER -->
    <div class="inner organization" v-else>
      <h4 class="title">{{ headerOrganizationName }}</h4>
    </div>
  </div>
</template>
<script>
import OrderState from '@/components/OrderState'
import RequestState from '../requests/components/RequestState.vue'
import OrderLink from '@/components/order/OrderLink'
import InterestedButton from '@/components/order/common/components/InterestedButton.vue'
import ApproveRequestModal from '@/components/requests/components/ApproveRequestModal.vue'
import { permissions } from '@/router/permissions.js'
/* import TransformToOrderModal from '../requests/components/TransformToOrderModal.vue' */
export default {
  name: 'AppSubHeader',
  inject: ['eventBus'],
  props: {
    organizationName: String
  },
  components: { OrderState, OrderLink, InterestedButton, RequestState },
  data () {
    return {
      order: {},
      request: {}
    }
  },
  computed: {
    requestId () {
      return this.$route.params.requestId
    },
    orderId () {
      return this.$route.params.orderId
    },
    headerOrganizationName () {
      return this.organizationName || (this.$globals.userOrganization && this.$globals.userOrganization.name) || ''
    },
    routePrefix () {
      return this.$orderRoutePrefix(this.order.type, this.order.subtype)
    },
    requestEditRouteTo () {
      return this.$route.name === 'request-settings' ? 'request-overview' : 'request-settings'
    },
    showInterestedButton () {
      return this.$route.name === 'freeOrderHome' ||
        (
          this.$globals.user &&
          this.$globals.user.permissions && this.$globals.user.permissions.includes(permissions.ORDER_OFFER_SET) &&
          !this.order.isInterested &&
          !this.order._canEdit &&
          this.$globals.sessionType !== 'supplier'
        )
    },
    canTransformToOrder () {
      return this.request.state === 'approved' &&
      this.$globals.user.permissions && this.$globals.user.permissions?.includes(permissions.ORDER_ORDER_CREATE_0)
    },
    canEditRequest () {
      return this.$globals.user &&
      this.request.user === this.$globals.user.id &&
      this.request.organization === this.$globals.userOrganization.id &&
      (this.request.state === 'planned' || this.request.state === 'rejected')
    },
    canApproveRequest () {
      return (this.$route.name === 'requestEmailOverview' && this.$route.query.token) ||
      (this.request.approvers && this.request.approvers.some(approver => approver.id === this.$globals.user.id) &&
      this.request.organization === this.$globals.userOrganization.id &&
      this.request.state === 'approving' &&
      this.$route.name === 'request-overview')
    }
  },
  watch: {
    requestId (val) {
      if (!val) {
        this.request = {}
      }
    },
    orderId (val) {
      if (!val) {
        this.order = {}
      }
    }
  },
  methods: {
    goBack () {
      this.$router.push(this.$globals.backPath)
    },
    editRequest () {
      if (this.$route.name === 'request-settings') {
        this.eventBus.$emit('load-request')
      }
      this.$router.push({ name: this.requestEditRouteTo, params: { requestId: this.$route.params.requestId } })
    },
    async publishRequest () {
      this.eventBus.$emit('publish-request')
    },
    approveRequestModal (val) {
      this.$emit('vuedals:new', {
        name: 'approve-request',
        escapable: true,
        title: 'Schvaľovanie žiadanky',
        component: ApproveRequestModal,
        props: {
          stanceProp: val
        },
        onClose: () => {
          this.eventBus.$emit('load-request')
        }
      })
    },
    publishOrder () {
      if (this.$route.name === `${this.routePrefix}admin-settings`) {
        this.eventBus.$emit('publish-order')
      } else {
        this.$router.push({ name: `${this.routePrefix}admin-settings`, params: { orderId: this.order.id, action: 'publish' } })
      }
    },
    /* openTransformRequestToOrder () {
      this.$emit('vuedals:new', {
        name: 'new-order-modal',
        escapable: true,
        title: 'Vytvoriť zákazku zo žiadanky',
        component: TransformToOrderModal
      })
    } */
  },
  mounted () {
    this.eventBus.$on('order-data', (val) => {
      this.$set(this, 'order', val)
    })
    this.eventBus.$on('request-data', (val) => {
      this.$set(this, 'request', val)
    })
  }
}
</script>
<style lang="scss" scoped>
.order-header {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #3C4C66;
  min-height: 100px;
  background-image: url('../../assets/wave-bg2.svg');
  background-position: left top -240px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  h4 {
    color: #fff;
    font-size: 24px;
    line-height: 1.25;
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  .inner {
    width: 1200px;
    display: grid;
    grid-template-columns: 270px auto auto;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      padding-left: 15px;
      padding-right: 15px;
      .state {
        margin-left: 0px;
        margin-top: 0px;
        .order-state {
          margin-left: 0px;
          margin-top: 20px;
        }
      }
      .actions {
        margin-left: 0px;
      }
      .back-button {
        margin-top: 5px;
      }
    }
    i {
      font-size: 18px;
    }

    &.organization {
      display: flex;
      width: 670px;
      padding: 0px;
    }

    .title {
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 15px;
      font-weight: bold;
    }

    .back-button {
      justify-self: start;
      align-self: center;
      @media (max-width: 600px) {
        justify-self: center;
      }
    }

    .state {
      display: flex;
      align-items: baseline;
      gap: 10px;
      max-width: 550px;
      margin-top: 10px;
      @media (max-width: 600px) {
        justify-content: center;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: end;
      @media (max-width: 600px) {
        justify-content: center;
      }
    }
  }
}
</style>
