<template>
  <div class="file-upload">
    <span style="display: none">
      <input type="file" id="file" @change="setFiles" multiple>
    </span>
    <input type="file" name="file" id="file-upload" class="hide" multiple="multiple" @change="setFiles"><br />
    <div id="drop-target-document-files" class="drop-area">
      <i class="icon-upload-cloud drag-hide"></i>
      <span class="drag-visible">Súbory vložte sem </span>
      <span class="drag-hide">Pretiahnite sem dokumenty z plochy alebo priečinkov, ktoré chcete nahrať alebo
        <label for="file-upload">kliknite sem a vyberte súbory </label> na nahratie.
        <span>(Maximálna veľkosť jedného súboru je 2GB.)</span>
      </span>
    </div>
    <div v-if="messages.length > 0">
      <ul>
        <li v-for="(message, index) in messages" class="text-danger" :key="`warning-message-${index}`">{{ message }}</li>
      </ul>
    </div>
    <table class="table">
      <tr>
        <th>Názov súboru</th>
        <th>Veľkosť</th>
        <th>Typ</th>
        <th></th>
      </tr>
      <tr v-for="(file, index) in savedFiles" v-bind:key="file.id">
        <td class="file-name">{{ file.name }}</td>
        <td>{{ file.size | formatFileSize }}</td>
        <td>{{ file.type | formatFileType }}</td>
        <td><a href="" @click.prevent="removeSavedFileModal(index, savedFiles)"><i class="icon-delete font-size-25 text-danger"></i></a></td>
      </tr>
      <tr v-for="(file, index) in files" v-bind:key="index + '-file'" v-bind:class="{'text-danger': file.size > fileSizeMax}">
        <td class="file-name">{{ file.name }}</td>
        <td>{{ file.size | formatFileSize }}</td>
        <td>{{ file.type | formatFileType }}</td>
        <td>
          <a
            href=""
            @click.prevent="removeFile(index)"
            title="Zmazať"
          >
            <i class="icon-delete font-size-25 text-danger"></i>
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
var dragDrop = require('drag-drop')
export default {
  name: 'FileUploadElement',
  props: {
    attachments: Array
  },
  data () {
    return {
      savedFiles: [],
      files: [],
      fileSizeMax: 2147483648,
      messages: []
    }
  },
  mounted: function () {
    let vm = this
    if (document.querySelector('#drop-target-document-files')) {
      dragDrop('#drop-target-document-files', function (files) {
        vm.processFiles(files)
      })
    }
  },
  watch: {
    attachments: {
      handler (val) {
        this.savedFiles = val
      },
      immediate: true
    }
  },
  methods: {
    reset: function () {
      this.files = []
    },
    setFiles: function (event) {
      this.processFiles(event.target.files)
    },
    removeFile (index) {
      this.files.splice(index, 1)
      this.$emit('input', this.files)
    },
    removeSavedFileModal (index, fileList) {
      this.YesNoAlert({
        title: `Skutočne chcete zmazať súbor ${fileList[index].name} ?`,
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.$emit('remove-saved', { index, fileList })
        }
      })
    },
    processFiles: function (files) {
      this.messages = []
      for (var i = 0; i < files.length; i++) {
        if (files[i].size <= this.fileSizeMax) {
          this.files.push(files[i])
        } else {
          this.messages.push(`Súbor ${files[i].name} prekračuje maximálnu veľkosť 2GB.`)
        }
      }
      this.$emit('input', this.files)
    }
  }
}
</script>
<style scoped lang="scss">
.table {
  tr td.file-name {
    max-width: 200px;
  }
}
</style>
