<template>
  <div>
    <div
      v-for="(round, index) in approvers"
      :key="index"
      class="card"
    >
      <div class="card-heading">
        <h1 class="card-title">Schvaľovanie {{index + 1}}. kolo</h1>
      </div>
      <v-table
        :items="round"
        :columns="approversColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template v-slot:cell(name)="{ item }">
          {{ item.approver.firstname }} {{ item.approver.lastname }}
        </template>
        <template v-slot:cell(stance)="{ item }">
          <div class="state-badge approver-state" :class="[stateClass(item.stance)]">
            <i :class="stateIconClass(item.stance)" class="font-size-18"></i><span>{{ item.stance | approverState }}</span>
          </div>
        </template>
      </v-table>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'RequetApproversList',
  inject: ['eventBus'],
  components: {
    VTable
  },
  data () {
    return {
      approvers: [],
      tableSort: {
        sortBy: 'date',
        sort: 'desc'
      }
    }
  },
  filters: {
    approverState (value) {
      var approverStates = {
        pending: 'Čakajúca',
        approved: 'Schválená',
        rejected: 'Neschválená'
      }
      return approverStates[value]
    }
  },
  computed: {
    approversColumns () {
      return [
        {
          key: 'name',
          label: 'Schvaľovateľ'
        },
        {
          key: 'note',
          label: 'Poznámka'
        },
        {
          key: 'stance',
          label: 'Stav'
        }
      ]
    },
    fetchParams () {
      return {
        request: this.$route.params.requestId
      }
    }
  },
  methods: {
    async fetchApprovers () {
      const { data } = await this.$http.post('/request/approver/list', this.fetchParams)
      this.approvers = data.stances
    },
    stateClass (state) {
      var classes = {
        pending: {
          'pending': true
        },
        approved: {
          'approved': true
        },
        rejected: {
          'rejected': true
        }
      }
      return classes[state]
    },
    stateIconClass (state) {
      var classes = {
        pending: {
          'icon-calendar': true
        },
        approved: {
          'icon-check': true
        },
        rejected: {
          'icon-ended': true
        }
      }
      return classes[state]
    }
  },
  mounted () {
    this.fetchApprovers()
    this.eventBus.$on('load-request', () => {
      this.fetchApprovers()
    })
  },
  destroyed () {
    this.eventBus.$off('load-request')
  },
  watch: {
    fetchParams () {
      this.fetchApprovers()
    }
  }
}
</script>
