<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Verejný obstarávateľ</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-4">
                  <b>Názov</b>
                </div>
                <div class="col-md-8">
                  <b>{{ order.organization.name }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b>IČO</b>
                </div>
                <div class="col-md-8">
                  {{ order.organization.ico }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b>Adresa</b>
                </div>
                <div class="col-md-8">
                  {{ order.organization.address }} {{ order.organization.address2 }}<br />
                  {{ order.organization.zip }} {{ order.organization.city }}<br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zodpovedná osoba</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.title1 }} {{ order.user.firstname }} {{ order.user.lastname }} {{ order.user.title2 }}<br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>E-mail</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.email }}<br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefónne číslo</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.phone }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <order-overview-charts :order="order" />
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Informácie o zákazke</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Názov</b>
                </div>
                <div class="col-md-7">
                  <b>{{ order.name }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Číslo zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.identifier }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Opis predmetu zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.description }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Podmienky účasti</b>
                </div>
                <div class="col-md-7">
                  {{ order.participationConditions }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Druh zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.subjectType | formatOrderSubject }}
                </div>
              </div>
              <div class="row" v-if="order.mainDeliveryPlace">
                <div class="col-md-5">
                  <b>Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác</b>
                </div>
                <div class="col-md-7">
                  {{ order.mainDeliveryPlace }}
                </div>
              </div>
              <div class="row" v-if="order.cpvCodeText">
                <div class="col-md-5">
                  <b>Hlavný CPV kód</b>
                </div>
                <div class="col-md-7">
                  {{ order.cpvCodeText.texts.SK }} ({{ order.cpvCodeText.code }})
                </div>
              </div>
              <div class="row" v-if="order.cpvCodesText && order.cpvCodesText.length > 0">
                <div class="col-md-5">
                  <b>Doplnkové CPV kódy</b>
                </div>
                <div class="col-md-7">
                  <template v-for="cpvCode in order.cpvCodesText"><span :key="cpvCode.code">{{ cpvCode.texts.SK }} ({{ cpvCode.code }})<br/></span></template>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav</b>
                </div>
                <div class="col-md-7">
                  <order-state :state="order.state" display="text"></order-state>
                </div>
              </div>
              <div class="row" v-if="order.estimatedPrice != '0'">
                <div class="col-md-5">
                  <b>Predpokladaná hodnota v &euro; bez DPH</b>
                </div>
                <div class="col-md-7">
                  {{ order.estimatedPrice | formatPrice }} &euro;
                </div>
              </div>
              <div class="row" v-if="order.fundingByEu">
                <div class="col-md-5">
                  <b>Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie</b>
                </div>
                <div class="col-md-7">
                  {{ order.fundingByEu | formatYesNo }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum vytvorenia</b>
                </div>
                <div class="col-md-7">
                  {{ order.createdAt | formatDate }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum aktualizácie</b>
                </div>
                <div class="col-md-7">
                  {{ order.updatedAt | formatDate }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum vyhlásenia súťaže</b>
                </div>
                <div class="col-md-7">
                  {{ order.journalAnnouncementDate | formatDate }}
                </div>
              </div>
              <div class="row" v-if="order.bidsLimitTo">
                <div class="col-md-5">
                  <b>Koniec lehoty na predkladanie ponúk</b>
                </div>
                <div class="col-md-7">
                  {{ order.bidsLimitTo | formatDateTime }}
                </div>
              </div>
              <div class="row" v-if="order.bidsOpenAt">
                <div class="col-md-5">
                  <b>Dátum otvárania ponúk</b>
                </div>
                <div class="col-md-7">
                  {{ order.bidsOpenAt | formatDateTime }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Postup</b>
                </div>
                <div class="col-md-7">
                  {{ order | formatProcedureType }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Aukcia</b>
                </div>
                <div class="col-md-7">
                  {{ order.auction ? 'Áno' : 'Nie' }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zákazka sa delí na časti</b>
                </div>
                <div class="col-md-7">
                  {{ order.divided ? 'Áno' : 'Nie' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <user-documents-table :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderState from '@/components/OrderState.vue'
import UserDocumentsTable from '@/components/order/common/components/UserDocumentsTable.vue'
import OrderOverviewCharts from '@/components/order/common/components/OrderOverviewCharts.vue'

export default {
  name: 'MarketResearchUserOverview',
  components: { OrderState, UserDocumentsTable, OrderOverviewCharts },
  inject: ['order'],
  data () {
    return {}
  },
  computed: {
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'date',
          label: 'Dátum'
        }
      ]
    }
  }
}
</script>
