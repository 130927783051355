<template>
  <div class="container-fluid" id="settings-department-edit">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">{{ $route.params.departmentId ? 'Upraviť organizačnú jednotku' : 'Vytovriť novú organizačnú jednotku'}} </h4>
      </div>
      <div class="card-block">
        <form novalidate="novalidate" v-if="!$route.params.departmentId || this.dataLoaded">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name" class="text-bold text-dark">Názov <small>*</small></label>
                <input type="text" v-model="department.name" class="form-control" placeholder="Názov" :class="{ error:  department.getField('name').hasErrors() }" />
                <label v-if=" department.getField('name').hasErrors()" class="error">
                  {{  department.getField('name').errors | firstMessage }}
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="type" class="text-bold text-dark">Typ <small>*</small></label>
                <department-type-select v-model="department.type" :error="department.getField('type').hasErrors()"></department-type-select>
                <label v-if=" department.getField('type').hasErrors()" class="error">
                  {{  department.getField('type').errors | firstMessage }}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="goBack"><i class="icon-arrow-left"></i> Späť</button>
          <button class="btn btn-success" @click.prevent="save" :disabled="department.hasErrors()"><i class="icon-save"></i> Uložiť</button>
        </div>
      </div>
    </div>
    <template v-if="$route.params.departmentId">
      <div class="card">
        <div class="card-heading">
          <h4 class="card-title">Používatelia</h4>
          <div class="actions">
            <button
              class="btn btn-tenderia-blue"
              @click="addUsersModal"
            >
              <i class="icon-add font-size-14"></i> Pridať
            </button>
          </div>
        </div>
        <v-table
          :items="usersItems"
          :columns="usersColumns"
          :sort.sync="tableSort"
          item-key="id"
          class="light"
        >
          <template v-slot:cell(actions)="{ item }">
            <a
              @click.stop="removeUserModal(item)"
              class="text-danger"

              title="Zmazať"
            >
              <i class="icon-delete"></i>
            </a>
          </template>
        </v-table>
        <div class="card-footer">
          <v-pagination
            v-if="usersTotal"
            :url-navigation="false"
            :page.sync="page"
            :per-page.sync="perPage"
            :total-elements="usersTotal"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-heading">
          <h4 class="card-title">Rozpočty</h4>
          <div class="actions">
            <button
              class="btn btn-tenderia-blue"
              @click="addBudgetsModal"
            >
              <i class="icon-add font-size-14"></i> Pridať
            </button>
          </div>
        </div>
        <v-table
          :items="budgetsItems"
          :columns="budgetsColumns"
          :sort.sync="tableSort"
          item-key="id"
          class="light"
        >
          <template v-slot:cell(validFrom)="{item}">
            {{ item.validFrom | formatDate }}
          </template>
          <template v-slot:cell(validTo)="{item}">
            {{ item.validTo | formatDate }}
          </template>
          <template v-slot:cell(value)="{item}">
            {{ $format.shortCurrencyFixed2(item.value) }}
          </template>
          <template v-slot:cell(actions)="{ item }">
            <a
              @click.stop="removeBudgetModal(item)"
              class="text-danger"

              title="Zmazať"
            >
              <i class="icon-delete"></i>
            </a>
          </template>
        </v-table>
        <div class="card-footer">
          <v-pagination
            v-if="budgetsTotal"
            :url-navigation="false"
            :page.sync="page"
            :per-page.sync="perPage"
            :total-elements="budgetsTotal"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import AddDepartmentUserModal from '@/components/departments/AddDepartmentUserModal.vue'
import AddBudgetModal from '@/components/departments/AddBudgetModal.vue'
import DepartmentTypeSelect from '@/components/forms/DepartmentTypeSelect.vue'
export default {
  name: 'SettingsAddDepartment',
  components: { VPagination, VTable, DepartmentTypeSelect },
  data () {
    return {
      department: new this.$models.Department({
        vm: this,
        dataKey: 'department'
      }),
      budgets: [],
      users: [],
      dataLoaded: false,
      page: 1,
      perPage: 10,
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    usersTotal () {
      return this.users && this.users.length
    },
    usersItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.users && this.users.slice(from, to)
    },
    usersColumns () {
      return [
        {
          key: 'firstname',
          label: 'Meno',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Priezvisko',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    },
    budgetsTotal () {
      return this.budgets && this.budgets.length
    },
    budgetsItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.budgets && this.budgets.slice(from, to)
    },
    budgetsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'validFrom',
          label: 'Dátum od',
          sortable: true
        },
        {
          key: 'validTo',
          label: 'Dátum do',
          sortable: true
        },
        {
          key: 'value',
          label: 'Výška',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    },
    fetchParams () {
      return {
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        unit: {
          id: this.$route.params.departmentId
        }

      }
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'settingsDepartments' })
    },
    removeUserModal (user) {
      this.YesNoAlert({
        title: 'Odstrániť užívateľa z organizačnej jednotky?',
        text: `Skutočne odstrániť užívateľa ${user.firstname} ${user.lastname}?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odstrániť',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeUser(user)
        }
      })
    },
    async removeUser (user) {
      try {
        let data = {
          unit: {
            id: this.$route.params.departmentId
          },
          user: user.id
        }
        await this.$http.post('/orgunit/user/remove', data)
        this.OkAlert({ text: `Užívateľ ${user.firstname} ${user.lastname} bol odstránený.` })
        this.fetchUsers()
      } catch (error) {
        this.ErrorAlert({ text: 'Užívateľa sa nepodarilo odstrániť.' }, error?.response?.data.error)
      }
    },
    removeBudgetModal (budget) {
      this.YesNoAlert({
        title: 'Odstrániť rozpočet z organizačnej jednotky?',
        text: `Skutočne odstrániť rozpočet ${budget.name}?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odstrániť',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeBudget(budget)
        }
      })
    },
    async removeBudget (budget) {
      try {
        let data = {
          unit: {
            id: this.$route.params.departmentId
          },
          budget: budget.id
        }
        await this.$http.post('/orgunit/budget/remove', data)
        this.OkAlert({ text: `Rozpočet ${budget.name} bol odstránený.` })
        this.fetchDepartment()
      } catch (error) {
        this.ErrorAlert({ text: 'Rozpočet sa nepodarilo odstrániť.' }, error?.response?.data.error)
      }
    },
    async save () {
      this.department.$validate()
      if (this.department.hasErrors()) {
        return
      }
      this.ShowLoading()
      let departmentData = {
        unit: {
          name: this.department.name,
          type: this.department.type
        }
      }

      if (this.$route.params.departmentId) {
        departmentData.unit.id = this.$route.params.departmentId
      }
      try {
        await this.$http.post('/orgunit/set', departmentData)
        this.CloseLoading()
        let message = 'Nová organizačná jednotka bola vytvorená.'
        if (this.$route.params.departmentId) {
          message = 'Údaje boli uložené.'
        }
        this.OkAlert({ title: 'Hotovo', text: message })
        this.goBack()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa uložiť informácie.' }, error?.response?.data.error)
      }
    },
    addUsersModal () {
      this.$emit('vuedals:new', {
        name: 'add-users',
        escapable: true,
        title: 'Pridať používateľov',
        component: AddDepartmentUserModal,
        onClose: () => {
          this.fetchUsers()
        }
      })
    },
    addBudgetsModal () {
      this.$emit('vuedals:new', {
        name: 'add-budgets',
        escapable: true,
        title: 'Pridať rozpočet',
        component: AddBudgetModal,
        onClose: () => {
          this.fetchDepartment()
        }
      })
    },
    async fetchUsers () {
      const { data } = await this.$http.post('/orgunit/user/list', this.fetchParams)
      this.users = data.users
    },
    async fetchDepartment () {
      if (!this.$route.params.departmentId) {
        return
      }
      this.dataLoaded = false
      this.ShowLoading()

      try {
        const { data: { unit } } = await this.$http.post('/orgunit/get', { unit: { id: this.$route.params.departmentId } })
        this.fetchUsers()
        this.department.name = unit.name
        this.department.type = unit.type
        this.budgets = unit.budgets
        this.CloseLoading()
        this.dataLoaded = true
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o orgaizačnej jednotke.' }, error?.response?.data.error)
      }
    }
  },
  watch: {
    department: {
      handler (department) {department.$validate({ debounce: 1000 })},
      deep: true,
      immediate: false
    },
    fetchParams () {
      this.fetchUsers()
    }
  },
  async mounted () {
    this.fetchDepartment()
  }
}
</script>
