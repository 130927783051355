<template>
  <div>
    <v-chart :style="`min-height: ${minHeight}px`" class="line-chart-echart" :option="chartOptions" autoresize />
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { LabelLayout } from 'echarts/features'
import VChart, { THEME_KEY } from 'vue-echarts'

use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LabelLayout
])

export default
{
  name: 'MultiLineChartEcharts',
  components: { VChart },
  provide: {
    [THEME_KEY]: 'light',
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    series: {
      type: Object,
      required: true
    },
    formatLabel: {
      type: Function,
      default: null
    },
    xFormat: {
      type: Function,
      default: null
    },
    yFormat: {
      type: Function,
      default: null
    },
    tooltipFormat: {
      type: [String, Function],
      default: ''
    },
    showLastNumValues: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 210
    }
  },
  data () {
    return {}
  },
  computed: {
    // Prepare data from the input grouped by series name
    preparedData() {
      // Extract all unique x-axis values from all series
      let xAxisValues = [
        ...new Set(Object.values(this.data).flat().map(item => item.xAxis)),
      ]

      // Show only last number of values
      if (this.showLastNumValues) {
        xAxisValues = xAxisValues.slice(-this.showLastNumValues)
      }

      // Prepare series data for each series name with assigned colors and labels
      const seriesData = Object.keys(this.data).map(seriesName => {
        const seriesConfig = this.series[seriesName] || {}
        return {
          name: seriesConfig.label || seriesName, // Use label if available, otherwise default to series name
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 12,
          lineStyle: {
            width: 4
          },
          stack: 'Total', // Optional, can be customized or removed
          itemStyle: {
            color: seriesConfig.color || '#3E97EF', // Assign color if available
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            fontWeight: 'bold',
            formatter: (params) => {
              return this.formatLabel ? this.formatLabel(params.value) : params.value
            },
          },
          // Experimental. If needed every even and odd value is up or down to prevent overlay. To hide overlaying number we can use hideOverlay: true
          /* labelLayout (params) {
            console.log(params)
            const gap = 7 // Adjust this gap as necessary
            const index = params.dataIndex
            const isFirstLabel = index === 0
            const isLastLabel = index === xAxisValues.length - 1 // Assuming `xAxisValues.length` is the total number of labels

            // Adjust the x position for the first and last labels
            let adjustedX = params.rect.x
            if (isFirstLabel) {
              adjustedX += gap // Move the first label slightly to the right
            } else if (isLastLabel) {
              adjustedX -= gap // Move the last label slightly to the left
            }

            // Calculate adjusted y position based on even/odd index
            const adjustedY = index % 2 === 0
              ? params.rect.y - params.labelRect.height // Even index: middle position
              : params.rect.y + params.labelRect.height / 2 // Odd index: adjust upwards

            return {
              x: adjustedX,
              y: adjustedY,
              align: 'center',
            }
          }, */
          data: xAxisValues.map(x => {
            // Find the corresponding value for the x-axis or default to 0 if not found
            const found = this.data[seriesName].find(
              item => item.xAxis === x
            )
            return found || { value: 0 }
          }),
        }
      })

      // Get all labels for the legend, fallback to the series name if no label is provided
      const seriesLabels = Object.keys(this.data).map(
        seriesName => this.series[seriesName]?.label || seriesName
      )

      return {
        xAxisValues,
        seriesData,
        seriesLabels,
      }
    },
    // Calculate splitNumber dynamically based on data range
    dynamicSplitNumber () {
      const allValues = Object.values(this.data).flat().map((item) => item.value)
      const min = Math.min(...allValues)
      const max = Math.max(...allValues)
      const range = max - min

      // Basic rule: split into roughly 5 segments, adjust if needed
      const magnitude = Math.floor(Math.log10(range)) // Determine the range magnitude
      let splitNumber = 5 // Default value

      // Adjust splitNumber based on the magnitude of the range
      if (magnitude >= 6) {
        splitNumber = 3 // Larger ranges like millions
      } else if (magnitude >= 3) {
        splitNumber = 4 // Thousands range
      } else {
        splitNumber = 3 // Smaller ranges
      }

      // Further adjust if necessary; keep between 3 and 10
      return splitNumber
    },
    defaultTooltipFormatter () {
      return (params) => {
        if (Array.isArray(params) && params.length === 1) {
          return `<span class="text-bold" style="color: ${params[0].color}">${params[0].name}</span> <br/><span class="text-bold">${params[0].seriesName}:</span> ${this.formatLabel ? this.formatLabel(params[0].value) : params[0].value}`
        } else {
          const tooltip = params.map((item) => {
            return `<span class="text-bold">${item.name}</span> <br/><span class="text-bold">${item.seriesName}:</span> ${this.formatLabel ? this.formatLabel(item.value) : item.value}`
          })
          return tooltip.join('<br/>')
        }
      }
    },
    // Generate ECharts options based on the prepared data
    chartOptions() {
      const { xAxisValues, seriesData, seriesLabels } = this.preparedData

      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          borderColor: Object.keys(this.series).length === 1 ? this.series[Object.keys(this.series)[0]].color : null,
          formatter: this.tooltipFormat || this.defaultTooltipFormatter
        },
        legend: {
          data: seriesLabels,
          bottom: 'bottom',
          type: 'scroll',
          pageIcons: {
            horizontal: [
              'M 0.8163 -3.7594 L 3.5781 -1.2538 C 4.2207 -0.6707 5.25 -1.1267 5.25 -1.9944 L 5.25 -7.0056 C 5.25 -7.8733 4.2207 -8.3293 3.5781 -7.7463 L 0.8163 -5.2406 C 0.3789 -4.8438 0.3789 -4.1563 0.8163 -3.7594 Z',
              'M -0.8163 3.7594 L -3.5781 1.2538 C -4.2207 0.6707 -5.25 1.1267 -5.25 1.9944 L -5.25 7.0056 C -5.25 7.8733 -4.2207 8.3293 -3.5781 7.7463 L -0.8163 5.2406 C -0.3789 4.8438 -0.3789 4.1563 -0.8163 3.7594 Z',
            ]
          },
        },
        grid: {
          top: 28,
          left: '3%',
          right: 24,
          bottom: 28,
          containLabel: true,
        },
        /* toolbox: {
          feature: {
            saveAsImage: {},
          },
        }, */
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisValues,
          splitLine: {
            show: true
          },
          axisLabel: {
            fontWeight: 'bold',
            color: '#000',
            fontSize: 13,
            formatter: (value) => {
              return this.xFormat ? this.xFormat(value) : value
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontWeight: 'bold',
            color: '#000',
            fontSize: 13,
            formatter: (value) => {
              return this.yFormat ? this.yFormat(value) : value
            },
          },
          splitNumber: this.dynamicSplitNumber
        },
        series: seriesData,
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.line-chart-echart {
  display: flex;
  min-width: 0;
  svg {
    left: unset;
  }
}
</style>
