import { TenderiaHttp as http } from './TenderiaHttp.js'

export default {
  async createContract (contractData) {
    let { data: { id } } = await http.post('/order/set', contractData)
    return id
  },
  async cloneContract (contractData) {
    let { data: { order } } = await http.post('/order/clone', contractData)
    return order
  },
  async getOrder (orderId, user) {
    let { data: { order, integration, parent } } = await http.post('/order/get', { order: { id: orderId } })
    order.attachments = order.attachments.reverse()
    if (order.divided) {
      let { data: { orders: parts } } = await http.post('/order/list', { parent: orderId, limit: 100, role: 'buyer' })
      order._parts = parts.map(part => Object.assign({}, { _canEdit: user.type !== 'observer' }, part))
    }
    if (parent) {
      return { order: { ...order, integration }, parent }
    } else {
      return { ...order, integration }
    }
  },
  syncCountries () {
    http.post('/enum/countries/list', { limit: 10000 }).then(function (response) {
      localStorage.setItem('COUNTRIES', JSON.stringify(response.data.enum))
    })
  },
  syncCpvCodes () {
    http.post('/enum/cpvmain' + '/list', { limit: 10000 }).then(function (response) {
      localStorage.setItem('CPV_CODES_MAIN', JSON.stringify(response.data.enum))
    })
  }
}
