/* import AdminCommission from '@/components/order/common/views/AdminCommission'
import AdminCommissionAdd from '@/components/order/common/views/AdminCommissionAdd' */
import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminCriteria from '@/components/order/common/views/AdminCriteria'
import AdminCriteriaAdd from '@/components/order/common/views/AdminCriteriaAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import AdminItems from '@/components/order/common/views/AdminItems'
import AdminItemsAdd from '@/components/order/common/views/AdminItemsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import Admin from '../views/Admin'
import AdminApplicants from '../views/AdminApplicants'
import AdminHome from '../views/AdminHome'
import AdminInterested from '../views/AdminInterested'
import AdminOffers from '@/components/order/common/views/AdminOffers'
import AdminOfferView from '@/components/order/common/views/AdminOfferView.vue'
import AdminOverviewDnsCall from '@/components/order/common/views/AdminOverviewDnsCall'
import AdminSettingsDnsCall from '@/components/order/common/views/AdminSettingsDnsCall.vue'
import User from '../views/User'
import UserOffers from '../views/UserOffers'
import UserOffersAdd from '../views/UserOffersAdd'
import UserOffersOpening from '@/components/order/common/views/UserOffersOpening'
import UserOfferView from '@/components/order/common/views/UserOfferView.vue'
import UserOverviewDnsCall from '@/components/order/common/views/UserOverviewDnsCall'

export default [
  {
    path: 'dnsv/admin/:orderId',
    name: 'dnsv-admin',
    redirect: { name: 'dnsv-admin-overview' },
    component: Admin,
    children: [
      {
        path: 'home',
        name: 'dnsv-admin-home',
        redirect: { name: 'dnsv-admin-overview' },
        component: AdminHome,
        children: [
          {
            path: 'overview',
            name: 'dnsv-admin-overview',
            component: AdminOverviewDnsCall
          },
          {
            path: 'settings',
            name: 'dnsv-admin-settings',
            component: AdminSettingsDnsCall
          },
          {
            path: 'documents',
            name: 'dnsv-admin-documents',
            component: AdminDocuments
          },
          {
            path: 'documents/add',
            name: 'dnsv-admin-documents-add',
            component: AdminDocumentsAdd
          },
          {
            path: 'documents/edit/:id',
            name: 'dnsv-admin-documents-edit',
            component: AdminDocumentsAdd
          },
          {
            path: 'items',
            name: 'dnsv-admin-items',
            component: AdminItems
          },
          {
            path: 'items/add',
            name: 'dnsv-admin-items-add',
            component: AdminItemsAdd
          },
          {
            path: 'items/update/:index',
            name: 'dnsv-admin-items-update',
            component: AdminItemsAdd
          },
          {
            path: 'criteria',
            name: 'dnsv-admin-criteria',
            component: AdminCriteria
          },
          {
            path: 'criteria/add',
            name: 'dnsv-admin-criteria-add',
            component: AdminCriteriaAdd
          },
          {
            path: 'criteria/update/:index',
            name: 'dnsv-admin-criteria-update',
            component: AdminCriteriaAdd
          }
          /*
          {
            path: 'commission',
            name: 'dnsv-admin-commission',
            component: AdminCommission
          },
          {
            path: 'commission/add',
            name: 'dnsv-admin-commission-add',
            component: AdminCommissionAdd
          } */
        ]
      },
      {
        path: 'communication',
        name: 'dnsv-admin-communication',
        component: AdminCommunication
      },
      {
        path: 'communication/add',
        name: 'dnsv-admin-communication-add',
        component: AdminCommunicationAdd
      },
      {
        path: 'offers',
        name: 'dnsv-admin-offers',
        props: {
          round: 1
        },
        component: AdminOffers
      },
      {
        path: 'offers/:offerId',
        name: 'dnsv-admin-offers-view',
        component: AdminOfferView
      },
      {
        path: 'interested',
        name: 'dnsv-admin-interested',
        component: AdminInterested
      },
      {
        path: 'applicants',
        name: 'dnsv-admin-applicants',
        component: AdminApplicants
      }
    ]
  },
  {
    path: 'dnsv/user/:orderId',
    name: 'dnsv-user',
    redirect: { name: 'dnsv-user-overview' },
    component: User,
    children: [
      {
        path: 'overview',
        name: 'dnsv-user-overview',
        component: UserOverviewDnsCall
      },
      {
        path: 'communication',
        name: 'dnsv-user-communication',
        component: UserCommunication
      },
      {
        path: 'communication/add',
        name: 'dnsv-user-communication-add',
        component: UserCommunicationAdd
      },
      {
        path: 'offers',
        name: 'dnsv-user-offers',
        component: UserOffers
      },
      {
        path: 'offers/add',
        name: 'dnsv-user-offers-add',
        component: UserOffersAdd
      },
      {
        path: 'offers/view',
        name: 'dnsv-user-offers-view',
        component: UserOfferView
      },
      {
        path: 'offers-opening',
        name: 'dnsv-user-offers-opening',
        component: UserOffersOpening
      }
    ]
  }
]
