<template>
  <div class="rating">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      v-for="(star, index) in stars"
      :key="index"
      :class="star"
    >
      <path d="M15.015 5.882l-4.497-.654-2.01-4.075a.57.57 0 00-1.017 0l-2.01 4.075-4.496.654a.566.566 0 00-.314.967l3.254 3.172-.769 4.479a.566.566 0 00.822.597L8 12.982l4.022 2.115a.566.566 0 00.822-.597l-.769-4.48L15.33 6.85a.565.565 0 00-.314-.967z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    value: Number,
    numberOfStars: {
      type: Number,
      default: 5
    },
    max: Number
  },
  computed: {
    stars () {
      const val = (this.value / this.max) * this.numberOfStars
      const rounded = Math.round(val)
      const arr = []
      for (let i = 1; i <= this.numberOfStars; i++) {
        arr.push(i <= rounded ? 'star full' : 'star empty')
      }
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  display: flex;
  gap: 5px;
  margin: 6px;
  .star {
    width: 16px;
    &.full {
      fill: #F2C94C;
    }
    &.empty {
      path {
        stroke: #D3D3D3;
        stroke-width: 1;
        fill: transparent;
      }
    }
  }
}
</style>
