<template>
  <div class="container-fluid">
    <ul class="order-main-menu mb-3">
      <li>
        <router-link :to="{ name: 'pt-user-overview', params: {orderId: $route.params.orderId}}">Prehľad</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'pt-user-communication', params: {orderId: $route.params.orderId}}">Komunikácia</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'pt-user-offers', params: {orderId: $route.params.orderId}}">Moja ponuka</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'pt-user-offers-opening', params: {orderId: $route.params.orderId}}">Otváranie ponúk</router-link>
      </li>
      <!-- <li>
        <router-link v-bind:to="{ name: 'pt-user-auctions', params: {orderId: $route.params.orderId}}">Aukcie</router-link>
      </li> -->
    </ul>
    <router-view v-if="loaded"></router-view>
  </div>
</template>

<script>

export default {
  name: 'PtUser',
  inject: ['eventBus'],
  data () {
    return {
      loaded: false,
      order: {
        state: '',
        isApplicant: false,
        isInterested: false,
        organization: {},
        user: {},
        _parts: []
      }
    }
  },
  computed: {
    canInterest () {
      if (this.order.divided) {
        return this.order.state === 'active' && !this.order._parts.reduce((accumulator, part) => part.isInterested && accumulator, true)
      } else {
        return this.order.state === 'active' && !this.order.isInterested
      }
    }
  },
  provide () {
    return {
      order: this.order,
      routePrefix: 'pt-'
    }
  },
  methods: {
    async loadOrder () {
      let { data: { order, user } } = await this.$http.post('/order/read', { order: { id: this.$route.params.orderId } })
      Object.assign(this.order, order, {
        isApplicant: user.isApplicant,
        isInterested: user.isInterested
      })
      this.order.attachments.reverse()
      if (this.order.divided) {
        let { data: { orders: parts } } = await this.$http.post('/order/catalog', { parent: this.$route.params.orderId, limit: 100 })
        this.order._parts = parts
      }
      this.eventBus.$emit('order-data', this.order)
      this.loaded = true
    }
  },
  mounted () {
    this.eventBus.$on('order-updated', () => {
      this.loadOrder()
    })
    this.loadOrder()
  },
  destroyed () {
    this.eventBus.$off('order-updated')
  }
}
</script>
