<template>
  <div class="input-group date">
    <input type="text"
           :id="name"
           :name="name"
           :disabled="disabled"
           v-model="formatedDateTime"
           autocomplete="off"
           class="form-control"
    />
    <button v-if="formatedDateTime && !disabled" class="input-group-addon clear" @click="clearDate">
      <i class="icon-close font-size-18"></i>
    </button>
  </div>
</template>

<script>
import moment from 'moment'
require('bootstrap-datetime-picker')
export default {
  name: 'DateTimeInput',
  props: ['name', 'value', 'disabled'],
  data: function () {
    return {
      formatedDateTime: '',
      date: new Date()
    }
  },
  methods: {
    clearDate () {
      this.formatedDateTime = ''
      this.$emit('input', null)
    }
  },
  mounted () {
    if (this.value) {
      this.date = new Date(this.value)
      this.formatedDateTime = moment(this.date).format('DD. MM. YYYY HH:mm')
    } else {
      this.formatedDateTime = ''
    }
    var vm = this
    var dateTimePicker = $(`#${this.name}`).datetimepicker({
      format: 'dd. mm. yyyy hh:ii',
      fontAwesome: true,
      language: 'sk',
      autoclose: true,
      todayHighlight: true,
      weekStart: 1,
      initialDate: this.date,
      timezone: 2,
      minDate: this.minDate || false,
      maxDate: this.maxDate || false
    })
    dateTimePicker.on('change', function () {
      let date = $(`#${vm.name}`).datetimepicker('getDate')
      vm.$emit('input', date ? date.toISOString() : '')
    })
  }
}
</script>
