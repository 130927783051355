<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" id="request-settings-general">
          <div class="card-heading">
            <h4 class="card-title">Základné údaje žiadanky</h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">Názov žiadanky<small>*</small></label>
                <input type="text" placeholder="Názov" v-model="requestModel.name" class="form-control" :class="{ error: requestModel.getField('name').hasErrors() }" />
                <label v-if="requestModel.getField('name').hasErrors()" class="error">
                  {{ requestModel.getField('name').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">Schvaľovatelia<small>*</small></label>
                <div v-if="Array.isArray(requestModel.budgetApprovers) && requestModel.budgetApprovers.length > 0" class="column mb-2">
                  <span v-for="(approver, index) in requestModel.budgetApprovers" :key="index">
                    {{ approver.firstname }} {{ approver.lastname }} ({{ approver.email }})
                  </span>
                </div>
                <request-approvers-select
                  v-model="requestModel.approvers"
                  :class="{ error: requestModel.getField('approvers').hasErrors() }"
                  aria-required="true"
                />
                <label v-if="requestModel.getField('approvers').hasErrors()" class="error">
                  {{ requestModel.getField('approvers').errors | firstMessage }}
                </label>
              </div>

              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">Druh zákazky<small>*</small></label>
                <div>
                  <div class="radio radio-inline">
                    <input
                      id="subject-type-goods"
                      type="radio"
                      name="request-subject-type"
                      v-model="requestModel.subjectType"
                      value="goods"
                    />
                    <label for="subject-type-goods">Tovary</label>
                  </div>
                  <div class="radio radio-inline">
                    <input
                      id="subject-type-services"
                      type="radio"
                      name="request-subject-type"
                      v-model="requestModel.subjectType"
                      value="services"
                    />
                    <label for="subject-type-services">Služby</label>
                  </div>
                  <div class="radio radio-inline">
                    <input
                      id="subject-type-work"
                      type="radio"
                      name="request-subject-type"
                      v-model="requestModel.subjectType"
                      value="work"
                    />
                    <label for="subject-type-work">Práce</label>
                  </div>
                </div>

                <label v-if="requestModel.getField('subjectType').hasErrors()" class="error">
                  {{ requestModel.getField('subjectType').errors | firstMessage }}
                </label>
              </div>

              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">Kategória (CPV)<small>*</small></label>
                <main-cpv-code-select
                  v-model="requestModel.cpvCode"
                  :class="{ error: requestModel.getField('cpvCode').hasErrors() }"
                  aria-required="true"
                />
                <label v-if="requestModel.getField('cpvCode').hasErrors()" class="error">
                  {{ requestModel.getField('cpvCode').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">Rozpočet</label>
                <budget-select
                  v-model="requestModel.budgets"
                  :class="{ error: requestModel.getField('budgets').hasErrors() }"
                  :multiple="false"
                  aria-required="true"
                />
                <label v-if="requestModel.getField('budgets').hasErrors()" class="error">
                  {{ requestModel.getField('budgets').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">PHZ bez DPH<small>*</small></label>
                <vue-numeric
                  :disabled="disableValue"
                  class="form-control"
                  currency="€"
                  separator="space"
                  decimal-separator=","
                  :precision="2"
                  :empty-value="0"
                  v-model="requestModel.value"
                  :class="{ error: requestModel.getField('value').hasErrors() }"
                />
                <label v-if="requestModel.getField('value').hasErrors()" class="error">
                  {{ requestModel.getField('value').errors | firstMessage }}
                </label>
                <label>
                  Po pridaní položiek budu sumy automaticky prepočítané
                </label>
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">PHZ s DPH<small>*</small></label>
                <vue-numeric
                  :disabled="disableValue"
                  class="form-control"
                  currency="€"
                  separator="space"
                  decimal-separator=","
                  :precision="2"
                  :empty-value="0"
                  v-model="requestModel.valueWithVat"
                  :class="{ error: requestModel.getField('valueWithVat').hasErrors() }"
                />
                <label v-if="requestModel.getField('valueWithVat').hasErrors()" class="error">
                  {{ requestModel.getField('valueWithVat').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">Dátum dodania<small>*</small></label>
                <date-input
                  name="deliveryDate"
                  v-model="requestModel.deliveryDate"
                  placeholder="Dátum dodania"
                  :error="requestModel.getField('deliveryDate').hasErrors()"
                ></date-input>
                <label v-if=" requestModel.getField('deliveryDate').hasErrors()" class="error">
                  {{  requestModel.getField('deliveryDate').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-12 form-group">
                <label class="text-dark text-bold">Opis predmetu zákazky</label>
                <textarea id="description" v-model="requestModel.description" class="form-control"></textarea>
                Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br />
                <label v-if="requestModel.getField('description').hasErrors()" class="error">
                  {{ requestModel.getField('description').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-12 form-group">
                <label class="text-dark text-bold">Odôvodnenie potreby</label>
                <textarea id="description" v-model="requestModel.justification" class="form-control"></textarea>
                Počet zostávajúcich znakov: {{ justificationTextRemaining }}<br />
                <label v-if="requestModel.getField('justification').hasErrors()" class="error">
                  {{ requestModel.getField('justification').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-12 form-group">
                <label class="text-dark text-bold">Poznámka</label>
                <textarea id="description" v-model="requestModel.note" class="form-control"></textarea>
                Počet zostávajúcich znakov: {{ noteTextRemaining }}<br />
                <label v-if="requestModel.getField('note').hasErrors()" class="error">
                  {{ requestModel.getField('note').errors | firstMessage }}
                </label>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label" for="file-upload">Súbory</label>
                  <file-upload-element v-model="files" :attachments="requestModel.attachments" @remove-saved="removeSavedFile"></file-upload-element>
                  <!-- <label class="error" for="file-upload">Musíte pridať aspoň jeden súbor.</label> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <request-suppliers-list :request="requestModel" />
    <items-list-widget :request="requestModel" />
    <div class="row no-gutters justify-content-end">
      <div v-if="$route.params.requestId">
        <button class="btn btn-danger" @click="removeRequestModal()" href=""><i class="icon-delete"></i>Zmazať žiadanku</button>
      </div>
      <div>
        <button class="btn btn-success" @click="saveSettings()" href=""><i class="icon-save"></i>Uložiť</button>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/forms/DateInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'
import ItemsListWidget from '@/components/requests/components/ItemsListWidget.vue'
import FileUploadElement from '@/components/forms/FileUploadElement.vue'
import RequestApproversSelect from '@/components/forms/RequestApproversSelect.vue'
import BudgetSelect from '@/components/forms/BudgetSelect.vue'
import UploadManager from '@/components/utils/UploadManager.js'
import RequestSuppliersList from '../components/RequestSuppliersList.vue'

export default {
  name: 'RequestSettings',
  components: {
    DateInput,
    MainCpvCodeSelect,
    VueNumeric,
    ItemsListWidget,
    FileUploadElement,
    RequestApproversSelect,
    BudgetSelect,
    RequestSuppliersList
  },
  inject: ['request', 'eventBus'],
  data () {
    return {
      requestModel: new this.$models.Request(Object.assign(
        {
          vm: this,
          dataKey: 'requestModel'
        }
      )),
      files: [],
      requestId: this.$route.params.requestId
    }
  },
  watch: {
    request: {
      handler (request) {
        Object.assign(this.requestModel, request)
      },
      deep: true,
      immediate: false
    },
    requestModel: {
      handler (request) {
        request.$validate({ debounce: 1000 })
      },
      deep: true,
      immediate: false
    },
    requestItems: {
      handler (items) {
        this.requestModel.value = items.reduce((total, item) => total + (item.estimatedPrice || 0), 0)
        this.requestModel.valueWithVat = items.reduce((total, item) => total + (item.estimatedPrice + (item.estimatedPrice * (item.vat / 100)) || 0), 0)
      }
    },
    requestBudget: {
      handler (val) {
        if (val) {
          this.fetchBudgetApprovers(val)
        } else {
          this.requestModel.budgetApprovers = []
        }
      }
    }
  },
  computed: {
    disableValue () {
      return Array.isArray(this.requestModel.items) && this.requestModel.items.length > 0
    },
    requestItems () {
      return this.requestModel.items || []
    },
    requestBudget () {
      return Array.isArray(this.requestModel.budgets) && this.requestModel.budgets.length > 0 ? this.requestModel.budgets[0].id : null
    },
    budgetFetchParams () {
      return {
        organization: {
          id: this.$globals.userOrganization.id
        },
        budget: this.requestBudget
      }
    },
    descriptionTextRemaining () {
      return (this.requestModel.description && 4000 - this.requestModel.description.length) || 4000
    },
    justificationTextRemaining () {
      return (this.requestModel.justification && 4000 - this.requestModel.justification.length) || 4000
    },
    noteTextRemaining () {
      return (this.requestModel.note && 4000 - this.requestModel.note.length) || 4000
    },
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
  methods: {
    async fetchBudgetApprovers () {
      let { data } = await this.$http.post('/organization/approver/list', this.budgetFetchParams)
      this.requestModel.budgetApprovers = data.approvers
    },
    onError (error) {
      this.$emit('epm:close')
      this.ErrorAlertReport({ text: 'Pri spracovaní nastala chyba.' }, error, error?.response?.data.error)
    },
    finishSave () {
      this.CloseLoading()
      let message = 'Nová žiadanka bola vytvorená.'
      if (this.$route.params.requestId) {
        message = 'Údaje boli uložené.'
      }
      this.$router.push({ name: 'request-overview', params: { requestId: this.requestId } })
      this.eventBus.$emit('load-request')
      this.OkAlert({ title: 'Hotovo', text: message })
    },
    async addAttachemnts (attachments) {
      let data = {
        request: {
          id: this.requestId
        },
        attachment: {
          id: attachments
        }
      }
      try {
        await this.$http.post('/request/attachment/add', data)
        this.$emit('epm:close')
        this.finishSave()
      } catch (error) {
        this.onError(error)
      }
    },
    async saveSettings () {
      this.requestModel.$validate()
      let requestInvalid = this.requestModel.hasErrors()
      if (requestInvalid) {
        return
      }
      this.ShowLoading({ title: 'Ukladám nastavenia žiadanky' })
      Object.assign(this.request, this.requestModel)
      const data = {
        request: {
          name: this.request.name,
          description: this.request.description,
          value: this.request.value,
          valueWithVat: this.request.valueWithVat,
          subjectType: this.request.subjectType,
          cpvCode: this.request.cpvCode,
          justification: this.request.justification,
          deliveryDate: this.request.deliveryDate,
          note: this.request.note,
          suppliers: this.request.suppliers && this.request.suppliers.map(approver => approver._id),
          items: this.request.items,
          id: this.request.id,
          approvers: this.request.approvers && this.request.approvers.map(approver => approver.id),
          budgets: this.request.budgets && this.request.budgets.map(budget => budget.id)
        }
      }
      try {
        const response = await this.$http.post('/request/set', data)
        this.requestId = response.data.id
        if (this.files.length > 0) {
          this.$emit('epm:new', { title: 'Ukladám prílohy žiadanky', type: 'upload' })
          const uploader = new UploadManager(this.addAttachemnts, this.onError, { request: response.data.id }, this.$http)
          uploader.startUpload(this.files, {
            offset: 0,
            totalCount: this.files.length
          })
        } else {
          this.finishSave()
        }
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error?.response?.data.error)
      }
    },
    removeRequestModal () {
      this.YesNoAlert({
        title: 'Skutočne chcete zmazať túto žiadanku?',
        text: 'žiadanka sa po zmazaní už nebude dať obnoviť.',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then(async (result) => {
        if (result.value) {
          this.ShowLoading()
          try {
            await this.$http.post('/request/delete', { request: { id: this.$route.params.requestId } })
            this.OkAlert({ text: 'Žiadanka bola zmazaná.' })
            this.CloseLoading()
            this.$router.push({ path: '/app/requests/all' })
          } catch (error) {
            this.CloseLoading()
            this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error?.response?.data.error)
          }
        }
      })
    },
    removeSavedFile (value) {
      var data = {
        request: {
          id: this.request.id
        },
        attachment: {
          id: value.fileList[value.index].id
        }
      }
      try {
        this.$http.post('/request/attachment/remove', data)
        this.requestModel.attachments.splice(value.index, 1)
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error?.response?.data.error)
      }
    }
  },
  created () {
    if (this.$route.params.requestId) {
      Object.assign(this.requestModel, this.request)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!to.params.requestId) {
      this.requestModel.$reset()
    }
    next()
  }
}
</script>
