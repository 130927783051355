<template>
  <div>
    <order-settings-log-detail :row="row"></order-settings-log-detail>
    <div class="row">
      <div class="col-md-4 control-label">
        Meno:
      </div>
      <div class="col">
        {{ offerData.applicantDetails.businessName || offerData.applicantDetails.groupName  }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Adresa:
      </div>
      <div class="col">
        {{  offerData.applicantDetails.address }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Ičo:
      </div>
      <div class="col">
        <span> {{  offerData.applicantDetails.ico | formatIco }} </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Kontaktná osoba:
      </div>
      <div class="col">
        {{  offerData.applicantDetails.contactPerson }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Telefónne číslo:
      </div>
      <div class="col">
        {{  offerData.applicantDetails.phone }}
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close()"><i class="icon-close"></i> Zavrieť</button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOffers',
  props: ['row'],
  components: { OrderSettingsLogDetail },
  data: function () {
    return {
      offerData: null
    }
  },
  created: function () {
    this.offerData = this.row.status === '200' ? this.row.data.offer : this.row.data.request.offer
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    }
  },
  filters: {
    formatIco: function (value) {
      if (value.length === 1) {
        return value[0]
      } else {
        let string = ''
        value.forEach(ico => {
          string += `${ico}, `
        })
        return string.substring(0, string.length - 2)
      }
    }
  }
}
</script>

<style>

</style>
