<template>
  <div :class="['stat-num', colorClass(value, inverse)]">
    <slot>
      <i v-if="value !== 0" class="icon icon-dropdown" :class="arrowClass(value, inverseArrow)" />
      <span v-text="value === null ? emptyText : format(value)"/>
    </slot>
  </div>
</template>

<script>

const k = { true: -1, false: 1 }

export default {
  components: { },
  props: {
    emptyText: String,
    format: {
      type: Function,
      default: v => v
    },
    inverse: Boolean,
    inverseArrow: Boolean,
    value: Number
  },
  methods: {
    arrowClass: (v, inverse) => v * k[inverse] < 0 ? null : 'down',
    colorClass: (v, inverse) => v === null || v === 0 ? null : v * k[inverse] > 0 ? 'positive' : 'negative'
  }
}
</script>

<style lang="scss" scoped>
.stat-num {
  display: flex;
  align-items: center;
  &.positive {
    color: var(--color-green);
  }
  &.negative {
    color: var(--color-red);
  }
  .icon {
    margin-right: 3px;
    font-size: 12px;
    &.down {
      transform: rotate(180deg);
    }
  }
}
</style>
