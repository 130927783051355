<template>
  <div>
    <v-chart class="pie-chart-echart" :option="chartOptions" autoresize />
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

use([SVGRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent])

export default
{
  name: 'PieChartEcharts',
  components: { VChart },
  provide: {
    [THEME_KEY]: 'light',
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    innerText: {
      type: String,
      default: ''
    },
    innerSubtext: {
      type: [String, Number],
      default: ''
    },
    tooltipFormat: {
      type: [String, Function],
      default: '' //{a} for series name, {b} for data item name, {c} for data value, {d} for percentage.
    },
    labelFormat: {
      type: [String, Function],
      default: '{d}%'
    },
    color: {
      type: Array,
      default: () => ['#ed6484', '#5988cf', '#EB5E4A', '#35de90', '#f29f3f']
    }
  },
  data () {
    return {}
  },
  computed: {
    chartOptions () {
      const options = {
        color: this.color,
        title: {
          text: this.innerText,
          subtext: this.innerSubtext,
          textAlign: 'center',
          left: '69%',
          top: '40%',
          textStyle: {
            fontSize: 12,
          },
          subtextStyle: {
            fontSize: 14,
          }
        },
        tooltip: { trigger: 'item', formatter: this.tooltipFormat },
        legend: {
          show: true,
          left: 'left',
          top: '15%',
          orient: 'vertical',
          type: 'scroll',
          pageIcons: {
            vertical: [
              'M3.75939 0.816313C4.15625 0.378889 4.84375 0.37889 5.24061 0.816314L7.74626 3.57807C8.32927 4.22067 7.87331 5.25 7.00564 5.25L1.99436 5.25C1.12669 5.25 0.670732 4.22067 1.25375 3.57807L3.75939 0.816313Z',
              'M -3.7594 -0.8163 L -1.2538 -3.5781 C -0.6707 -4.2207 -1.1267 -5.25 -1.9944 -5.25 L -7.0056 -5.25 C -7.8733 -5.25 -8.3293 -4.2207 -7.7463 -3.5781 L -5.2406 -0.8163 C -4.8438 -0.3789 -4.1563 -0.3789 -3.7594 -0.8163 Z',
            ]
          },
          itemGap: 15,
          textStyle: {
            width: 170,
            overflow: 'break'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['45%', '80%'],
            center: ['70%', '50%'],
            padAngle: 2,
            itemStyle: { borderRadius: 5 },
            selectedOffset: 5,
            percentPrecision: 0,
            label: {
              show: true,
              formatter: this.labelFormat,
              position: 'inside',
              color:'black',
              fontSize: 12,
              fontWeight: 'bold',
              textBorderColor: '#FFFFFF',
              textBorderWidth: 2,
            },
            data: this.data?.filter((item) => item.value !== 0) || []
          },
        ],
      }

      return options
    },
  }
}
</script>
<style lang="scss" scoped>
.pie-chart-echart {
  display: flex;
  min-height: 175px;
  min-width: 0;
  svg {
    left: unset;
  }
}
</style>
