<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: 'pk-admin-settings', params: {orderId: $route.params.orderId}}">Nastavenia</router-link>
      <router-link v-if="order.divided" :to="{ name: 'pk-admin-parts', params: $route.params}">Časti</router-link>
      <router-link :to="{ name: 'pk-admin-documents', params: $route.params}">Dokumenty</router-link>
      <router-link :to="{ name: 'pk-admin-items', params: $route.params}">Položky</router-link>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PkAdminHome',
  inject: ['order']
}
</script>
