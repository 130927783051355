<template>
  <div>
    <div v-if="loaded">
      <multiselect
        v-model="selectedCpvCodes"
        :disabled="disabled"
        :options="cpvCodes"
        :multiple="true"
        :preserve-search="true"
        track-by="code"
        openDirection="bottom"
        :clear-on-select="false"
        :close-on-select="false"
        :searchable="true"
        :custom-label="customLabel"
        :taggable="true"
        :show-labels="false"
        placeholder="Vyberte CPV kód"
      >
      </multiselect>
    </div>
    <div v-if="!loaded">
      Načítavam CPV kódy
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'SupplCpvCodeSelect',
  components: { Multiselect },
  props: {
    value: Array,
    disabled: false
  },
  data: function () {
    return {
      loaded: false,
      selectedCpvCodes: [],
      cpvCodes: []
    }
  },
  watch: {
    selectedCpvCodes () {
      if (this.selectedCpvCodes === null) {
        this.$emit('input', '')
      } else {
        this.$emit('input', this.selectedCpvCodes.map(cpvCode => cpvCode.code))
      }
    }
  },
  methods: {
    customLabel ({ text, code }) {
      return `${text} - (${code})`
    }
  },
  async mounted () {
    var vm = this
    var codes = JSON.parse(localStorage.getItem('CPV_CODES_MAIN'))
    if (codes != null) {
      vm.cpvCodes = codes
      vm.loaded = true
    } else {
      await this.$http.post('/enum/cpvmain' + '/list', { limit: 10000 }).then(function (response) {
        localStorage.setItem('CPV_CODES_MAIN', JSON.stringify(response.data.enum))
        vm.cpvCodes = response.data.enum
        vm.loaded = true
      })
    }
    if (this.value) {
      this.selectedCpvCodes = this.cpvCodes.filter(cpvCode => this.value.includes(cpvCode.code))
    }
  }
}
</script>
