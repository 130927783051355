<template>
  <div class="container-fluid">
    <ul class="order-main-menu" :class="{'mb-3': !$route.fullPath.includes('/home/')}">
      <li>
        <router-link :to="{ name: 'prk-admin-home', params: {orderId: $route.params.orderId}}">Prehľad</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'prk-admin-interested', params: {orderId: $route.params.orderId}}">Záujemcovia</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'prk-admin-communication', params: {orderId: $route.params.orderId}}">Komunikácia</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'prk-admin-applicants', params: {orderId: $route.params.orderId}}">Uchádzači</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'prk-admin-offers', params: {orderId: $route.params.orderId}}">Ponuky</router-link>
      </li>
    </ul>
    <router-view v-if="order.id" ref="child" />
  </div>
</template>

<script>
import { isEqual } from 'lodash'

export default {
  name: 'PrkAdmin',
  inject: ['eventBus'],
  data () {
    return {
      loading: false,
      order: {
        id: null,
        state: null,
        attachments: [],
        announcementUrl: '',
        commission: [],
        criteria: [],
        items: [],
        estimatedPrice: null,
        description: null,
        journalAnnouncementDate: null,
        bidsLimitTo: null,
        organization: null,
        privateNote: null,
        offerOpeningType: null,
        subjectType: null,
        cpvCode: null,
        cpvCodes: [],
        procedureReverse: null,
        private: null,
        requiredAuthLevel: null,
        bidsEvaluateAt: null,
        mainDeliveryPlace: null,
        fundingByEu: null,
        participationConditions: null,
        bidsOpenAt: null,
        biddingMethod: null,
        auction: null,
        partDetails: {
          combinationAssign: null,
          combinationInfo: null,
          maxPartsAssign: null,
          maxPartsBidding: null,
          partsBidding: null
        },
        _canEdit: this.$globals.user.type !== 'observer',
        _parts: []
      },
      orderChanged: {
        value: false
      },
      oldOrder: {}
    }
  },
  provide () {
    return {
      order: this.order,
      routePrefix: 'prk-',
      orderChanged: this.orderChanged
    }
  },
  watch: {
    order: {
      handler (order) {
        if (!this.loading && !isEqual({ ...order }, this.oldOrder)) {
          this.orderChanged.value = true
          this.oldOrder = { ...order }
        }
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    async loadOrder () {
      this.loading = true
      let order = await this.$tenderiaService.getOrder(this.$route.params.orderId, this.$globals.user)
      Object.assign(this.order, order)
      this.order.announcementUrl = this.order.announcementUrl || `${this.$globals.backendHost}/public/order/${this.$route.params.orderId}`
      this.eventBus.$emit('order-data', this.order)
      this.oldOrder = { ...this.order }
      this.loading = false
    },
    cloneOrder () {
      this.eventBus.$emit('open-order-modal', { type: 'order', action: 'clone', data: { order: this.order } })
    }
  },
  mounted () {
    this.eventBus.$on('order-updated', () => {
      this.loadOrder()
    })
    this.loadOrder()
  },
  destroyed () {
    this.eventBus.$off('order-updated')
  }
}
</script>
