import numberFormat from './number-format'

import mapValues from 'lodash/mapValues'

export function sortString (params) {
  if (params && params.sortBy && params.sort) {
    return `${params.sort === 'desc' ? '-' : ''}${params.sortBy}`
  }
}

export function country (code) {
  return JSON.parse(localStorage.getItem('COUNTRIES')).find(country => country.code === code).text
}

export const orderState = (value) => {
  const orderStates = {
    planned: 'V príprave',
    active: 'Prebiehajúce',
    finished: 'Ukončené',
    cancelled: 'Zrušené'
  }
  return orderStates[value]
}

const numeric = mapValues({
  perc: numberFormat('percent'),
  perc2: numberFormat('percent2'),
  num: numberFormat('num'),
  numFixed1: numberFormat('numFixed1'),
  numFixed2: numberFormat('numFixed2'),
  shortNum: numberFormat('shortNum'),
  currency: numberFormat('currency', ['currency']).currency('EUR').pre(Math.trunc),
  shortCurrency: numberFormat('shortCurrency', ['currency']).currency('EUR'),
  shortCurrencyFixed2: numberFormat('shortCurrencyFixed2', ['currency']).currency('EUR'),
  price: numberFormat('price', ['currency']).currency('EUR')
}, fn => fn.locale('sk-SK'))

const vatCurrency = numeric.currency
  .empty('n/a')
  .post((res, args) => `${res} (${args[2] ? 's DPH' : 'bez DPH'})`)

const vatPrice = numeric.price
  .empty('n/a')
  .post((res, args) => `${res} (${args[2] ? 's DPH' : 'bez DPH'})`)

export default {
  sortString,
  ...numeric,
  vatCurrency, // (val, currency, vat) => ...
  vatPrice, // (val, currency, vat) => ...
  country,
  orderState
}
