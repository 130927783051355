<script>
export default {
  props: ['message', 'selectedMessages', 'active'],
  name: 'BaseItem',
  computed: {
    seen: function () {
      return this.message.userData.flags & 1
    },
    overdue: function () {
      return this.message.userData.flags & 16
    }
  },
  methods: {
    onSelectedChange: function (event) {
      if (event.target.checked) {
        this.$emit('select-message', this.message.id)
      } else {
        this.$emit('unselect-message', this.message.id)
      }
    },
    setAsSeen: function () {
      this.message.userData.flags = this.message.userData.flags | 1
      this.$emit('item-clicked', this.message.id)
    }
  }
}
</script>
