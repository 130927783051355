<template>
  <a :href="$globals.backendHost + file.link + '?token=' + $globals.authToken" target="_blank">
    <div class="file-icon">
      <i :class="fileClass" class="fa"></i>
    </div>
    <div class="file-info">
      <span class="file-name ">{{ file.name }}</span>
      <span class="file-size ">{{ file.size | formatFileSize }}</span>
    </div>
  </a>
</template>

<script>
var db = require('mime-db')
var mime = require('mime-type')(db)

export default {
  name: 'FileIconLink',
  props: ['file'],
  computed: {
    fileExt: function () {
      return mime.extension(this.file.mime)
    },
    fileClass: function () {
      return {
        'icon-pdf': this.fileExt === 'pdf',
        'icon-doc': this.fileExt === 'doc' || this.fileExt === 'docx' || this.fileExt === 'odt' || this.fileExt === 'rtf',
        'icon-excel': this.fileExt === 'xls' || this.fileExt === 'xlsx' || this.fileExt === 'ods',
        'icon-archive': this.fileExt === 'zip' || this.fileExt === 'rar',
        'icon-doc-1': this.fileExt === 'jpg' || this.fileExt === 'jpeg' || this.fileExt === 'png' || this.fileExt === 'gif'
      }
    }
  }
}
</script>
