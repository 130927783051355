<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title"><span v-if="order.divided && order.parent && order.part">Časť {{ order.part }} - </span>Vyhodnotenie ponúk</h4>
    </div>
    <template v-if="openingEnabledBySettings">
      <v-table
        :items="offers"
        :columns="tableColumns"
        item-key="id"
        class="light"
      >
        <template #header="{headersStyles}">
          <thead>
            <col>
            <col>
            <colgroup :span="order.criteria.length * 2"></colgroup>
            <col>
            <col>
            <tr>
              <th :style="headersStyles[4]" rowspan="2" class="valign-middle text-center">Por. č.</th>
              <th :style="headersStyles[1]" rowspan="2" class="valign-middle text-center">Uchádzač</th>
              <th :colspan="order.criteria.length * 2" scope="colgroup" class="text-center border right">Návrhy na plnenie kritérií na vyhodnotenie ponúk</th>
              <th :style="headersStyles[3]" rowspan="2" class="valign-middle text-center">Celkový počet bodov</th>
              <th :style="headersStyles[4]" rowspan="2" class="valign-middle text-center">Poradie</th>
            </tr>
            <tr>
              <th :style="headersStyles[2]" scope="col" colspan="2" v-for="criterion in order.criteria" :key="criterion.id" class="text-center second-row">
                <div class="two-row-header">
                  <div>{{ criterion.name }} <span v-if="criterion.type === 'price'">(EUR)</span><span v-else>({{ criterion.unit }})</span></div>
                  <span class="text-secondary">Max. počet bodov: {{ criterion.maximumPoints }}</span>
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template #body>
          <tbody>
            <tr v-for="offer in offers" :key="offer.id">
              <td>{{offer.rankByDate}}.</td>
              <td>
                <span>{{ offer.applicantDetails.businessName }}</span>
              </td>
              <template v-for="criterion in order.criteria">
                <td class="text-right" :key="`${offer.id}-${criterion._id}-value`">
                  <span v-if="proposals[offer.id][criterion._id]">{{ formatProposalValue(proposals[offer.id][criterion._id]) }}</span>
                </td>
                <td class="text-right" :key="`${offer.id}-${criterion._id}-points`">
                  <span v-if="proposals[offer.id][criterion._id]">{{ proposals[offer.id][criterion._id].points | formatNumber }}</span>
                </td>
              </template>
              <td class="text-center">{{offer.totalPoints | formatNumber}}</td>
              <td class="text-center">{{offer.rankByPoints}}.</td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </template>
    <div class="card-block" v-else>
      <p class="text-bold text-center pdd-vertical-25 font-size-20">Otváranie ponúk je neverejné.</p>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
let sFormatNumber = require('simple-format-number')

export default {
  name: 'UserOffersEvaluationWidget',
  components: { VTable },
  inject: ['routePrefix'],
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      offers: [],
      openingDate: '',
      proposals: {},
      criteria: {},
      loading: false,
      openingEnabledBySettings: false
    }
  },
  computed: {
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    openingEnabled () {
      return this.openingEnabledBySettings && this.order.isApplicant && this.openingDate && this.serverTime > this.openingDate
    },
    tableColumns () {
      return [
        {
          key: 'rankByDate'
        },
        {
          key: 'applicant'
        },
        {
          key: 'criteria'
        },
        {
          key: 'totalPoints'
        },
        {
          key: 'rankByPoints'
        }
      ]
    }
  },
  watch: {
    openingEnabled (value) {
      if (value) {
        this.loadOffers()
      }
    }
  },
  filters: {
    formatNumber (value) {
      return value ? sFormatNumber(value, { fractionDigits: 2, symbols: { decimal: ',', grouping: '' } }) : ''
    }
  },
  methods: {
    formatProposalValue (proposal) {
      return proposal
        ? sFormatNumber(proposal.value, { fractionDigits: this.criteria[proposal.criterion].decimalCount, symbols: { decimal: ',', grouping: ' ' } })
        : ''
    },
    prepareOfferProposals (offer) {
      let proposals = {}
      offer.proposals.forEach(proposal => {
        proposals[proposal.criterion] = proposal
      })
      this.proposals[offer.id] = proposals
    },
    prepareCriteria () {
      this.order.criteria.forEach(criterion => {
        this.criteria[criterion._id] = criterion
      })
    },
    async loadOffers () {
      this.prepareCriteria()
      this.offers = []
      let { data: { offers } } = await this.$http.post('/offer/catalog', { order: this.order.id, limit: 100, sort: 'sentAt', stage: '!excluded' })
      offers.forEach(offer => {
        if (this.order.procedureType === 'ssse' && this.order.bidsOpenAt || (this.order.procedureType === 'tste' && this.order.bidsOpenAt2 && offer.stage !== 'excluded')) {
          this.offers.push(offer)
          this.prepareOfferProposals(offer)
        }
      })
    },
  },
  created () {
    if (this.order.procedureType === 'tste' && this.order.bidsOpenAt2) {
      this.openingDate = new Date(this.order.bidsOpenAt2)
    }
    if (this.order.procedureType === 'ssse' && this.order.bidsOpenAt) {
      this.openingDate = new Date(this.order.bidsOpenAt)
    }
    if (!this.order.auction && this.order.offerOpeningType === 'distant') {
      this.openingEnabledBySettings = true
    }
  }
}
</script>
<style lang="scss" scoped>
.second-row {
  border-right: 1px solid #E0E0E0;
  .two-row-header {
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;
  }
}
</style>
