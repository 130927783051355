// // The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'popper.js'
import 'lodash'
import 'gasparesganga-jquery-loading-overlay'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'bootstrap-datetime-picker'
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css'
import 'bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.sk.js'
import 'simple-format-number'
import 'countdown'
import 'filesize'
import 'mime-type'
import 'mime-db'
import 'drag-drop'
import 'vue-numeric'
import { default as Vuedals } from 'vuedals'
import { default as TenderiaProgressModal } from './plugins/TenderiaProgressModal'
import Vue from 'vue'
import VueMce from 'vue-mce'
import 'tinymce/tinymce'
import 'tinymce-i18n/langs/sk'
import 'tinymce/themes/modern/theme'
import 'tinymce/skins/lightgray/skin.min.css'
import 'tinymce/plugins/print'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/table'
import 'tinymce/plugins/paste'
import App from './App'
import router from './router'
import STORE from './store'
import VueTabs from 'vue-nav-tabs'
import VueRawModel from 'vue-rawmodel'
import Supplier from './models/Supplier.js'
import Organization from './models/Organization.js'
import User from './models/User.js'
import Profile from './models/Profile.js'
import Request from './models/Request.js'
import Invoice from './models/Invoice.js'
import Department from './models/Department.js'
import Budget from './models/Budget.js'
import RequestItem from './models/RequestItem.js'
import './assets/css/ei-icon.css'
import './assets/css/tenderia-icon.css'
import './assets/sass/tenderia.styles.scss'
import './plugins/filters'
import Alerts from './components/mixins/Alerts.js'
import { TenderiaHttp } from './services/TenderiaHttp.js'
import TenderiaService from './services/TenderiaService'
import formats from '@/utils/format'
import AOS from 'aos'
import 'aos/dist/aos.css'
import PortalVue from 'portal-vue'

/* eslint-disable no-new */
Vue.use(VueRawModel)
Vue.use(VueTabs)
Vue.use(Vuedals)
Vue.use(TenderiaProgressModal)
Vue.use(VueMce)
Vue.use(PortalVue)
Vue.prototype.$http = TenderiaHttp
Vue.prototype.$tenderiaService = TenderiaService
Vue.config.productionTip = false

Vue.mixin(Alerts)

export const globalStore = new Vue({
  data: {
    user: null,
    userOrganization: null,
    backendUrl: process.env.VUE_APP_BACKEND_URL,
    backendHost: process.env.VUE_APP_BACKEND_HOST,
    env: process.env.NODE_ENV,
    backPath: '/app/dashboard'
  }
})

Vue.prototype.$globals = globalStore

const user = JSON.parse(localStorage.getItem('user'))
if (user) {
  Vue.prototype.$globals.user = user
}

const userOrganization = JSON.parse(localStorage.getItem('userOrganization'))
if (userOrganization) {
  Vue.prototype.$globals.userOrganization = userOrganization
}

Vue.prototype.$globals.authToken = localStorage.getItem('token')
Vue.prototype.$globals.sessionType = localStorage.getItem('sessionType')
Vue.prototype.$format = formats

new Vue({
  el: '#app',
  router,
  store: STORE,
  models: {
    Supplier,
    Organization,
    User,
    Profile,
    Invoice,
    Department,
    Budget,
    Request,
    RequestItem
  },
  components: { App },
  template: '<App/>',
  created () {
    if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
      window.addEventListener('hashchange', () => {
        var currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    }
    AOS.init()
  },
  provide () {
    return {
      eventBus: new Vue()
    }
  }
})

window.setInterval(function () {
  let time = STORE.getters.getCurrentTime
  STORE.commit('setCurrentTime', time + 1000)
}, 1000)
