<template>
  <div class="card chart-card">
    <div class="card-heading">
      <h4 class="card-title">Úspory</h4>
    </div>
    <div v-if="savingsChartData" class="p-2">
      <bar-chart-echart
        :data="savingsChartData"
        :series="savingChartsSeries"
        :tooltipFormat="tooltipFormat"
        :formatLabel="$format.shortCurrencyFixed2"
        :yFormat="$format.shortCurrency"
        :minHeight="175"
        labelParam="labelValue"
      />
    </div>

    <div v-else class="card-body">
      <span>Momentálne neobsahuje žiadne záznamy</span>
    </div>
  </div>
</template>

<script>
import BarChartEchart from '@/components/ui/BarChartEchart.vue'

export default
{
  name: 'OrderSavingsChart',
  components: { BarChartEchart },
  data () {
    return {
      orderSavingsStats: null,
      orderSavingsTotal: 0
    }
  },
  computed: {
    tooltipFormat () {
      return (params) => {
        const tooltip = params.map((item) => {
          return `<span class="text-bold">${item.seriesName}:</span> ${this.$format.shortCurrencyFixed2(item.data.labelValue)}`
        })
        return tooltip.join('<br/>')
      }
    },
    savingChartsSeries () {
      return {
        estimatedValue: {
          color: '#3E97EF',
          label: 'PHZ',
          stack: this.orderSavingsStats.savingsValue < 0 && 'realValue'
        },
        bestValue: {
          color: '#FF9D3B',
          label: 'Najlepšia ponuka',
          stack: this.orderSavingsStats.savingsValue > 0 && 'realValue'
        },
        savingsValue: {
          color: this.orderSavingsStats.savingsValue > 0 ? '#1FCB7C' : '#EB5757',
          label: 'Úspory',
          stack: 'realValue'
        }
      }
    },
    savingsChartData () {
      const stats = this.orderSavingsStats
      if (!stats || (stats.estimatedValue === 0 && stats.bestValue === 0)) {
        return null
      }
      // Define a value for the x-axis value
      const xAxis = 'PHZ / Reálna hodnota'

      // Prepare the data structure based on the fetched object
      const data = {
        estimatedValue: [
          { xAxis, value: stats.estimatedValue, labelValue: stats.estimatedValue }
        ],
        bestValue: [
          { xAxis, value: stats.bestValue, labelValue: stats.bestValue }
        ],
        savingsValue: [
          { xAxis, value: Math.abs(stats.savingsValue), labelValue: stats.savingsValue }
        ]
      }

      return data
    }
  },
  methods: {
    async fetchStatistics () {
      const { data: { stats } } = await this.$http.post('/stats/generate', {
        object: 'orders',
        type: 'order-value-savings'
      })
      this.orderSavingsStats = stats
    },
  },
  mounted () {
    this.fetchStatistics()
  }
}
</script>
