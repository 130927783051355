<template>
  <multiselect
    v-model="perPage"
    :options="items"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    placeholder=""
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'PerPageSelect',
  components: { Multiselect },
  props: {
    value: '',
    items: {
      type: Array,
      default: () => [5, 10, 20, 30]
    }
  },
  data () {
    return {
      perPage: '10'
    }
  },
  watch: {
    perPage (value) {
      this.$emit('input', value)
    }
  },
  mounted () {
    if (this.value) {
      this.perPage = this.value
    }
  }
}
</script>
