<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Identifikácia dodávateľa</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Uchádzač je členom skupiny dodávateľov, ktorá predkladá ponuku</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName ? 'Áno' : 'Nie' }}
                </div>
              </div>
              <div class="row" v-if="offer.applicantDetails.groupName">
                <div class="col-md-5">
                  <b>Názov skupiny dodávateľov</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName }}
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-5">
                  <b>Obchodné meno alebo názov uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.businessName }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Sídlo alebo miesto podnikania uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.address }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IČO</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li v-for="(ico, index) in offer.applicantDetails.ico" :key="`ico-${index}`">{{ ico }}</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Platca DPH</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.vatPayer | formatYesNo}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Štát</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.country }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zoznam osôb oprávnených konať v mene uchádzača</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li v-for="(authorizedPerson, index) in offer.applicantDetails.authorizedPersons" :key="`authorized-person-${index}`">{{ authorizedPerson }}</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Meno a priezvisko kontaktnej osoby</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.contactPerson }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefón</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.phone }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Fax</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.fax }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Email</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Systémové informácie</h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum odoslania uchádzačom</b>
                </div>
                <div class="col-md-7">
                  {{ offer.sentAt | formatDateTime }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IP adresa uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.userIP }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ offer.stage | formatStage }}
                </div>
              </div>
              <div class="row" v-if="offer.stage === 'excluded'">
                <div class="col-md-5">
                  <b>Dôvod vylúčenia ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ offer.legalBasis | formatLegalBasis }}<br />
                  <p>{{ offer.decision }}</p>
                </div>
              </div>
              <div class="row" v-if="offer.stage === 'excluded'">
                <div class="col-md-5">
                  <b>Právny základ pre vylúčenie ponuky</b>
                </div>
                <div class="col-md-7">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="offerOrder.items.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Položky</h4>
          </div>
          <items-table v-if="ready" :order="order" :offer="offer" :decimal-count="decimalCount" :editable="false" />
        </div>
      </div>
    </div>
    <div class="row" v-if="criteriaEnabled && Array.isArray(criteria) && criteria.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Návrh na plnenie kritérií</h4>
          </div>
          <div class="card-block">
            <div class="row form-group" v-for="criterion in criteria" :key="criterion._id">
              <div class="col-md-9">
                <label :for="criterion._id" class="text-bold text-dark">{{ criterion.name}} {{ taxInclusion(criterion) }}</label><br />
                <span style="font-size: 80%">Minimálna hodnota: {{ criterion.minimalValue === Number.MIN_SAFE_INTEGER ? "-&infin;" : formatNumber(criterion.minimalValue, criterion.decimalCount) }} Maximálna hodnota: {{ criterion.maximalValue === Number.MAX_SAFE_INTEGER ? "&infin;" : formatNumber(criterion.maximalValue, criterion.decimalCount) }} Počet desatinných miest: {{ criterion.decimalCount }}</span>
              </div>
              <div class="col-md-2 text-dark">
                {{ formatNumber(criterion.value, criterion.decimalCount) }}
              </div>
              <div class="col-md-1 text-dark">
                {{ criterion.unit }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="criteriaEnabled && Array.isArray(criteria) && criteria.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Prílohy kritérií</h4>
            <div class="actions">
              <button
                v-if="offer.attachments2 && offer.attachments2.length > 0"
                class="btn btn-tenderia-grey-blue"
                @click.prevent="downloadAttachments('ATTACHMENTS2')"
              >
                <i class="icon-download"></i>Stiahnuť všetky prílohy
              </button>
            </div>
          </div>
          <v-table
            :items="offer.attachments2"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row" v-if="offerOrder.procedureType === 'tste'">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Sprievodný text ponuky</h4>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col-md-12" v-if="offer.body">
                {{ offer.body }}
              </div>
              <p class="text-dark text-center pdd-vertical-25 col-md-12" v-else>
                Sprievodný text ponuky uchádzač nevyplnil.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="offerOrder.procedureType === 'tste'">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Prílohy ponuky</h4>
            <div class="actions">
              <button
                v-if="offer.attachments && offer.attachments.length > 0"
                class="btn btn-tenderia-grey-blue"
                @click.prevent="downloadAttachments('ATTACHMENTS')"
              >
                <i class="icon-download"></i>Stiahnuť všetky prílohy
              </button>
            </div>
          </div>
          <v-table
            :items="offer.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template v-slot:cell(name)="{ item }">
              <a
                :href="$globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.mime | formatFileType }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
      <button v-if="canExclude" class="btn btn-danger" @click="excludeModal()">Vylúčiť ponuku</button>
    </div>
  </div>
</template>

<script>
import BaseOfferView from '@/components/order/common/components/BaseOfferView.vue'
import ItemsTable from '@/components/order/common/components/ItemsTable.vue'
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'PtAdminOfferDisplay',
  extends: BaseOfferView,
  components: { ItemsTable, VTable },
  data () {
    return {
      offerOrder: {}
    }
  },
  filters: {
    formatStage: function (value) {
      var stages = {
        unopened: 'Netvorená',
        opened: 'Otvorená',
        opened2: 'Otvorená',
        excluded: 'Vylúčená'
      }
      return stages[value]
    }
  },
  computed: {
    criteriaEnabled () {
      return this.criteriaOpeningDate && this.serverTime > this.criteriaOpeningDate && this.offer.stage !== 'excluded'
    },
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
  async mounted () {
    if (this.$globals.user.type === 'observer') {
      let { data: { offer } } = await this.$http.post('/offer/observe', { offer: { id: this.$route.params.offerId } })
      this.offer = offer
    } else {
      let { data: { offer } } = await this.$http.post('/offer/open', { offer: { id: this.$route.params.offerId } })
      this.offer = offer
    }
    if (this.offerOrder.procedureType === 'tste' && this.offerOrder.bidsOpenAt2) {
      this.criteriaOpeningDate = new Date(this.offerOrder.bidsOpenAt2)
    }
    if ((this.offerOrder.procedureType === 'ssse' || this.offerOrder.procedureType === 'dns') && this.offerOrder.bidsOpenAt) {
      this.criteriaOpeningDate = new Date(this.offerOrder.bidsOpenAt)
    }
    this.prepareCriteria()
    this.ready = true
  },
  created () {
    if (this.$route.params.partId) {
      for (let i = 0; i < this.order._parts.length; i++) {
        if (this.order._parts[i].id === this.$route.params.partId) {
          Object.assign(this.offerOrder, this.order._parts[i])
          break
        }
      }
    } else {
      Object.assign(this.offerOrder, this.order)
    }
  }
}
</script>
