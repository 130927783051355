<template>
  <div class="layout free-order">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <div class="public-form-container p-3">
          <div class="registration-header">
            <span class="registration-title">
              <span class="text-primary">Vytvorte si účet</span>
              tenderia<span class="text-primary">.</span>
            </span>
            <span class="registration-subtitle">
              Registrácia používateľa
            </span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <template>
                <template v-if="showOrganizationFields">
                  <div class="row">
                    <div class="col-md-12 row no-gutters align-items-baseline">
                      <h4>Firma / organizácia</h4>
                      <v-badge v-if="form.manualMode" color="primary" glow>
                        <small>Manuálne zadané údaje</small>
                      </v-badge>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label class="control-label" form="name">
                          Názov <small>*</small>
                        </label>
                        <rpo-search
                          placeholder="Názov"
                          :value="form.manualMode ? profile.organization.name : form.organization"
                          :manualMode="form.manualMode"
                          :error="profile.organization.getField('name').hasErrors()"
                          @manualModeChange="onManualModeChange"
                          @input="updateOrganizationName"
                        >
                          <slot slot="prepend">
                            <span/>
                          </slot>
                        </rpo-search>
                        <label v-if=" profile.organization.getField('name').hasErrors()" class="error">
                          {{  profile.organization.getField('name').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="control-label" form="ic">IČO <small>*</small></label>
                        <rpo-search
                          placeholder="IČO"
                          :value="form.manualMode ? profile.organization.ico : form.organization"
                          :error="profile.organization.getField('ico').hasErrors()"
                          itemText="cin"
                          :manualMode="form.manualMode"
                          @manualModeChange="onManualModeChange"
                          @input="updateOrganizationCin"
                        >
                          <slot slot="prepend">
                            <span/>
                          </slot>
                        </rpo-search>
                        <!-- <input
                          id="ico"
                          v-model="profile.organization.ico"
                          class="form-control"
                          placeholder="IČO"
                          :class="{ error: profile.organization.getField('ico').hasErrors() }"
                        /> -->
                        <label v-if="profile.organization.getField('ico').hasErrors()" class="error">
                          {{ profile.organization.getField('ico').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="control-label" form="icdph">IČ DPH</label>
                        <input id="icdph" type="text" v-model=" profile.organization.icdph" class="form-control" placeholder="IČ DPH" :class="{ error:  profile.organization.getField('icdph').hasErrors() }" />
                        <label v-if=" profile.organization.getField('icdph').hasErrors()" class="error">
                          {{  profile.organization.getField('icdph').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <div class="form-group">
                        <label class="control-label" form="address">Ulica <small>*</small></label>
                        <input id="address" type="text" v-model=" profile.organization.address" class="form-control" placeholder="Ulica" :class="{ error:  profile.organization.getField('address').hasErrors() }" />
                        <label v-if=" profile.organization.getField('address').hasErrors()" class="error">
                          {{  profile.organization.getField('address').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="control-label" form="address2">Číslo domu <small>*</small></label>
                        <input id="address2" type="text" v-model=" profile.organization.address2" class="form-control" placeholder="Číslo domu" :class="{ error:  profile.organization.getField('address2').hasErrors() }" />
                        <label v-if=" profile.organization.getField('address2').hasErrors()" class="error">
                          {{  profile.organization.getField('address2').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                        <label class="control-label" form="city">Mesto <small>*</small></label>
                        <input id="city" type="text" v-model=" profile.organization.city" class="form-control" placeholder="Mesto" :class="{ error:  profile.organization.getField('city').hasErrors() }" />
                        <label v-if=" profile.organization.getField('city').hasErrors()" class="error">
                          {{  profile.organization.getField('city').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="control-label" form="zip">PSČ <small>*</small></label>
                        <input id="zip" type="text" v-model=" profile.organization.zip" class="form-control" placeholder="PSČ" :class="{ error:  profile.organization.getField('zip').hasErrors() }" />
                        <label v-if=" profile.organization.getField('zip').hasErrors()" class="error">
                          {{  profile.organization.getField('zip').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="control-label" form="country">Krajina <small>*</small></label>
                        <country-select v-model=" profile.organization.country" :value=" profile.organization.country"></country-select>
                        <label v-if=" profile.organization.getField('country').hasErrors()" class="error">
                          {{  profile.organization.getField('country').errors | firstMessage }}
                        </label>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="row">
                  <div class="col-md-12">
                    <h4>Používateľ</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" form="firstname">Meno <small>*</small></label>
                      <input id="firstname" type="text" v-model=" profile.user.firstname" class="form-control" placeholder="Meno" :class="{ error:  profile.user.getField('firstname').hasErrors() }" />
                      <label v-if=" profile.user.getField('firstname').hasErrors()" class="error">
                        {{  profile.user.getField('firstname').errors | firstMessage }}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" form="lastname">Priezvisko <small>*</small></label>
                      <input id="lastname" type="text" v-model=" profile.user.lastname" class="form-control" placeholder="Priezvisko" :class="{ error:  profile.user.getField('lastname').hasErrors() }" />
                      <label v-if=" profile.user.getField('lastname').hasErrors()" class="error">
                        {{  profile.user.getField('lastname').errors | firstMessage }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">Titul pred menom</label>
                      <input id="title1" type="text" v-model=" profile.user.title1" class="form-control" placeholder="Titul" :class="{ error:  profile.user.getField('title1').hasErrors() }" />
                      <label v-if=" profile.user.getField('title1').hasErrors()" class="error">
                        {{  profile.user.getField('title1').errors | firstMessage }}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">Titul za menom</label>
                      <input id="title2" type="text" v-model=" profile.user.title2" class="form-control" placeholder="Titul" :class="{ error:  profile.user.getField('title2').hasErrors() }" />
                      <label v-if=" profile.user.getField('title2').hasErrors()" class="error">
                        {{  profile.user.getField('title2').errors | firstMessage }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">Email <small>*</small></label>
                      <input id="email" type="text" v-model=" profile.user.email" class="form-control" placeholder="Email" :class="{ error:  profile.user.getField('email').hasErrors() }" />
                      <label v-if=" profile.user.getField('email').hasErrors()" class="error">
                        {{  profile.user.getField('email').errors | firstMessage }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">Telefónne čislo <small>*</small></label>
                      <input id="phone" type="text" v-model=" profile.user.phone" class="form-control" placeholder="Zadávajte v tvare +421 123 456 789" :class="{ error:  profile.user.getField('phone').hasErrors() }" />
                      <label v-if=" profile.user.getField('phone').hasErrors()" class="error">
                        {{  profile.user.getField('phone').errors | firstMessage }}
                      </label>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="row no-gutters justify-content-center w-100">
              <router-link to="/" class="btn btn-tenderia-grey-blue btn-inverse"><i class="icon-arrow-left"></i> Späť</router-link>
              <button
                type="submit"
                @click.prevent="registerUser"
                class="btn btn-tenderia-blue"
                :disabled="showOrganizationFields ? profile.hasErrors(): profile.user.hasErrors()"
              >
                Zaregistrovať sa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import CountrySelect from './forms/CountrySelect.vue'
import RpoSearch from '@/components/ui/RpoSearch.vue'
import VBadge from '@/components/ui/Badge.vue'
export default {
  name: 'UserRegistration',
  components: { CountrySelect, AppLoginHeader, AppFooter, RpoSearch, VBadge },
  data () {
    return {
      eidToken: '',
      supplierToken: '',
      inviteToken: '',
      form: {
        organization: {},
        manualMode: false
      },
      profile: new this.$models.Profile({
        vm: this,
        dataKey: 'profile',
        organization: {
          mode: 'registration'
        },
        user: {
          mode: 'registration'
        },
        representative: true
      })
    }
  },
  computed: {
    showOrganizationFields () {
      return !this.supplierToken
    }
  },
  watch: {
    profile: {
      handler (profile) {profile.$validate({ debounce: 500 })},
      deep: true,
      immediate: true
    },
    'form.organization' (value) {
      if (value && value.cin && !this.form.manualMode) {
        this.profile.organization.address = value.address.street
        this.profile.organization.address2 = value.address.building_number
        this.profile.organization.city = value.address.municipality
        this.profile.organization.zip = value.address.postal_code?.replace(/\s+/g, '')
        this.profile.organization.country = value.address.country.toUpperCase()
        this.profile.organization.name = value.name
        this.profile.organization.ico = value.cin
      }
      if (!value) {
        this.profile.organization.address = ''
        this.profile.organization.address2 = ''
        this.profile.organization.city = ''
        this.profile.organization.zip = ''
        this.profile.organization.country = ''
        this.profile.organization.name = ''
        this.profile.organization.ico = ''
      }
    }
  },
  created () {
    if (this.$route.params.eidToken) {
      this.eidToken = this.$route.params.eidToken
    }
    if (this.$route.params.supplierToken) {
      this.supplierToken = this.$route.params.supplierToken
    } else if (this.$route.params.inviteToken) {
      this.inviteToken = this.$route.params.inviteToken
    }
  },
  methods: {
    updateOrganizationName (val) {
      if (this.form.manualMode) {
        this.profile.organization.name = val
      } else {
        this.form.organization = val
      }
    },
    updateOrganizationCin (val) {
      if (this.form.manualMode) {
        this.profile.organization.ico = val
      } else {
        this.form.organization = val
      }
    },
    onManualModeChange (val) {
      this.form.manualMode = val
      this.form.organization = null
      this.profile.organization.name = ''
      this.profile.organization.ico = ''
    },
    async registerUser () {
      this.profile.$validate()
      if (this.profile.hasErrors()) {
        return
      }
      this.ShowLoading()
      let profile = Object.assign({}, this.profile)
      if (this.eidToken) {
        profile.eidToken = this.eidToken
      }
      if (this.supplierToken) {
        profile.supplierToken = this.supplierToken
        profile.organization = null
        profile.representative = null
      }
      if (this.inviteToken) {
        profile.inviteToken = this.inviteToken
      }

      try {
        let { data: { notify } } = await this.$http.post('/user/register', profile)
        this.CloseLoading()
        $('#registration-modal').modal('toggle')
        if (notify) {
          this.OkAlert({
            title: 'Registrácia čaká na schválenie',
            text: 'Vaša registrácia bude overená administrátorom do 24 hodín. V prípade, že sa tak nestane kontaktujte technickú podporu.'
          })
        } else {
          this.OkAlert({
            title: 'Potvrďte svoju registráciu',
            text: 'Proces registrácie dokončíte kliknutím na odkaz, ktorý sme Vám odoslali na email ' + this.profile.user.email
          })
        }
        this.profile.user.$reset()
        this.profile.organization.$reset()
        this.$router.push('/')
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ title: 'Pri registrácii nastala chyba', text: 'V prípade opakovania problému kontaktujte technickú podporu' }, error?.response?.data.error)
      }
    }
  }
}
</script>
