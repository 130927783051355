<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h1 v-if="$route.params.state === 'all'" class="card-title">Všetky žiadanky</h1>
        <h1 v-else class="card-title">{{requestState($route.params.state)}}</h1>
        <div class="actions">
          <button @click="showFilter = !showFilter" class="btn btn-success">
            <i class="icon-filter"></i><span>{{showFilter ? 'Skryť' : 'Zobraziť'}} filter</span>
          </button>
          <button @click="fetchRequestsXlsx" class="btn btn-success">
            <i class="icon-excel"></i><span>Exportovať do excelu</span>
          </button>
        </div>
      </div>
      <v-collapsible>
        <div v-if="showFilter" class="card-block">
          <div class="row gutters-sm align-items-baseline tabel-filters">
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">Hľadaj v názve alebo popise:</label>
              <input v-model="fulltext" class="form-control">
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">Dátum vytvorenia od:</label>
              <date-input
                name="date"
                v-model="dateFrom"
                :max-date="new Date()"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">Dátum vytvorenia do:</label>
              <date-input
                name="date-to"
                v-model="dateTo"
                :max-date="new Date()"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">CPV kód:</label>
              <main-cpv-code-select v-model="cpvCode" placeholder="" />
            </div>
            <div v-if="$route.params.state === 'all'" class="col-md-4 form-group">
              <label class="text-bold text-dark">Stav:</label>
              <multiselect
                v-model="state"
                :options="statesOptions"
                :multiple="true"
                :preserve-search="true"
                track-by="value"
                label="label"
                openDirection="bottom"
                :clear-on-select="false"
                :close-on-select="false"
                :searchable="true"
                :taggable="true"
                :show-labels="false"
                placeholder=""
              >
              </multiselect>
            </div>
            <div v-if="$route.name === 'requests'" class="col-md-4 form-group">
              <label class="text-bold text-dark">Používateľ:</label>
              <user-select
                v-model="users"
                placeholder=""
                aria-required="true"
              />
            </div>
          </div>
        </div>
      </v-collapsible>
      <v-table
        :items="requests.requests"
        :columns="requestsColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template v-slot:cell(name)="{ item }">
          <router-link :to="`/app/requests/${item.id}/overview`">{{ item.name }}</router-link>
        </template>
        <template v-slot:cell(approvers)="{ item }">
          <span v-for="approver in item.approvers" :key="approver.id">
            {{ approver.firstname }} {{ approver.lastname }} ({{ approver.email }}),
          </span>
        </template>
        <template v-slot:cell(createdAt)="{ item }">
          {{ item.createdAt | formatDateTime }}
        </template>
        <template v-slot:cell(value)="{ item }">
          {{ $format.shortCurrencyFixed2(item.value) }}
        </template>
        <template v-slot:cell(state)="{ item }">
          <request-state :state="item.state" />
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="requests.total"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="requests.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import saveAs from 'file-saver'
import VTable from './ui/Table.vue'
import VCollapsible from './ui/Collapsible.vue'
import VPagination from './ui/Pagination.vue'
import DateInput from '@/components/forms/DateInput.vue'
import RequestState from './requests/components/RequestState.vue'
import Multiselect from 'vue-multiselect'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import UserSelect from '@/components/forms/UserSelect.vue'

export default {
  name: 'RequestsPage',
  components: {
    VTable,
    VCollapsible,
    VPagination,
    DateInput,
    RequestState,
    Multiselect,
    MainCpvCodeSelect,
    UserSelect
  },
  data () {
    return {
      requests: {
        requests: [],
        total: 0
      },
      showFilter: true,
      fulltext: '',
      dateFrom: '',
      dateTo: '',
      state: [],
      cpvCode: '',
      users: [],
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    statesOptions () {
      return [
        { value: 'planned', label: 'V príprave' },
        { value: 'approving', label: 'Čakajúce' },
        { value: 'approved', label: 'Schválené' },
        { value: 'rejected', label: 'Odmietnuté' },
        { value: 'archived', label: 'Archivované' }
      ]
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    requestsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia',
          sortable: true
        },
        {
          key: 'approvers',
          label: 'Schvaľovatelia',
          sortable: true
        },
        {
          key: 'value',
          label: 'Hodnota',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav',
          sortable: true
        }
      ]
    },
    fetchParams () {
      const paramsMap = {
        all: '',
        planned: 'planned',
        approving: 'approving',
        approved: 'approved',
        rejected: 'rejected',
        archived: 'archived,'
      }

      const params = {
        state: paramsMap[this.$router.currentRoute.params.state] || this.state.map(state => state.value),
        limit: this.perPage,
        page: this.page,
        offset: this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        filter: this.fulltext,
        user: this.users,
        cpvCode: this.cpvCode
      }

      if (this.$route.name === 'myRequests') {
        params.user = true
      }
      if (this.$route.name === 'requests') {
        params.approver = true
      }

      return params
    }
  },
  methods: {
    requestState (value) {
      var requestStates = {
        planned: 'V príprave',
        approving: 'Čakajúce',
        approved: 'Schválené',
        rejected: 'Odmietnuté',
        archived: 'Archivované'
      }
      return requestStates[value]
    },
    async fetchRequests () {
      const { data } = await this.$http.post('/request/list', this.fetchParams)
      this.requests = data
    },
    async fetchRequestsXlsx () {
      const url = '/request/list'
      let { data } = await this.$http({ method: 'POST', url, data: { ...this.fetchParams, format: 'xlsx' }, responseType: 'blob' })
      const blob = new Blob([ data ])
      saveAs(blob, 'Žiadanky.xlsx')
    }
  },
  mounted () {
    this.fetchRequests()
  },
  watch: {
    fetchParams () {
      this.fetchRequests()
    }
  }
}
</script>
