<template>
  <multiselect
    v-model="selectedSupplier"
    :options="suppliers"
    track-by="_id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    placeholder="Vyberte si používateľov"
  >
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'RequestSuppliersSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      selectedSupplier: [],
      suppliers: []
    }
  },
  computed: {
    fetchParams () {
      return {
        organization: {
          id: this.$globals.userOrganization.id
        }
      }
    }
  },
  async mounted () {
    let { data } = await this.$http.post('/organization/supplier/list', this.fetchParams)
    this.suppliers = data.suppliers
    if (this.value) {
      this.selectedSupplier = this.suppliers.filter(user => this.value.find(val => val.id === user.id))
    }
  },
  watch: {
    selectedSupplier (val) {
      if (!val) {
        this.$emit('input', [])
      } else {
        this.$emit('input', val)
      }
    },
    value (val) {
      if (!val) {
        this.selectedSupplier = []
      }
    }
  }
}
</script>
