<template>
  <div class="card" id="my-offer">
    <div class="card-heading">
      <h4 class="card-title">Vaša ponuka</h4>
      <div class="actions ">
        <button v-if="offer.id" class="btn btn-tenderia-blue" @click="openOffer()">
          <span>Zobraziť ponuku</span>
        </button>
        <button v-if="canSendApplication" class="btn btn-success" @click="sendOffer()">
          <i class="icon-send"></i> <span>Odoslať ponuku</span>
        </button>
        <button v-if="canEditApplication" class="btn btn-danger" @click="removeOfferModal()">
          <i class="icon-delete"></i> <span>Zmazať ponuku</span>
        </button>
        <button v-if="canRevokeApplication" class="btn btn-warning" @click="revokeOfferModal()">
          <i class="icon-close"></i> Odvolať ponuku
        </button>
        <button v-if="canCreateApplication" class="btn btn-tenderia-blue" @click="addOffer()">
          <i class="icon-add font-size-14"></i> Vytvoriť ponuku
        </button>
        <button v-if="canEditApplication" class="btn btn-tenderia-blue" @click="addOffer()">
          <i class="icon-pencil"></i> Upraviť ponuku
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <p class="py-3">
            Koniec lehoty na predkladanie ponúk: <span v-if="order.bidsLimitTo" class="text-bold">{{ order.bidsLimitTo | formatDateTime }}</span><span class="text-bold" v-else>Termín nebol stanovený</span>
          </p>
          <p class="text-dark text-center pdd-vertical-25" v-if="displayTimer">
            <countdown :deadline="bidsLimitTo" :server-time="serverTime"></countdown>
          </p>
          <p class="text-dark text-center text-danger lead" v-if="!authLevelComplied">
            Pre predloženie alebo odvolanie ponuky je vyžadované prihlásenie pomocou eID!
          </p>
        </div>
      </div>
    </div>
    <v-table
      v-if="offer.id"
      :items="[offer]"
      :columns="offerColumns"
      item-key="id"
      class="light"
    >
      <template v-slot:cell(createdAt)="{ item }">
        {{ item.createdAt | formatDateTime }}
      </template>
      <template v-slot:cell(updatedAt)="{ item }">
        {{ item.updatedAt | formatDateTime }}
      </template>
      <template v-slot:cell(sentAt)="{ item }">
        <span v-if="item.state !== 'new'">{{ item.sentAt | formatDateTime }}</span>
      </template>
      <template v-slot:cell(state)="{ item }">
        <span class="order-state" :class="{'active': item.state !== 'new', 'canceled': item.state === 'new'}">{{ item.state | formatOfferState}}</span>
      </template>
    </v-table>
    <p class="text-bold text-dark text-center pdd-vertical-50" v-else>
      Nemáte vytvorenú žiadnu ponuku
    </p>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import VTable from '@/components/ui/Table.vue'

export default {
  name: 'DnsUserApplications',
  components: { Countdown, VTable },
  inject: ['order', 'routePrefix', 'eventBus'],
  data () {
    return {
      offer: {
        id: null,
        state: null
      }
    }
  },
  computed: {
    offerColumns () {
      return [
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia'
        },
        {
          key: 'updatedAt',
          label: 'Dátum poslednej aktualizácie'
        },
        {
          key: 'sentAt',
          label: 'Dátum odoslania ponuky'
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    displayTimer () {
      return this.serverTime < this.bidsLimitTo
    },
    bidsLimitTo () {
      return new Date(this.order.bidsLimitTo)
    },
    serverTime () {
      return new Date(this.$store.state.stats.currentTime)
    },
    canCreateApplication () {
      return this.basicRequirements && !this.offer.id
    },
    canEditApplication () {
      return this.basicRequirements && this.offer.id && this.offer.state === 'new'
    },
    canSendApplication () {
      return this.basicRequirements && this.offer.id && this.offer.state === 'new'
    },
    canRevokeApplication () {
      return this.basicRequirements && this.offer.id && this.offer.state === 'sent'
    },
    basicRequirements () {
      return this.serverTime < this.bidsLimitTo && this.authLevelComplied
    },
    authLevelComplied () {
      return this.$globals.user.authLevel >= this.order.requiredAuthLevel
    }
  },
  filters: {
    formatOfferState (value) {
      var states = {
        new: 'Neodoslaná',
        sent: 'Odoslaná',
        seen: 'Otvorená'
      }
      return states[value]
    }
  },
  methods: {
    addOffer () {
      this.$router.push({ name: `${this.routePrefix}user-offers-add`, params: { orderId: this.order.id } })
    },
    sendOffer () {
      this.$router.push({ name: `${this.routePrefix}user-offers-add`, params: { orderId: this.order.id, action: 'send' } })
    },
    openOffer () {
      this.$router.push({ name: `${this.routePrefix}user-offers-view`, params: { orderId: this.order.id } })
    },
    revokeOfferModal () {
      this.YesNoAlert({
        text: 'Skutočne chcete odvolať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odvolať'
      }).then((result) => {
        if (result.value) {
          this.revokeOffer()
        }
      })
    },
    async revokeOffer () {
      this.ShowLoading({ title: 'Odvolávam ponuku' })
      try {
        await this.$http.post('/offer/set', { offer: { organization: this.$globals.userOrganization.id, order: this.order.id, id: this.offer.id, state: 'new' } })
        this.CloseLoading()
        this.offer.state = 'new'
        this.PopupAlert({ title: 'Ponuka bola odvolaná' })
        this.eventBus.$emit('order-updated')
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
      }
    },
    removeOfferModal () {
      this.YesNoAlert({
        text: 'Skutočne chcete zmazať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeOffer()
        }
      })
    },
    async removeOffer () {
      this.ShowLoading({ title: 'Odstraňujem ponuku' })
      try {
        await this.$http.post('/offer/remove', { offer: { order: this.order.id } })
        this.offer.id = null
        this.CloseLoading()
        this.PopupAlert({ title: 'Ponuka bola odstránená' })
        this.eventBus.$emit('order-updated')
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
      }
    }
  },
  async created () {
    let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.order.id } })
    if (offer.id) {
      this.offer = offer
    }
  }
}
</script>
