<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" id="order-settings-general">
          <div class="card-heading">
            <h4 class="card-title">Základné údaje</h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="contract-name" class="control-label text-dark text-bold">Názov zákazky <small>*</small></label>
                  <div>
                    <input id="contract-name" type="text" v-model="order.name" class="form-control" required="required" disabled="disabled" aria-required="true">
                  </div>
                </div>

                <div class="form-group">
                  <label for="estimated-price" class="control-label">Predpokladaná hodnota v &euro; bez DPH <small>*</small></label>
                  <div>
                    <vue-numeric class="form-control" currency="€" separator="space" decimal-separator="," :precision="2" :empty-value="0" :disabled="!order._canEdit" v-model="order.estimatedPrice"></vue-numeric>
                    <label v-if="form.estimatedPrice.error" class="error">{{ form.estimatedPrice.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie <small>*</small></label>
                  <div>
                    <div class="radio radio-inline">
                      <input id="funding-by-eu-yes" type="radio" v-model="order.fundingByEu" value="yes" :disabled="!order._canEdit" /><label for="funding-by-eu-yes">Áno</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="funding-by-eu-no" type="radio" v-model="order.fundingByEu" value="no" :disabled="!order._canEdit" /><label for="funding-by-eu-no">Nie</label>
                    </div>
                    <label v-if="form.fundingByEu.error" class="error">{{ form.fundingByEu.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="announcement-url" class="control-label">URL v tenderia</label>
                  <div class="input-group">
                    <input id="announcement-url" type="text" v-model="order.announcementUrl" class="form-control" readonly>
                    <button class="input-group-addon copy" @click="copyToClipboard(order.announcementUrl)">
                      <i class="ei-copy font-size-18"></i>
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <label for="journal-jnnouncement-date" class="control-label">Dátum vyhlásenia súťaže</label>
                  <div>
                    <date-input name="journal-jnnouncement-date" v-model="order.journalAnnouncementDate" :value="order.journalAnnouncementDate" :disabled="!order._canEdit"></date-input>
                    <label for="journal-jnnouncement-date" v-if="form.journalAnnouncementDate.error" class="error">{{ form.journalAnnouncementDate.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group" v-if="order.type === 'dns'">
                  <label for="duration" class="control-label">Trvanie DNS (v mesiacoch) <small>*</small></label>
                  <div>
                    <input id="duration" :disabled="!order._canEdit" type="number" min="1" max="48" v-model.lazy="order.duration" class="form-control">
                  </div>
                </div>

                <div class="form-group">
                  <label for="journal" class="control-label">Číslo vestníka</label>
                  <div>
                    <input id="journal" type="text" v-model="order.journal" class="form-control" :disabled="!order._canEdit">
                    <label for="journal" v-if="form.journal.error" class="error">{{ form.journal.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="announcement" class="control-label">Číslo oznámenia/výzvy</label>
                  <div>
                    <input id="announcement" type="text" v-model="order.announcement" class="form-control" :disabled="!order._canEdit">
                    <label for="announcement" v-if="form.announcement.error" class="error">{{ form.announcement.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Druh zákazky <small>*</small></label>
                  <div>
                    <div class="radio radio-inline">
                      <input id="subject-type-goods" type="radio" name="order-subject-type" v-model="order.subjectType" value="goods" :disabled="!order._canEdit" /> <label for="subject-type-goods">Tovary</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="subject-type-services" type="radio" name="order-subject-type" v-model="order.subjectType" value="services" :disabled="!order._canEdit" /> <label for="subject-type-services">Služby</label>
                    </div>
                    <div class="radio radio-inline">
                      <input id="subject-type-work" type="radio" name="order-subject-type" v-model="order.subjectType" value="work" :disabled="!order._canEdit" /> <label for="subject-type-work">Práce</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Hlavný CPV kód <small>*</small></label>
                  <div>
                    <main-cpv-code-select v-if="order.id" v-model="order.cpvCode" :value="order.cpvCode" :disabled="!order._canEdit"></main-cpv-code-select>
                    <label v-if="form.cpvCode.error" class="error">{{ form.cpvCode.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Doplnkové CPV kódy</label>
                  <div>
                    <suppl-cpv-code-select v-if="order.id" v-model="order.cpvCodes" :value="order.cpvCodes" :disabled="!order._canEdit"></suppl-cpv-code-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="description" class="control-label">Opis predmetu zákazky <small>*</small></label>
                  <div>
                    <textarea id="description" v-model="order.description" class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br />
                    <label for="description" v-if="form.description.error" class="error">{{ form.description.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="announcement" class="control-label">Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác</label>
                  <div>
                    <input id="announcement" type="text" v-model="order.mainDeliveryPlace" class="form-control" :disabled="!order._canEdit">
                    <label for="announcement" v-if="form.mainDeliveryPlace.error" class="error">{{ form.mainDeliveryPlace.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="private-note" class="control-label">Poznámka</label>
                  <div>
                    <textarea id="private-note" v-model="order.privateNote"  class="form-control" :disabled="!order._canEdit"></textarea>
                    Počet zostávajúcich znakov: {{ privateNoteTextRemaining }}
                  </div>
                </div>

                <div class="form-group">
                  <label v-if="order.type === 'dns'" for="bids-limit-to" class="control-label">Koniec lehoty na predkladanie žiadostí o zaradenie do DNS <small>*</small></label>
                  <label v-else for="bids-limit-to" class="control-label">Koniec lehoty na predkladanie žiadostí o účasť <small>*</small></label>
                  <div>
                    <date-time-input name="bids-limit-to" v-model="order.bidsLimitTo" :value="order.bidsLimitTo"  :disabled="!order._canEdit"></date-time-input>
                    <label for="bids-limit-to" v-if="form.bidsLimitTo.error" class="error">{{ form.bidsLimitTo.errorMessage }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label v-if="order.type === 'dns'" for="bids-limit-to" class="control-label">Koniec lehoty na predkladanie žiadostí o zaradenie do DNS <small>*</small></label>
                  <label v-else for="bids-open-at" class="control-label">Dátum a čas otvárania  žiadostí o účasť</label>
                  <div>
                    <date-time-input name="bids-open-at" v-model="order.bidsOpenAt" :value="order.bidsOpenAt"  :disabled="!order._canEdit"></date-time-input>
                    <label for="bids-open-at" v-if="form.bidsOpenAt.error" class="error">{{ form.bidsOpenAt.errorMessage }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center no-gutters justify-content-between">
      <div class="row no-gutters">
        <button v-if="order.state === 'active' && order._canEdit"
                @click="changeStateModal('finished')"
                class="btn btn-tenderia-blue"
        >
          <i class="icon-check"></i> Ukončiť zákazku
        </button>
        <button v-if="order.state === 'active' && order._canEdit"
                @click="changeStateModal('cancelled')"
                class="btn btn-danger"
        >
          <i class="icon-close"></i> Zrušiť zákazku
        </button>
        <div v-if="order.state === 'planned' && order._canEdit">
          <button class="btn btn-danger" @click="removeOrderModal()" href=""><i class="icon-delete"></i>Zmazať zákazku</button>
        </div>
      </div>
      <div class="row no-gutters">
        <button v-if="order.integration.eforms.enabled" class="btn btn-success" @click="openEformsModal">Vyplniť v eForms</button>
        <div v-if="order._canEdit">
          <button class="btn btn-success" @click="saveSettings()" href=""><i class="icon-save"></i>Uložiť</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminOrder from '@/components/order/common/components/AdminOrder'
import DateInput from '@/components/forms/DateInput.vue'
import DateTimeInput from '@/components/forms/DateTimeInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import SupplCpvCodeSelect from '@/components/forms/SupplCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'

export default {
  name: 'AdminSettingsDns',
  extends: AdminOrder,
  components: { DateInput, DateTimeInput, MainCpvCodeSelect, SupplCpvCodeSelect, VueNumeric },
  methods: {
    validateBeforePublish () {
      this.validateEstimatedPrice()
      this.validatecpvCode()
      this.validateDescription()
      this.validateBidsOpenAt()
      this.validateBidsLimitTo()
      this.validateMainDeliveryPlace()
      this.validateFundingByEu()
      this.validateJournalAnnouncementDate()
      this.validateJournal()
      this.validateAnnouncement()

      return this.form.estimatedPrice.valid && this.form.cpvCode.valid && this.form.description.valid && this.form.journalAnnouncementDate.valid && this.form.bidsOpenAt.valid && this.form.bidsLimitTo && this.form.journal.valid && this.form.announcement.valid && this.form.mainDeliveryPlace.valid && this.form.fundingByEu.valid
    },
    async saveSettings (state) {
      let orderIsValid = this.validateBeforePublish()
      if (this.order.state === 'active' && !orderIsValid) {
        return
      }
      this.ShowLoading({ title: 'Ukladám nastavenia zákazky' })
      let data = {
        order: {
          id: this.order.id,
          name: this.order.name,
          estimatedPrice: this.order.estimatedPrice.toString().replaceAll(' ', '').replaceAll(',', '.'),
          description: this.order.description,
          announcementUrl: this.order.announcementUrl,
          journalAnnouncementDate: this.order.journalAnnouncementDate,
          bidsLimitTo: this.order.bidsLimitTo,
          bidsOpenAt: this.order.bidsOpenAt,
          organization: this.order.organization.id,
          privateNote: this.order.privateNote,
          subjectType: this.order.subjectType,
          cpvCode: this.order.cpvCode,
          cpvCodes: this.order.cpvCodes,
          journal: this.order.journal,
          announcement: this.order.announcement,
          mainDeliveryPlace: this.order.mainDeliveryPlace,
          fundingByEu: this.order.fundingByEu,
          duration: this.order.duration || null
        }
      }
      try {
        await this.$http.post('/order/set', data)
        this.eventBus.$emit('order-updated')
        this.CloseLoading()
        if (state === 'active') {
          this.changeState(state)
        } else {
          this.PopupAlert({ text: 'Zmeny boli uložené.' })
        }
      } catch (error) {
        this.Closeloading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
      }
    }
  },
  destroyed () {
    this.eventBus.$off('publish-order')
  },
  mounted () {
    if (this.$route.params.action === 'publish') {
      this.publishModal()
    }
    this.eventBus.$on('publish-order', () => {
      this.publishModal()
    })
  }
}
</script>
