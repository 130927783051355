<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card" v-for="part in order._parts" :key="part.id">
        <div class="card-heading">
          <h4 class="card-title">Časť {{ part.part}} - {{ part.name }}</h4>
        </div>
        <div class="card-block">
          <div class="list-table">
            <div class="row">
              <div class="col-md-3">
                <b>Opis predmetu zákazky</b>
              </div>
              <div class="col-md-9">
                {{ part.description }}
              </div>
            </div>
            <div class="row" v-if="part.estimatedPrice > 0">
              <div class="col-md-3">
                <b>Predpokladaná hodnota v &euro; bez DPH</b>
              </div>
              <div class="col-md-9">
                {{ part.estimatedPrice | formatPrice }} &euro;
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b>Druh zákazky</b>
              </div>
              <div class="col-md-9">
                {{ part.subjectType | formatOrderSubject }}
              </div>
            </div>
            <div class="row" v-if="part.cpvCodeText">
              <div class="col-md-3">
                <b>Hlavný CPV kód</b>
              </div>
              <div class="col-md-9">
                {{ part.cpvCodeText.texts.SK }} ({{ part.cpvCodeText.code }})
              </div>
            </div>
            <div class="row" v-if="part.cpvCodesText && part.cpvCodesText.length > 0">
              <div class="col-md-3">
                <b>Doplnkové CPV kódy</b>
              </div>
              <div class="col-md-9">
                <ul class="list-unstyled">
                  <li v-for="cpvCode in part.cpvCodesText" :key="`${part.id}-${cpvCode.code}`">{{ cpvCode.texts.SK }} ({{ cpvCode.code }})</li>
                </ul>
              </div>
            </div>
            <div class="row" v-if="part.bidsOpenAt">
              <div class="col-md-3">
                <b>Dátum otvárania ponúk <span v-if="order.procedureType === 'tste'">(ostatené)</span></b>
              </div>
              <div class="col-md-9">
                {{ part.bidsOpenAt | formatDateTime }}
              </div>
            </div>
            <div class="row" v-if="part.bidsOpenAt2">
              <div class="col-md-3">
                <b>Dátum otvárania ponúk (kritériá)</b>
              </div>
              <div class="col-md-9">
                {{ part.bidsOpenAt2 | formatDateTime }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b>Aukcia</b>
              </div>
              <div class="col-md-9">
                {{ part.auction ? 'Áno' : 'Nie' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartsList',
  inject: ['order']
}
</script>
