<template>
  <form>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="nazov-jednotky" class="text-dark text-bold">Organizačné jednotky <small>*</small></label>
          <department-select
            v-model="departments"
            :class="{ error: form.departments.error, valid: form.departments.valid }"
            aria-required="true"
          />
          <label v-if="form.departments.error" class="error" for="nazov-jednotky">Toto pole je povinné.</label>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close">Zrušiť</button>
      <button class="btn btn-success" @click.prevent="addDepartments">Pridať</button>
    </div>
  </form>
</template>

<script>
import DepartmentSelect from '@/components/forms/DepartmentSelect.vue'
export default {
  name: 'AddDepartmentDepartmentModal',
  components: { DepartmentSelect },
  data () {
    return {
      departments: [],
      form: {
        departments: {
          error: false,
          valid: false
        }
      },
      validators: [
        this.validateDepartments
      ]
    }
  },
  methods: {
    showLoading () {
      $('.vuedal').LoadingOverlay('show')
    },
    hideLoading () {
      $('.vuedal').LoadingOverlay('hide')
    },
    close () {
      this.$emit('vuedals:close', null)
    },
    async addDepartments () {
      if (!this.validateDepartment()) {
        return
      }
      this.showLoading()
      let departmentsData = {
        budget: {
          id: this.$route.params.budgetId
        },
        unit: {
          id: this.departments
        }
      }
      try {
        await this.$http.post('/budget/orgunit/add', departmentsData)
        this.hideLoading()
        this.close()
      } catch (error) {
        this.hideLoading()
        this.ErrorAlert({ title: 'Chyba', text: 'Organizačné jednotky sa nepodarilo pridať' }, error?.response?.data.error)
      }
    },
    validateDepartment () {
      let state = this.form.departments
      state.valid = state.error = false
      if (this.departments.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    }
  },
  watch: {
    departments () {
      this.validateDepartment()
    }
  }
}
</script>
