<template>
  <multiselect
    v-model="selectedDepartments"
    :options="departments"
    track-by="id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    placeholder="Vyberte si organizačné jednotky"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'DepartmentSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      selectedDepartments: null,
      departments: []
    }
  },
  computed: {
    fetchParams () {
      return {
        id: this.$globals.userOrganization.id
      }
    }
  },
  async mounted () {
    let { data } = await this.$http.post('/orgunit/list', this.fetchParams)
    this.departments = data.units
    if (this.value) {
      this.selectedDepartments = this.departments.filter(department => this.value.includes(department.id))
    }
  },
  watch: {
    selectedDepartments (val) {
      if (!val) {
        this.$emit('input', [])
      } else {
        this.$emit('input', val.map(department => department.id))
      }
    }
  }
}
</script>
