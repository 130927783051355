<template>
  <vue-tabs v-model="activeTab">
    <v-tab title="Založiť novú zákazku">
      <div class="px-3 pt-4 pb-2">
        <new-order-form :config="config" />
      </div>
    </v-tab>

    <v-tab title="Klonovať zákazku">
      <div class="px-3 pt-4 pb-2">
        <clone-order-form :config="config" />
      </div>
    </v-tab>

    <!-- <v-tab title="Import z UVO">
        <div class="px-3 pt-4 pb-2">
          <import-order-form :config="config" />
        </div>
      </v-tab> -->
  </vue-tabs>
</template>

<script>
import NewOrderForm from './NewOrderForm.vue'
import CloneOrderForm from './CloneOrderForm.vue'
/* import ImportOrderForm from './ImportOrderForm.vue' */

export default {
  name: 'OrderModalTabs',
  components: { NewOrderForm, CloneOrderForm },
  props: {
    config: Object
  },
  data () {
    return {
      activeTab: '',
      actions: {
        new: '',
        clone: 'Klonovať zákazku'
        /* import: 'Import z UVO' */
      }
    }
  },
  created () {
    if (this.config.action) {
      this.activeTab = this.actions[this.config.action]
    }
  }
}
</script>
