export function hexToRgb (hex) {
  hex = hex.replace('#', '')
  if (hex.length === 3) {
    const [r, g, b] = hex
    hex = [r, r, g, g, b, b].join('')
  }
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return [r, g, b]
}

export function colorVars (color, name = 'color') {
  if (color.startsWith('#')) {
    return {
      [`--${name}-rgb`]: hexToRgb(color).join(','),
      [`--${name}`]: color
    }
  }
  return {
    [`--${name}-rgb`]: `var(--color-${color}-rgb)`,
    [`--${name}`]: `var(--color-${color})`
  }
}

export function colorVar (color, name = 'color') {
  if (color.startsWith('#')) {
    return {
      [`--${name}`]: color
    }
  }
  return {
    [`--${name}`]: `var(--color-${color})`
  }
}

export function interpolate (color1, color2, weight) {
  if (color1.startsWith('#')) {
    color1 = hexToRgb(color1)
  }
  if (color2.startsWith('#')) {
    color2 = hexToRgb(color2)
  }
  const w2 = weight
  const w1 = 1 - w2
  return [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ]
}
