<template>
  <form id="remove-commission-member-form">
    <div class="row form-group">
      <label class="col-md-3 control-label" for="date-of-appeal">Dátum odvolania <small>*</small></label>
      <div class="col-md-9">
        <date-input name="date-of-appeal" v-model="dateOfAppeal" :value="dateOfAppeal"></date-input>
        <label v-if="form.dateOfAppeal.error" id="firstname-error" class="error" for="date-of-appeal">Dátum musí byť vyplnený.</label>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-md-3 control-label" for="reason-of-appeal">Dôvod odvolania <small>*</small></label>
      <div class="col-md-9">
        <textarea id="reason-of-appeal" v-model="reasonOfAppeal"  class="form-control"></textarea>
        Počet zostávajúcich znakov: {{ reasonOfAppealTextRemaining }}
        <label v-if="form.reasonOfAppeal.error" class="error" for="reason-of-appeal">Dôvod musí byť vyplnený.</label>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close(null)"><i class="icon-close"></i> Zavrieť</button>
      <button class="btn btn-tenderia-success" @click.prevent="save()"><i class="icon-save"></i> Uložiť</button>
    </div>
  </form>
</template>

<script>
import DateInput from '@/components/forms/DateInput.vue'
export default {
  name: 'CommissionMemberRemove',
  components: { DateInput },
  props: ['member'],
  data: function () {
    return {
      reasonOfAppeal: '',
      dateOfAppeal: '',
      form: {
        reasonOfAppeal: {
          error: false,
          valid: false
        },
        dateOfAppeal: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    reasonOfAppealTextRemaining: function () {
      return 4000 - this.reasonOfAppeal.length
    }
  },
  methods: {
    validateReasonOfAppeal: function () {
      var state = this.form.reasonOfAppeal
      state.error = state.valid = false
      if (this.reasonOfAppeal.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    validateDateOfAppeal: function () {
      var state = this.form.dateOfAppeal
      state.error = state.valid = false
      if (this.dateOfAppeal.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    validate: function () {
      this.validateReasonOfAppeal()
      this.validateDateOfAppeal()

      return this.form.reasonOfAppeal.valid && this.form.dateOfAppeal.valid
    },
    showLoading: function () {
      $('#remove-commission-member-form').LoadingOverlay('show')
    },
    hideLoading: function () {
      $('#remove-commission-member-form').LoadingOverlay('hide')
    },
    close: function (data) {
      this.$emit('vuedals:close', data)
    },
    save: function () {
      if (!this.validate()) {
        return
      }
      this.showLoading()
      var data = {
        order: {
          id: this.$route.params.orderId
        },
        member: {
          id: this.member._id,
          state: 'deleted',
          event: {
            type: 'denomination',
            note: this.reasonOfAppeal,
            validTo: this.dateOfAppeal
          }
        }
      }
      var vm = this
      this.$http.post('/order/member/set', data).then(function () {
        vm.hideLoading()
        vm.close('removed')
        vm.PopupAlert({ text: 'Zmeny boli uložené.' })
      }).catch((error) => {
        vm.hideLoading(null)
        vm.close()
        vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error?.response?.data.error)
      })
    }
  }
}
</script>
