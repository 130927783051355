<template>
  <div class="mt-3">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">Moje organizácie</h4>
      </div>
      <div>
        <v-table
          :items="relationsItems"
          :columns="relationsColumns"
          item-key="id"
          class="light"
        >
          <template v-slot:cell(organizationName)="{ item }">
            <div class="row no-gutters align-items-center">{{ item.name }}<i v-if="item.type === 'home'" title="Domáca organizácia" class="icon-home ml-1"></i></div>
          </template>
        </v-table>
        <div class="card-footer">
          <v-pagination
            v-if="relationsTotal"
            :url-navigation="false"
            :page.sync="page"
            :per-page.sync="perPage"
            :per-page-options="pageSizeOptions"
            :total-elements="relationsTotal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '../ui/Table.vue'
import VPagination from '../ui/Pagination.vue'

export default {
  name: 'UserOrganizations',
  components: {
    VTable,
    VPagination
  },
  data () {
    return {
      relations: [],
      relationsTotal: 0,
      page: 1,
      perPage: 10,
      showEdit: false,
      showLoading: true,
      showStats: false
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    relationsColumns () {
      return [
        {
          key: 'organizationName',
          label: 'Názov'
        }
      ]
    },
    relationsItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.relations && this.relations.slice(from, to)
    }
  },
  created () {
    this.reloadOrganizations()
  },
  methods: {
    openEdit () {
      this.showEdit = true
    },
    closeEdit () {
      this.showEdit = false
    },
    async reloadOrganizations () {
      let homeOrganizationId = this.$globals.user.relations.find(r => r.type === 'home').organization
      this.relations = this.$globals.user.organizations
      this.relations.find(org => org.id === homeOrganizationId).type = 'home'
      this.relationsTotal = this.relations.length
      this.showLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
i {
  font-size: 18px;
  line-height: 14px;
}
</style>
