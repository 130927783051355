import { Uploader } from '@/components/tenderia-uploader.js'
var db = require('mime-db')
var mime = require('mime-type')(db)
import { Bus } from '@/plugins/TenderiaProgressModal'

export default class UploadManager {
  constructor(onDone, onError, fields, transport, authorization) {
    this.files = []
    this.uploadedFiles = []
    this.current = 0
    this.onDone = onDone
    this.uploadStatus = {}
    this.$http = transport
    this.fields = fields
    this.onError = onError
    this.uploadToken = ''
    this.authorization = authorization || localStorage.getItem('token')
    this.offset = 1
  }

  getFiles() {
    return this.files
  }

  getFileType(index) {
    return this.files[index].type
  }

  startUpload(files, options) {
    this.files = files
    this.uploadedFiles = []
    this.next(0)
    if (options && options.totalCount) {
      this.uploadStatus.total = options.totalCount
    } else {
      this.uploadStatus.total = files.length
    }
    if (options && options.offset) {
      this.offset = options.offset + 1
    }
  }

  next(nr) {
    this.current = nr
    try {
      if (nr < this.files.length) {
        this.setFile(nr)
      } else {
        this.onDone(this.uploadedFiles.map(item => item.id))
      }
    }
    catch (error) {
      console.log(error)
      this.onError(error)
    }
  }

  setStatusMessage(nr, percent) {
    try {
      this.uploadStatus.currentIndex = nr + this.offset
      this.uploadStatus.currentName = this.files[nr].name
      this.uploadStatus.currentPercent = percent
      Bus.$emit('upload:status', this.uploadStatus)
    } catch (error) {
      console.log(error)
      this.onError(error)
    }
  }

  async setFile(nr) {
    this.setStatusMessage(nr, 0)
    try {
      var data = { file: Object.assign({}, this.fields) }
      if (!data.file.name) data.file.name = this.files[nr].name
      data.file.mime = mime.lookup(this.files[nr].name)
      const response = await this.$http.post('/file/set', data)
      this.uploadedFiles[nr] = { index: nr, id: response.data.id, mime: data.file.mime, token: response.data.uploadToken }
      this.uploadFile(nr)
    } catch (error) {
      console.log(error)
      this.uploadedFiles[nr] = { index: nr, error: error }
      this.onError(error)
    }
  }

  uploadFile(nr) {
    let uploader = new Uploader({ authorization: this.authorization, chunkSize: 524288 })
    uploader.on('progress', function onProgress(evt, id, message) {
      this.setStatusMessage(nr, message)
    }.bind(this))
    uploader.on('complete', function onComplete() {
      this.setStatusMessage(nr, 100)
      this.next(nr + 1)
    }.bind(this))

    try {
      const uploadToken = this.uploadedFiles[nr]?.token
      uploader.on('error', this.onError)
      uploader.on('abort', this.onError)
      uploader.addFile(this.files[nr], process.env.VUE_APP_BACKEND_URL + '/file/upload', uploadToken)
      uploader.start()
    } catch (error) {
      console.log(error)
      this.onError(error)
    }
  }
}
